import { useEffect, useState } from "react";
import { getAllLessonsOfMyOtherTeachers } from "../../services/lessonsHelper";
import DataTable from 'react-data-table-component';
import moment from "moment";
import { useAuth } from "../../authContext";
import { useNavigate } from "react-router-dom";
import { sendFriendRequest } from "../../services/friendRequestHelper";
import { LANGUAGELEVEL } from "../../utils/data";
import { useTranslation } from "react-i18next";

const LessonListOthers = () => {
    const { t } = useTranslation();
    const [lessons, setLessons] = useState([]);
    const { user } = useAuth();
    const navigate = useNavigate();
    const [filterLevel, setFilterLevel] = useState('all');
    const filteredLessons = lessons.filter(item => {
        if (filterLevel === "all") {
            return item;
        } else {
            return item.level && item.level.includes(filterLevel)
        }
    });

    const hanldeSendFriendRequest = async (lesson) => {        
        await sendFriendRequest(lesson.uid, user.uid, lesson.author, user.displayName, user.photoURL, "teacher", "").then(() => {
            const lessonUpdated = lessons.filter(l => l.id !== lesson.id);
            setLessons(lessonUpdated);
        });
    }

    const COLUMNS = [{ name: t("lesson_column_title"), selector: row => <span title={row.title}>{row.title}</span> },
    { name: t("lesson_column_author"), selector: row => <span title={row.author}>{row.author}</span> },
    { name: t("lesson_column_level"), selector: row => <span title={t(row.level)}>{t(row.level)}</span> },
    { name: t("lesson_column_description"), selector: row => <span title={row.description}>{row.description}</span> },
    { name: t("lesson_column_price"), selector: row => row.price ?? 0, sortable: true },
    { name: t("lesson_column_startAt"), cell: row => (<span>{moment(row.startDate.toDate()).format('DD/MM/YYYY hh:mm:ss a')}</span>) },
    { name: t("lesson_column_endAt"), cell: row => (<span>{moment(row.endDate.toDate()).format('DD/MM/YYYY hh:mm:ss a')}</span>) },
    {
        cell: row => (
            <>
                {!row?.friendRequestSend && <button className="btn btn-light mr-1" title={t("lesson_send_friend_request")} onClick={() => hanldeSendFriendRequest(row)}><i class="ri-user-add-line"></i></button>}
            </>
        )
    }];
    
    const loadLessons = async (uid) => {
        await getAllLessonsOfMyOtherTeachers(uid).then((lessons) => setLessons(lessons));
    }

    useEffect(() => {
        loadLessons(user.uid);
    }, []);

    return (
        <div className="iq-card px-2">
            <div className="iq-card-header">
                <div className="row justify-content-between">
                    <div class="col-sm-12 col-md-6">
                        <h2 className="pt-2">{t("lesson_all")}</h2>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div className="d-flex float-right">
                        <button onClick={() => navigate("/lessons/my")} className="btn btn-primary mt-2">{t("lesson_title_my")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iq-card-body">
                <div className="containder">
                    <div className="row">
                    <div className="form-row mr-2">
                            <div className="form-group form-inline">
                                <label htmlFor="level" className="mr-2">{t("lesson_level")}</label>
                                <select id="level" name="level" className="form-control" onChange={(e) => setFilterLevel(e.target.value)}>
                                    <option value="all" selected>Todos</option>
                                    {LANGUAGELEVEL.map((level) => <option value={level.value}>{t(level.label)}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <DataTable columns={COLUMNS} data={filteredLessons} pagination paginationComponentOptions={{ rowsPerPageText: t("datatable_rows_PerPageText") }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LessonListOthers;