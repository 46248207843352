import { collection, orderBy, startAfter, limit, query, where, addDoc, deleteDoc, getDocs, doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebaseConfig";

/**
 * Like posts
 */

export const getLikebyPostId = async (uid, pid) => {
    let dataLikes = [];
    try {
        const q = query(collection(db, `users/${uid}/posts/${pid}/likes`));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            dataLikes.push({id: doc.id, ...doc.data()});
        });
    } catch (error) {
        console.error('Error |getCommentByPostId| :',error);
        return false;
    }
    return dataLikes;
}

export const previousPostLike = async (uid, pid, userId) => {
    let dataLikes = [];
    try {
        const q = query(collection(db, `users/${uid}/posts/${pid}/likes`),
                    where("uid", "==", userId)
                    );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            dataLikes.push({id: doc.id, ...doc.data()});
        });
    } catch (error) {
        console.error('Error |previousLike| :',error);
        return false;
    }
    return dataLikes;
}

export const createPostLike = async (uid, pid, like) => {
    let dataLikes = {};
    try {
        dataLikes = await addDoc(collection(db, `users/${uid}/posts/${pid}/likes`), like);
    } catch (error) {
        console.error('Error |createPostLike| :',error);
        return false;
    }
    return dataLikes;
}

export const deletePostLike = async (uid, pid, lid) => {
    try {
        await deleteDoc(doc(db, `users/${uid}/posts/${pid}/likes`, lid));
    } catch (error) {
        console.error('Error |deletePostLike| :',error);
        return false;
    }
    return true;
}

/**
 * Like Comments
 */
 export const getLikebyCommentId = async (uid, pid, cid) => {
    let dataLikes = [];
    try {
        const q = query(collection(db, `users/${uid}/posts/${pid}/comments/${cid}/likes`));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            dataLikes.push({id: doc.id, ...doc.data()});
        });
    } catch (error) {
        console.error('Error |getLikebyCommentId| :',error);
        return false;
    }
    return dataLikes;
}

export const previousCommentLike = async (uid, pid, cid, userId) => {
    let dataLikes = [];
    try {
        const q = query(collection(db, `users/${uid}/posts/${pid}/comments/${cid}/likes`),
                    where("uid", "==", userId)
                    );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            dataLikes.push({id: doc.id, ...doc.data()});
        });
    } catch (error) {
        console.error('Error |previousCommentLike| :',error);
        return false;
    }
    return dataLikes;
}

export const createCommentLike = async (uid, pid, cid, like) => {
    let dataLikes = {};
    try {
        dataLikes = await addDoc(collection(db, `users/${uid}/posts/${pid}/comments/${cid}/likes`), like);
    } catch (error) {
        console.error('Error |createCommentLike| :',error);
        return false;
    }
    return dataLikes;
}

export const deleteCommentLike = async (uid, pid, cid, lid) => {
    try {
        await deleteDoc(doc(db, `users/${uid}/posts/${pid}/comments/${cid}/likes`, lid));
    } catch (error) {
        console.error('Error |deleteCommentLike| :',error);
        return false;
    }
    return true;
}

export const uploadComment = async (uid, file) => {
    const storageRef = ref(storage, `posts/${uid}/comments/${file.name}`);
    const result = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
}