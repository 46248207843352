import { query } from "firebase/database";
import { collection, getDocs, limit, orderBy, startAfter, where } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const getCourses = async (language, nextValues = false, lastVisible = null) => {
    let result = [];
    let documents = [];

    let path = "cursos/wVfuT5wJvkMjkJACVpzO/videos";
    if(language === "french"){
        path = "cursos/xfe1vbeu9sQCGfryzTfh/videos";
    }else if(language === "german"){
        path = "cursos/g8HzKg3qtSx5tk6n2CFg/videos";
    }

    try {
        if(!nextValues){
            const queryDocs = query(collection(db, path), orderBy("orden"), limit(27));
            documents = await getDocs(queryDocs);
        }else{
            const queryDocs = query(collection(db, path), orderBy("orden"), startAfter(lastVisible.orden), limit(27))
            documents = await getDocs(queryDocs);
        }

        documents.forEach((doc) => {
            result.push({ id: doc.id, ...doc.data() });
        });
    } catch (error) {
        console.error('Error |getCourses| :', error);
    }
    return result;
}

export const getCoursesByLevel = async (language, level, nextValues = false, lastVisible = null) => {
    let result = [];
    let documents = [];

    let path = "cursos/wVfuT5wJvkMjkJACVpzO/videos";
    if(language === "french"){
        path = "cursos/xfe1vbeu9sQCGfryzTfh/videos";
    }else if(language === "german"){
        path = "cursos/g8HzKg3qtSx5tk6n2CFg/videos";
    }

    try {
        if(!nextValues){
            const queryDocs = query(collection(db, path), where("nivel", "==", level), orderBy("orden"), limit(27));
            documents = await getDocs(queryDocs);
        }else{
            const queryDocs = query(collection(db, path), where("nivel", "==", level), orderBy("orden"), startAfter(lastVisible.orden), limit(27))
            documents = await getDocs(queryDocs);
        }

        documents.forEach((doc) => {
            result.push({ id: doc.id, ...doc.data() });
        });
    } catch (error) {
        console.error('Error |getCoursesByLevel| :', error);
    }
    return result;
}