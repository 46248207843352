import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'

import { useTranslation } from "react-i18next";
import { isEmailTaken, resetPassword } from "../services/authHelper";
import { isValidEmail } from "./Utils";


export const ModalPassword = (props) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
  
    const recoverPassword = async () => {
        setDisableButton(true);
        const { data } = await isEmailTaken(email);
        if(data === false) {
            alert('No existe ese usuario');
            setDisableButton(false);
        } else {
            const result = await resetPassword(email);
            if(result) {
                alert('Revisa tu correo.')
                setDisableButton(false);
            } else {
                alert('Error en el sistema, intente más tarde por favor.')
            }
        }
    }
    
    const validateEmail = (email) => {
        setEmail(email);
        setIsValid(isValidEmail(email));
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {t('login_recover_pass_title')}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t('login_recover_pass_body')}</p>
                <div className="form-group">
                    <input type="email" id="correo" onChange={(e) => validateEmail(e.target.value)} className="form-control mb-0" placeholder={t('login_email')} />
                </div>
                {disableButton && 
                    <div className="text-center">
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="visually-hidden"></span>
                        </Spinner>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                {isValid > 0 &&
                    <Button onClick={recoverPassword} disabled={disableButton}>{t('button_send')}</Button>
                }
                <Button className="btn-danger" onClick={props.onHide}>{t('button_close')}</Button>
            </Modal.Footer>
        </Modal>
    );
  }