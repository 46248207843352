import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext";
import { getStudentLessons, getTeacherLessons, updateLesson } from "../../services/lessonsHelper";
import DataTable from 'react-data-table-component';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { confirmDelete } from "../Utils";
import { getUserByUid } from "../../services/authHelper";
import { LANGUAGELEVEL } from "../../utils/data";
import { bookLesson } from "../../services/calendarHelper";
import { MDetalleClase } from "./MDetalleClase";

const MyLesson = () => {
    const { t } = useTranslation();
    const { user, userData, setUserData } = useAuth();
    const [lessons, setLessons] = useState([]);
    const navigate = useNavigate();
    const [filterLevel, setFilterLevel] = useState('all');
    const [mostrarDetalle, setMostrarDetalle] = useState(false);
    const [infoClase, setInfoClase] = useState(null);

    const filteredLessons = lessons.filter(item => {
        if (filterLevel === "all") {
            return item;
        } else {
            return item.level && item.level.includes(filterLevel)
        }
    });

    const loadLessons = async (uid) => {
        let currentUserData = userData || null;
        if (userData == null) {
            currentUserData = await getUserByUid(uid);
            setUserData(currentUserData);
        }
        if (currentUserData.userType === "teacher") {
            await getTeacherLessons(uid, setLessons);
        } else if (currentUserData.userType === "student") {
            await getStudentLessons(uid, setLessons);
        }
    }

    const deleteLesson = async (lesson) => {
        const text = `${t("lesson_alert_acction_delete")} ${lesson.title}`
        confirmDelete({
            icon: "warning", title: t("lesson_alert_delete_title"), text: text, fn: async (result) => {
                if (result.isConfirmed) {
                    await updateLesson({ ...lesson, isActive: false });
                }
            }
        });
    }

    const handleScheduleLesson = async (lesson) => {
        const uid = user.uid;
        const eventData = {
            uid: uid, 
            id: lesson.id,
            title: lesson.title, 
            description: `${lesson.author} ${lesson.description}`,
            dateStart: lesson.startDate,
            dateEnd: lesson.endDate,
            color: "#FF0000"
        }
        await bookLesson(eventData).then(() => {
            const lessonUpdated = filteredLessons.map(l => {
                if(l.id === lesson.id){
                    l.isScheduled = true;
                    return l;
                }
                return l;
            });
            setLessons(lessonUpdated);
        });
    }

    const mostrarDetalleClase = (row) => {
        setMostrarDetalle(!mostrarDetalle);
        setInfoClase(row);
    }

    const COLUMNS = [{ name: t("lesson_column_title"), selector: row => <span title={row.title}>{row.title}</span> },
    { name: t("lesson_column_description"), selector: row => <span title={row.author}>{row.author}</span> },
    { name: t("lesson_column_level"), selector: row => t(row.level), sortable: true },
    { name: t("lesson_column_price"), selector: row => row.price, sortable: true },
    { name: t("lesson_column_startAt"), cell: row => (<span>{moment(row.startDate.toDate()).format('DD/MM/YYYY hh:mm a')}</span>) },
    { name: t("lesson_column_endAt"), cell: row => (<span>{moment(row.endDate.toDate()).format('DD/MM/YYYY hh:mm a')}</span>) },
    {
        cell: row => (
            <>
                {userData && userData.userType === "teacher" && <>
                    <button className="btn btn-light mr-1" title={t("lesson_edit_class")} onClick={() => navigate(`/lessons/${row.id}/edit`)}><i class="ri-pencil-line"></i></button>
                    <button className="btn btn-light mr-1" title={t("lesson_manage_class")} onClick={() => navigate(`/lessons/${row.id}/students`)}><i class="ri-user-add-line"></i></button>
                    {/* <button className="btn btn-light mr-1" title={t("lesson_start_class")} onClick={() => window.open(`/lessons/${row.id}/class`)}><i class="ri-arrow-right-line"></i></button> */}
                    <button className="btn btn-light mr-1" title="Detalle" onClick={() => mostrarDetalleClase(row)}><i class="fa fa-eye"></i></button>
                    <button className="btn btn-light mr-1" title={t("lesson_delete_class")} onClick={() => deleteLesson(row)}><i class="ri-delete-bin-line"></i></button>
                </>}
                {userData && userData.userType === "student" && <>
                    {/* {row.approved && <button title={t("lesson_start_class")} className="btn btn-light mr-1" onClick={() => window.open(`/lessons/${row.id}/class`)}><i class="ri-arrow-right-line"></i></button>} */}
                    <button className="btn btn-light mr-1" title="Detalle" onClick={() => mostrarDetalleClase(row)}><i class="fa fa-eye"></i></button>
                    {!row.isScheduled && <button title={t("lesson_schedule_class")} className="btn btn-light mr-1" onClick={() => handleScheduleLesson(row)}><i class="ri-calendar-event-line"></i></button>}
                </>}
            </>
        )
    }];

    useEffect(() => {
        loadLessons(user.uid);
    }, []);

    return (
        <div className="iq-card px-2">
            <div className="iq-card-header">
                <div className="row justify-content-between">
                    <div class="col-sm-12">
                        <div className="d-flex float-right">
                            {userData && userData.userType === "student" &&
                                (
                                    <>
                                        <span className="mt-3 mr-2">{t("lesson_available_lessons")}</span>
                                        <button onClick={() => navigate("/lessons/all")} className="btn btn-primary mt-2 mr-1">{t("lesson_my_teachers")}</button>
                                        <button onClick={() => navigate("/lessons/others")} className="btn btn-primary mt-2">{t("lesson_my_others_teachers")}</button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="iq-card-body">
                <div className="containder">
                    <div className="row">
                        <div className="form-row mr-2">
                            <div className="form-group">
                                <select id="level" name="level" className="form-control" onChange={(e) => setFilterLevel(e.target.value)}>
                                    <option value="all" selected>{t("lesson_language_all")}</option>
                                    {LANGUAGELEVEL.map((level) => <option value={level.value}>{t(level.label)}</option>)}
                                </select>
                            </div>
                        </div>
                        {userData && userData.userType === "teacher" && <>                            
                            <div className="col-auto">
                                <button className="btn btn-primary" onClick={() => navigate("/lessons/new")}><i class="ri-add-fill"></i> {t("lesson_add")}</button>
                            </div>
                            <div className="col">
                                <div className="float-right">
                                    <p><b>{t("lesson_class_collected")}: $</b> {userData.infoPagos?.porpagar || 0} <b>{t("lesson_class_paid")}: $</b> {userData.infoPagos?.pagadas || 0}</p>
                                </div>                                
                            </div>
                        </>}
                        <div className="table-responsive">
                            {lessons && lessons.length > 0 && <DataTable columns={COLUMNS} data={filteredLessons} pagination paginationComponentOptions={{ rowsPerPageText: t("datatable_rows_PerPageText") }} />}
                            {infoClase && <MDetalleClase show={mostrarDetalle} onHide={()=> setMostrarDetalle(false)} infoClase={infoClase} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyLesson;