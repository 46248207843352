import { Button, Modal } from "react-bootstrap"
import { acceptedVideoCall, cancelVideoCall } from "../../services/videocallHelper";

const IncomingCall = (props) => {
    
    return (
        <Modal className="zitop" show={props.showIncomingCall}>
            <Modal.Body> <h4>LLamada entrante de <b>{props.incomingCall.callerName}</b></h4>
            </Modal.Body>
            <Modal.Footer>
                <Button className="rechazar" variant="danger" onClick={() => cancelVideoCall(props.incomingCall, props.onHide)} >
                    Rechazar
                </Button>
                <Button className="aceptar" variant="primary" onClick={() => acceptedVideoCall(props.incomingCall, props.onHide)}>
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default IncomingCall