import { collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { db } from "../firebaseConfig";

const collectionName = "ejercicios";

export const getAllExercises = async (uid, setExercises, language, isPremium) => {
    console.log("isPremium", isPremium, language);
    let q = isPremium ? query(collection(db, collectionName)) : query(collection(db, collectionName), where("tipo", "==", "2"));
    if(language && language !== ""){
        q = isPremium ? query(collection(db, collectionName), where("idioma", "==", language))
        : query(collection(db, collectionName), where("idioma", "==", language), where("tipo", "==", "2"));
    }

    const unsubscribe = onSnapshot(q, (snapshot) => {
        const exercises = [];
        snapshot.forEach((doc) => {
            exercises.push({ id: doc.id, ...doc.data() });
        });
        if(exercises.length === 0 && language !== "english"){
            getAllExercises(uid, setExercises, "english", isPremium);
        }else{
            markUserExercise(uid, exercises).then((result) => setExercises(result));
        }
    });

    return () => unsubscribe();
}

export const markUserExercise = async(uid, exercises) => {
    let result = [];
    const userExercices = await getUserExercises(uid);
    for (const exercise of exercises) {
        const isEvaluated = userExercices.find(e => e.id === exercise.id);
        exercise.isEvaluated = isEvaluated ? "exercises_completed_yes" : "exercises_completed_no";
        exercise.urlStatus = isEvaluated ? "https://pangulearning.com/images/ecompletado.png" : "https://pangulearning.com/images/enocompletado.png";
        result.push(exercise);
    }
    return result;
}

export const getExerciseById = async (id) => {
    let exercise = {};
    const docRef = doc(db, collectionName, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        exercise = docSnap.data()
    }
    return exercise;
}

export const getQuestions = async (id, orderby = false) => {
    let questions = [];
    try {
        const snapshot = await getDocs(collection(db, `${collectionName}/${id}/preguntas`));
        snapshot.forEach((doc) => {
            questions.push({ id: doc.id, ...doc.data() })
        });
        if(orderby){
            const orderedQuestions = questions.sort((a, b) => a.orden - b.orden);
            return orderedQuestions;
        }
    } catch (error) {
        console.log("error getQuestions", error);
    }
    return questions;
}

export const markEvaluatedExcercise = async(uid, exerciseId) => {
    try {
        const docRef = doc(db,`users/${uid}`, collectionName, exerciseId);
        await setDoc(docRef, { idejercicio: exerciseId,terminado: "1"})
    } catch (error) {
        console.log("error markEvaluatedExcercise", error);
    }
}

export const getUserExercises = async(uid) => {
    let questions = [];
    try {
        const snapshot = await getDocs(collection(db, `users/${uid}/${collectionName}`));
        snapshot.forEach((doc) => {
            questions.push({ id: doc.id, ...doc.data() })
        });
    } catch (error) {
        console.log("error getUserExercises", error);
    }
    return questions;
}