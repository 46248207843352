import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const getBankList = async() => {
    let banks = [];
    try {
        const q = query(collection(db, 'bancos'), orderBy("orden"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            banks.push({ id: doc.id, ...doc.data() })
        });
    } catch (error) {
        console.log("error getBankList", error);
    }
    return banks;
}