import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { updateUser } from "../../services/authHelper";
import { ErrorMessage } from "../ErrorMessage";

const SocialNetwork = ({ userData, setUserData }) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    
    const handleSocialChange = (evt) => {
        evt.preventDefault();
        const { name, value } = evt.target;
        const rawValue = value.trim();
        if (rawValue === "") {
            setErrors({ ...errors, [name]: { message: { key: "validations_required" } } })
        }
        else {
            const accounts = {...userData["accounts"]};
            accounts[name] = value; 
            setErrors({ ...errors, [name]: undefined })
            setUserData({ ...userData, accounts: accounts });
        }
    }

    const handleOnSubmit = async (evt) => {
        evt.preventDefault();
        setIsSubmitting(true);
        try {
            await updateUser(userData);
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <>
            <h4>{t("profile_social_tab")}</h4>
            <hr />
            <div className="row">
                <div className="iq-card-body">
                    <form onSubmit={handleOnSubmit} className="formview">
                        <div className="row">
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="facebook">Facebook</Form.Label>
                                <b>{userData?.accounts?.facebook}</b><a target="_blank" href={userData?.accounts?.facebook} className="btn btn-sm btn-primary">{t("perfil_visit")}</a>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="ios">IOS</Form.Label>
                                <b>{userData?.accounts?.iOS}</b><a target="_blank" href={userData?.accounts?.iOS} className="btn btn-sm btn-primary">{t("perfil_visit")}</a>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="instagram">Instagram</Form.Label>
                                <b>{userData?.accounts?.instagram}</b><a target="_blank" href={userData?.accounts?.instagram} className="btn btn-sm btn-primary">{t("perfil_visit")}</a>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="twitter">Twitter</Form.Label>
                                <b>{userData?.accounts?.twitter}</b><a target="_blank" href={userData?.accounts?.twitter} className="btn btn-sm btn-primary">{t("perfil_visit")}</a>
                            </Form.Group>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SocialNetwork;