import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../authContext";
import { ensureUserLoaded } from "../../services/userHelper";

const VideoLesson = () => {
    const { lessonId } = useParams();
    const { user, userData, setUserData } = useAuth();

    useEffect(() => {
        const init = async () => {
            const uData = await ensureUserLoaded(user.uid, userData, setUserData);
            let timeoutID;
            if (!uData?.subscription?.isPremium) {
                timeoutID = setTimeout(() => {
                    window.close();
                }, 1800000);
            }else{
                if(timeoutID){
                    clearTimeout(timeoutID);
                }
            }
            const root = document.getElementById("meet");

            var domain = 'meet.jit.si';
            var options = {
                roomName: lessonId,
                width: 800,
                height: 800,
                parentNode: root, userInfo: {
                    email: user.email,
                    displayName: user.displayName
                }
            };
            new window.JitsiMeetExternalAPI(domain, options);

            return () => {
                if(timeoutID){
                    clearTimeout(timeoutID);
                }
            };
        }

        init();

    }, [lessonId, user]);

    return (<div id="meet"></div>);
}

export default VideoLesson;