import React, { useState } from 'react'
import { useAudioRecorder } from '@sarafhbk/react-audio-recorder'
import { RecordFill, StopFill, XCircle } from 'react-bootstrap-icons';
import { sendAudio } from '../../services/chatHelper';
import { useAuth } from '../../authContext';
import { deleteFileStorage, uploadFileToStorage } from '../../services/storageUtil';
import Spinner from 'react-bootstrap/esm/Spinner';

function Recording({ setIsRecording, toUserName, idChat }) {
  const { user } = useAuth()
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  async function audioSend(e) {
    if (audioResult) {
      e.preventDefault();
      setIsUploading(true);
      const audioBlob = await fetch(audioResult).then((r) => r.blob()).catch((error) => {
        setIsRecording(false);
        console.log("error", error);
        return;
      });
      const file = new File([audioBlob], 'voice.ogg', { type: 'audio/ogg' });
      const dTime = new Date().getTime()
      const path = `chats/${user.uid}/audios/${idChat}/${dTime}`
      uploadFileToStorage(path, file, { contentType: 'audio/ogg' }, setProgress).then((url) => {
        try {
          sendAudio({ userUid: user.uid, idChat, toUserName: toUserName, fromUserName: user.displayName, audioUrl: url });
        } catch (error) {
          deleteFileStorage(url);
        } finally {
          setIsRecording(false);
        }
      }).catch((error) => {
        console.log("uploadFileToStorage error", error);
      });
    }
  }

  const {
    audioResult,
    timer,
    startRecording,
    stopRecording
  } = useAudioRecorder()

  return (
    <>

      <div className="chat-footer p-3 bg-white d-flex align-items-center justify-content-between">
        {isUploading && <div>
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden"></span>
          </Spinner>
          <span>{progress}</span>
        </div>
        }
        <audio controls src={audioResult} />
        <form className='d-flex align-items-center' onSubmit={audioSend}>
          <h3 className='text-danger' style={{ cursor: 'pointer' }}>
            <StopFill
              className='mt-1 mx-2'
              onClick={stopRecording}
            />
          </h3>
          <h2 className='mt-2'>
            <div>{new Date(timer * 1000).toISOString().substr(11, 8)}</div>
          </h2>
          <div className='d-flex align-items-center'>
            <h3 className='text-dark' style={{ cursor: 'pointer' }}>
              <RecordFill
                className='mx-2 mt-1'
                onClick={startRecording}
              />
            </h3>
          </div>
          <div className='d-flex align-items-center'>
            <h3 className='text-primary mx-2 mb-1'>
              <button
                type='submit'
                className="btn btn-primary d-flex align-items-center p-2 mt-2">
                <i className="fa fa-paper-plane-o" aria-hidden="true"
                ></i>
              </button>
            </h3>
            <h2 className=''>
              <XCircle className='text-danger' style={{ cursor: 'pointer' }}
                onClick={() => { setIsRecording(false) }}
              />
            </h2>
          </div>
        </form>
      </div>
    </>
  )
}

export default React.memo(Recording)