import { deleteDoc, doc } from "firebase/firestore"
import { useEffect, useRef, useState } from "react"
import { Shuffle, Trash3Fill } from "react-bootstrap-icons"
import ReactPlayer from "react-player"
import { useAuth } from "../../authContext"
import { db } from "../../firebaseConfig"
import { traducir } from "../../services/chatHelper"
import { createImageFromInitials } from "../Utils"
import { deleteFileStorage } from '../../services/storageUtil';

const ChatContent = ({ idChat, messageList, avatarFrom}) => {
    const { user } = useAuth();
    const lastMessage = useRef();
    const myAvatar = user.photoURL ? user.photoURL : createImageFromInitials(50, user.displayName, '#50b5ff');
    const lang = localStorage.getItem('languaje') || "es"; 

    const handleMessageDelete = async (message) => {
      const { id, image, audio, video, messageType, from } = message;
      let isFile = false;
      if(messageType === "1" || image){
        isFile = true;
        await deleteFileStorage(image);
      }
      else if(messageType === "2" || audio){
        isFile = true;
        await deleteFileStorage(audio);
      }else if(messageType === "3" || video){
        isFile = true;
        await deleteFileStorage(video);
      }
      await deleteDoc(doc(db, `users/${user.uid}/chats/${idChat}/Messages/${id}`));
      if(isFile){
        await deleteDoc(doc(db, `users/${idChat}/chats/${user.uid}/Messages/${from}`));
      }
    }

    useEffect(() => {
        lastMessage.current?.scrollIntoView({ behaivor: 'smooth' })
      }, [messageList]);
    
    return (
        <>
          <div className="chat-content scroller">
            {messageList.map((message, index) => {
              if (message.type === 'send') {
                return (
                  <div ref={lastMessage} key={index}>
                    < div className="chat mt-1" >
                      <div className="chat-user mt-1 ">
                        <div className="avatar mt-4" >
                          <img src={myAvatar} alt="avatar" className="avatar-35 " />
                        </div>
                        <span className="chat-time mt-1">{message.dateUtc}</span>
                      </div>
                      <div className="chat-detail">
                        <div className="chat-message d-flex  align-items-center justify-content-end" >
                          <div className='mr-2 ' >
                            <h4>
                              <Trash3Fill onClick={() => handleMessageDelete(message)}
                              />
                            </h4>
                          </div>
                          <div className='mr-2' >
                            <h4>
                              <Shuffle key={index}
                                onClick={() => {
                                  traducir({ message: message.message, UserUid: user.uid, idChat, idMessage: message.id, lang })
                                }}
                              />
                            </h4>
                          </div>
    
                          <div className='d-flex flex-column justify-content-center '>
                            <p>
                              {message.message}
                            </p>
                            {message.image ?
                              <p><img src={message.image} height="200px" width="200px" alt='' ></img></p>
                              : <div style={{ visibility: 'hidden' }}></div>
                            }
                            {message.video ?
                              <div className='ml-auto bd-highlight'>
                                <ReactPlayer
                                  url={message.video}
                                  height="180px"
                                  width="300px"
                                  controls
                                />
                              </div>
                              : <></>}
                            {message.audio ?
                              <audio
                                controls src={message.audio}>
                              </audio>
                              : <></>}
                          </div>
                        </div>
                      </div>
                    </div >
                  </div>)
              } else if (message.type === 'received') {
                return (
                  <div ref={lastMessage} key={index}>
                    < div className="chat chat-left" >
                      <div className="chat-user">
                        <div className="avatar m-0">
                          <img src={avatarFrom} alt="avatar" className="avatar-35 " />
                        </div>
                        <span className="chat-time mt-1">{message.dateUtc}</span>
                      </div>
                      <div className="chat-detail">
                        <div className="chat-message d-flex align-items-center justify-content-center " >
                          <div>
                            <p className="mr-2">
                              {message.message}
                            </p>
                            {message.image ?
                              <p><img src={message.image} height="200px" width="200px" alt=''></img></p>
                              : null}
                            {message.video ?
                              <div className="ml-auto  bd-highlight">
                                <ReactPlayer
                                  url={message.video}
                                  height="180px"
                                  width="300px"
                                  controls
                                />
                              </div>
                              : null}
                            {message.audio ?
                              <audio controls src={message.audio}> </audio>
                              : <></>}
                          </div>
                          <div className='mr-2 ' >
                            <h4>
                              <Trash3Fill
                                onClick={() => {
                                  handleMessageDelete(message)
                                }}
                              />
                            </h4>
                          </div>
                          <div className='mr-2' >
                            <h4>
                              <Shuffle
                                onClick={() => {
                                  traducir({ message: message.message, UserUid: user.uid, idChat, idMessage: message.id, lang })
                                }}
                              />
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div >
                  </div>)
              }
              return null;
            })}
          </div>
        </>
      )
}

export default ChatContent;