import { useTranslation } from "react-i18next";

export const ErrorMessage = ({error}) => {
    const { t } = useTranslation();
    const errorStyle = {
        color: "#dc3545",
        width: "100%"
    }
    if (error === undefined) {
        return null;
    } else if (typeof error === 'string') {
        return <div style={errorStyle}>{error}</div>;
    } else {
        const { key, values } = error;
        return <div style={errorStyle}>{t(key, values)}</div>;
    }
}