import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { ErrorMessage } from "../ErrorMessage";
import { updateEvent } from "../../services/calendarHelper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const initialValues = {title: "", description: "", dateStart: "", dateEnd: "", color: ""};

export const UpdateEvents = (props) => {
  const { t } = useTranslation();
  const [event, setEvent] = useState(props.event);
  const [startDate, setStartDate] = useState(props.event.start);
  const [endDate, setEndDate] = useState(props.event.end);
  const [validation, setValidation] = useState(initialValues);

  const handleClose = () => { setEvent(initialValues); props.onHide();};

  const handleUpdateEvent = async(evt) => {
    evt.preventDefault();
    await checkValidation();
    if(!isDirty()){
      const { id, title, description, color } = event;
      const updatedEvent = {id, title, description, color,  dateStart: startDate, dateEnd: endDate};
      await updateEvent(updatedEvent);
      setEvent(null);
      handleClose();
    }
  }

  const handleOnChange = (property, value) => {
    setEvent({ ...event, [property]: value });
    if(value.trim() === ""){
      setValidation({...validation, [property]: `${t("validations_required")}`})
    }else{
      setValidation({...validation, [property]: ""})
    }
  }

  const isDirty = () => {
    let hasError = false;
    for (const item of Object.entries(validation)) {
      if(item[1].length > 0 ){
        hasError = true;
        break;
      }
    }
    return hasError;
  }

  const checkValidation = async() => {
    let errors = validation;
    if (event.title === "") {
      errors.title = `${t("validations_required")}`;
    } else {
      errors.title = "";
    }
    if (event.description === "") {
      errors.description = `${t("validations_required")}`;
    } else {
      errors.description = "";
    }
    if (event.color === "") {
      errors.color = `${t("validations_required")}`;
    } else {
      errors.color = "";
    }
    if (event.dateStart === "") {
      errors.dateStart = `${t("validations_required")}`;
    } else {
      errors.dateStart = "";
    }
    if (event.dateEnd === "") {
      errors.dateEnd = `${t("validations_required")}`;
    } else {
      errors.dateEnd = "";
    }

    setValidation(errors);
  };

  const filterStartDate = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const filterEndDate = (time) => {
    const selectedDate = new Date(time);
    return startDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("calendario_edit")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>{t("calendario_titulo")}</Form.Label>
                  <Form.Control
                    type="text"
                    id="title"
                    className="form-control mb-0"
                    placeholder={t("calendario_titulo")}
                    defaultValue={props.event.title}
                    isInvalid={!!validation.title}
                    onChange={(e) => handleOnChange("title", e.target.value)}
                  />
                  <ErrorMessage error={validation.title?.message} />
                  <ErrorMessage error={validation.title} />
                </Form.Group>
              </div>
              <div className="form-group col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>{t("calendario_descripcion")}</Form.Label>
                  <Form.Control
                    type="text"
                    id="description"
                    className="form-control mb-0"
                    placeholder={t("calendario_descripcion")}
                    defaultValue={props.event.description}
                    isInvalid={!!validation.description}
                    onChange={(e) => handleOnChange("description", e.target.value)}
                  />
                  <ErrorMessage error={validation.description} />
                </Form.Group>
              </div>
            </div>
            <div className="form-group">
              <label>{t("calendario_prioridad")}</label>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <label
                    className="flex justify-center items-center cursor-pointer hover:brightness-90 duration-200 w-8 h-8 rounded-md p1 m"
                    htmlFor="p1"
                  >
                    <Form.Check type="radio">
                      <Form.Check.Input
                        type="radio"
                        defaultChecked={props.event.color === "#f94176"}
                        onChange={(e) => handleOnChange("color", e.target.value)}
                        isInvalid={!!validation.color}
                        value="#f94176"
                        id="p1"
                        name="prioridad"
                      />
                      <Form.Check.Label style={{ color: "white" }} htmlFor="p1">
                        {t("c_prioridad1")}
                      </Form.Check.Label>
                    </Form.Check>
                  </label>
                </div>
                <div className="form-group col-md-3">
                  <label
                    className="flex justify-center items-center cursor-pointer hover:brightness-90 duration-200 w-8 h-8 rounded-md p2 m"
                    htmlFor="p2"
                  >
                    <Form.Check type="radio">
                      <Form.Check.Input
                        type="radio"
                        defaultChecked={props.event.color === "#50b5ff"}
                        onChange={(e) => handleOnChange("color", e.target.value)}
                        isInvalid={!!validation.color}
                        value="#50b5ff"
                        id="p2"
                        name="prioridad"
                      />
                      <Form.Check.Label style={{ color: "white" }} htmlFor="p2">
                        {t("c_prioridad2")}
                      </Form.Check.Label>
                    </Form.Check>
                  </label>
                </div>
                <div className="form-group col-md-3">
                  <label
                    className="flex justify-center items-center cursor-pointer hover:brightness-90 duration-200 w-8 h-8 rounded-md p3 m"
                    htmlFor="p3"
                  >
                    <Form.Check type="radio">
                      <Form.Check.Input
                        type="radio"
                        defaultChecked={props.event.color === "#663387"}
                        onChange={(e) => handleOnChange("color", e.target.value)}
                        isInvalid={!!validation.color}
                        value="#663387"
                        id="p3"
                        name="prioridad"
                      />
                      <Form.Check.Label style={{ color: "white" }} htmlFor="p3">
                        {t("c_prioridad3")}
                      </Form.Check.Label>
                    </Form.Check>
                  </label>
                </div>
                <div className="form-group col-md-3">
                  <label
                    className="flex justify-center items-center cursor-pointer hover:brightness-90 duration-200 w-8 h-8 rounded-md p4 m"
                    htmlFor="p4"
                  >
                    <Form.Check type="radio">
                      <Form.Check.Input
                        type="radio"
                        defaultChecked={props.event.color === "#f2cc21"}
                        onChange={(e) => handleOnChange("color", e.target.value)}
                        isInvalid={!!validation.color}
                        value="#f2cc21"
                        id="p4"
                        name="prioridad"
                      />
                      <Form.Check.Label style={{ color: "white" }} htmlFor="p4">
                        {t("c_prioridad4")}
                      </Form.Check.Label>
                    </Form.Check>
                  </label>
                </div>
              </div>
              <ErrorMessage error={validation.color} />
            </div>
            <div className="form-row">
            <div className="form-group col-sm-6">
              <label htmlFor="dateStart">{t("calendario_inicio")}</label>
              <div className="form-row">
                <div className="form-group ">
                <DatePicker className="form-control" dateFormat="d/MM/yyyy h:m aa" showTimeSelect selected={startDate} minDate={startDate}
                  onChange={(date) => { setStartDate(date); handleOnChange("dateStart", date.toISOString());}}
                  filterTime={filterStartDate} />
                  <ErrorMessage error={validation.dateStart} />
                </div>
              </div>
            </div>

            <div className="form-group col-sm-6">
              <label htmlFor="dateEnd">{t("calendario_final")}</label>
              <div className="form-row">
                <div className="form-group">
                  <DatePicker className="form-control" dateFormat="d/MM/yyyy h:m aa" showTimeSelect selected={endDate} minDate={startDate}
                  onChange={(date) => { setEndDate(date); handleOnChange("dateEnd", date.toISOString());}}
                  filterTime={filterEndDate} /> 
                  <ErrorMessage error={validation.dateEnd} />
                </div>
              </div>
            </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdateEvent}>{t("button_send")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
