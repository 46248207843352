import { t } from "i18next";
import { useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useAuth } from "../../authContext";
import { ShowSwalAlert } from "../../components/Utils";
import { processPay } from "../services/userService";

export const ProfesorPay = (props) => {
    const { user } = useAuth();
    const [amount, setAmount] = useState(0);
    const [file, setFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const refFileInput = useRef(null);

    const handleAmountChange = event => {
        const value = event.target.value;
        const result = parseInt(value) ? parseInt(value) : '';
        setAmount(result);
    };

    const onHide = () => {
        setAmount(0);
        setFile(null);
        setFileDataURL(null);
        props.onHide();
    }

    const pay = async () => {
        const MAX_AMOUNT = props.pInfo?.infoPagos?.porpagar;
        if (amount > 0 && amount <= MAX_AMOUNT) {
            if (file === null) {
                ShowSwalAlert({ icon: 'info', title: t("admin_profesors_receipt_required") });
            } else {
                const prevPInfo = {...props.pInfo?.infoPagos};
                const pagadasAct = props.pInfo?.infoPagos?.pagadas + amount;
                const porPagarAct = MAX_AMOUNT - amount;
                const infoPagosAct = props.pInfo;
                infoPagosAct.infoPagos.porpagar = porPagarAct;
                infoPagosAct.infoPagos.pagadas = pagadasAct;
                setIsSubmitting(!isSubmitting);
                await processPay(infoPagosAct, amount, user.uid, file).then((result) => {
                    onHide();
                    if(result.isSuccess){
                        ShowSwalAlert({ icon: 'success', title: t("admin_profesors_payment_success") });
                    }else{
                        props.pInfo.infoPagos = prevPInfo;
                        ShowSwalAlert({ icon: 'info', title: t(result.message) });
                    }
                }).catch((error) => {
                    console.error("error pay", error);
                    ShowSwalAlert({ icon: 'error', title: t("error_something_wrong") });
                }).finally(() => setIsSubmitting(!isSubmitting));
            }

        } else {
            ShowSwalAlert({ icon: 'info', title: t("admin_profesors_amount_message") });
        }
    }

    const triggerOnclick = () => {
        refFileInput.current.click();
    }

    const handleFileChange = async (evt) => {
        evt.preventDefault();
        const fileList = evt.target.files;
        const fileReader = new FileReader();
        if (fileList && fileList.length) {
            const sFile = fileList[0];
            setFile(sFile);
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result) {
                    setFileDataURL(result)
                }
            }
            fileReader.readAsDataURL(sFile);
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{props.lesson?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>{t("admin_profesors_fullname")}</label>
                            <input type="text" className="form-control" readOnly value={props.pInfo?.fullname} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>{t("admin_profesors_totalPay")}</label>
                            <input type="text" className="form-control" readOnly value={props.pInfo?.infoPagos?.porpagar} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>{t("admin_profesors_bank_label")}</label>
                            <input type="text" className="form-control" readOnly value={props.pInfo?.infoPagos?.banco} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>{t("admin_profesors_clabe_label")}</label>
                            <input type="text" className="form-control" readOnly value={props.pInfo?.infoPagos?.clabe} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>{t("admin_profesors_amount_Pay")}</label>
                            <input type="number" className="form-control" value={amount} onChange={handleAmountChange} />                            
                        </div>
                        <div className="form-group col-md-6">
                        <div className="cover-container">
                            <img className="rounded" height={250} width={250} style={{ maxWidth: "100%" }}
                                src={fileDataURL ? fileDataURL : "https://firebasestorage.googleapis.com/v0/b/pangea-daee0.appspot.com/o/default%2Freceipt.png?alt=media&token=f0bdf8c1-73ae-466d-9cd1-69b9e026ea73"} alt="receipt" />
                            <div className="p-image">
                                <i className="ri-pencil-line upload-button" onClick={triggerOnclick} />
                                <input ref={refFileInput} className="file-upload" type="file"
                                    onChange={handleFileChange} accept=".png, .jpg, .jpeg" />
                            </div>
                        </div>
                    </div>
                    </div>
                    <button className="btn btn-primary" disabled={isSubmitting} onClick={pay}>{t("admin_profesors_confirm_pay")} {isSubmitting && <Spinner animation="border"  variant="light" size="sm" />} </button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ProfesorPay;