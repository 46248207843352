import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import { CreatePosts } from "../components/posts/CreatePosts";
import { getMyPostsAndFriendPosts } from "../services/postHelper";
import FriendSuggestion from "../components/suggestion/FriendSuggestion";
import { useAuth } from "../authContext";
import { createImageFromInitials, getRandomColor } from "../components/Utils";
import { getUserByUid } from "../services/authHelper";
import { processLessonsNotification } from "../services/lessonsHelper";
import PostList from "../components/posts/PostList";
import { Facebook } from 'react-content-loader'
import ModalSubscription from "../components/subscriptions/ModalSubscription";
import { getSubscriptionList } from "../services/subscriptionsHelper";

const Home = () => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const { user, userData, setUserData } = useAuth();
  const uid = user.uid;
  const [postsLoading, setPostsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [showSubscription, setShowSubscription] = useState(false);

  const init = async () => {
    await getMyPostsAndFriendPosts(uid)
      .then((data) => {
        setPosts(data);
        setPostsLoading(!postsLoading);
      });
    const data = await getUserByUid(uid);
    if (!data.subscription.isPremium) {
      await getSubscriptionList().then(list => {
        setSubscriptions(list);
        if (list.length > 0) {
          setShowSubscription(!showSubscription);
        }
      });

    }
    setUserData(data);
    const timer = setTimeout(() => {
      processLessonsNotification(uid, user);
    }, 3000);
    return () => clearTimeout(timer);
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout>
      <div className="container padding90">
        <div className="row">
          <div className="col-lg-8 row m-0 p-0">
            <div className="col-sm-12">
              <div id="post-modal-data" className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">{t('home_create_post')}</h4>
                  </div>
                </div>
                <div className="iq-card-body" data-toggle="modal" data-target="#post-modal">
                  <div className="d-flex align-items-center">
                    <div className="user-img">
                      <img src={(user.photoURL && user.photoURL?.length >= 0) ? user.photoURL : createImageFromInitials(500, user.displayName, getRandomColor())} alt="userimg" className="avatar-60 rounded-circle" />
                    </div>
                    <form className="post-text ml-3 w-100" onClick={() => setModalShow(true)}>
                      <input type="text" className="form-control rounded" placeholder={t('home_type_here')} style={{ border: 'none' }} />
                    </form>
                  </div>
                  <hr />
                  <ul className="post-opt-block d-flex align-items-center list-inline m-0 p-0">
                    <li className="iq-bg-primary rounded p-2 pointer mr-3" onClick={() => setModalShow(true)}><img src="images/small/07.png" alt="icon" className="img-fluid" /> {t("home_image_video")}</li>
                    <li className="iq-bg-primary rounded p-2 pointer">
                      <div className="iq-card-header-toolbar d-flex align-items-center">
                        <div className="dropdown">
                          <span className="dropdown-toggle" id="post-option" data-toggle="dropdown">
                            <i className="ri-more-fill" />
                          </span>
                          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="post-option" style={{}}>
                            <a className="dropdown-item" href="#">Otro</a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {postsLoading ? <Facebook /> : <PostList posts={posts} />}
          </div>
          <CreatePosts
            show={modalShow}
            user={user}
            setPosts={setPosts}
            posts={posts}
            onHide={() => setModalShow(false)} />
          <div className="col-lg-4" >
            <FriendSuggestion />
          </div>
        </div>
      </div>
      <ModalSubscription
        show={showSubscription}
        user={userData}
        onHide={() => setShowSubscription(false)}
        subscriptions={subscriptions} />
    </Layout>
  );
}

export default Home