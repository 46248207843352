import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useAuth } from "../authContext";
import { onVideoCallCanceled } from "../services/videocallHelper";

const Videocall = () => {
	const { id } = useParams();
	const { user } = useAuth();

	useEffect(() => {
		var domain = 'meet.jit.si';
		var options = {
			roomName: id,
			width: 800,
			height: 800,
			parentNode: document.querySelector('#meet'), userInfo: {
				email: user.email,
				displayName: user.displayName
			}
		};
		var api = new window.JitsiMeetExternalAPI(domain, options);
	}, []);

	useEffect(() => {
		onVideoCallCanceled(user.uid);
	}, []);

	return (<div id="meet"></div>);
}

export default Videocall;