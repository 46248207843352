import { addDoc, collection, doc, query, onSnapshot, setDoc, orderBy, where, getDocs, getDoc, deleteDoc, limit } from "firebase/firestore";

import { db } from "../firebaseConfig";

export const getNotificationBytId = async (uid, setNewNotifications) => {
    let dataNoti = [];
    try {
        const q = query(collection(db, `users/${uid}/notifications`), orderBy('timestamp', 'desc'), limit(30));
        
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            dataNoti.push({ id: doc.id, ...doc.data() });
            if (!doc.data().isViewed) {
                setNewNotifications(true);
            }
        });
    } catch (error) {
        console.error('Error |getNotificationBytId| :', error);
        return false;
    }
    return dataNoti;
}

export const listenerNotification = async (uid, setNotifications, setNewNotifications) => {
    let dataNoti = []
    try {
        const q = query(collection(db, `users/${uid}/notifications`), orderBy('timestamp', 'desc'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const changeValue = { id: change.doc.id, ...change.doc.data() };
                if (change.type === "added") {
                    dataNoti = [];
                    querySnapshot.docs.forEach((doc) => {
                        dataNoti.push({ id: doc.id, ...doc.data() });
                    })
                    const result = dataNoti.filter(dn => dn.id !== changeValue.id);
                    setNotifications([changeValue, ...result]);
                    setNewNotifications(true);
                }
                if (change.type === "modified") {
                    dataNoti = [];
                    querySnapshot.docs.forEach((doc) => {
                        dataNoti.push({ id: doc.id, ...doc.data() });
                    })
                    const updateNotis = dataNoti.filter(dn => dn.id !== change.doc.id);
                    setNotifications([changeValue, ...updateNotis]);
                    const allReaded = dataNoti.filter(dn => !dn.isViewed);
                    if (allReaded.length > 0) {
                        setNewNotifications(true);
                    } else {
                        setNewNotifications(false);
                    }
                }
                if (change.type === "removed") {
                    dataNoti = [];
                    querySnapshot.docs.forEach((doc) => {
                        dataNoti.push({ id: doc.id, ...doc.data() });
                    })
                    const updateNotis = dataNoti.filter(dn => dn.id !== changeValue.id);
                    setNotifications([...updateNotis]);
                }
            });
        });
        return () => unsubscribe();
    } catch (error) {
        console.error('Error |getNotificationBytId| :', error);
        return false;
    }
    // return dataNotifications;
}

/**
 * 
 * @param {*} userId - Id del Usuario al que le llegara la notoificación
 * @param {*} typeId - Id del Post o Comentario
 * @param {*} user - Objecto del usuario que esta en sesión
 * @param {*} texto  - Texto de la notificacion
 * @param {*} typeText  - Tipo Post, Comment, Class
 * @returns Objeto creado en la BD
 */
export const createNotification = async (userId, typeId, user, texto, typeText = "") => {
    let notification = {
        uid: user.uid,
        typeId: typeId,
        photoProfile: user.photoURL,
        userName: user.displayName,
        text: texto,
        isViewed: false,
        timestamp: new Date(),
        type: typeText
    };
    let dataComment = {};
    try {
        if (typeText === "class") {
            await updateNotification(userId, typeId, notification);
        } else {
            dataComment = await addDoc(collection(db, `users/${userId}/notifications`), notification);
        }
    } catch (error) {
        console.error('Error |createNotification| :', error);
        return false;
    }
    return dataComment;
}

export const updateNotification = async (uid, nid, notification) => {
    try {
        await setDoc(doc(db, `users/${uid}/notifications/`, nid), notification);
    } catch (error) {
        console.error('Error |updateNotification| :', error);
        return false;
    }
    return true;
}

export const previousNotification = async (uid, typeId, setDisableLike) => {
    let notifications = [];
    try {
        const q = query(collection(db, `users/${uid}/notifications/`),
            where("typeId", "==", typeId)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            notifications.push({ id: doc.id, ...doc.data() });
        });
        if (notifications.length > 0) {
            await deleteDoc(doc(db, `users/${uid}/notifications`, notifications[0].id));
            setDisableLike(false);
            return true;
        } else {
            setDisableLike(false);
            return false;
        }
    } catch (error) {
        console.error('Error |previousNotification| :', error);
        return false;
    }
}