import { useAuth } from "../../authContext";
import { Posts } from "./Posts";

const PostList = ({ posts }) => {
    const { user } = useAuth();
    return (
        <>
            {posts && posts.length > 0 && posts.map((post, index) => (
                <Posts key={post.id} user={user} post={post} />
            ))}
        </>
    );
}

export default PostList;