import { updateProfile } from "firebase/auth";
import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../authContext";
import { updateUser } from "../../services/authHelper";
import { COUNTRIES, GENDERS, LANGUAGES_EN, LANGUAGES_ES } from "../../utils/data";
import { ErrorMessage } from "../ErrorMessage";

const BasicInfoView = ({ userData, setUserData, isReadOnly = false , isEnable="" }) => {
    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const LANGUAGES = i18n.language === "es"  ? LANGUAGES_ES : LANGUAGES_EN;
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isReadOnly) {
            setIsSubmitting(true);
            try {
                await updateProfile(user, {
                    displayName: `${userData.firstName} ${userData.lastName}`,
                    photoURL: userData.profilePicture
                });
                await updateUser(userData);
            } catch (error) {
                console.log("error", error);
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const handleOnchange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const rawValue = value.trim();
        if (rawValue === "") {
            setErrors({ ...errors, [name]: { message: { key: "validations_required" } } })
        }
        else {
            setErrors({ ...errors, [name]: undefined })
            setUserData({ ...userData, [name]: value });
        }
    };

    const handleLanguageChange = (evt) => {
        evt.preventDefault();
        const { name, value } = evt.target;
        const rawValue = value.trim();
        if (rawValue === "") {
            setErrors({ ...errors, [name]: { message: { key: "validations_required" } } })
        }
        else {
            const copy = { ...userData["language"] }
            copy[name] = value;
            setErrors({ ...errors, [name]: undefined })
            setUserData({ ...userData, language: copy });
        }
    }

    return (
        <>
            <h4>{t("profile_basic_information")}</h4>
            <hr />
            <div className="row">
                <div className="iq-card-body">
                    <form onSubmit={handleSubmit} className="formview">
                        <div className="row align-items-center">
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="firstName">{t("register_firstName")}</Form.Label>
                                <b>{userData?.firstName}</b>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="lastName">{t("register_lastName")}</Form.Label>
                                <b>{userData?.lastName}</b>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="email">{t("register_email")}</Form.Label>
                                <b>{userData?.email}</b>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="telephoneNumber">{t("profile_telephone")}</Form.Label>
                                <b>{userData?.telephoneNumber}</b>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="countryOfOrigin">{t("profile_country_of_origin")}</Form.Label>
                                <b>{userData?.countryOfOrigin}</b>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="countryResidence">{t("profile_country_residence")}</Form.Label>
                                <b>{userData?.countryResidence}</b>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="gender">{t("profile_gender")}</Form.Label>
                                <b>{userData?.gender}</b>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="dateOfBirth">{t("register_date_dob")}</Form.Label>
                                <b>{userData?.dateOfBirth}</b>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="usertype">{t("profile_user_type")}</Form.Label>
                                <b>{t(userData?.userType)}</b>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="profession">{t("profile_profession")}</Form.Label>
                                <b>{userData?.profession}</b>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="favoriteHobbie">{t("profile_hobby")}</Form.Label>
                                <b>{userData?.favoriteHobbie}</b>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="native">{t("profile_native_language")}</Form.Label>
                                <b>{userData?.language?.native}</b>
                            </Form.Group>
                            {userData?.userType && (userData?.userType === "student") && <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="goalLearning">{t("register_target_lang_s")}</Form.Label>
                                <b>{userData?.language?.goalLearning}</b>
                            </Form.Group>}
                        </div>
                     
                    </form>
                </div>
            </div>
        </>
    )
}

export default BasicInfoView;