import { t } from "i18next";
import { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { updateUser } from "../../services/authHelper";
import { getBankList } from "../../services/paymentHelper";
import { ErrorMessage } from "../ErrorMessage";

const Payment = ({ userData, setUserData }) => {
    const [bankList, setBankList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    const handleOnSubmit = async (evt) => {
        evt.preventDefault();
        setIsSubmitting(true);
        try {
            await updateUser(userData);
        } finally {
            setIsSubmitting(false);
        }
    }

    const handleOnChange = (evt) => {
        evt.preventDefault();
        const { name, value } = evt.target;
        const rawValue = value.trim();
        if (rawValue === "") {
            setErrors({ ...errors, [name]: { message: { key: "validations_required" } } })
        }
        else {
            const paymentData = {...userData["infoPagos"]};
            paymentData[name] = value; 
            setErrors({ ...errors, [name]: undefined })
            setUserData({ ...userData, infoPagos: paymentData });
        }
    }

    const loadBankList = async() => {
        await getBankList().then(r => setBankList(r));
    }

    useEffect(() => {
        loadBankList();
    }, []);

    return (
        <>
            <h4>{t("profile_payment_tab")}</h4>
            <hr />
            <div className="row">
                <div className="iq-card-body">
                    <form onSubmit={handleOnSubmit}>
                    <div className="row">
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="banco">{t("profile_payment_bank")}</Form.Label>
                                <select id="banco" name="banco" value={userData?.infoPagos?.banco} onChange={handleOnChange} className="form-control">
                                    {bankList.map((b) => <option value={b.name}>{b.name}</option>)}
                                </select>
                                <ErrorMessage error={errors.bank?.message} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="clabe">{t("profile_payment_clabe")}</Form.Label>
                                <Form.Control type="text" id="clabe" name="clabe" isInvalid={!!errors?.clabe}
                                    onChange={handleOnChange} onBlur={handleOnChange} defaultValue={userData?.infoPagos?.clabe} />
                                <ErrorMessage error={errors.clabe?.message} />
                            </Form.Group>
                        </div>
                        <button type="submit" disabled={isSubmitting} className="btn btn-primary mr-2">
                            {t("profile_update")} {isSubmitting && <Spinner animation="border" variant="light" size="sm" />}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Payment;