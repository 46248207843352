import { Form, FormSelect, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuth } from "../authContext";
import { DropLanguajes } from "../i18n/dropLanguajes";
import { ErrorMessage } from "../components/ErrorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import { createNewUser } from "../services/userHelper";
import { useNavigate } from "react-router-dom";
import { ShowSwalAlert } from "../components/Utils";
import { updateProfile } from "firebase/auth";

const SignUp = () => {
    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const navigate = useNavigate();

    const validationSchema = yup.object({
        firstName: yup.string().trim().required(),
        lastName: yup.string().trim().required(),
        email: yup.string().email().required(),
        userType: yup.string().nullable().required(),
        dateOfBirth: yup.date().nullable().required(),
            // .test('dob', { key: 'validations_age' }, (value) => moment().diff(value, 'years') >= 18),
        gender: yup.string().optional(),
        nativeLang: yup.string().required(),
        targetLang: yup.string().required(),
        termsOfUse: yup.boolean().oneOf([true], { key: 'validations_termsOfUse' })
    });
    const { register, watch, handleSubmit, formState: { isSubmitting, errors } } = useForm({
        defaultValues: {
            firstName: user.displayName, lastName: "", email: user.email,
            userType: "", dateOfBirth: null, gender: "", nativeLang: "",
            targetLang: "", termsOfUse: false
        },
        resolver: yupResolver(validationSchema)
    });
    const userType = watch('userType', t('register_p'));

    const onSubmit = async (data) => {
        let userData = {...data, uid: user.uid};
        if(user.photoURL != null){
            userData.profilePicture = user.photoURL;
        }else{
            userData.profilePicture = "";
        }
        try {
            await updateProfile(user, {
                displayName: `${userData.firstName} ${userData.lastName}`,
                photoURL: userData.profilePicture
            });
            await createNewUser(userData);
            navigate("/");
        } catch (error) {
            ShowSwalAlert({icon: "error", title: t('error_something_wrong')});
        }
    }

    return (
        <div className="sign-in register">
            <div className="container p-0">
                <div className="row">
                    <div className="col-md-6 text-center pt-5 order-2 order-lg-1">
                        <div className="sign-in-intro text-white">
                            <div className="sign-in-logo">
                            <img src="https://ibloom.com.mx/pangu/hame.svg" alt="Pángu" />
                            </div>
                        
                        </div>
                    </div>
                    <div className="col-md-6 bg-white pt-5 order-1 order-lg-2 todoh">
                        <div className="float-right align-middle">
                            <DropLanguajes t={t} i18n={i18n} />
                        </div>
                        <div className="sign-in-form">
                            <h1 className="mb-0">{t("register_title")}</h1>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    <Form.Group className="form-group col-md-6">
                                        <Form.Label htmlFor="firstName">{t("register_firstName")}</Form.Label>
                                        <Form.Control type="text" id="firstName" className="form-control mb-0"
                                            placeholder={t("register_firstName")} isInvalid={!!errors.firstName}
                                            {...register("firstName", { onChange: e => {
                                                e.target.value = e.target.value.trim()
                                            }})}
                                        />
                                        <ErrorMessage error={errors.firstName?.message} />
                                    </Form.Group>
                                    <Form.Group className="form-group col-md-6">
                                        <Form.Label htmlFor="lastName">{t("register_lastName")}</Form.Label>
                                        <Form.Control type="text" id="lastName" className="form-control mb-0"
                                            placeholder={t("register_lastName")} isInvalid={!!errors.lastName}
                                            {...register("lastName", { onChange: e => {
                                                e.target.value = e.target.value.trim()
                                            }})}
                                        />
                                        <ErrorMessage error={errors.lastName?.message} />
                                    </Form.Group>
                                </div>

                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="email">{t("register_email")}</Form.Label>
                                    <Form.Control type="email" id="email" className="form-control mb-0"
                                        placeholder={t("register_email")} readOnly={true}
                                        {...register("email")}
                                    />
                                </Form.Group>

                                <div className="form-row mb-1">
                                    <label className="col-sm-12">{t("register_utype")}</label>
                                    <div className="form-group col-md-6">
                                        <Form.Check type="radio">
                                            <Form.Check.Input type="radio" id={t("register_p")} name="userType" value={t("register_p")}
                                                {...register("userType")} />
                                            <Form.Check.Label htmlFor={t("register_p")}>{t("register_p")}</Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <Form.Check type="radio">
                                            <Form.Check.Input type="radio" id={t("register_e")} name="userType" value={t("register_e")}
                                                {...register("userType")} />
                                            <Form.Check.Label htmlFor={t("register_e")}>{t("register_e")}</Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                    <ErrorMessage error={errors.userType?.message} />
                                </div>

                                <div className="form-row">
                                    <Form.Group className="form-group col-sm-6 mb-1">
                                        <Form.Label htmlFor="dateOfBirth">{t("register_date_dob")}</Form.Label>
                                        <Form.Control type="date" id="dateOfBirth" className="form-control mb-0"
                                            placeholder={t("register_date_dob")} isInvalid={!!errors.dateOfBirth}
                                            {...register("dateOfBirth")}
                                        />
                                        <ErrorMessage error={errors.dateOfBirth?.message} />
                                    </Form.Group>

                                    <Form.Group className="form-group col-sm-6 mb-1">
                                        <Form.Label htmlFor="gender">{t("register_gender")}</Form.Label>
                                        <FormSelect id="gender" className="browser-default custom-select"
                                            isInvalid={!!errors.gender} {...register("gender")} >
                                            <option value="Male">Masculino</option>
                                            <option value="Female">Femenino</option>
                                        </FormSelect>
                                        <ErrorMessage error={errors.gender?.message} />
                                    </Form.Group>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="nativeLang">{t("register_native_lang")}</Form.Label>
                                            <FormSelect id="nativeLang" className="browser-default custom-select"
                                                isInvalid={!!errors.nativeLang} {...register("nativeLang")} >
                                                <option value="spanish">Español</option>
                                                <option value="english">Inglés</option>
                                            </FormSelect>
                                            <ErrorMessage error={errors.nativeLang?.message} />
                                        </Form.Group>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="targetLang">{userType === t("register_p") ? t("register_target_lang_t") : t("register_target_lang_s")}</Form.Label>
                                            <FormSelect id="targetLang" className="browser-default custom-select"
                                                isInvalid={!!errors.targetLang} {...register("targetLang")} >
                                                <option value="spanish">Español</option>
                                                <option value="english">Inglés</option>
                                            </FormSelect>
                                            <ErrorMessage error={errors.targetLang?.message} />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="form-group col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Check type="checkbox">
                                            <Form.Check.Input type="checkbox" id="termsOfUse" name="userType" isInvalid={!!errors.termsOfUse} {...register("termsOfUse")} />
                                            <Form.Check.Label htmlFor="termsOfUse">{t("register_terms_of_use")}</Form.Check.Label>
                                        </Form.Check>
                                        <ErrorMessage error={errors.termsOfUse?.message} />
                                    </Form.Group>
                                </div>
                                
                                <div className="d-inline-block w-100">
                                    <button type="submit" className="btn btn-primary float-right">
                                        {t("register_signin")} {" "} {isSubmitting && <Spinner animation="border" variant="light" size="sm" />}
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;