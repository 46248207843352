export const teacherModel = {
    uid: "",
    email: "",
    userType: "",
    dateOfBirth: "",
    countryResidence: "",
    countryOfOrigin: "",
    telephoneNumber: "",
    isAdult: "",
    gender: "",
    profilePicture: "",
    confirmedAccount: "",
    termsOfUse: "",
    scholarship: "",
    favoriteHobbie: "",
    wouldLikeToSpeak: "",
    profession: "",
    accounts: {
        facebook: "",
        instagram: "",
        twitter: "",
        iOS: "",
    },
    interests: {
        list: [],
    },
    language: {
        native: "",
        other: [
            {
                language: "",
                level: "",
            },
        ],
        teachingLanguajes: [],
        teachingLevel: "",
        priceHour: "",
    },
    infoPagos: {
        misganancias: 0,
        pagadas: 0,
        porpagar: 0,
        total: 0
    },
    teachingCertificate: {
        language: "",
        nameCertification: "",
        issuedBy: "",
        date: "",
        link: "",
        validate: "",
    },
    availability: {
        zone: "",
        days: [
            {
                day: "",
                hour: "",
            },
        ],
    },
    wayToPay: {
        type: "",
        clabe: "",
        bank: "",
    },
    descriptionProfile: {
        videoLink: "",
        cv: "",
        description: "",
    },
};