import React, { useEffect } from 'react'
import { useState } from 'react';
import { XCircle } from 'react-bootstrap-icons';
import ReactPlayer from 'react-player'
import Spinner from 'react-bootstrap/esm/Spinner';

function PreviewImage({ file, setFile, setIsFileSeleted, progress, isSubmitting}) {
  const [fileDataURL, setFileDataURL] = useState(null);

  useEffect(() => {
    let fileReader;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result) {
          setFileDataURL(result)
        }
      }
      fileReader.readAsDataURL(file);
    }

    return () => {
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }
  }, [file]);

  async function closePreview() {
    setFile("");
    setIsFileSeleted(false);
  }

  return (
    <>
      <div className="chat-content">
        <div className='row d-flex flex-column flex-grow-1  justify-content-center'>          
          <div className='d-flex flex-column  align-items-center'>
            <h3 className='p-2'>
              <XCircle style={{ cursor: 'pointer' }} onSubmit={closePreview} onClick={() => { closePreview() }}></XCircle>
            </h3>
            {file?.type.split('/')[0] === "image" ?
              <img className="mt-4 mb-3" src={fileDataURL} width="200px" height="200px" alt="img" />
              :
              <>
                <ReactPlayer
                  url={fileDataURL}
                  width='50%'
                  height='50%'
                  controls
                />
              </>
            }
            <div className="mt-1">
              {isSubmitting && <div className="text-center">
                <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden"></span>
                </Spinner>
                <span className="ml-2">{progress}</span>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PreviewImage