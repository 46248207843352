import { useState } from "react";
import Layout from "../components/Layout";
import { MTareasAlumno } from "../components/MTareasAlumno";

const Tareas = () => {
  const [mostrarRealizadas, setMostrarRealizadas] = useState(false);

  return (
    <Layout>
      <div className="header-for-bg">
        <div className="background-header position-relative">
          <img
            src="https://ibloom.com.mx/pangu/tareas.jpg"
            className="img-fluid w-100"
            alt="header-bg"
          />
          <div className="title-on-header">
            <div className="data-block">
              <h2>Tareas</h2>
            </div>
          </div>
        </div>
      </div>
      <div id="content-page" className="content-page">
        <div className="container ">
          <div className="row">
            <div className="col-sm-12">
              {/*todas las tareas alumno*/}
              <div className="container">
                <div className="tareas">
                  {/*Card*/}
                  <div className="card">
                    {/*Card Header*/}
                    <div className="card-header">
                      <p>
                        {" "}
                        <i
                          className="fa fa-home ml-4"
                          aria-hidden="true"
                        />{" "}
                        Tareas{" "}
                        <span className="float-right">
                          {" "}
                          <span className="mr-4 navTask">Todas</span>{" "}
                          <span
                            onClick={() =>
                              setMostrarRealizadas(!mostrarRealizadas)
                            }
                            className="mr-4"
                          >
                            Realizadas
                          </span>{" "}
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                        </span>{" "}
                      </p>
                    </div>
                    {/*Card Body*/}
                    <div className="card-body">
                      <p className="heading1">
                        <span className="tomorrow">Mañana</span>{" "}
                        <span className="float-right headingright">
                          2 tareas
                        </span>{" "}
                      </p>
                      <p>
                        {" "}
                        <i
                          className="fa fa-calculator ml-2"
                          aria-hidden="true"
                        />{" "}
                        <span className="task mt-4">Matemáticas</span>
                        <span className="time ml-2">Sin ver</span>{" "}
                        <span className="float-right">Nueva</span>{" "}
                      </p>
                      <p>
                        <i
                          className="fa fa-file-text-o ml-2"
                          aria-hidden="true"
                        />
                        <span className="task">Español</span>{" "}
                        <span className="time ml-2">Sin ver</span>{" "}
                        <span className="float-right">Nueva</span>
                      </p>
                      <p className="heading2">
                        {" "}
                        <span className="today">Hoy</span>{" "}
                        <span className="float-right headingright">
                          5 tareas
                        </span>
                      </p>
                      <p>
                        {" "}
                        <i
                          className="fa fa-calculator ml-2"
                          aria-hidden="true"
                        />{" "}
                        <span className="task mt-4">Matemáticas</span>
                        <span className="time ml-2">Vista</span>{" "}
                        <span className="float-right">En proceso</span>{" "}
                      </p>
                      <p>
                        <i
                          className="fa fa-keyboard-o ml-2"
                          aria-hidden="true"
                        />{" "}
                        <span className="task">Computación</span>{" "}
                        <span className="time ml-2">Sin ver</span>
                        <span className="float-right">Nueva</span>{" "}
                      </p>
                      <p>
                        <i
                          className="fa fa-eyedropper ml-2"
                          aria-hidden="true"
                        />{" "}
                        <span className="task">Química</span>{" "}
                        <span className="time ml-2">Vista</span>{" "}
                        <span className="float-right">En proceso</span>{" "}
                      </p>
                      <p>
                        <i
                          className="fa fa-newspaper-o ml-2"
                          aria-hidden="true"
                        />
                        <span className="task">Historia</span>{" "}
                        <span className="time ml-2">Sin ver</span>{" "}
                        <span className="float-right">Nueva</span>
                      </p>
                      <p>
                        <i
                          className="fa fa-file-text-o ml-2"
                          aria-hidden="true"
                        />
                        <span className="task">Español</span>{" "}
                        <span className="time ml-2">Sin ver</span>{" "}
                        <span className="float-right">Nueva</span>
                      </p>
                      <p className="heading2">
                        <span className="tomorrow">Ayer</span>{" "}
                        <span className="float-right headingright">
                          2 tareas
                        </span>{" "}
                      </p>
                      <p>
                        {" "}
                        <i
                          className="fa fa-calculator ml-2"
                          aria-hidden="true"
                        />{" "}
                        <span className="task mt-4">Matemáticas</span>
                        <span className="time ml-2">Vista</span>{" "}
                        <b class="atiempo">Entregada a tiempo</b>
                        <span className="float-right">Realizada</span>{" "}
                      </p>
                      <p>
                        <i
                          className="fa fa-file-text-o ml-2"
                          aria-hidden="true"
                        />
                        <span className="task">Español</span>{" "}
                        <span className="time ml-2">Vista</span>{" "}
                        <b class="adestiempo">Entregada a destiempo</b>
                        <span className="float-right">Realizada</span>
                      </p>
                      <p className="heading2">
                        <span className="tomorrow">Hace 2 días</span>{" "}
                        <span className="float-right headingright">
                          3 tareas
                        </span>{" "}
                      </p>
                      <p>
                        {" "}
                        <i
                          className="fa fa-calculator ml-2"
                          aria-hidden="true"
                        />{" "}
                        <span className="task mt-4">Matemáticas</span>
                        <span className="time ml-2">Vista</span>{" "}
                        <b class="atiempo">Entregada a tiempo</b>
                        <span className="float-right">Realizada</span>{" "}
                      </p>
                      <p>
                        <i
                          className="fa fa-file-text-o ml-2"
                          aria-hidden="true"
                        />
                        <span className="task">Español</span>{" "}
                        <span className="time ml-2">Vista</span>{" "}
                        <b class="adestiempo">Entregada a destiempo</b>
                        <span className="float-right">Realizada</span>
                      </p>
                      <p>
                        <i
                          className="fa fa-newspaper-o ml-2"
                          aria-hidden="true"
                        />
                        <span className="task">Historia</span>{" "}
                        <span className="time ml-2">Vista</span>{" "}
                        <b class="adestiempo">Entregada a destiempo</b>
                        <span className="float-right">Realizada</span>
                      </p>
                      <a className="vermastareas" href="#">
                        Ver más tareas
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* todas las tareas alumno*/}
              {mostrarRealizadas && (
                <MTareasAlumno
                  show={mostrarRealizadas}
                  onHide={() => setMostrarRealizadas(!mostrarRealizadas)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Tareas;
