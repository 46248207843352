import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import Layout from "../layout/Layout";
import DataTable from 'react-data-table-component';
import { getProfesors } from "../services/userService";
import ProfesorPay from "../modal/ProfesorPay";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <div className="col-md-6">
            <div className="input-group">
                <input type="text" placeholder="Filtrar por nombre" aria-label="Search Input"
                    value={filterText} onChange={onFilter} className="form-control" />
                <div className="input-group-append">
                    <button type="button" onClick={onClear} className="btn btn-primary"><i class="fa fa-search" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
    </>
);

const Profesors = () => {
    const [list, setList] = useState([]);
    const [showPay, setShowPay] = useState(false);
    const [profesorInfo, setProfesorInfo] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = list.filter((item) => {
        const result = (item.firstName && item.firstName.toLowerCase().includes(filterText.toLowerCase())) || (item.lastName && item.lastName.toLowerCase().includes(filterText.toLowerCase()));
        return result;
    });

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);


    const COLUMNS = [{ name: t("admin_profesors_firstName"), selector: row => <span title={row.firstName}>{row.firstName}</span> },
    { name: t("admin_profesors_lastName"), selector: row => <span title={row.lastName}>{row.lastName}</span> },
    { name: t("admin_profesors_payable"), selector: row => <span>{row?.infoPagos?.porpagar || 0}</span> },
    { name: t("admin_profesors_paid"), selector: row => <span>{row?.infoPagos?.pagadas || 0}</span> },
    { name: t("admin_profesors_free_class"), selector: row => <span title={row.countFreeClass}>{row.countFreeClass}</span> },
    { name: t("admin_profesors_paid_class"), selector: row => <span title={row.countPaidClass}>{row.countPaidClass}</span> },
    { name: t("admin_profesors_isPremium"), selector: row => <span>{(row?.subscription?.isPremium && !row?.subscription?.isTrial) ? "Si": "No"}</span> },
    {
        cell: row => (
            <>
                {(row?.subscription?.isPremium && !row?.subscription?.isTrial) && row?.infoPagos?.porpagar > 0 && <>
                    {<button className="btn btn-light mr-1" onClick={() => profesorPay(row)}>Pagar</button>}
                </>
                }
            </>
        )
    }
    ];

    useEffect(() => {
        const loadProfesors = async () => {
            var result = await getProfesors();
            setList(result);
        };

        loadProfesors();
    }, []);

    const closePay = () => {
        setShowPay(false);
    }

    const profesorPay = (pInfo) => {
        if (pInfo?.infoPagos?.porpagar) {
            setProfesorInfo(pInfo);
            setShowPay(true);
        }
    }

    return (
        <Layout>
            <div className="header-for-bg">
                <div className="background-header position-relative">
                    <img
                        src="https://ibloom.com.mx/pangu/admin.jpg"
                        className="img-fluid w-100"
                        alt="header-bg"
                    />
                    <div className="title-on-header">
                        <div className="data-block">
                            <h2>Profesores</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-page" className="content-page">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div className="iq-card-body">
                                    <div className="container">
                                        <div className="row">
                                            <div className="table-responsive">
                                                <DataTable columns={COLUMNS} data={filteredItems}
                                                    pagination
                                                    paginationComponentOptions={{ rowsPerPageText: t("datatable_rows_PerPageText") }}
                                                    subHeader
                                                    subHeaderComponent={subHeaderComponentMemo} />
                                            </div>
                                        </div>
                                    </div>
                                    {showPay && <ProfesorPay show={showPay} onHide={closePay} pInfo={profesorInfo} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Profesors;