// import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { setLocale } from 'yup';
import { yupLocale } from './utils/yupLocale';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

setLocale(yupLocale);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
