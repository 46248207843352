import { Link } from "react-router-dom";

const Materias = ({ target }) => {
    return (
        <div data-aos="fade-right" className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-centered aos-init aos-animate" style={{}}>
            <ul className="tabrow area" style={{ margin: 0 }}>
                <li className="grado pre_seg_bar" rel="libros_sexto_2020"><a style={{ zIndex: 10, color: 'white' }}>Mis materias<img src="https://www.conaliteg.sep.gob.mx/icons/librogris.png" style={{ width: 20 }} alt /></a></li>
            </ul>
            <div className="secc_libs" id="libros_sexto_2020">
                <div className="row" style={{ marginTop: '2%' }}>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                        <div className="row">
                            <Link to={target}>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                    <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                        <img src="https://ibloom.com.mx/pangu/ingles.jpg" className="img-responsive img-book" alt="Inglés." />
                                        <b>Inglés.</b>
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                        <div className="row">
                            <Link to={target}>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                    <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                        <img src="https://www.conaliteg.sep.gob.mx/2022/m/P6DMA/000.jpg" className="img-responsive img-book" alt="Desafíos Matemáticos." />
                                        <b>Desafíos Matemáticos.</b>
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                        <div className="row">
                            <Link to={target}>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                    <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                        <img src="https://www.conaliteg.sep.gob.mx/2022/m/P6ESA/000.jpg" className="img-responsive img-book" alt="Español." />
                                        <b>Español.</b>
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                        <div className="row">
                            <Link to={target}>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                    <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                        <img src="https://www.conaliteg.sep.gob.mx/2022/m/P6HIA/000.jpg" className="img-responsive img-book" alt="Historia." />
                                        <b>Historia.</b>
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                        <div className="row">
                            <Link to={target}>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                    <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                        <img src="https://www.conaliteg.sep.gob.mx/2022/m/Z7778/000.jpg" className="img-responsive img-book" alt="Cuaderno de Actividades. Geografía." />
                                        <b>Cuaderno de Actividades. Geografía.</b>
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                        <div className="row">
                            <Link to="/exercises/all">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                    <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                        <img src="https://www.conaliteg.sep.gob.mx/2022/m/Z7823/000.jpg" className="img-responsive img-book" alt="Formación Cívica y Ética. Cuaderno de aprendizaje." />
                                        <b>Formación Cívica y Ética. Cuaderno de aprendizaje.</b>
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* FIN SEXTO 2020*/}
        </div>
    );
}

export default Materias;