import React, { useState } from 'react';
import Login from '../Login';
import InicioChat from './view/InicioChat';
import { useAuth } from '../../authContext';

function Chat() {
  const  { user } = useAuth();
  const [friendData, setFriendData] = useState(null);
  return (
    <div className="app">
      {user ? (
        <>
          <InicioChat friendData={friendData} setFriendData={setFriendData} />
        </>) : (<Login />)}
    </div>
  );
}
export default Chat;
