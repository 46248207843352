import { useTranslation } from "react-i18next";
import { useAuth } from "../../authContext";
import { createVideocall } from "../../services/videocallHelper";

const ChatHeader = ({ avatarFrom, toUserName, friendData, fromAllFriends }) => {
    const { t } = useTranslation();
    const { user } = useAuth();

    const onVideoCall = async () => {
        const roomUid = await createVideocall({ uidFrom: user.uid, uidTo: friendData.uid, callerName: user.displayName });
        if (roomUid) {
          window.open(`/videocall/${roomUid}`);
        }
      }

    return (
        <div className="chat-head ">
            <header className="d-flex justify-content-between align-items-center bg-white pt-3 pr-3 pb-3">
                <div className="d-flex align-items-center">
                    {
                        fromAllFriends && <div className="sidebar-toggle">
                            <i className="ri-menu-3-line" />
                        </div>
                    }
                    <div className={fromAllFriends ? "avatar chat-user-profile m-0 mr-3" : "avatar chat-user-profile ml-3 mr-3"}>
                        <img src={avatarFrom} alt="avatar" className="avatar" style={{ width: "50px" }} />
                        <span className="avatar-status">{friendData?.online ? <i className="ri-checkbox-blank-circle-fill text-success" /> : <i className="ri-checkbox-blank-circle-fill text-danger" />}</span>
                    </div>
                    <h5 className="mb-0">{toUserName}</h5>
                </div>
                <>
                    <div className="chat-user-detail-popup scroller">
                        <div className="user-profile text-center">
                            <button type="submit" className="close-popup p-3"><i className="ri-close-fill" /></button>
                            <div className="user mb-4">
                                <div className="avatar m-0">
                                    <img src={avatarFrom} alt="avatar" style={{ width: "50px" }} />
                                </div>
                                <div className="user-name mt-4">
                                    <h4>{toUserName}</h4>
                                </div>
                                <div className="user-desc">
                                    <p>{friendData?.email}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="chatuser-detail text-left mt-4">
                                <div className="row">
                                    <div className="col-6 col-md-6 title"> {t("chat_details_name")}:</div>
                                    <div className="col-6 col-md-6 text-right">{toUserName}</div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-6 col-md-6 title">{t("chat_details_tel")}:</div>
                                    <div className="col-6 col-md-6 text-right">{friendData?.telephoneNumber}</div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-6 col-md-6 title">{t("chat_details_birth")}:</div>
                                    <div className="col-6 col-md-6 text-right">{friendData?.dateOfBirth}</div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-6 col-md-6 title">{t("chat_details_gender")}:</div>
                                    <div className="col-6 col-md-6 text-right">{friendData?.gender}</div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-6 col-md-6 title">{t("chat_details_language")}:</div>
                                    <div className="col-6 col-md-6 text-right">{friendData?.language ? friendData?.language.native : ''}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                {friendData?.online && <div className="chat-header-icons d-flex">
                    <button className="btn chat-icon-phone iq-bg-primary mr-2" onClick={() => onVideoCall()}>
                        <i className="ri-phone-line" />
                    </button>
                    <button onClick={() => onVideoCall()} className="btn chat-icon-video iq-bg-primary">
                        <i className="ri-vidicon-line" />
                    </button>
                </div>
                }
            </header>
        </div>
    );
}

export default ChatHeader;