import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { t } from "i18next";
import moment from "moment";
import { useState } from "react";
import { paypalSubscription } from "../../Config";
import { activateSubscription, enablePremiumSubscription } from "../../services/subscriptionsHelper";
import { ShowSwalAlert } from "../Utils";

const SubscriptionPayExternal = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const createSubscription = (data, actions) => {
        const planId = props.subscription?.planId;
        return actions.subscription.create({ 'plan_id': planId });
    }
    const onApprove = async (data, actions) => {
        await activateUserSubscription(data.subscriptionID, data.orderID);
    }

    const activateUserSubscription = async (subscriptionID, orderId) => {
        setIsSubmitting(!isSubmitting);
        const startDate = new Date(moment());
        await enablePremiumSubscription(props.user, subscriptionID, props.subscription?.type, orderId).then(r => {
            ShowSwalAlert({ icon: 'success', title: t('subscription_activate') });
        }).then(() => {
            activateSubscription(props.user, props.subscription, startDate, subscriptionID, orderId);
        }).finally(() => {
            setIsSubmitting(!isSubmitting);
            window.location.href = "checkout://plan";
        })
    }

    return (
        <>
            <div className="col">
                <div className="iq-card">
                    <div className="iq-card-body text-center">
                        <h2 className="mb-4">${props.subscription?.price}</h2>
                        {
                            props.subscription?.price > 0 && <>
                                <h3 className="mb-4">{t("subscription_pay_title")}</h3>
                                <p className="font-size-18">{t("subscription_pay_description")}</p>
                            </>
                        }
                        <ul className="list-unstyled line-height-4 mb-0">
                            <li>{`${props.subscription?.days} ${t('subscription_days')}`}</li>
                        </ul>
                    </div>
                    <div className="text-center">
                        <PayPalScriptProvider options={paypalSubscription}>
                            <PayPalButtons
                                style={{ layout: "vertical", label: "subscribe" }}
                                createSubscription={createSubscription}
                                onApprove={onApprove}
                            /></PayPalScriptProvider>
                    </div>
                </div>
            </div>

        </>
    );
}

export default SubscriptionPayExternal;