import { useEffect } from "react";
import { useState } from "react";
import { Facebook } from "react-content-loader";
import { useParams } from "react-router-dom";
import { getUserByUid } from "../../services/authHelper";
import { getSubscriptionId } from "../../services/subscriptionsHelper";
import SubscriptionPayExternal from "./SubscriptionPayExternal";

const YearlySubscription = () => {
    const { iduser } = useParams();
    const [isFetching, setIsFetching] = useState(true);
    const [userData, setUserData] = useState(null);
    const [subscription, setSubscription] = useState(null);

    const loadUserData = async (uid) => {
        const uData = await getUserByUid(uid);
        const subscriptionData = await getSubscriptionId("eTzXunNHV8omNUGdtx7O");
        setUserData(uData);
        setSubscription(subscriptionData);
        setIsFetching(!isFetching);
    };

    useEffect(() => {
        loadUserData(iduser);
    }, [iduser]);

    if (isFetching) {
        return <Facebook />
    }
    return (
        <>
            <div id="content-page" className="content-page">
                <SubscriptionPayExternal user={userData} subscription={subscription} />
            </div>
        </>
    );
}

export default YearlySubscription;