import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getCommentByPostId } from '../../services/commentHelper';
import { createPostLike, deletePostLike, getLikebyPostId, previousPostLike } from '../../services/likeHelper';
import { createNotification, previousNotification } from '../../services/notificationHelper';
import { deletePost, deletePostFile } from '../../services/postHelper';
import { FALLBACK_PROFILE_PICTURE } from '../../services/userHelper';
import { Comments } from '../comments/Comments';
import { CreateComment } from '../comments/CreateComment';
import { confirmDelete } from '../Utils';

export const Posts = ({ user, post, enableDelete = false, updatePost = function (pId) { } }) => {
    const [comments, setComments] = useState([]);
    const [likes, setLikes] = useState([]);
    const [disableLike, setDisableLike] = useState(false);
    const { id, text, timestamp, userName, photoProfile, photoPost, video, audio, status } = post;
    const postDate = timestamp.seconds ? new Date(timestamp.seconds * 1000) : timestamp._seconds ? moment(timestamp._seconds * 1000).toDate(): timestamp;
    const times = moment(postDate).format('DD/MM/YYYY');
    const { t } = useTranslation();

    const getComments = async () => {
        await getCommentByPostId(post.uid, id)
            .then((data) => {
                setComments(data);
            });
    }

    const getLikes = async () => {
        await getLikebyPostId(post.uid, id)
            .then((data) => {
                setLikes(data);
            });
    }

    useEffect(() => {
        getLikes();
    }, []);

    const addLike = async () => {
        if (!disableLike) {
            setDisableLike(true);
            const like = {
                uid: user.uid,
                nameUser: user.displayName ? user.displayName : 'Anonymus',
                timestamp: new Date()
            }
            const isExist = await previousPostLike(post.uid, id, user.uid);
            if (isExist && isExist.length > 0) {
                await deletePostLike(user.uid, id, isExist[0].id)
                const cleanLikes = likes.filter(li => li.id !== isExist[0].id);
                setLikes(cleanLikes);
                await previousNotification(post.uid, post.id, setDisableLike);
            } else {
                const result = await createPostLike(post.uid, id, like);
                if (result) {
                    setLikes([{ id: result.id, like }, ...likes]);
                    const text = `${user.displayName} ${t('post_like')}`;
                    const isNotify = await createNotification(post.uid, post.id, user, text, "megusta");
                    if (isNotify) {
                        setDisableLike(false);
                    }
                } else {
                    console.log('NO SE GUARDO EL LIKE');
                }
            }
        }
    }

    const handleDeletePost = async () => {
        confirmDelete({
            icon: "warning", title: t("post_confirm_delete"), text: "", fn: async (result) => {
                if (result.isConfirmed) {
                    let postFileDeleted = false;
                    if (status === "1" || photoPost) {
                        await deletePostFile(photoPost).then((result) => {
                            postFileDeleted = result;
                        });
                    }
                    if (status === "3" || video) {
                        await deletePostFile(video).then((result) => {
                            postFileDeleted = result;
                        });
                    }

                    if(!postFileDeleted){
                        postFileDeleted = status === "0" || text.length > 0;
                    }

                    if (postFileDeleted) { 
                    }

                    await deletePost(user.uid, id).then(() => {
                        updatePost(id);
                    });
                }
            }
        });
    }

    return (
        <div className="col-sm-12">
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-body">
                    <div className="user-post-data">
                        <div className="d-flex flex-wrap">
                            <div className="media-support-user-img mr-3">
                                <img className="rounded-circle img-fluid"
                                 onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = FALLBACK_PROFILE_PICTURE;
                                  }}
                                 src={photoProfile && photoProfile?.length >= 0 ? photoProfile : user.photoURL} alt="" />
                            </div>
                            <div className="media-support-info mt-2">
                                <h5 className="mb-0 d-inline-block">{userName}</h5>
                                <p className="mb-0 text-primary">{times}</p>
                            </div>
                            {enableDelete &&
                                <div class="iq-card-post-toolbar">
                                    <div class="dropdown">
                                        <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                            <i class="ri-more-fill"></i>
                                        </span>
                                        <div class="dropdown-menu m-0 p-0">
                                            <button class="dropdown-item p-3" onClick={() => handleDeletePost()}>
                                                <div class="d-flex align-items-top">
                                                    <div class="icon font-size-20"><i class="ri-save-line"></i></div>
                                                    <div class="data p-1">
                                                        <h6>{t("posts_delete_post")}</h6>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="mt-3">
                        <p>{text}</p>
                    </div>
                    <div className="user-post">
                        <div className="d-flex col-md-12 justify-content-md-center">
                            {photoPost &&
                                <img src={photoPost} alt="post-image" className="img-fluid rounded w-100" />
                            }
                            {video &&
                                <video className="img-fluid rounded w-100" controls controlsList="nodownload"><source src={video} type="video/mp4" /></video>
                            }
                            {audio &&
                                <video className="img-fluid rounded w-100" controls controlsList="nodownload"><source src={audio} type="audio/mpeg" /></video>
                            }
                        </div>
                    </div>
                    <div className="comment-area mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="like-block position-relative d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                    <div className="like-data">
                                        <div className="dropdown">
                                            {/* <span className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button" onClick={() => addLike()}> */}
                                            <span onClick={() => addLike()}>
                                                <img src="images/icon/01.png" className="img-fluid" alt="" /> {likes.length > 0 ? '' : t('posts_likes')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="total-like-block ml-2 mr-3">
                                        <div className="dropdown">
                                            <span className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                                {likes.length > 0 ? `${likes.length}` : ''}
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                <div className="total-comment-block">
                                    <div className="dropdown">
                                        <span className="dropdown-toggle" onClick={() => getComments()} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                            {comments.length > 0 ? `${comments.length} ${t('posts_comments')}` : `${t('posts_show_comments')}`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <ul className="post-comments p-0 m-0">
                            {comments && comments.length > 0 && comments.map((comment, index) =>
                                <Comments
                                    key={comment.id}
                                    comment={comment}
                                    user={user}
                                    post={post}
                                />
                            )}
                        </ul>
                        <CreateComment
                            user={user}
                            post={post}
                            comments={comments}
                            setComments={setComments}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}