import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useAuth } from "../../authContext";
import allLocales from "@fullcalendar/core/locales-all";
import { Tooltip } from "bootstrap";
import { loadCalendarEvents } from "../../services/calendarHelper";

export const FullC = () => {
  const [events, setEvents] = useState([]);
  const { user } = useAuth();
  const lang = localStorage.getItem('languaje'); 
  let tooltipInstance = null;

  useEffect(() => {
    loadCalendarEvents(user.uid, setEvents);
  }, []);
  
  const handleMouseEnter = (info) => {
    if (info.event.extendedProps.description) {
      tooltipInstance = new Tooltip(info.el, {
        title: `${info.event.title} : ${info.event.extendedProps.description}`,
        html: true,
        placement: "top",
        trigger: "hover",
        container: "body"
      });

      tooltipInstance.show();
    }
  };

  const handleMouseLeave = (info) => {
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
    }
  };

  return (
    <FullCalendar
      locale={lang ? lang : "es"}
      locales={allLocales}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      events={events}
      initialView="dayGridMonth"
      headerToolbar={{
        start: "prev,next,today",
        center: "title",
        end: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      dayMaxEventRows={3}
      eventMouseEnter={handleMouseEnter}
      eventMouseLeave={handleMouseLeave}
    />
  );
};
