import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import SubscriptionPay from './SubscriptionPay';
import { Button } from 'react-bootstrap';

export const ModalSubscription = (props) => {
    const { t } = useTranslation();
    const [selSubscription, setSelSubscription] = useState(null);
    const [step, setStep] = useState("subscription");
    const subscriptions = props.subscriptions;

    const handleClose = () => {
        setSelSubscription(null);
        props.onHide();
    }
    return (
        <Modal show={props.show} onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                {/* <Modal.Title id="contained-modal-title-vcenter">
                    {t('posts_create_title')}
                </Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className={`row ${step === 'subscription' ? '' : 'd-none'}`}>
                        {subscriptions.map((subscription, idx) => {
                            return (
                                <div key={idx} className={'col-lg-4 col-md-6 col-sm-12'}>
                                    <div class="iq-card">
                                        <div class="iq-card-body border text-center rounded">
                                            <span class="font-size-16 text-uppercase">{t(`subscription_${subscription.type}`)}</span>
                                            <h2 class="mb-4">${subscription.price}</h2>
                                            <ul class="list-unstyled line-height-4 mb-0">
                                                <li>{`${subscription.days} ${t('subscription_days')}`}</li>
                                                {/* <li>{subscription.planId}</li> */}
                                            </ul>
                                            <button class="btn btn-primary mt-5" onClick={() => {
                                                setSelSubscription(subscription);
                                                setStep("payment");
                                            }}>{t("subscription_buy_it")}</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                    <div className={`row justify-content-md-center ${step === 'payment' ? '' : 'd-none'}`}>
                        {step === 'payment' && <SubscriptionPay user={props.user} subscription={selSubscription} onHide={handleClose} setStep={setStep} />}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-danger" onClick={handleClose}>{t('subscription_continue_free')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalSubscription;