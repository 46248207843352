import moment from "moment";
import { Modal } from "react-bootstrap";

export const MDetalleClase = (props) => {
    return (
        <>
            <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Detalle de la Clase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tareas tareas2">
                        {/*Card*/}
                        <div className="card">
                            {/*Card Header*/}
                            <div className="card-header">
                                <p>{props.infoClase.title}</p>
                            </div>
                            {/*Card Body*/}
                            <div className="card-body">
                                <p><span className="task mt-4">{props.infoClase.description}</span>
                                    <span className="time ml-2">{moment(props.infoClase.startDate.toDate()).format('DD/MM/YYYY hh:mm a')} - {moment(props.infoClase.endDate.toDate()).format('DD/MM/YYYY hh:mm a')} </span> <span className="float-right">{props.infoClase.price}</span> </p>
                                <p><span className="task mt-4"> Material de
                                    apoyo</span><span className="float-right">4 elementos</span> </p>
                                <a href="#">
                                    <i className="fa fa-file-text-o ml-2" aria-hidden="true" /> Biobliografia
                                </a>
                                <a href="#">
                                    <i className="fa fa-file-video-o ml-2" aria-hidden="true" /> Video
                                </a>
                                <a href="#">
                                    <i className="fa fa-file-pdf-o ml-2" aria-hidden="true" /> Plantilla
                                </a>
                                <a href="#">
                                    <i className="fa fa-file-excel-o ml-2" aria-hidden="true" /> Fórmulas
                                </a>
                                <br />
                                <br />
                                <p><span className="taskmt-4"> Comentario de
                                    profesor:</span> Por
                                    favor de entregar todo en pdf</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}