import { t } from "i18next";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { cancelPremium } from '../../services/subscriptionsHelper';
import { ShowSwalAlert, ShowSwalAlertWithCallback } from "../Utils";
const Subscription = ({ userData }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const cancelPremiumSubscription = async () => {
        setIsSubmitting(!isSubmitting);
        await cancelPremium(userData.uid).then(() => {
            ShowSwalAlertWithCallback({
                icon: 'success', title: t("subscription_canceled"), fn: () => {
                    window.location.reload();
                }
            });
        }).catch((error) => {
            console.error("error cancel", error);
            ShowSwalAlert({ icon: 'error', title: t("error_something_wrong") });
        }).finally(() => {
            setIsSubmitting(!isSubmitting);
        });
    }

    return (
        <>
            <h4>{t("profile_subscriptions_tab")}</h4>
            <hr />
            <div className="row">
                <div className="iq-card-body">
                    {userData?.subscription?.isPremium ?
                        <>
                            <div className="form-group">
                                <label className="font-weight-bold">{t(`subscription_type_${userData?.subscription?.type}`)}</label>
                            </div>
                            <button className="btn btn-primary mr-2" onClick={() => cancelPremiumSubscription()} disabled={isSubmitting}>
                                {t("profile_subscriptions_cancel")} {isSubmitting && <Spinner animation="border" variant="light" size="sm" />}
                            </button>
                        </>
                        :
                        <>
                            <p>{t("profile_subscriptions_empty")}</p>
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default Subscription;