import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createLesson } from "../../services/lessonsHelper";
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "../ErrorMessage";
import { useAuth } from "../../authContext";
import { LANGUAGELEVEL } from "../../utils/data";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { ensureUserLoaded } from "../../services/userHelper";
import moment from "moment";
import { ShowSwalAlert } from "../Utils";

const AddLesson = () => {
    const { t } = useTranslation();
    const { user, userData, setUserData } = useAuth();
    const navigate = useNavigate();
    const refFileInput = useRef(null);

    const validationSchema = yup.object({
        level: yup.string().required(),
        title: yup.string().required(),
        description: yup.string().required(),
        startDate: yup.date().nullable().required(),
        endDate: yup.date().nullable().required(),
        price: yup.number().required().integer()
    });
    const { register, control, handleSubmit, formState: { isSubmitting, errors } } = useForm({
        defaultValues: {
            level: '',
            title: '',
            description: '',
            startDate: null,
            endDate: null,
            price: 0
        },
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data) => {
        const sDate = moment(data.startDate);
        const eDate = moment(data.endDate);
        const minutes = eDate.diff(sDate, 'minutes');
        if (!userData?.subscription?.isPremium && minutes > 30) {
            ShowSwalAlert({icon: 'info', title: t('lesson_add_notification_free')});
        }
        else {
            const lesson = { ...data };
            lesson.uid = user.uid;
            lesson.isActive = true;
            lesson.createdAt = new Date();
            lesson.type = "free";
            if (lesson > 0) {
                lesson.type = "paid";
            }
            await createLesson(lesson);
            navigate("/lessons/my");
        }
    }

    useEffect(() => {
        const init = async() => {
            await ensureUserLoaded(user.uid, userData, setUserData);
        }

        init();
    }, []);

    const handleOnFileClick = () => {
        refFileInput.current.click();
      };

    return (
        <div className="iq-card px-2">
            <div className="iq-card-header">
                <div className="row justify-content-between">
                    <div class="col-sm-12 col-md-6">
                        <h2 className="pt-2">{t("lesson_add")}</h2>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div className="d-flex float-right">
                            <button onClick={() => navigate("/lessons/my")} className="btn btn-light mt-2"> <i class="ri-arrow-go-back-fill"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iq-card-body">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <Form.Group className="col-sm-12 mb-1">
                            <Form.Label htmlFor="level">{t("lesson_add_level")}</Form.Label>
                            <select id="level" name="level" className={"form-control" + (!!errors.level ? " is-invalid" : "")} {...register("level")}>
                                {LANGUAGELEVEL.map((level) => <option value={level.value}>{t(level.label)}</option>)}
                            </select>
                            <ErrorMessage error={errors.level?.message} />
                        </Form.Group>
                        <Form.Group className="col-sm-12 mb-1">
                            <Form.Label htmlFor="title">{t("lesson_add_title")}</Form.Label>
                            <Form.Control id="title" type="text" isInvalid={!!errors.title} {...register("title")} />
                            <ErrorMessage error={errors.title?.message} />
                        </Form.Group>
                        <Form.Group className="col-sm-12 mb-1">
                            <Form.Label htmlFor="description">{t("lesson_add_description")}</Form.Label>
                            <Form.Control id="description" as="textarea" rows={2} style={{ lineHeight: "1.5" }} isInvalid={!!errors.description} {...register("description")} />
                            <ErrorMessage error={errors.description?.message} />
                        </Form.Group>
                        <Form.Group className="col-sm-6 mb-1">
                            <Form.Label htmlFor="startDate">{t("lesson_add_start")}</Form.Label>
                            <Controller id="startDate" name="startDate"
                                render={({ field }) =>
                                    <DatePicker className={"form-control" + (!!errors.startDate ? " is-invalid" : "")} {...field} dateFormat="d/MM/yyyy h:m aa"
                                        selected={field.value} onChange={(e) => field.onChange(e)} showTimeSelect />}
                                control={control} />
                            <ErrorMessage error={errors.startDate?.message} />
                        </Form.Group>
                        <Form.Group className="col-sm-6 mb-1">
                            <Form.Label htmlFor="endDate">{t("lesson_add_end")}</Form.Label>
                            <Controller id="endDate" name="endDate"
                                render={({ field }) =>
                                    <DatePicker className={"form-control" + (!!errors.endDate ? " is-invalid" : "")} {...field} dateFormat="d/MM/yyyy h:m aa"
                                        selected={field.value} onChange={(e) => field.onChange(e)} showTimeSelect isInvalid={!!errors.endDate} />}
                                control={control} />
                            <ErrorMessage error={errors.endDate?.message} />
                        </Form.Group>                        

                        {
                            userData?.subscription?.isPremium ? <Form.Group className="col-sm-6 mb-1">
                                <Form.Label htmlFor="price">{t("lesson_add_price")}</Form.Label>
                                <Form.Control id="price" type="number" min="0" isInvalid={!!errors.price} {...register("price")} />
                                <ErrorMessage error={errors.price?.message} />
                            </Form.Group>
                                : <Form.Group className="col-sm-6 mb-1">
                                    <Form.Label htmlFor="price">{t("lesson_add_price")}</Form.Label>
                                    <Form.Control id="price" type="number" min="0" value="0" disabled readOnly />
                                </Form.Group>
                        } 

                        <Form.Group className="col-sm-6 mb-1">
                            <Form.Label htmlFor="material">Material de apoyo: pdf, video, imagenes</Form.Label>
                            <div className="">
                                {/* <i className="ri-pencil-line upload-button" onClick={handleOnFileClick} /> */}
                                <input ref={refFileInput} className="" type="file" accept="*/*" />
                            </div>
                        </Form.Group>

                        <Form.Group className="col-sm-12 mb-1">
                            <Form.Label htmlFor="comentarios">Comentarios</Form.Label>
                            <Form.Control id="comentarios" as="textarea" rows={2} style={{ lineHeight: "1.5" }}  />
                        </Form.Group>                       
                    </div>
                    <Button type="submit" disabled={isSubmitting} className="mt-3"><i class="ri-save-line"></i>{t("lesson_add_save")}</Button>
                </Form>
            </div>
        </div>
    );
}

export default AddLesson;