import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext";
import { getQuestions, markEvaluatedExcercise } from "../../services/exercisesHelper";
import { ShowSwalAlert } from "../Utils";

const Choices = ({ exerciseId, exerciseType }) => {
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [isEvaluate, setIsEvaluate] = useState(false);

    const { t } = useTranslation();
    const { user } = useAuth();
    const navigate = useNavigate();

    const loadQuestions = async () => {
        await getQuestions(exerciseId).then((questions) => { setQuestions(questions); });
    }
    useEffect(() => {
        loadQuestions();
    }, [exerciseId]);

    const handleOnChange = (id, idx) => {
        const index = answers.findIndex(a => a.id === id);
        if (index >= 0) {
            const newAnswers = [...answers];
            newAnswers[index].answer = idx;
            setAnswers(newAnswers);
        } else {
            setAnswers([...answers, { id, answer: idx }]);
        }
    }

    const checkAnwers = async () => {
        if (answers.length === 0 || answers.length < questions.length) {
            ShowSwalAlert({ icon: "info", title: t('exercises_all_answers_required') });
            return;
        }

        const updatedQuestions = questions.map(q => {
            const r = answers.find(a => a.id === q.id);
            if (r) {
                q.answer = r.answer;
                q.feedback = "" + r.answer === q.correcta ? "ri-check-line bg-success" : "ri-close-line bg-danger";
            }
            return q;
        });
        setQuestions(updatedQuestions);
        try {
            await markEvaluatedExcercise(user.uid, exerciseId);
        } finally {
            setIsEvaluate(!isEvaluate);
        }
    }

    return (
        <>
            {questions.map((question, qkey) => {
                return (
                    <div className="col-sm-12" key={qkey}>
                        <p className="w-100">{question.pregunta}</p>
                        {exerciseType === "1" &&
                            <div className="form-group">
                                <div><input type="radio" value={question.opcion1} name={question.id} id={`${question.id}-${question.opcion1}`} onChange={() => handleOnChange(question.id, question.opcion1)} /> <label htmlFor={`${question.id}-${question.opcion1}`}>{question.opcion1}</label> {question.answer === question.opcion1 ? <i className={question.feedback}></i> : null}</div>
                                <div><input type="radio" value={question.opcion2} name={question.id} id={`${question.id}-${question.opcion2}`} onChange={() => handleOnChange(question.id, question.opcion2)} /> <label htmlFor={`${question.id}-${question.opcion2}`}>{question.opcion2}</label> {question.answer === question.opcion2 ? <i className={question.feedback}></i> : null}</div>
                            </div>
                        }
                        {exerciseType === "2" && <div className="form-group">{question.respuestas.map((r, idx) =>
                            <div key={idx}><input type="radio" id={`${question.id}-${idx}`} onChange={() => handleOnChange(question.id, idx)} value={r} name={question.id} /> <label htmlFor={`${question.id}-${idx}`}>{r}</label> {question.answer === idx ? <i className={question.feedback}></i> : null}</div>
                        )}
                        </div>
                        }

                    </div>
                );
            })
            }
            {!isEvaluate ? <button onClick={() => checkAnwers()} className="btn btn-primary">{t("exercises_verify")}</button> : <button onClick={() => navigate("/exercises/all")} className="btn btn-primary mt-2"><i class="ri-arrow-go-back-fill"></i></button>}
        </>
    );
}

export default Choices;