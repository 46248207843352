import { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getLessonsById, updateLesson } from "../../services/lessonsHelper";
import { ErrorMessage } from "../ErrorMessage";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { LANGUAGELEVEL } from "../../utils/data";
import { useTranslation } from "react-i18next";

const EditLesson = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [lesson, setLesson] = useState(null);
    const { lessonId } = useParams();

    const validationSchema = yup.object({
        level: yup.string().required(),
        title: yup.string().required(),
        description: yup.string().required(),
        startDate: yup.date().nullable().required(),
        endDate: yup.date().nullable().required(),
        price: yup.number().required().integer()
    });
    const { register, control, setValue, handleSubmit, formState: { isSubmitting, errors } } = useForm({
        defaultValues: { level: "", title: "", description: "", startDate: null, endDate: null },
        resolver: yupResolver(validationSchema)
    });

    const loadLesson = async (id) => {
        const result = await getLessonsById(id);
        if (result) {
            setLesson(result);
            setValue("level", result.level);
            setValue("title", result.title);
            setValue("description", result.description);
            setValue("startDate", result.startDate.toDate());
            setValue("endDate", result.endDate.toDate());
            if(result.price){
                setValue("price", result.price);
            }else{
                setValue("price", 0);
            }
        }
    }

    const onSubmit = async (data) => {
        const { price } = data;
        let lType = "free";
        if(price > 0){
            lType = "paid";
        }
        const lessonData = { ...data, id: lesson.id, type: lType };
        await updateLesson(lessonData);
        navigate("/lessons/my");
    }

    useEffect(() => {
        loadLesson(lessonId);
    }, []);

    return (
        <div className="iq-card px-2">
            <div className="iq-card-header">
                <div className="row justify-content-between">
                    <div class="col-sm-12 col-md-6">
                        <h2 className="pt-2">{lesson?.title}</h2>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div className="d-flex float-right">
                            <button onClick={() => navigate("/lessons/my")} className="btn btn-light mt-2"> <i class="ri-arrow-go-back-fill"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iq-card-body">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                    <Form.Group className="col-sm-12 mb-1">
                            <Form.Label htmlFor="level">{t("lesson_add_level")}</Form.Label>
                            <select id="level" name="level" className={"form-control" + (!!errors.level ? " is-invalid" : "")} {...register("level")}>
                                {LANGUAGELEVEL.map((level) => <option value={level.value}>{t(level.label)}</option>)}
                            </select>
                            <ErrorMessage error={errors.level?.message} />
                        </Form.Group>
                        <Form.Group className="col-sm-12 mb-1">
                            <Form.Label htmlFor="title">{t("lesson_add_title")}</Form.Label>
                            <Form.Control id="title" type="text" isInvalid={!!errors.title} {...register("title")} />
                            <ErrorMessage error={errors.title?.message} />
                        </Form.Group>
                        <Form.Group className="col-sm-12 mb-1">
                            <Form.Label htmlFor="description">{t("lesson_add_description")}</Form.Label>
                            <Form.Control id="description" as="textarea" rows={2} style={{ lineHeight: "1.5" }} isInvalid={!!errors.description} {...register("description")} />
                            <ErrorMessage error={errors.description?.message} />
                        </Form.Group>
                        <Form.Group className="col-sm-6 mb-1">
                            <Form.Label htmlFor="startDate">{t("lesson_add_start")}</Form.Label>
                            <Controller id="startDate" name="startDate"
                                render={({ field }) =>
                                    <DatePicker className={"form-control" + (!!errors.startDate ? " is-invalid" : "")} {...field} dateFormat="d/MM/yyyy h:m aa"
                                        selected={field.value} onChange={(e) => field.onChange(e)} showTimeSelect />}
                                control={control} />
                            <ErrorMessage error={errors.startDate?.message} />
                        </Form.Group>
                        <Form.Group className="col-sm-6 mb-1">
                            <Form.Label htmlFor="endDate">{t("lesson_add_end")}</Form.Label>
                            <Controller id="endDate" name="endDate"
                                render={({ field }) =>
                                    <DatePicker className={"form-control" + (!!errors.endDate ? " is-invalid" : "")} {...field} dateFormat="d/MM/yyyy h:m aa"
                                        selected={field.value} onChange={(e) => field.onChange(e)} showTimeSelect isInvalid={!!errors.endDate} />}
                                control={control} />
                            <ErrorMessage error={errors.endDate?.message} />
                        </Form.Group>
                        <Form.Group className="col-sm-6 mb-1">
                            <Form.Label htmlFor="price">{t("lesson_add_price")}</Form.Label>
                            <Form.Control id="price" type="number" min="0" isInvalid={!!errors.price} {...register("price")} />
                            <ErrorMessage error={errors.price?.message} />
                        </Form.Group>
                    </div>
                    <Button type="submit" disabled={isSubmitting} className="mt-3"><i class="ri-save-line"></i>{t("lesson_update")} {isSubmitting && <Spinner animation="border" variant="light" size="sm" />}</Button>
                </Form>
            </div>
        </div>
    );
}

export default EditLesson;