import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../authContext";
import { getUserByUid } from "../../services/authHelper";

const LessonByIdWrapper = () => {
    const { userData, setUserData} = useAuth();
    
    const loadUserData = async(uid) => {
        let currentUserData = userData || null;
        if (userData == null) {
            currentUserData = await getUserByUid(uid);            
            setUserData(currentUserData);
        }
    }

    useEffect(() => {
        loadUserData();
    }, []);

    return (
        <>
            <Outlet />
        </>
    )
}

export default LessonByIdWrapper;