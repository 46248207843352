import { useState } from "react";
import Layout from "../components/Layout";
import { MTareasProfesor } from "../components/MTareasProfesor";
import { MNuevaTarea } from "../components/MNuevaTarea";

const TareasDocente = () => {
    const [mostrarRevisadas, setMostrarRevisadas] = useState(false);
    const [mostrarNuevaTarea, setMostrarNuevaTarea] = useState(false);

    return (
        <Layout>
            <div className="header-for-bg">
                <div className="background-header position-relative">
                    <img
                        src="https://ibloom.com.mx/pangu/tareas.jpg"
                        className="img-fluid w-100"
                        alt="header-bg"
                    />
                    <div className="title-on-header">
                        <div className="data-block">
                            <h2>Tareas</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-page" className="content-page">
                <div className="container ">
                    <div className="row">
                        <div className="col-sm-12">

                            <div className="container">
                                <div className="tareas">
                                    {/*Card*/}
                                    <div className="card">
                                        {/*Card Header*/}
                                        <div className="card-header">
                                            <p> <i className="fa fa-home ml-4" aria-hidden="true" /> Tareas <a className="btnnuevatarea" onClick={() => setMostrarNuevaTarea(!mostrarNuevaTarea)}>Nueva</a> <span className="float-right"> <span className="mr-4 navTask">Todas</span> <span className="mr-4" onClick={() => setMostrarRevisadas(!mostrarRevisadas)}>Revisadas</span> <i className="fa fa-search" aria-hidden="true" /> </span> </p>
                                        </div>
                                        {/*Card Body*/}
                                        <div className="card-body">
                                            <p className="heading1"><span className="tomorrow">Mañana</span> <span className="float-right headingright">2
                                                tareas</span> </p>
                                            <p> <i className="fa fa-calculator ml-2" aria-hidden="true" /> <span className="task mt-4">Matemáticas</span>
                                                <span className="time ml-2">Alumnos</span> <span className="float-right">Nueva</span> </p>
                                            <p><i className="fa fa-file-text-o ml-2" aria-hidden="true" />
                                                <span className="task">Español</span> <span className="time ml-2">Alumnos</span> <span className="float-right">Nueva</span>
                                            </p>
                                            <p className="heading2"> <span className="today">Hoy</span> <span className="float-right headingright">5 tareas</span>
                                            </p>
                                            <p> <i className="fa fa-calculator ml-2" aria-hidden="true" /> <span className="task mt-4">Matemáticas</span>
                                                <span className="time ml-2">Alumnos</span> <span className="float-right">En revisión</span> </p>
                                            <p><i className="fa fa-keyboard-o ml-2" aria-hidden="true" /> <span className="task">Computación</span> <span className="time ml-2">Alumnos</span><span className="float-right">Sin revisar</span> </p>
                                            <p><i className="fa fa-eyedropper ml-2" aria-hidden="true" /> <span className="task">Química</span> <span className="time ml-2">Alumnos</span> <span className="float-right">En revisión</span> </p>
                                            <p><i className="fa fa-newspaper-o ml-2" aria-hidden="true" />
                                                <span className="task">Historia</span> <span className="time ml-2">Alumnos</span> <span className="float-right">Sin
                                                    revisar</span>
                                            </p>
                                            <p><i className="fa fa-file-text-o ml-2" aria-hidden="true" />
                                                <span className="task">Español</span> <span className="time ml-2">Alumnos</span> <span className="float-right">En
                                                    revisión</span>
                                            </p>
                                            <p className="heading2"><span className="tomorrow">Ayer</span> <span className="float-right headingright">2
                                                tareas</span> </p>
                                            <p> <i className="fa fa-calculator ml-2" aria-hidden="true" /> <span className="task mt-4">Matemáticas</span>
                                                <span className="time ml-2">Alumnos</span> <span className="float-right">Revisada</span> </p>
                                            <p><i className="fa fa-file-text-o ml-2" aria-hidden="true" />
                                                <span className="task">Español</span> <span className="time ml-2">Alumnos</span> <span className="float-right">Revisada</span>
                                            </p>
                                            <p className="heading2"><span className="tomorrow">Hace 2 días</span> <span className="float-right headingright">3
                                                tareas</span> </p>
                                            <p> <i className="fa fa-calculator ml-2" aria-hidden="true" /> <span className="task mt-4">Matemáticas</span>
                                                <span className="time ml-2">Alumnos</span> <span className="float-right">Revisada</span> </p>
                                            <p><i className="fa fa-file-text-o ml-2" aria-hidden="true" />
                                                <span className="task">Español</span> <span className="time ml-2">Alumnos</span> <span className="float-right">Revisada</span>
                                            </p>
                                            <p><i className="fa fa-newspaper-o ml-2" aria-hidden="true" />
                                                <span className="task">Historia</span> <span className="time ml-2">Alumnos</span> <span className="float-right">Revisada</span>
                                            </p>
                                            <a className="vermastareas" href="#">Ver más tareas</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {mostrarRevisadas && <MTareasProfesor show={mostrarRevisadas} onHide={() => setMostrarRevisadas(!mostrarRevisadas)} />}
                            {mostrarNuevaTarea && <MNuevaTarea show={mostrarNuevaTarea} onHide={() => setMostrarNuevaTarea(!mostrarNuevaTarea)} />}
                            
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default TareasDocente;