import Layout from "../components/Layout";
import { useTranslation } from 'react-i18next';
import { useAuth } from '../authContext';
import { useEffect, useState } from 'react';
import { deleteFriend, ensureUserLoaded } from '../services/userHelper';
import Swal from 'sweetalert2';
import { db, collection, query, onSnapshot } from '../firebaseConfig';
import { createVideocall } from "../services/videocallHelper";
import { getUserFriends } from "../services/friendHelper";
import { Link } from "react-router-dom";

const Amigos = () => {
  const { t } = useTranslation();
  const { user, userData, setUserData } = useAuth();
  const [friends, setFriends] = useState([]);
  const collectionName = "users";

  const getFriends = async (uid) => {
    var q = query(collection(db, collectionName, uid, "friends"));
    const unsub = onSnapshot(q, async (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ uid: doc.id, ...doc.data() });
      });
      getUserFriends(docs).then((friends) => {
        setFriends(friends);
      });

    });
    return () => unsub;
  }

  const init = async (uid) => {
    await ensureUserLoaded(uid, userData, setUserData);
    await getFriends(uid); 
  }

  useEffect(() => {
    init(user.uid);
  }, [user.uid]);

  const onVideoCall = async (userTo) => {
    const roomUid = await createVideocall({ uidFrom: user.uid, uidTo: userTo, callerName: user.displayName });
    if (roomUid) {
      window.open(`/videocall/${roomUid}`);
    }
  }

  var deleteFriendEvent = function (idUserCurrent, idUserFriend) {
    Swal.fire({
      title: t('friends_alert_delete_title'),
      text: t('friends_alert_delete_info'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('friends_alert_delete_confirm_button'),
      cancelButtonText: t('friends_alert_delete_cancel_button'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        let x = await deleteFriend(idUserCurrent, idUserFriend);
        if (typeof x[0] == "undefined" && typeof x[1] == "undefined") {
          Swal.fire(
            t('friends_alert_delete_success_title'),
            t('friends_alert_delete_success_info'),
            'success'
          )
        }
      }
    })



  }

  return (

    <Layout>
      <div className="header-for-bg">
        <div className="background-header position-relative">
          <img
            src="https://ibloom.com.mx/pangu/amigos.jpg"
            className="img-fluid w-100"
            alt="header-bg"
          />
          <div className="title-on-header">
            <div className="data-block">
              <h2>{t('friends_label_principal')}</h2>
            </div>
          </div>
        </div>
      </div>
      <div id="content-page" className="content-page">
        <div className="container">
          <div className="row">
            {
              friends.map((friend) => {
                let x = (
                  <div className="col-md-6">
                    <div className="iq-card">
                      <div className="iq-card-body profile-page p-0">
                        <div className="profile-header-image">
                          <div className="cover-container">
                            <img
                              src={friend.cover ? friend.cover : 'https://firebasestorage.googleapis.com/v0/b/pangea-daee0.appspot.com/o/cover%2FTxuKt54ExEVi4QF5draqOxxFDRw1?alt=media&token=a37dc880-eff6-4959-8c9b-0f3c9f0dc986'}
                              alt="profile-bg"
                              className="rounded img-fluid w-100"
                            />
                          </div>
                          <div className="profile-info p-4">
                            <div className="user-detail">
                              <div className="d-flex flex-wrap justify-content-between align-items-start">
                                <div className="profile-detail d-flex">
                                  <div className="profile-img pr-4">
                                    <img
                                      id={'friends-profile-fr-' + friend.uid}
                                      src={friend.profilePicture}
                                      alt="profile-img"
                                      className="avatar-130 img-fluid"
                                      style={{ heigth: '11vh' }}
                                    />
                                  </div>
                                  <div className="user-data-block">
                                    <h4 className="">{`${friend.firstName} ${friend.lastName}`}</h4>
                                    <div>
                                      {
                                        userData?.subscription?.isPremium && 
                                        <button onClick={() => onVideoCall(friend.uid)} className="btn iconoazul">
                                          <i className="ri-vidicon-line ri-2x"></i>
                                        </button>
                                      }
                                      <Link to={`/chat/${friend.uid}`} className="btn iconoazul d-none">
                                        <i class="ri-message-2-line ri-2x"></i>
                                      </Link>
                                    </div>
                                    <h6>{t(friend.userType)}</h6>

                                    <div className="row">
                                      <div className="col-lg-6 col-md-12 mt-2">
                                        <a href={"viewuser/" + friend.uid} className="btn btn-lg btn-primary mr-2" style={{ width: "max-content" }}>
                                          {t('friends_button_view_profile')}
                                        </a>
                                      </div>
                                      <div className="col-lg-6 col-md-12 mt-2">
                                        <button type="submit" className="btn btn-lg btn-dark" onClick={() => deleteFriendEvent(user.uid, friend.uid)}>
                                          {t('friends_button_remove_friend')}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
                return x;
              })
            }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Amigos;