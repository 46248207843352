import React, { useState } from 'react'

export const DropLanguajes = ({ t, i18n }) => {

    const languajeStorage = localStorage.getItem('languaje'); 

    const [languaje, setLanguaje] = useState(languajeStorage ? languajeStorage : 'es');


    const options = [
        { label: 'change_language_es', value: 'es' },
        { label: 'change_language_en', value: 'en' }
    ];
    
    const handleChange = (e) => {
        i18n.changeLanguage(e.target.value);
        setLanguaje(e.target.value);
        localStorage.setItem('languaje', e.target.value);
    }

    return (
        <div className="col select1">
            <select value={languaje} onChange={handleChange}>
                {options.map((option, idx) => (
                    <option key={idx} value={option.value}>{t(option.label)}</option>
                ))}
            </select>
        </div>
    )
}
