import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../authContext';

const ModalViewCourse = (props) => {
    const { t } = useTranslation();
    const { userData } = useAuth();

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.course?.nombre}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-course-preview">
                    <video controls controlsList="nodownload">
                        <source src={props.course?.url} />
                    </video>
                </div>
            </Modal.Body>
            {
                (userData?.subscription?.isPremium && !userData?.subscription?.isTrial) && <Modal.Footer>
                    <a href={props.course?.url} download className="btn btn-danger">{t('courses_download')}</a>
                </Modal.Footer>
            }
        </Modal>
    );
}
export default ModalViewCourse;