import { t } from "i18next";
import Swal from "sweetalert2";

export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const getInitials = (name) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return;

    return initials.toUpperCase();
};



export const createImageFromInitials = (size, name, color) => {
    if (name == null) return;
    name = getInitials(name)

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size

    context.fillStyle = "#ffffff"
    context.fillRect(0, 0, size, size)

    context.fillStyle = `${color}50`
    context.fillRect(0, 0, size, size)

    context.fillStyle = color;
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.font = `${size / 2}px Montserrat`
    context.fillText(name, (size / 2), (size / 2))

    return canvas.toDataURL()
};

export const isValidEmail = (email) => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !(!email || regex.test(email) === false);
}

export const ShowSwalAlert = (obj) => {
    Swal.fire({
        icon: obj.icon,
        title: obj.title
      });
}

export const ShowSwalAlertWithCallback = (obj) => {
    Swal.fire({
        icon: obj.icon,
        title: obj.title
      }).then(obj.fn);
}

export const confirmDelete = (obj) => {
    Swal.fire({
        icon: obj.icon,
        title: obj.title,
        text: obj.text,
        showCancelButton: true,
        confirmButtonText: t('confirm_delete_yes'),
        cancelButtonText: t('confirm_delete_No'),
      }).then(obj.fn);
}