import React, { useState } from 'react'
import BarSideChat from './BarSideChat'
import Chat from '../../../components/chat/Chat';

function ScreenChat({ friendData, setFriendData }) {
  return (
    <div id="content-page" className="content-page">
        <div className="container padding90">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-body chat-page p-0">
                  <div className="chat-data-block">
                    <div className="row">
                      <BarSideChat friendData={friendData} setFriendData={setFriendData} />
                      <Chat friendData={friendData} fromAllFriends={true} />                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
export default ScreenChat


