import { addDoc, collection, doc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const createVideocall = async ({uidFrom, uidTo, callerName}) => {
    const currentDate = new Date();
    const videoCallId = `${uidFrom}_${uidTo}`
    var futureDate = new Date(currentDate.getTime() + 5 * 60000);
    try {
      await setDoc(doc(db, `users/${uidTo}/videocalls/${uidFrom}`), {
        userFrom: uidFrom,
        userTo: uidTo,
        validUntil: futureDate,
        status: "pending",
        isCaller: true,
        id: videoCallId,
        callerName: callerName
      });
      await setDoc(doc(db, `users/${uidFrom}/videocalls/${uidTo}`), {
        userFrom: uidFrom,
        userTo: uidTo,
        status: "pending",
        isCaller: false,
        id: videoCallId
      });
      return videoCallId;

    } catch (error) {
      console.log("error createVideocall", error);
    }
}

export const listenVideoCall = async (userId, setShowIncomingCall, setIncomingCall) => {
  const q = query(collection(db, `users/${userId}/videocalls`))
  const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added" || change.type === "modified") {
          const incomingCall = change.doc.data();
          if(incomingCall.status === "pending" && incomingCall.isCaller){
            setShowIncomingCall(true);
            setIncomingCall(incomingCall);
          }
      }
    });
  });
  return () => unsubscribe();
}

export const onVideoCallCanceled = async (userId) => {
  const q = query(collection(db, `users/${userId}/videocalls`))
  const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const incomingCall = change.doc.data();
      if(change.type === "modified"){
        if(incomingCall.status === "canceled"){
          //window.close();
        }
      }
    });
  });
  return () => unsubscribe();
}

export const acceptedVideoCall = async(incomingCall, onHide) => {
  try {
    updateDoc(doc(db, `users/${incomingCall.userTo}/videocalls/${incomingCall.userFrom}`),{
      status: "accepted"
    });
    onHide();
    window.open(`/videocall/${incomingCall.id}`);
  } catch (error) {
    console.log("error acceptedVideoCall", error);
  }
}

export const cancelVideoCall = async(incomingCall, onHide) => {
  try {
    updateDoc(doc(db, `users/${incomingCall.userTo}/videocalls/${incomingCall.userFrom}`),{
      status: "canceled"
    });
    setDoc(doc(db, `users/${incomingCall.userFrom}/videocalls/${incomingCall.userTo}`), {status: "canceled"});
    onHide();
  } catch (error) {
    console.log("error acceptedVideoCall", error);
  }
}