import Layout from "../components/Layout";

const Calificaciones = () => {

    return (
        <Layout>
            <div className="header-for-bg">
                <div className="background-header position-relative">
                    <img
                        src="https://ibloom.com.mx/pangu/calificaciones.jpg"
                        className="img-fluid w-100"
                        alt="header-bg"
                    />
                    <div className="title-on-header">
                        <div className="data-block">
                            <h2>Calificaciones</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-page" className="content-page">
                <div className="container ">
                    <div className="row">
                        <div className="col-sm-12">
                            <div>
                                <div className="container calificaciones">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="card" style={{ width: '18rem' }}>
                                                <img className="card-img-top" src="https://ibloom.com.mx/pangu/boleta.png" alt="Card image cap" />
                                                <div className="card-body">
                                                    <h5 className="card-title">Promedio General: 8</h5>
                                                    <p className="card-text">Calendario Escolar 2022-2023</p>
                                                    <a href="https://ibloom.com.mx/pangu/boleta.png" download className="btn btn-primary">Descargar</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="card" style={{ width: '18rem' }}>
                                                <img className="card-img-top" src="https://ibloom.com.mx/pangu/boleta.png" alt="Card image cap" />
                                                <div className="card-body">
                                                    <h5 className="card-title">Promedio General: 8</h5>
                                                    <p className="card-text">Calendario Escolar 2020-2021</p>
                                                    <a href="https://ibloom.com.mx/pangu/boleta.png" download className="btn btn-primary">Descargar</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="card" style={{ width: '18rem' }}>
                                                <img className="card-img-top" src="https://ibloom.com.mx/pangu/boleta.png" alt="Card image cap" />
                                                <div className="card-body">
                                                    <h5 className="card-title">Promedio General: 8</h5>
                                                    <p className="card-text">Calendario Escolar 2019-2020</p>
                                                    <a href="https://ibloom.com.mx/pangu/boleta.png" download className="btn btn-primary">Descargar</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-5">
                                        <a href="#" className="btn btn-primary">Ver más</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Calificaciones;