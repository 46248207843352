import { useState } from "react";
import Picker from 'emoji-picker-react';
import { useTranslation } from "react-i18next";
import { traducirInput } from "../../services/chatHelper";
import Spinner from "react-bootstrap/esm/Spinner";

const ChatFooter = ({ inputMessage, setInputMessage, setIsRecording, handleFileChange, isFileSeleted, sendMessages, isSubmitting }) => {
    const { t } = useTranslation();
    const [showPicker, setShowPicker] = useState(false);
    const selectChannel = t("chat_message_placeholder");
    const messageValue = (/^\s/.test(inputMessage)) ? "" : inputMessage;
    const lang = localStorage.getItem('languaje') || "es"; 

    const onEmojiClick = (evt, emojiObject) => {
        evt.preventDefault();
        setInputMessage(prevInput => prevInput + emojiObject.emoji);
        setShowPicker(false)
    }

    return (
        <>
            <div className="chat-footer p-3 bg-white">
                <form className="d-flex align-items-center justify-content-between p-relative"
                    onSubmit={sendMessages}
                >
                    <div className="chat-attagement d-flex justify-content-center justify-content-center">
                        <button className="Emojis">
                            <i className="fa fa-smile-o mr-2" aria-hidden="true" onClick={(evt) => { evt.preventDefault(); setShowPicker(!showPicker); }}/>
                            {showPicker &&
                                <Picker
                                    native
                                    disableAutoFocus
                                    onEmojiClick={onEmojiClick}
                                    pickerStyle={{
                                        width: '95%',
                                        height: '50%',
                                        position: 'absolute',
                                        cursor: 'pointer',
                                        bottom: '70px'
                                    }}
                                />
                            }
                        </button>
                    </div>
                    <div className="chat-attagement d-flex">
                        <label htmlFor='img'>
                            <i className="fa fa-paperclip pr-3 mt-3" aria-hidden="true" style={{ cursor: 'pointer' }} />
                        </label>
                        <input type="file" id="img" accept='image/*, video/mp4, video/m4v' style={{ display: 'none' }}
                            className="file" onChange={handleFileChange}
                        />
                    </div>
                    <div className='mr-2' onClick={() => { traducirInput({ inputMessage, setInputMessage, lang}) }}>
                        <i className="fa fa-exchange" aria-hidden="true" style={{ cursor: 'pointer' }}></i>
                    </div>
                    <input
                        type="text"
                        placeholder={selectChannel}
                        className="form-control mr-3"
                        value={messageValue}
                        required={!isFileSeleted}
                        onChange={e => {
                            setInputMessage(e.target.value)
                        }}
                    />
                    <>
                        {inputMessage || isFileSeleted ? 
                            <button type="submit" disabled={isSubmitting}
                                className="btn btn-primary d-flex align-items-center p-2">
                                    <i className="fa fa-paper-plane-o" aria-hidden="true"/>
                                    {" "}
                                    {isSubmitting && <Spinner animation="border"  variant="light" size="sm" />} 
                                <span className="d-none d-lg-block ml-1">{t("chat_send")}</span>
                            </button> :
                            <h3 className='text-primary'>
                                <i className="fa fa-microphone" aria-hidden="true"
                                    onClick={() => {
                                        setIsRecording(true)
                                    }}
                                ></i>
                            </h3>
                        }
                    </>
                </form>
            </div>
        </>
    )
}

export default ChatFooter;