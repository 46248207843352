import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebaseConfig";
const noop = () => { }

export const uploadFileToStorage = async (path, file, metadata = null, setProgress = noop) => {
    return new Promise(async (resolve, reject) => {
        const storageRef = ref(storage, path);
        const uploadTask = metadata ? uploadBytesResumable(storageRef, file, metadata) : uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed", (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(progress);
        },
            (error) => {
                reject(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL);
                });
            }
        );
    });
}

export const deleteFileStorage = async (fileUrl) => {
    let result = false;
    const fileRef = ref(storage, fileUrl);
    await deleteObject(fileRef).then(() => result = true);
    return result;
}
