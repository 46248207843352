import { useState } from "react";
import CourseFilter from "./CourseFilter";
import CoursesByLevel from "./CoursesByLevel";

const FrenchCourses = () => {
    const [level, setLevel] = useState("All");

    return(
        <>
            <div className="courses">
                <CourseFilter level={level} setLevel={setLevel} />
                <CoursesByLevel lang="french" level={level} />
            </div>
        </>
    );
}

export default FrenchCourses;