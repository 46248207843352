export const paypalConfig = {
    "client-id": "AZmSK2-_R0xb7nJTjYom2dv5vI24dnkulNbx6yFrw0BhHEHWsljkk5yWkyzDsgYfVKdzh9ClDuu8Qmzh",
    currency: "MXN",
    intent: "capture",
};

export const paypalSubscription = {
    "client-id": "ASY8TtRU9xUXDu2Gg59DQUHCJySjC1oTuCOHjcNXWW2fpmrFynsVCKNApLZ36e8Kr6t4Mm1EFUAiNaIl",
    currency: "MXN",
    intent: "subscription",
    vault:true
};

export const paypalSubscriptionTEST = {
    "client-id": "AbiG6Y5sI1Rk5J2sNt1Y8vZ9FtSD17_aW9QxNnsWgAXFB6qhWZln94oiuCwcYUT0WOPMrwJtxUWumuwL",
    currency: "MXN",
    intent: "subscription",
    vault:true
};