import { useEffect } from "react";
import { useAuth } from "../authContext";
import { getUserByUid } from "../services/authHelper";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = ({children}) => {
    const { user, userData, setUserData } = useAuth();
    const loadUserData = async(uid) => {
        let currentUserData = userData || null;
        if (userData == null) {
            currentUserData = await getUserByUid(uid);
            setUserData(currentUserData);
        }
    }

    useEffect(() => {
        loadUserData(user.uid);
    }, []);

    return (
        <>
            <Header />
            <Sidebar />
            <div id="content-page" className="content-page">
                {children}
            </div>
        </>
    );
}

export default Layout;