import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getLessonsById, getStudentsByLesson, updatedLessonStudent } from "../../services/lessonsHelper";
import DataTable from 'react-data-table-component';
import { useTranslation } from "react-i18next";

const StudentsByLesson = () => {
    const { lessonId } = useParams();
    const [lesson, setLesson] = useState(null);
    const [students, setStudents] = useState([]);
    const [calif, setCalif] = useState(0);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const loadLesson = async (lessonId) => {
        const result = await getLessonsById(lessonId);
        const studentList = await getStudentsByLesson(lessonId);
        setLesson(result);
        setStudents(studentList);
    }

    const updateStudentList = (student, updatedStatus) => {
        const updatedStudents = students.map((s) => {
            if(s.id === student.id){
                const updatedStudent = {...s, ...updatedStatus};
                return updatedStudent;
            }
            return s;
        });
        setStudents(updatedStudents);
    }

    const acceptStudent = async (student) => {
        const updatedStatus =  { status: "accepted" };
        await updatedLessonStudent(lessonId, student.id, updatedStatus, true).then((result) => {
           if(result){
            updateStudentList(student, updatedStatus);
           }
        });
    }

    const refusedStudent = async(student) => {
        const updatedStatus =  { status: "refused" };
        await updatedLessonStudent(lessonId, student.id, updatedStatus).then((result) => {
            if(result){
             updateStudentList(student, updatedStatus);
            }
         });
    } 

    const updateStudentScore = async(student) => {
        const updatedScore =  { score: student.score || 0 };
        await updatedLessonStudent(lessonId, student.id, updatedScore).then((result) => {
            if(result){
             updateStudentList(student, updatedScore);
             //Show modal success
            }
         });
    } 

    const onCalifChange = (evt, student) => {
        const regex = /^[1-10]$/;
        const value = evt.target.value;
        const updatedScore = { score: parseInt(value) };
        updateStudentList(student, updatedScore);
        // if (value === "" || regex.test(value)) {
        //     const updatedScore = { score: parseInt(value) };
        //     updateStudentList(student, updatedScore);
        // }
    }

    const COLUMNS = [
        { name: "Nombre", selector: row => row.fullName },
        { name: "Estatus", cell: row => (
            <>
                {row.status === "accepted" && <span>Inscripto</span>}
                {row.status === "refused" && <span>Rechazado</span>}
                {row.status === "pending" && <span>Pendiente de aprobación</span>}
            </>
        )},
        {
            cell: row => (
                <>
                    {row.status === "pending" && <>
                        <button className="btn btn-light mr-1" onClick={() => acceptStudent(row)}><i class="ri-check-line"></i></button>
                        <button className="btn btn-light mr-1" onClick={() => refusedStudent(row)}><i class="ri-close-line"></i></button>
                    </>}
                </>
            )
        },
        { name: "Calificación", cell: row => (
                <div className="form-row m-1">
                    <input type="number" value={row.score} onChange={(evt) => onCalifChange(evt, row)} className="form-control mb-1" />
                    <button className="btn btn-light" onClick={() => updateStudentScore(row)}>Guardar</button>
                    {/* {JSON.stringify(row)} */}
                </div>
            )
        }
    ];

    useEffect(() => {
        loadLesson(lessonId);
    }, [lessonId]);

    return (
        <div className="iq-card px-2">
            <div className="iq-card-header">
                <div className="row justify-content-between">
                    <div class="col-sm-12 col-md-6">
                        <h2 className="pt-2">{lesson?.title}</h2>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div className="d-flex float-right">
                            <button onClick={() => navigate("/lessons/my")} className="btn btn-light mt-2"> <i class="ri-arrow-go-back-fill"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iq-card-body">
                <div className="containder">
                    <div className="row">
                        <h4>Alumnos inscriptos</h4>
                        <div className="table-responsive">
                            <DataTable columns={COLUMNS} data={students} pagination paginationComponentOptions={{ rowsPerPageText: t("datatable_rows_PerPageText") }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentsByLesson;