export const COUNTRIES = [
    { id: 4, code: "DE", country: "Alemania" },
    { id: 13, code: "AR", country: "Argentina" },
    { id: 47, code: "CN", country: "China" },
    { id: 73, code: "ES", country: "España" },
    { id: 75, code: "US", country: "Estados Unidos" },
    { id: 146, code: "MX", country: "México" },
];

export const LANGUAGES_ES = [
    { value: "spanish", label: "Español" },
    { value: "english", label: "Inglés" },
    { value: "french", label: "Francés" },
    { value: "german", label: "Alemán" }
];

export const LANGUAGES_EN = [
    { value: "spanish", label: "Spanish" },
    { value: "english", label: "English" },
    { value: "french", label: "French" },
    { value: "german", label: "German" }
];

export const GENDERS = [
    { id: 1, value: "Male", label: "Hombre" },
    { id: 2, value: "Female", label: "Mujer" }
];

export const LANGUAGELEVEL = [
    {id: 1, value: "a1", label: "a1"},
    {id: 2, value: "a2", label: "a2"},
    {id: 3, value: "b1", label: "b1"},
    {id: 4, value: "b2", label: "b2"},
    {id: 5, value: "c1", label: "c1"},
    {id: 6, value: "c2", label: "c2"}
]

export const EXCERCISELEVELS = [
    {id: 1, value: "A1", label: "a1"},
    {id: 2, value: "A2", label: "a2"},
    {id: 3, value: "B1", label: "b1"},
    {id: 4, value: "B2", label: "b2"},
    {id: 4, value: "C1", label: "c1"},
    {id: 4, value: "C2", label: "c2"}
]