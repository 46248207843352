import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { es_ES } from './es_ES';
import { en_US } from './en_US';



const resources = {
  es: es_ES,
  en: en_US
};
const currentLang = localStorage.getItem('languaje');

i18n.use(initReactI18next).init({
  resources,
  lng: currentLang || 'es',
  
  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;