import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../authContext";
import Chat from "../../components/chat/Chat";
import Layout from "../../components/Layout";
import { getFriendData, isMyFriend } from "../../services/friendHelper";

const FriendChat = () => {
    const { user } = useAuth();
    const { friendId } = useParams();
    const [friendData, setFriendData] = useState(null);
    const [isFriend, setIsFriend] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const loadFriendData = async() => {
        const isFriend = await isMyFriend(user.uid, friendId);
        if(isFriend){
            setIsFriend(true);
            getFriendData(friendId, setFriendData);
            // .then((friendData) => {
            //     setIsFriend(true);
            //     setFriendData(friendData);
            // }).finally(() => setIsLoading(false))
        }else{
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadFriendData();
    }, [friendId]);

    useEffect(() => {
        if(friendData){
            setIsLoading(false);
        }
    }, [friendData]);

    if(isLoading){
        return null;
    }
    
    if(!isFriend){
        navigate(`/viewuser/${friendId}`);
    }

    return (
        <Layout>
            <div id="content-page" className="content-page">
                <div className="container padding90">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div className="iq-card-body chat-page p-0">
                                    <div className="chat-data-block">
                                        <div className="row">
                                            {(isFriend && friendData) && <Chat friendData={friendData} fromAllFriends={false} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default FriendChat;