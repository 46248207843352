import { async } from "@firebase/util";
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

const collectionName = "examenes";

export const getAllTests = async (uid, setTests) => {
    const q = query(collection(db, collectionName), orderBy("orden"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
        const tests = [];
        snapshot.forEach((doc) => {
            tests.push({ id: doc.id, ...doc.data() });
        });
        markUserTestCompleted(uid, tests).then((result) => setTests(result));
    });

    return () => unsubscribe();
}

const markUserTestCompleted = async (uid, tests) => {
    let result = [];
    for (const test of tests) {
        const userTestInfo = await getUserTestInfo(uid, test.id);
        if (userTestInfo) {
            let score = 0;
            test.isCompleted = userTestInfo.status === 3;
            test.urlStatus = test.isCompleted ? "https://pangulearning.com/images/ecompletado.png" : "https://pangulearning.com/images/enocompletado.png";
            if (userTestInfo.grammarandvocabulary) {
                score = score + userTestInfo.grammarandvocabulary;
            }
            if (userTestInfo.listening) {
                score = score + userTestInfo.listening;
            }
            if (userTestInfo.reading) {
                score = score + userTestInfo.reading;
            }
            test.score = score;
        }
        result.push(test);
    }
    return result;
}

export const getTitleWithRoute = async (uid, diagnosticId) => {
    let data = [];
    const userTestInfo = await getUserTestInfo(uid, diagnosticId);
    if (diagnosticId === "FZoWXqmkOQVVCQXywbQ0") {
        data = [
            {
                id: 1, titulo: "Grammar and Vocabulary", to: "grammar",
                isCompleted: userTestInfo.hasOwnProperty("grammarandvocabulary"),
                urlStatus: userTestInfo.hasOwnProperty("grammarandvocabulary") ? "https://pangulearning.com/images/ecompletado.png" : "https://pangulearning.com/images/enocompletado.png",
                score: userTestInfo.hasOwnProperty("grammarandvocabulary") ? userTestInfo.grammarandvocabulary : 0
            },
            {
                id: 2, titulo: "Reading", to: "a-reading",
                isCompleted: userTestInfo.hasOwnProperty("reading"),
                urlStatus: userTestInfo.hasOwnProperty("reading") ? "https://pangulearning.com/images/ecompletado.png" : "https://pangulearning.com/images/enocompletado.png",
                score: userTestInfo.hasOwnProperty("reading") ? userTestInfo.reading : 0
            },
            {
                id: 3, titulo: "Listening", to: "a-listening",
                isCompleted: userTestInfo.hasOwnProperty("listening"),
                urlStatus: userTestInfo.hasOwnProperty("listening") ? "https://pangulearning.com/images/ecompletado.png" : "https://pangulearning.com/images/enocompletado.png",
                score: userTestInfo.hasOwnProperty("listening") ? userTestInfo.listening : 0
            }
        ]
    } else if (diagnosticId === "8qFy4EXGGBQygnvBsFAO") {
        data = [
            {
                id: 1, titulo: "Grammar and Vocabulary", to: "grammar", isCompleted: userTestInfo.hasOwnProperty("grammarandvocabulary"),
                urlStatus: userTestInfo.hasOwnProperty("grammarandvocabulary") ? "https://pangulearning.com/images/ecompletado.png" : "https://pangulearning.com/images/enocompletado.png",
                score: userTestInfo.hasOwnProperty("grammarandvocabulary") ? userTestInfo.grammarandvocabulary : 0
            },
            {
                id: 2, titulo: "Reading", to: "bc-reading",
                isCompleted: userTestInfo.hasOwnProperty("reading"),
                urlStatus: userTestInfo.hasOwnProperty("reading") ? "https://pangulearning.com/images/ecompletado.png" : "https://pangulearning.com/images/enocompletado.png",
                score: userTestInfo.hasOwnProperty("reading") ? userTestInfo.reading : 0
            },
            {
                id: 3, titulo: "Listening", to: "c-listening",
                isCompleted: userTestInfo.hasOwnProperty("listening"),
                urlStatus: userTestInfo.hasOwnProperty("listening") ? "https://pangulearning.com/images/ecompletado.png" : "https://pangulearning.com/images/enocompletado.png",
                score: userTestInfo.hasOwnProperty("listening") ? userTestInfo.listening : 0
            }
        ]
    } else if (diagnosticId === "wr0qO3NFhUtP71HhWwLk") {
        data = [
            {
                id: 1, titulo: "Grammar and Vocabulary", to: "grammar",
                isCompleted: userTestInfo.hasOwnProperty("grammarandvocabulary"),
                urlStatus: userTestInfo.hasOwnProperty("grammarandvocabulary") ? "https://pangulearning.com/images/ecompletado.png" : "https://pangulearning.com/images/enocompletado.png",
                score: userTestInfo.hasOwnProperty("grammarandvocabulary") ? userTestInfo.grammarandvocabulary : 0
            },
            {
                id: 2, titulo: "Listening", to: "b-listening",
                isCompleted: userTestInfo.hasOwnProperty("listening"),
                urlStatus: userTestInfo.hasOwnProperty("listening") ? "https://pangulearning.com/images/ecompletado.png" : "https://pangulearning.com/images/enocompletado.png",
                score: userTestInfo.hasOwnProperty("listening") ? userTestInfo.listening : 0
            },
            {
                id: 3, titulo: "Reading", to: "bc-reading",
                isCompleted: userTestInfo.hasOwnProperty("reading"),
                urlStatus: userTestInfo.hasOwnProperty("reading") ? "https://pangulearning.com/images/ecompletado.png" : "https://pangulearning.com/images/enocompletado.png",
                score: userTestInfo.hasOwnProperty("reading") ? userTestInfo.reading : 0
            }]
    }

    return data;
}

export const getDiagnosticById = async (diagnosticId) => {
    let data = {};
    try {
        const docRef = doc(db, collectionName, diagnosticId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            data = { id: docSnap.id, ...docSnap.data() };
        }
    } catch (error) {
        console.log("error getDiagnosticById", error);
    }
    return data;
}

export const getGrammarDiagnostic = async (diagnosticId) => {
    let path = undefined;
    if (diagnosticId === "FZoWXqmkOQVVCQXywbQ0") {
        path = `${diagnosticId}/grammarandvocabulary/33kIK2xDRKtJiHLxAUe1/preguntas`;
    } else if (diagnosticId === "8qFy4EXGGBQygnvBsFAO") {
        path = `${diagnosticId}/grammarandvocabulary/0bGAcTnIqWQeMEOnfgx7/preguntas`;
    } else if (diagnosticId === "wr0qO3NFhUtP71HhWwLk") {
        path = `${diagnosticId}/grammarandvocabulary/Pv1MInlWdFn3t4KBd77I/preguntas`;
    }
    return getGrammarQuestions(path);
}

export const isTopicEvaluated = async (uid, id, key) => {
    let evaluated = false;
    const docRef = doc(db, `users/${uid}/examenes/${id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const result = { id: docSnap.id, ...docSnap.data() };
        if (result.hasOwnProperty(key) && result[key] >= 0) {
            evaluated = true;
        }
    }
    return evaluated;
}

export const isUserTestExist = async (uid, id) => {
    let exists = false;
    const docRef = doc(db, `users/${uid}/examenes/${id}`);
    const docSnap = await getDoc(docRef);
    exists = docSnap.exists()
    return exists;
}

export const createInitialUserTest = async (uid, id) => {
    const exists = await isUserTestExist(uid, id);
    if (exists === false) {
        try {
            const docRef = doc(db, `users/${uid}/examenes/${id}`);
            setDoc(docRef, { status: 0 }, { merge: true });
        } catch (error) {
            console.log("error createInitialUserTest", error);
        }
    }
}

export const getUserTestInfo = async (uid, id) => {
    let data = {};
    try {
        const docRef = doc(db, `users/${uid}/examenes/${id}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            data = { id: docSnap.id, ...docSnap.data() };
        }
    } catch (error) {
        console.log("error getUserTestInfo", error);
    }
    return data;
}
export const saveScore = async (uid, id, data) => {
    try {
        const docRef = doc(db, `users/${uid}/examenes/${id}`);
        setDoc(docRef, data, { merge: true });
    } catch (error) {
        console.log("error saveGrammarScore", error);
    }
}

export const getListeningDiagnostic = async (diagnosticId) => {
    let data = {};
    let path = undefined;

    if (diagnosticId === "FZoWXqmkOQVVCQXywbQ0") {
        path = `${collectionName}/${diagnosticId}/listening/ojnSv4ZdeYcJNrS9Xs0m`;
    } else if (diagnosticId === "8qFy4EXGGBQygnvBsFAO") {
        path = `${collectionName}/${diagnosticId}/listening/kqBVThotvUMt8Pv8kPWP`;
    } else if (diagnosticId === "wr0qO3NFhUtP71HhWwLk") {
        path = `${collectionName}/${diagnosticId}/listening/NpDzk1G0IUyJ3Xcj7MQb`;
    }
    const docRef = doc(db, path);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        data = { id: docSnap.id, ...docSnap.data() };
    }
    return data;
}

export const getBListeningQuestions = async (diagnosticId) => {
    let questions = [];
    try {
        const q = query(collection(db, `${collectionName}/${diagnosticId}/listening/NpDzk1G0IUyJ3Xcj7MQb/preguntas`), orderBy("orden"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            questions.push({ id: doc.id, ...doc.data() })
        });
    } catch (error) {
        console.log("error getAReadingQuestions", error);
    }
    return questions;
}

export const getCListeningQuestions = async (diagnosticId) => {
    let questions = [];
    try {
        const q = query(collection(db, `${collectionName}/${diagnosticId}/listening/kqBVThotvUMt8Pv8kPWP/preguntas`), orderBy("orden"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            questions.push({ id: doc.id, ...doc.data() })
        });
    } catch (error) {
        console.log("error getAReadingQuestions", error);
    }
    return questions;
}

export const getReadingDiagnostic = async (diagnosticId) => {
    let data = {};
    let path = undefined;

    if (diagnosticId === "FZoWXqmkOQVVCQXywbQ0") {
        path = `${collectionName}/${diagnosticId}/reading/1rGE30uSf4KRmzquTYjk`;
    } else if (diagnosticId === "8qFy4EXGGBQygnvBsFAO") {
        path = `${collectionName}/${diagnosticId}/reading/xEekbCLY8EeIZBPjbCME`;
    } else if (diagnosticId === "wr0qO3NFhUtP71HhWwLk") {
        path = `${collectionName}/${diagnosticId}/reading/FSHbKJcSrbSL4wp2RBkJ`;
    }
    const docRef = doc(db, path);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        data = { id: docSnap.id, ...docSnap.data() };
    }
    return data;
}

export const getAReadingQuestions = async (diagnosticId) => {
    let questions = [];
    try {
        const q = query(collection(db, `${collectionName}/${diagnosticId}/reading/1rGE30uSf4KRmzquTYjk/preguntas`), orderBy("orden"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            questions.push({ id: doc.id, ...doc.data() })
        });
    } catch (error) {
        console.log("error getAReadingQuestions", error);
    }
    return questions;
}

export const getGrammarQuestions = async (subPath) => {
    let questions = [];
    try {
        const q = query(collection(db, `${collectionName}/${subPath}`), orderBy("orden"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            questions.push({ id: doc.id, ...doc.data() })
        });
    } catch (error) {
        console.log("error getGrammarQuestions", error);
    }
    return questions;
}

export const computeUserLevel = async (uid) => {
    let userLevel = { total: 110, score: undefined, wrongAnswers: undefined }
    let level = "";
    try {
        let score = 0;
        const q = query(collection(db, `users/${uid}/examenes`));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const testResult = doc.data();
            score = score + testResult.grammarandvocabulary;
            score = score + testResult.listening;
            score = score + testResult.reading;
        });
        if (score >= 0 && score <= 10) {
            level = "Pre-A1";
        }
        else if (score >= 11 && score <= 20) {
            level = "A1";
        }
        else if (score >= 21 && score <= 30) {
            level = "A2";
        }
        else if (score >= 31 && score <= 35) {
            level = "A2+";
        }
        else if (score >= 36 && score <= 45) {
            level = "B1";
        }
        else if (score >= 46 && score <= 55) {
            level = "B1+";
        }
        else if (score >= 56 && score <= 65) {
            level = "B2";
        }
        else if (score >= 66 && score <= 75) {
            level = "B2+";
        }
        else if (score >= 76 && score <= 95) {
            level = "C1";
        }
        else if (score >= 95) {
            level = "C2";
        }
        userLevel.level = level;
        userLevel.score = score;
        userLevel.wrongAnswers = userLevel.total - score
    } catch (error) {
        console.log("error computeUserLevel", error);
    }
    return userLevel;
}

export const saveUserResult = async (uid, data) => {
    try {
        const docRef = doc(db, `users/${uid}`);
        updateDoc(docRef, data);
    } catch (error) {
        console.log("error saveUserResult", error);
    }
}