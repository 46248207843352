import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext";
import { getUserByUid } from "../../services/authHelper";
import { getAllExercises } from "../../services/exercisesHelper";
import { EXCERCISELEVELS } from "../../utils/data";
import { MNuevoEjercicio } from "./MNuevoEjercicio";

const ExerciseList = () => {
    const { t } = useTranslation();
    const { user, userData, setUserData } = useAuth();
    const [exercises, setExercises] = useState([]);
    const [filterLevel, setFilterLevel] = useState('all');
    const [mostrarDetalle, setMostrarDetalle] = useState(false);

    const navigate = useNavigate();
    const filteredExcercises = exercises.filter(e => {
        if (filterLevel === "all") {
            return e;
        } else {
            return e.nivel && e.nivel.toUpperCase().includes(filterLevel)
        }
    })

    const COLUMNS = [{ name: t("lesson_column_title"), selector: row => <span title={row.titulo}>{row.titulo}</span>, sortable: true, width: "300px" },
    { name: t("exercises_column_level"), selector: row => t(row.nivel), sortable: true, width: "200px" },
    { name: t("exercises_column_type"), selector: row => 
    row.tipo ==="1" ? t("exercises_truefalse_option") : 
    row.tipo ==="2" ? t("exercises_multiplechoices_option") : 
    row.tipo ==="3" ? t("exercises_tie_in") : 
    row.tipo ==="4" ? t("exercises_sentence_completion") : 
    row.tipo, sortable: true, width: "200px" },
    { name: t("exercises_completed"), selector: row => <img src={row.urlStatus} alt="status" />, width: "140px" },
    {
        cell: row => (
            <>
                <button title={t("exercises_column_resolve")} onClick={() => navigate(`/exercises/${row.id}`)} className="btn btn-light mr-1"><i class="ri-arrow-right-line"></i></button>
            </>
        ),
        width: "100px"
    }
    ]

    const loadUserData = async(uid) => {
        let currentUserData = userData || null;
        if (currentUserData == null) {
            currentUserData = await getUserByUid(uid);
            setUserData(currentUserData);
        }
        return currentUserData;
    }

    const loadExcercises = async () => {
        const uData = await loadUserData(user.uid);
        const goalLearning = uData?.language?.goalLearning;
        await getAllExercises(user.uid, setExercises, goalLearning, uData?.subscription?.isPremium);
    }

    useEffect(() => {
        loadExcercises();
    }, []);

    return (
        <div className="iq-card px-2">
            <div className="iq-card-body">
                <div className="containder">
                    <div className="row">
                        <div className="form-row mr-2">
                            <div className="form-group">
                                <select id="level" name="level" className="form-control" onChange={(e) => setFilterLevel(e.target.value)}>
                                    <option value="all" selected>{t("exercises_filter_all")}</option>
                                    {EXCERCISELEVELS.map((level, idx) => <option key={idx} value={level.value}>{t(level.label)}</option>)}
                                </select>
                            </div>
                        </div>
                        {userData && userData.userType === "teacher" &&
                                <>
                                    <div className="col-auto">
                                        <button className="btn btn-primary" onClick={() => setMostrarDetalle(!mostrarDetalle) }><i class="ri-add-fill"></i> Nuevo ejercicio</button>
                                    </div>
                                </>
                            }
                        <div className="table-responsive">
                            <DataTable columns={COLUMNS} data={filteredExcercises} pagination defaultSortFieldId={2} paginationComponentOptions={{ rowsPerPageText: t("datatable_rows_PerPageText") }} />
                        </div>
                    </div>
                </div>
            </div>
            {<MNuevoEjercicio show={mostrarDetalle} onHide={()=> setMostrarDetalle(false)} />}
        </div>
    );
}

export default ExerciseList;