import Layout from "../components/Layout";
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from "react-bootstrap";
import { useAuth } from "../authContext";
import { Facebook } from "react-content-loader";
import { useEffect, useState } from "react";
import { getUserByUid } from "../services/authHelper";
import EnglishCourses from "../components/courses/EnglishCourses";
import GermanCourses from "../components/courses/GermanCourses";
import FrenchCourses from "../components/courses/FrenchCourses";

const Courses = () => {
    const { t } = useTranslation();
    const { user, userData, setUserData } = useAuth();
    const [tab, setTab] = useState('english');
    const availableCourses = ["english", "french", "german"];

    useEffect(() => {
        const loadUserData = async (uid) => {
            if (userData == null) {
                await getUserByUid(uid).then(r => {
                    setUserData(r);
                    const goalLearning = r.language.goalLearning;
                    if(availableCourses.includes(goalLearning)){
                        setTab(goalLearning);
                    }
                });
            } else {
                const goalLearning = userData.language.goalLearning;
                if(availableCourses.includes(goalLearning)){
                    setTab(goalLearning);
                }
            }
    
        }
        loadUserData(user.uid);
    }, [user.uid]);

    if (userData == null) {
        return <Facebook />
    }
    
    return (
        <Layout>
            <div className="header-for-bg">
                <div className="background-header position-relative">
                    <img
                        src="https://ibloom.com.mx/pangu/contenido.jpg"
                        className="img-fluid w-100"
                        alt="header-bg"
                    />
                    <div className="title-on-header">
                        <div className="data-block">
                            <h2>{t('courses_main_title')}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-page" className="content-page">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card px-2">
                                <div className="iq-card-body">
                                    <Tabs activeKey={tab}
                                        onSelect={(k) => setTab(k)}
                                        id="courses"
                                        className="mb-3">
                                        <Tab eventKey="english" title={t(`language_english`)}>
                                            <EnglishCourses  />
                                        </Tab>
                                        <Tab eventKey="french" title={t(`language_french`)}>
                                            <FrenchCourses />
                                        </Tab>
                                        <Tab eventKey="german" title={t(`language_german`)}>
                                            <GermanCourses />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Courses;