import moment from "moment";
import { Modal } from "react-bootstrap";

export const MDetalleAlumno = (props) => {
    return (
        <>
            <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Detalle del alumno</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tareas tareas2">
                        {/*Card*/}
                        <div className="card">
                            {/*Card Header*/}
                            <div className="card-header">
                                {/* <p>{props.infoClase.title}</p> */}
                            </div>
                            {/*Card Body*/}
                            <div className="card-body">
                                <div className="container">
                                    <div className="tareas dalumno">
                                        {/*Card*/}
                                        <div className="card">
                                            {/*Card Header*/}
                                            {/*Card Body*/}
                                            <div className="card-body">
                                                <p className="heading1"> <span className="today">Tareas</span> <span className="float-right headingright">5 tareas</span>
                                                </p>
                                                <p> <i className="fa fa-calculator ml-2" aria-hidden="true" /> <span className="task mt-4">Matemáticas</span><span className="time ml-2">En proceso</span> <span className="float-right">Vista: hoy 11 am</span> </p>
                                                <p><i className="fa fa-keyboard-o ml-2" aria-hidden="true" /> <span className="task">Computación</span> <span className="time ml-2">Sin ver</span><span className="float-right">Sin ver</span> </p>
                                                <p><i className="fa fa-eyedropper ml-2" aria-hidden="true" /> <span className="task">Química</span> <span className="time ml-2">Terminada</span> <span className="float-right">Vista: ayer 2 pm</span> </p>
                                                <p><i className="fa fa-newspaper-o ml-2" aria-hidden="true" />
                                                    <span className="task">Historia</span> <span className="time ml-2">Sin ver</span> <span className="float-right">Sin
                                                        ver</span>
                                                </p>
                                                <p><i className="fa fa-file-text-o ml-2" aria-hidden="true" />
                                                    <span className="task">Español</span> <span className="time ml-2">Sin ver</span> <span className="float-right">Sin
                                                        ver</span>
                                                </p>
                                                <p className="heading1"> <span className="today">Evaluaciones</span> <span className="float-right headingright">2
                                                    evaluaciones</span> </p>
                                                <p> <i className="fa fa-calculator ml-2" aria-hidden="true" /> <span className="task mt-4">Matemáticas</span><span className="time ml-2">1° Parcial</span> <span className="float-right">Vista: hoy 12 am</span> </p>
                                                <p><i className="fa fa-keyboard-o ml-2" aria-hidden="true" /> <span className="task">Computación</span> <span className="time ml-2">1° Parcial</span><span className="float-right">Vista: hoy 4 pm</span> </p>
                                                <p className="heading1"><span className="tomorrow">Comentarios profesor</span> <span className="float-right headingright">2
                                                    Comentarios</span> </p>
                                                <p> <i className="fa fa-calculator ml-2" aria-hidden="true" /> <span className="task mt-4">Matemáticas</span> <span className="time ml-2">La entrega es en pdf </span> <span className="float-right">Visto: hoy 1 pm</span> </p>
                                                <p><i className="fa fa-file-text-o ml-2" aria-hidden="true" />
                                                    <span className="task">Español</span> <span className="time ml-2">Necesito que le digan a sus papas que este viernes
                                                        tenemos junta </span> <span className="float-right">Visto: ayer 11 pm</span>
                                                </p>
                                                <p className="heading1"><span className="tomorrow">Última actividad</span> <span className="float-right headingright">4
                                                    ubicaciones</span> </p>
                                                <a href="https://goo.gl/maps/Lbc5VgzwY3xZvjix6" target="_blank">
                                                    <p> <i className="fa fa-map-marker ml-2" aria-hidden="true" /> <span className="task mt-4">Plaza de la
                                                        Constitución</span> <span className="time ml-2">192.323.423.345 / chrome / android</span> <span className="float-right">hoy 10 am</span> </p>
                                                </a>
                                                <a href="https://goo.gl/maps/pR7YDFTHnV4ekxkA9" target="_blank">
                                                    <p> <i className="fa fa-map-marker ml-2" aria-hidden="true" /> <span className="task mt-4">El Califa
                                                    </span> <span className="time ml-2">192.656.867.213 / chrome / mac</span> <span className="float-right">ayer 5
                                                        pm</span> </p>
                                                </a>
                                                <a href="https://goo.gl/maps/MY43rRqrHgu6n9Hm8" target="_blank">
                                                    <p> <i className="fa fa-map-marker ml-2" aria-hidden="true" /> <span className="task mt-4">Museo de Cera
                                                    </span> <span className="time ml-2">192.756.433.212 / safari / ios</span> <span className="float-right">hace 2 días
                                                        10 am</span> </p>
                                                </a>
                                                <a href="https://goo.gl/maps/PnFQr7cASf9SX7Xi7" target="_blank">
                                                    <p> <i className="fa fa-map-marker ml-2" aria-hidden="true" /> <span className="task mt-4">Madre Café</span> <span className="time ml-2">192.345.453.389 / firefox / pc</span> <span className="float-right">hace 4 días 8 am</span>
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}