export const es_ES = {
  translation: {
    change_language_es: "Español",
    change_language_en: "Inglés",

    language_spanish: "Español",
    language_english: "Inglés",
    language_french: "Francés",
    language_german: "Alemán",

    //VALIDATIONS
    validations_invalid: "El campo no es válido",
    validations_required: "Se requiere campo",
    validations_invalidType: "Tiene que ser un {{type}}",
    validations_email: "El correo electrónico es invalido",
    validations_stringMin: "Debe tener al menos {{min}} caracteres",
    validations_stringMax: "Debe tener como máximo {{max}} caracteres",
    validations_age: "Debes ser mayor de 18 años",
    validations_termsOfUse: "Debe aceptar los términos de uso",
    validations_file_not_allowed: "Archivo no permitido",
    validations_max_file_size: "El tamaño máximo de archivo es {{max}} MB",
    validations_password_confirm: "Las contraseñas no coinciden",

    //ERRORS
    error_something_wrong: "Algo salió mal.",

    // BUTTONS
    button_close: "Cerrar",
    button_accept: "Aceptar",
    button_send: "Enviar",

    // LOGIN
    login_friend: "Encontrar nuevos amigos",
    login_connect:
      "Te ayuda a conectarte y compartir con las personas en tu vida",
    login_message: "Ingresa con tu correo electrónico y contraseña.",
    login_title: "Iniciar sesión",
    login_email: "Correo electrónico",
    login_password: "Contraseña",
    login_signin: "Ingresar",
    login_registered: "¿No tienes cuenta?",
    login_signup: "Regístrate",
    login_recover_pass: "¿Olvidaste tu contraseña?",
    login_recover_pass_title: "Recupera tu contraseña",
    login_recover_pass_body:
      "Ingresa tu correo electrónico para recuperar tu contraseña.",
    login_user_pass_wrong: "Usuario o contraseña inválido.",
    login_something_wrong: "Ocurrió un error, por favor intenta nuevamente.", 

    // REGISTER
    register_friend: "Encontrar nuevos amigos",
    register_connect:
      "Te ayuda a conectarte y compartir con las personas en tu vida",
    register_title: "Nuevo usuario",
    register_email: "Correo electrónico",
    register_email_taken: "El correo ya ha sido registrado",
    register_firstName: "Nombre",
    register_lastName: "Apellidos",
    register_password: "Contraseña",
    register_password_confirm: "Confirmar contraseña",
    register_utype: "Selecciona tu perfil",
    register_p: "Profesor",
    register_e: "Estudiante",
    register_date_dob: "Fecha de nacimiento",
    register_gender: "Género",
    register_native_lang: "Idioma nativo",
    register_target_lang_t: "Idioma de enseñanza",
    register_target_lang_s: "Idioma de aprendizaje",
    register_terms_of_use: "Términos de uso",
    register_terms_separator: "y",
    register_privacy: "privacidad",
    register_signin: "Registrar",
    register_registered: "¿Ya tienes cuenta?",
    register_signup: "Iniciar sesión",

    // HOME
    home_friend_request: "Solicitud de amistad",
    home_create_post: "Crear publicación",
    home_type_here: "Escribe algo...",
    home_search: "Escribe aquí para buscar",
    home_image_video: "Imagen/Video",
    friend_request_header_cancel: "Rechazar",
    friend_request_header_accept: "Aceptar",
    student: "Estudiante",
    teacher: "Profesor",

    //PEOPLE KNOW COMPONENT
    people_know_title: "Sugerencia de amistad",
    people_know_subtitle: "Tal vez te interese conectar con ...",
    people_know_footer: "Última actualización hace 1 min.",

    // NOTIFICATION
    notification_title: "Notificaciones",
    notification_no_messages: "No tienes notificaciones",
    notification_goto_class: "Ir a la clase",
    notification_goto_lesson: "Ver",

    // SIDEBAR
    sidebar_home: "Inicio",
    sidebar_profile: "Perfil",
    sidebar_friends: "Amigos",
    sidebar_notifications: "Notificaciones",
    sidebar_friend_request: "Solicitud de amistad",
    sidebar_chat: "Chat",
    sidebar_calendar: "Calendario",
    sidebar_lessons: "Clases",
    sidebar_exercises: "Ejercicios",
    sidebar_diagnostic: "Evaluaciones",
    sidenar_courses: "Contenido",

    // POSTS
    posts_create_title: "Crea tu publicación",
    posts_title_post: "Título de la publicación",
    posts_type_here: "Escribe tu publicación...",
    posts_comments: "Comentarios",
    posts_show_comments: "Mostrar comentarios",
    posts_comment_placeholder: "Agregar comentario...",
    posts_likes: "Me gusta",
    posts_delete_post: "Eliminar publicación",
    post_like: "le gustó tu publicación",
    post_comment: "comentó tu publicación",
    post_confirm_delete: "¿Seguro que quieres eliminar esta publicación?",

    // COMMENT
    comment_like: "le gustó tu comentario",

    //PROFILE
    profile_telephone: "Teléfono",
    profile_user_type: "Tipo de usuario",
    profile_profession: "Profesión",
    profile_basic_information: "Información básica",
    profile_hobby: "Pasatiempo",
    profile_native_language: "Idioma nativo",
    profile_country_of_origin: "País de origen",
    profile_country_residence: "País de residencia",
    profile_gender: "Género",
    profile_update: "Actualizar",
    profile_aboutme_tab: "Acerca de mí",
    profile_aboutme_description: "Descripción",
    profile_aboutme_cv: "CV",
    profile_aboutme_videolink: "Link de video",
    profile_social_tab: "Redes sociales",
    profile_payment_tab: "Pagos",
    profile_subscriptions_tab: "Subscripciones",
    profile_subscriptions_cancel: "Cancelar",
    profile_subscriptions_empty: "Sin subscripiones",
    profile_payment_bank: "Banco",
    profile_payment_clabe: "Clabe",

    //PERFIL
    perfil_publicaciones: "Publicaciones",
    perfil_seguidores: "Seguidores",
    perfil_seguidos: "Seguidos",
    perfil_publicacion: "Crear publicación",
    perfil_contenido: "Escribe algo aquí",
    perfil_comentarios: "Comentarios",
    perfil_gusta: "Me gusta",
    perfil_traducir: "Traducir",
    perfil_responder: "Responder",
    perfil_compartir: "Compartido",
    perfil_tab1: "Línea de tiempo",
    perfil_tab2: "Información",
    perfil_visit: "Visitar",

    //CHAT
    chat_search: "Buscar",
    chat_send: "Enviar",
    chat_message_placeholder: "Escribe un mensaje aquí",
    chat_message_label: "Mensajes",
    chat_start: "Inicia una conversación",
    chat_details_name: "Nombre",
    chat_details_tel: "Teléfono",
    chat_details_birth: "Fecha de nacimiento",
    chat_details_gender: "Género",
    chat_details_language: "Idioma nativo",
    barSide_about_me: "Sobre mí",
    barSide_status: "Status",
    barSide_online: "En línea",
    barSide_offline: "Desconectado",
    
    //CALENDARIO
    calendario_add: "Añadir evento",
    calendario_edit: "Editar evento",
    calendario_titulo: "Título",
    calendario_descripcion: "Descripción",
    calendario_inicio: "Fecha de inicio",
    calendario_final: "Fecha de término",
    c_startDate: "Inicio",
    c_endDate: "Fin",
    calendario_prioridad: "Seleccione la prioridad",
    c_prioridad1: "Prioridad 1",
    c_prioridad2: "Prioridad 2",
    c_prioridad3: "Prioridad 3",
    c_prioridad4: "Otro",
    c_title: "Calendario y eventos",
    c_evento: "Evento",
    c_title_event: "Eventos de hoy",
    c_c: "a",
    c_classification: "Clasificación",
    c_events_today: "Sin eventos para el día de hoy",

    //Friends
    friends_label_principal: 'Lista de amigos',
    friends_button_view_profile: 'Ver perfil',
    friends_button_remove_friend: 'Eliminar',
    friends_alert_delete_title: '¿Estás seguro?',
    friends_alert_delete_info: '¡La acción no se podrá revertir después!',
    friends_alert_delete_confirm_button: 'Sí, eliminar!!',
    friends_alert_delete_cancel_button: 'Cancelar',
    friends_alert_delete_success_title: 'Eliminado!',
    friends_alert_delete_success_info: 'El amigo fue eliminado',
    friends_add_friend: 'Agregar a mis amigos',
    friends_delete_friend: 'Eliminar de mis amigos',
    friends_request_send: 'Solicitud enviada',
    friends_accept_friend: 'Aceptar amigo',

    //LESSONS
    lesson_add: "Nueva clase",
    lesson_add_title: "Título",
    lesson_add_level: "Nivel",
    lesson_add_description: "Descripción",
    lesson_add_start: "Inicio",
    lesson_add_end: "Fin",
    lesson_add_price: "Precio",
    lesson_add_save: "Registrar",
    lesson_add_notification_free: "Solo se pueden crear clases de 30 minutos",
    lesson_update: "Actualizar",
    lesson_title_my: "Mis clases",
    lesson_my_teachers: "Mis profesores",
    lesson_my_others_teachers: "Otros profesores",
    lesson_start_class: "Iniciar clase",
    lesson_edit_class: "Editar clase",
    lesson_delete_class: "Eliminar clase",
    lesson_manage_class: "Administrar alumnos de la clase",
    lesson_schedule_class: "Agendar clase",
    lesson_available_lessons: "Clases disponibles",
    lesson_all: "Todas las clases",
    lesson_language_all: "Todos",
    lesson_level: "Nivel",
    lesson_send_friend_request: "Enviar solicitud de amistad",
    lesson_column_title: "Título",
    lesson_column_author: "Autor",
    lesson_column_description: "Descripción",
    lesson_column_level: "Nivel",
    lesson_column_price: "Precio",
    lesson_column_startAt: "Inicia",
    lesson_column_endAt: "Finaliza",
    lesson_alert_delete_title: "Confirmar operación",
    lesson_alert_acction_delete: "Eliminar",
    lesson_notification: "Tienes clase de {{title}} a las {{date}}",
    lesson_enrollment_complete: "Inscripción completada",
    lesson_enrollment_notification: "Tienes una solicitud de inscripción para {{title}}",
    lesson_class_collected: "Cobradas",
    lesson_class_paid: "Pagadas",

    //EXERCISES
    exercises_title: "Ejercicios",
    exercises_multiplechoices_option: "Opción múltiple",
    exercises_truefalse_option: "Verdadero/Falso",
    exercises_tie_in: "Relacionar",
    exercises_sentence_completion: "Completar frase",
    exercises_column_title: "Título",
    exercises_column_level: "Nivel",
    exercises_column_type: "Tipo",
    exercises_column_resolve: "Resolver",
    exercises_verify: "Verificar",
    exercises_completed: "Completado",
    exercises_completed_yes: "Sí",
    exercises_completed_no: "No",
    exercises_filter_all: "Todos",
    exercises_all_answers_required: "Debe responder todas las preguntas",
    exercises_question: "Pregunta",
    exercises_answer: "Respuesta",
    exercises_options: "Opciones",

    //TESTS
    diagnostictests_title: "Evaluaciones",
    diagnostictests_column_title: "Título",
    diagnostictests_column_level: "Nivel",
    diagnostictests_column_questions: "Total de preguntas",
    diagnostictests_column_start: "Iniciar evaluación",
    diagnostictests_column_completed: "Completado",
    diagnostictests_column_score: "Puntaje obtenido",
    diagnostictests_completed_yes: "Sí",
    diagnostictests_completed_No: "No",
    diagnostictests_compute_level: "Calcular nivel",
    diagnostictests_level_result: "Nivel obtenido",
    diagnostictests_verify: "Verificar",
    diagnostictests_total_questions: "Total de preguntas",
    diagnostictests_level_score: "Respuestas correctas",
    diagnostictests_level_wrong_answers: "Respuestas erróneas",

    //Courses
    courses_main_title: "Contenido",
    courses_all_levels: "Todos",
    courses_no_records_found: "No hay registros para mostrar",
    courses_download: "Descargar",

    //Header
    header_edit_profile: "Editar perfil",
    header_modify_your_data: "Modificar tus datos",
    header_logout: "Cerrar sesión",

    //english levels
    a1: "A1: Nivel iniciación",
    a2: "A2: Nivel básico",
    b1: "B1: Nivel intermedio",
    b2: "B2: Nivel intermedio alto",
    c1: "C1: Nivel avanzado",
    c2: "C2: Nivel muy avanzado",
    
    //SEARCHBAR
    searchbar_view_profile: "Ver perfil",

    //DataTable
    datatable_rows_PerPageText: "Registros por página:", 
    
    //GENERAL
    confirm_delete_yes: "Sí",
    confirm_delete_No: "No",

    //Subscription
    subscription_trial: "Prueba",
    subscription_monthly: "Mensual",
    subscription_biyearly: "Semestral",
    subscription_yearly: "Anual",
    subscription_days: "dias",
    subscription_type_monthly: "Subscripción mensual",
    subscription_type_biyearly: "Subscripción semestral",
    subscription_type_yearly: "Subscripción anual",
    subscription_activate: "Subscripción activada",
    subscription_canceled: "Subscripción cancelada",
    subscription_pay_goback: "Regresar",
    subscription_try_it: "Probar",
    subscription_buy_it: "Contratar",
    subscription_continue_free: "Continuar gratis",
    subscription_pay_title: "Se parte de la comunidad premium", 
    subscription_pay_description: "Desbloquea todas las características: videollamadas entre amigos, clases de paga y sin límite de tiempo y prueba de diagnostico gratis",

    //Admin
    admin_login_access_not_allowed: "No tienes acceso a este recurso",
    admin_profesors_title: "Lista de profesores",
    admin_profesors_firstName: "Nombre",
    admin_profesors_fullname: "Nombre del profesor",
    admin_profesors_lastName: "Apellidos",
    admin_profesors_payable: "Por pagar",
    admin_profesors_paid: "Pagado",
    admin_profesors_free_class: "Clases Gratis",
    admin_profesors_paid_class: "Clases De pago",
    admin_profesors_isPremium: "Es premium",
    admin_profesors_totalPay: "Total a pagar",
    admin_profesors_amount_Pay: "Monto a pagar",
    admin_profesors_receipt_required: "El comprobante es requerido",
    admin_profesors_subscription_required: "Se requiere una subscripción activa",
    admin_profesors_payment_success: "Pago efectuado correctamente",
    admin_profesors_amount_message: "El monto debe ser menor o igual al monto a pagar",
    admin_profesors_bank_label: "Banco",
    admin_profesors_clabe_label: "CLABE",
    admin_profesors_confirm_pay: "Confirmar pago",
    admin_students_title: "Lista de Estudiantes",
    admin_students_firstName: "Nombre",
    admin_students_lastName: "Apellidos",
    admin_students_email: "Correo"

  },
};