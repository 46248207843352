import { teacherModel } from '../models/teacher';
import { studentModel } from '../models/student';
import { getUserByUid, registerNewUser } from './authHelper';
import { db, collection, query, where, getDocs, doc, getDoc, deleteDoc } from '../firebaseConfig';
import { limit } from "firebase/firestore";
import moment from 'moment';
const collectionName = "users";

export const FALLBACK_PROFILE_PICTURE = "https://firebasestorage.googleapis.com/v0/b/pangea-daee0.appspot.com/o/perfil%2FprofilePicture?alt=media&token=f4bd9726-0b2e-4640-b7ea-3b5bc6ef07a6";
export const FALLBACK_COVER_PICTURE = "https://firebasestorage.googleapis.com/v0/b/pangea-daee0.appspot.com/o/default%2Fcover?alt=media&token=6d96fe47-d66e-49ee-b0d0-1667b640c8ed";

export const getFriendSuggestion = (user) => {
    return new Promise(async (resolve, reject) => {
        const getSuggest = new Promise(async (resolve, reject) => {
            //Get User Current Session
            //let docRef = doc(db, collectionName, user.uid);
            //let userSession = await getDoc(docRef);
            let arrayUserSession = user;
            //console.log(arrayUserSession.language.teachingLanguajes);
            //Si la estructura de sesion no trae el goalLearning del usuario, se coloca en defaul english.
            let value;
            //Get Users Correlation by languaje.native languaje.goalLearning 
            let querySuggestion;
            //dataUsers es un arreglo para almacenar los documentos de firesotre referentes a usuarios.
            let dataUsers = [];
            //arreglo con sugerencis de amistad.
            let arrayDataSuggestion = [];
            //Si el usuario que inicio sesion es estudiante.
            if (user.userType === "student") {
                //Si la estructura de sesion no trae el goalLearning del usuario, se coloca en defaul english.
                value = (typeof arrayUserSession.language.goalLearning !== "undefined") ? arrayUserSession.language.goalLearning : "english";
                //Get Users Correlation by languaje.native languaje.goalLearning 
                querySuggestion = query(collection(db, collectionName), where("language.native", "==", value, limit(17)));
                dataUsers = await getDocs(querySuggestion);
                arrayDataSuggestion = dataUsers.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));
                resolve(arrayDataSuggestion);
            }
            //Si el usuario es teacher, el dato que se correlaciona es languaje.native
            if (user.userType === "teacher") {
                value = (typeof arrayUserSession.language.teachingLanguajes !== "undefined") ? arrayUserSession.language.teachingLanguajes : "english";
                if (value !== "english") {
                    if (value.length === 1) {
                        //Si la estructura de sesion no trae el goalLearning del usuario, se coloca en defaul english.
                        value = arrayUserSession.language.teachingLanguajes[0].language;
                        //Get Users Correlation by languaje.goalLearning languaje.teachingLanguajes 
                        querySuggestion = query(collection(db, collectionName), where("language.goalLearning", "==", value, limit(17)));
                        dataUsers = await getDocs(querySuggestion);
                        arrayDataSuggestion = dataUsers.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));
                        resolve(arrayDataSuggestion);
                    }
                    else {
                        //Recorrer los idiomas.
                        value.forEach(async (data, index) => {
                            //Si la estructura de sesion no trae el goalLearning del usuario, se coloca en defaul english.
                            let valueQuery = data.language;
                            //Get Users Correlation by languaje.goalLearning languaje.teachingLanguajes 
                            querySuggestion = query(collection(db, collectionName), where("language.goalLearning", "==", valueQuery, limit(17)));
                            dataUsers = await getDocs(querySuggestion);
                            let arrayDataSuggestionTemp = dataUsers.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));
                            arrayDataSuggestion = [...arrayDataSuggestion, ...arrayDataSuggestionTemp];
                            if (parseInt(index) === value.length - 1)
                                resolve(arrayDataSuggestion);
                        });
                    }
                }
            }
        });
        //Ejecuta la promesa.
        let arrayDataSuggestion = await getSuggest;
        //Busca en las sugerencias, que el usuario en sesion haya mandado ya una solicitud a ese usuario
        const findRequestPending = new Promise(async (resolve, reject) => {
            let response = [];
            arrayDataSuggestion.forEach(async (val, index) => {
                let fr = await getFriendRequestById(val.uid, user.uid);
                if (typeof fr === "undefined") {
                    response.push(val);

                }
                if (parseInt(index) === arrayDataSuggestion.length - 1)
                    resolve(response);

            });
        });
        //Vuelve a asignarle valor a la variable, filtrando si ya se ha enviado solicitud.
        arrayDataSuggestion = await findRequestPending;
        //console.log(arrayDataSuggestion);
        if (arrayDataSuggestion.length > 0) {
            //Get Friend Request
            let friendsRequest = await getFriendRequest(user);
            //Validar que el usuario de sesion no aparezca en una solicitud del usuario sugerido.
            //Get userFriends
            let friends = await getFriends(user.uid);

            var usersSuggestion = [];

            //Si hay friendRequest o Amigos
            if (friendsRequest.length > 0 || friends.length > 0) {
                arrayDataSuggestion.map((val, index) => {
                    var indexUser = -1;
                    var indexUserFriends = -1;
                    //Busca en las solicitudes de amistad, algun usuario que ya tenga solicitud de amistad enviada para evitar ponerlo en la lista.
                    if (friendsRequest.length > 0)
                        indexUser = friendsRequest.findIndex(arr => arr.userIdRequester === val.uid);
                    //Busca en los amigos, algun usuario para quitarlo de la lista de sugerenicias.. y valida si antes fue encontrado como amigo.
                    if (friends.length > 0 && indexUser === -1)
                        indexUserFriends = friends.findIndex(arr => arr.uid === val.uid);
                    //Si el usuario que es sugerencia no esta como amigo y como solicitud de amistad, lo muestra.
                    if (indexUser === -1 && indexUserFriends === -1)
                        usersSuggestion.push(val);
                });
            } else
                usersSuggestion = arrayDataSuggestion;
            var friendsSuggestion;
            if (usersSuggestion.length > 0) {
                //Busca si el usuario logueado, esta en la lista para eliminarlo
                let findUserCurrent = usersSuggestion.findIndex(arr => arr.uid === user.uid);

                if (findUserCurrent >= 0)
                    delete usersSuggestion[usersSuggestion.findIndex(arr => arr.uid === user.uid)];
                //Se arman los datos necesarios para el comonente.
                friendsSuggestion = usersSuggestion.map(
                    doc => ({ uid: doc.uid, userType: doc.userType, name: doc.firstName?.split(" ")[0] + " " + doc.lastName?.split(" ")[0], roomName: "https://ibloom.com.mx/pangea?idu=" + doc.uid, profilePicture: doc.profilePicture }));
                resolve(friendsSuggestion);
            } else {
                friendsSuggestion = [];
                resolve(friendsSuggestion);
            }
        } else {
            friendsSuggestion = [];
            resolve(friendsSuggestion);
        }
    });
}


export const getFriendRequest = (user) => {
    return new Promise(async (resolve, reject) => {
        //Get Users Correlation by languaje.native languaje.goalLearning 
        var q = query(collection(db, collectionName, user.uid, "friendRequest"), where("status", "==", "pending"));
        var data = await getDocs(q);
        var arrayData = data.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));
        resolve(arrayData);
    });
}

export const getFriends = (userId) => {
    return new Promise(async (resolve, reject) => {
        //Get Users Correlation by languaje.native languaje.goalLearning 
        var q = query(collection(db, collectionName, userId, "friends"));
        var data = await getDocs(q);
        var arrayData = data.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));
        resolve(arrayData);

    });
}

export const getFriendRequestById = (userId, userIdRequester) => {
    return new Promise(async (resolve, reject) => {
        //Get Users Correlation by languaje.native languaje.goalLearning 
        //Get User Current Session
        let docRef = doc(db, collectionName, userId, "friendRequest", userIdRequester);
        let userSession = await getDoc(docRef);
        let arrayUserSession = userSession.data();
        resolve(arrayUserSession);
    });
}

export const getUserById = (userId) => {
    return new Promise(async (resolve, reject) => {
        //Get User Current Session
        let docRef = doc(db, collectionName, userId);
        let user = await getDoc(docRef);
        let arrayUser = user.data();
        resolve(arrayUser);
    })

}

const getCommonUserData = (data) => {
    const { uid, email, firstName, lastName, userType, dateOfBirth, gender,
        nativeLang, targetLang, termsOfUse, profilePicture } = data;
    const dateFormated = moment(dateOfBirth).format('YYYY-MM-DD');
    let userData = {
        uid, firstName, lastName, email, userType, dateOfBirth: dateFormated, gender,
        termsOfUse, profilePicture, language: {}
    };
    userData.language.native = nativeLang;

    if (nativeLang === "spanish") {
        userData.language.nativo = "español";
    } else if (nativeLang === "english") {
        userData.language.nativo = "inglés";
    } else if (nativeLang === "french") {
        userData.language.nativo = "francés"
    } else if (nativeLang === "german") {
        userData.language.nativo = "alemán"
    }
    if (userType === "Profesor" || userType === "Teacher") {
        userData.userTipo = "profesor";
        userData.userType = "teacher";
        userData.language.teachingLanguajes = [{ language: targetLang }];
    } else if (userType === "Estudiante" || userType === "Student") {
        userData.userTipo = "estudiante";
        userData.userType = 'student';
        userData.language.goalLearning = targetLang;
    }
    if (gender === "Male") {
        userData.genero = "Masculino";
    } else if (gender === "Female") {
        userData.genero = "Femenino";
    }
    return userData;
}

export const createNewUser = async (data) => {
    let newUser = {};
    const userData = getCommonUserData(data);
    const { userType } = userData;
    if (userType === "Profesor" || userType === "Teacher") {
        newUser = { ...teacherModel, ...userData };
    } else if (userType === "Estudiante" || userType === "Student") {
        newUser = { ...studentModel, ...userData };
    } else {
        newUser = userData;
    }
    return registerNewUser(newUser);
}

export const deleteFriend = async (idUserCurrent, idUserFriend) => {
    return new Promise(async (resolve, reject) => {
        try {
            //Se elimina la solicitud de amistad. los datos se guardan ahora al nivel del amigo.
            let responseDelete = await deleteDoc(doc(db, collectionName, idUserCurrent, "friends", idUserFriend));
            //Se elimina la solicitud de amistad. los datos se guardan ahora al nivel del amigo.
            let responseDelete2 = await deleteDoc(doc(db, collectionName, idUserFriend, "friends", idUserCurrent));
            resolve([responseDelete, responseDelete2]);
        } catch (e) {
            console.log(e);
            reject(e);
        }
    })
}

export const ensureUserLoaded = async (uid, userData, setUserData) => {
    let currentUserData = userData || null;
    if (userData == null) {
        currentUserData = await getUserByUid(uid);
        setUserData(currentUserData);
    }
    return currentUserData;
}