import { collection, orderBy, startAfter, limit, query, where, addDoc, deleteDoc, getDocs, doc, setDoc, Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { ref as refStorage, uploadBytes, getDownloadURL, uploadBytesResumable, ref, deleteObject } from "firebase/storage";
import { db, functions, storage } from "../firebaseConfig";

export const getFriendsPosts = async (uid, userId, nextValues = false, lastVisible = null) => {
    let dataPost = [];
    let documentSnapshots = [];
    try {
        // TODO: TO DEFINE STRUCTURE FROM FRIENDS
        // Query the first page of docs
        if (!nextValues) {
            const data = query(collection(db, `users/${uid}/posts`), orderBy("timestamp"), limit(25));
            documentSnapshots = await getDocs(data);
        } else {
            // Get the last visible document
            const data = query(collection(db, `users/${uid}/posts`),
                // where("", "==", valor)
                orderBy("timestamp"),
                startAfter(lastVisible),
                limit(25));

            documentSnapshots = await getDocs(data);
        }

        documentSnapshots.forEach((doc) => {
            dataPost.push({ id: doc.id, ...doc.data() });
        })
    } catch (error) {
        console.error('Error |getFriendsPosts| :', error);
        return false;
    }
    return dataPost;
}

export const getPostsByUserId = async (uid) => {
    let dataPosts = [];
    try {
        const q = query(collection(db, `users/${uid}/posts`), orderBy('timestamp', 'desc'));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            dataPosts.push({ id: doc.id, ...doc.data() });
        });
    } catch (error) {
        console.error('Error |getPostsByUserId| :', error);
        return false;
    }
    return dataPosts;
}

export const createPost = async (uid, post) => {
    let dataPost = {};
    try {
        dataPost = await addDoc(collection(db, `users/${uid}/posts`), post);
    } catch (error) {
        console.error('Error |createPost| :', error);
        return false;
    }
    return dataPost;
}

export const updatePost = async (uid, pid, post) => {
    try {
        await setDoc(doc(db, `users/${uid}/posts`, pid), post);
    } catch (error) {
        console.error('Error |updatePost| :', error);
        return false;
    }
    return true;
}

export const deletePost = async (uid, pid) => {
    try {
        await deleteDoc(doc(db, `users/${uid}/posts`, pid));
    } catch (error) {
        console.error('Error |deletePost| :', error);
        return false;
    }
    return true;
}

export const uploadPostPic = async (uid, file) => {
    const storageRef = refStorage(storage, `posts/${uid}/${new Date().getTime()}`);
    const result = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
}

export const getMyPostsAndFriendPosts = async (uid) => {
    let posts = [];
    try {
        const getMyPostsAndFriendPosts = httpsCallable(functions, "getMyPostsAndFriendPosts");
        await getMyPostsAndFriendPosts({ uid }).then(({ data }) => {
            posts = data
        });
    } catch (error) {
        console.error('Error |getMyPostsAndFriendPosts| :', error);
    }
    return posts;
}

export const uploadMedia = async (uid, file, setProgress) => {
    return new Promise(async (resolve, reject) => {
        const storageRef = refStorage(storage, `posts/${uid}/${new Date().getTime()}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed", (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(progress);
        },
            (error) => {
                reject(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL);
                });
            }
        ); 
    });
}

export const deletePostFile = async (fileUrl) => {
    let result = false;
    const fileRef = ref(storage, fileUrl);
    await deleteObject(fileRef).then(() => result = true);
    return result;
}