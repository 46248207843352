import Layout from "../components/Layout";

const Ranking = () => {

    return (
        <Layout>
            <div className="header-for-bg">
                <div className="background-header position-relative">
                    <img
                        src="https://ibloom.com.mx/pangu/ranking.jpg"
                        className="img-fluid w-100"
                        alt="header-bg"
                    />
                    <div className="title-on-header">
                        <div className="data-block">
                            <h2>Ranking</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-page" className="content-page">
                <div className="container ">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="card-deck mb-3 text-center">
                                    <div className="card mb-4 box-shadow">
                                        <div className="card-header">
                                            <h4 className="my-0 font-weight-normal">Nacional</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Alumno</th>
                                                            <th scope="col">Matricula</th>
                                                            <th scope="col">Escuela</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>Santiago García López</td>
                                                            <td>15560490</td>
                                                            <td>Escuela Primaria Federal "Revolución Agraria"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>Isabella Rodríguez Martínez</td>
                                                            <td>15560491</td>
                                                            <td>Escuela Primaria Federal "Niños Héroes"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>Lucas Hernández Sánchez</td>
                                                            <td>15560492</td>
                                                            <td>Escuela Primaria Federal "Benito Juárez"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">4</th>
                                                            <td>Valentina Pérez Fernández</td>
                                                            <td>456323</td>
                                                            <td>Escuela Primaria Federal "Miguel Hidalgo"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">5</th>
                                                            <td>Mateo González Ramírez</td>
                                                            <td>43353543</td>
                                                            <td>Escuela Primaria Federal "Jaime Torres Bodet"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">6</th>
                                                            <td>Sofía Torres Jiménez</td>
                                                            <td>32456</td>
                                                            <td>Escuela Primaria Federal "Miguel Hidalgo"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">7</th>
                                                            <td>Benjamín Flores Romero</td>
                                                            <td>32787654</td>
                                                            <td>Escuela Primaria Federal "Jaime Torres Bodet"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">8</th>
                                                            <td>Emilia Castro Vargas</td>
                                                            <td>76454</td>
                                                            <td>Escuela Primaria Federal "Niños Héroes"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">9</th>
                                                            <td>Nicolás Ruiz Medina</td>
                                                            <td>78943456</td>
                                                            <td>Escuela Primaria Federal "Jaime Torres Bodet"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">10</th>
                                                            <td>Victoria Morales Cruz</td>
                                                            <td>43434322</td>
                                                            <td>Escuela Primaria Federal "Benito Juárez"</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-4 box-shadow">
                                        <div className="card-header">
                                            <h4 className="my-0 font-weight-normal">Estatal</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlSelect1">Seleccione el estado</label>
                                                <select className="form-control" id="exampleFormControlSelect1">
                                                    <option value="Aguascalientes">Aguascalientes</option>
                                                    <option value="Baja California">Baja California</option>
                                                    <option value="Baja California Sur">Baja California Sur</option>
                                                    <option value="Campeche">Campeche</option>
                                                    <option value="Chiapas">Chiapas</option>
                                                    <option value="Chihuahua">Chihuahua</option>
                                                    <option selected value="CDMX">Ciudad de México</option>
                                                    <option value="Coahuila">Coahuila</option>
                                                    <option value="Colima">Colima</option>
                                                    <option value="Durango">Durango</option>
                                                    <option value="Estado de México">Estado de México</option>
                                                    <option value="Guanajuato">Guanajuato</option>
                                                    <option value="Guerrero">Guerrero</option>
                                                    <option value="Hidalgo">Hidalgo</option>
                                                    <option value="Jalisco">Jalisco</option>
                                                    <option value="Michoacán">Michoacán</option>
                                                    <option value="Morelos">Morelos</option>
                                                    <option value="Nayarit">Nayarit</option>
                                                    <option value="Nuevo León">Nuevo León</option>
                                                    <option value="Oaxaca">Oaxaca</option>
                                                    <option value="Puebla">Puebla</option>
                                                    <option value="Querétaro">Querétaro</option>
                                                    <option value="Quintana Roo">Quintana Roo</option>
                                                    <option value="San Luis Potosí">San Luis Potosí</option>
                                                    <option value="Sinaloa">Sinaloa</option>
                                                    <option value="Sonora">Sonora</option>
                                                    <option value="Tabasco">Tabasco</option>
                                                    <option value="Tamaulipas">Tamaulipas</option>
                                                    <option value="Tlaxcala">Tlaxcala</option>
                                                    <option value="Veracruz">Veracruz</option>
                                                    <option value="Yucatán">Yucatán</option>
                                                    <option value="Zacatecas">Zacatecas</option>
                                                </select>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Alumno</th>
                                                            <th scope="col">Matricula</th>
                                                            <th scope="col">Escuela</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>Sebastián Paredes Reyes</td>
                                                            <td>15021490</td>
                                                            <td>Escuela Primaria Federal "Revolución Agraria"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>Daniel Morales Rojas</td>
                                                            <td>164340491</td>
                                                            <td>Escuela Primaria Federal "Niños Héroes"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>Olivia Sánchez Navarro</td>
                                                            <td>153422492</td>
                                                            <td>Escuela Primaria Federal "Benito Juárez"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">4</th>
                                                            <td>Valentina Pérez Fernández</td>
                                                            <td>456323</td>
                                                            <td>Escuela Primaria Federal "Miguel Hidalgo"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">5</th>
                                                            <td>Mateo González Ramírez</td>
                                                            <td>43353543</td>
                                                            <td>Escuela Primaria Federal "Jaime Torres Bodet"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">6</th>
                                                            <td>Sofía Torres Jiménez</td>
                                                            <td>32456</td>
                                                            <td>Escuela Primaria Federal "Miguel Hidalgo"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">7</th>
                                                            <td>Benjamín Flores Romero</td>
                                                            <td>32787654</td>
                                                            <td>Escuela Primaria Federal "Jaime Torres Bodet"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">8</th>
                                                            <td>Emilia Castro Vargas</td>
                                                            <td>76454</td>
                                                            <td>Escuela Primaria Federal "Niños Héroes"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">9</th>
                                                            <td>Nicolás Ruiz Medina</td>
                                                            <td>78943456</td>
                                                            <td>Escuela Primaria Federal "Jaime Torres Bodet"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">10</th>
                                                            <td>Victoria Morales Cruz</td>
                                                            <td>43434322</td>
                                                            <td>Escuela Primaria Federal "Benito Juárez"</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-4 box-shadow">
                                        <div className="card-header">
                                            <h4 className="my-0 font-weight-normal">Ciudad</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Alumno</th>
                                                            <th scope="col">Ciudad</th>
                                                            <th scope="col">Matricula</th>
                                                            <th scope="col">Escuela</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>Gabriel Rivera Santos</td>
                                                            <td>Ciudad de México</td>
                                                            <td>15022390</td>
                                                            <td>Escuela Primaria Federal "Revolución Agraria"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>Antonella Fernández Castro</td>
                                                            <td>164342191</td>
                                                            <td>Escuela Primaria Federal "Niños Héroes"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>Olivia Sánchez Navarro</td>
                                                            <td>Ciudad de México</td>
                                                            <td>153422492</td>
                                                            <td>Escuela Primaria Federal "Benito Juárez"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">4</th>
                                                            <td>Valentina Pérez Fernández</td>
                                                            <td>Guadalajara </td>
                                                            <td>456323</td>
                                                            <td>Escuela Primaria Federal "Miguel Hidalgo"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">5</th>
                                                            <td>Mateo González Ramírez</td>
                                                            <td>Guadalajara </td>
                                                            <td>43353543</td>
                                                            <td>Escuela Primaria Federal "Jaime Torres Bodet"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">6</th>
                                                            <td>Sofía Torres Jiménez</td>
                                                            <td>Monterrey </td>
                                                            <td>32456</td>
                                                            <td>Escuela Primaria Federal "Miguel Hidalgo"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">7</th>
                                                            <td>Benjamín Flores Romero</td>
                                                            <td>Puebla </td>
                                                            <td>32787654</td>
                                                            <td>Escuela Primaria Federal "Jaime Torres Bodet"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">8</th>
                                                            <td>Emilia Castro Vargas</td>
                                                            <td>Tijuana </td>
                                                            <td>76454</td>
                                                            <td>Escuela Primaria Federal "Niños Héroes"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">9</th>
                                                            <td>Nicolás Ruiz Medina</td>
                                                            <td>León </td>
                                                            <td>78943456</td>
                                                            <td>Escuela Primaria Federal "Jaime Torres Bodet"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">10</th>
                                                            <td>Victoria Morales Cruz</td>
                                                            <td>Juárez </td>
                                                            <td>43434322</td>
                                                            <td>Escuela Primaria Federal "Benito Juárez"</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Ranking;