import React from 'react';
import { useTranslation } from 'react-i18next';
// import { getTokenFirebase, onMessageListener } from '../firebaseConfig';
import { updateNotification } from '../services/notificationHelper';
import { createImageFromInitials } from './Utils';

export const Notification = ({ notifications, setNotifications, user }) => {

    const { t } = useTranslation();

    const viewedNotification = async (idx) => {
        if (!notifications[idx].isViewed) {
            notifications[idx].isViewed = true;
            await updateNotification(user.uid, notifications[idx].id, notifications[idx]);
            setNotifications([...notifications]);
        }
    }

    return (
        <div className="iq-card-body p-0 ">
            <div className="bg-primary p-3">
                <h5 className="mb-0 text-white">{t('notification_title')}<small className="badge  badge-light float-right pt-1">{notifications.filter(n => !n.isViewed).length}</small></h5>
            </div>
            <div className="iq-friend-request">
                {notifications.length > 0 ?
                    notifications.map((noti, idx) => (
                        <div key={idx} className={`iq-sub-card ${noti.isViewed ? '' : 'table-primary'}`} onClick={() => viewedNotification(idx)}>
                            <div className="media align-items-center">
                                <div className>
                                    <img className="avatar-40 rounded" src={noti.photoProfile && noti.photoProfile?.length >= 0 ? noti.photoProfile : "https://firebasestorage.googleapis.com/v0/b/pangea-daee0.appspot.com/o/perfil%2FprofilePicture?alt=media&token=f4bd9726-0b2e-4640-b7ea-3b5bc6ef07a6"} alt="avatar" />
                                </div>
                                <div className="media-body ml-3">
                                    <h6 className="mb-0 ">{noti.userName} <small className="float-right font-size-12">{noti.timestamp.seconds ? new Date(noti.timestamp.seconds * 1000).toDateString() : noti.timestamp.toDateString()}</small></h6>
                                    <p className="mb-0">{noti.text}</p>
                                    {noti.type && noti.type === "class" && <button className="btn btn-sm btn-primary rounded mt-1" onClick={() => window.open(`/lessons/${noti.typeId}/class`)}>{t("notification_goto_class")}</button>}
                                    {noti.type && noti.type === "Solicitud" && <button className="btn btn-sm btn-primary rounded mt-1" onClick={() => window.location.href = `/lessons/${noti.typeId}/students`}>{t("notification_goto_lesson")}</button>}
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    <div className="media-body ml-3">
                        <p className="mb-0">{t('notification_no_messages')}</p>
                    </div>
                }
            </div>
        </div>
    );
}