import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext";
import { getQuestions, markEvaluatedExcercise } from "../../services/exercisesHelper";
import { ShowSwalAlert } from "../Utils";

const TieUp = ({ exerciseId }) => {
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [isEvaluate, setIsEvaluate] = useState(false);

    const { t } = useTranslation();
    const { user } = useAuth();
    const navigate = useNavigate();

    const loadQuestions = async () => {
        await getQuestions(exerciseId, true).then((questions) => { setQuestions(questions); });
    }

    useEffect(() => {
        loadQuestions();
    }, [exerciseId]);

    const handleOnChange = (id, answer) => {
        if (answer) {
            const index = answers.findIndex(a => a.id === id);
            if (index >= 0) {
                const newAnswers = [...answers];
                newAnswers[index].answer = answer;
                setAnswers(newAnswers);
            } else {
                setAnswers([...answers, { id, answer: answer }]);
            }
        }
    }

    const checkAnwers = async () => {
        if (answers.length === 0 || answers.length < questions.length) {
            ShowSwalAlert({ icon: "info", title: t('exercises_all_answers_required') });
            return;
        }

        const updatedQuestions = questions.map(q => {
            const r = answers.find(a => a.id === q.id);
            if (r) {
                q.answer = r.answer;
                q.feedback = "" + r.answer === q.correcta ? "ri-check-line bg-success" : "ri-close-line bg-danger";
            }
            return q;
        });
        setQuestions(updatedQuestions);
        try {
            await markEvaluatedExcercise(user.uid, exerciseId);
        } finally {
            setIsEvaluate(!isEvaluate);
        }
    }

    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <th>{t("exercises_question")}</th>
                    <th>{t("exercises_answer")}</th>
                    <th>{t("exercises_options")}</th>
                </thead>
                <tbody>
                    {questions.map((question, qkey) => {
                        return (
                            <tr key={qkey}>
                                <td>{question.pregunta}</td>
                                <td><input type="text" id={question.id} onChange={(evt) => handleOnChange(question.id, evt.target.value)} minLength="1" maxLength="1" /> {question.answer && <i className={question.feedback}></i>}</td>
                                <td>{question.opcion}</td>
                            </tr>
                        );
                    })
                    }
                </tbody>
            </table>
            {!isEvaluate ? <button onClick={() => checkAnwers()} className="btn btn-primary">{t("exercises_verify")}</button> : <button onClick={() => navigate("/exercises/all")} className="btn btn-primary mt-2"><i class="ri-arrow-go-back-fill"></i></button>}
        </div>
    );
}

export default TieUp;