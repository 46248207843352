import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, setDoc, doc, addDoc, getDoc, FieldPath, deleteDoc, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { onBackgroundMessage } from "firebase/messaging/sw";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};
const vapidKey = process.env.REACT_APP_VAPIDKEY;
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const messaging = getMessaging(app);
const rtdb = getDatabase(app);

if (messaging && 'serviceWorker' in navigator) {
  navigator.serviceWorker.register('./firebase-messaging-sw.js', {
    updateViaCache: 'none'
  })
}


const getTokenFirebase = (setTokenFound) => {
  return getToken(messaging, { vapidKey: vapidKey })
    .then((currentToken) => {
      console.log('current token for client: ', currentToken);
      if (currentToken) {
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        // console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
}

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

// onBackgroundMessage(messaging, (payload) => {
//   console.log('[firebase-messaging-sw.js] Received background message ', payload);
//   // Customize notification here
//   const notificationTitle = 'Background Message Title';
//   const notificationOptions = {
//     body: 'Background Message body.',
//     icon: '/firebase-logo.png'
//   };

//   self.registration.showNotification(notificationTitle,
//     notificationOptions);
// });

// Quitar comentario para progar localmente
// connectFunctionsEmulator(functions, "localhost", 5001);

export { app, auth, db, rtdb, collection, query, where, getDocs, setDoc, doc, addDoc, getDoc, deleteDoc, FieldPath, storage, onSnapshot, ref, getDownloadURL, functions, getTokenFirebase, onMessageListener };
