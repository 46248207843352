import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import { useAuth } from "../authContext";
import { Link, useParams } from 'react-router-dom';
import {
  getUserByUid
} from "../services/authHelper";
import { useTranslation } from "react-i18next";
import SocialNetworkView from "../components/profile/SocialNetworkView";
import BasicInfoView from "../components/profile/BasicInfoView";
import AboutMeView from "../components/profile/AboutMeView";
import PostListUser from "../components/posts/PostListUser";
import { friendRequestCheck, isFriendRequestSend, isUserFriend, sendFriendRequest } from "../services/friendRequestHelper";
import { deleteFriend, FALLBACK_COVER_PICTURE, FALLBACK_PROFILE_PICTURE } from "../services/userHelper";
import Swal from "sweetalert2";

const Viewuser = () => {
  const { iduser } = useParams();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [userData, setUserData] = useState(null);
  const [isFriend, setIsFriend] = useState(false);
  const [friendRequestSend, setFriendRequestSend] = useState(false);
  const [friendRequestPending, setFriendRequestPending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const loadUserData = async (uid) => {
    const data = await getUserByUid(uid);
    setUserData(data);
    await isUserFriend(user.uid, iduser).then((result) => setIsFriend(result));
    await isFriendRequestSend(user.uid, iduser).then((r) => setFriendRequestSend(r));
    await isFriendRequestSend(user.uid, iduser, true).then((r) => setFriendRequestPending(r));
    setIsLoading(false);
  };

  const deleteFriendEvent = async (idUserCurrent, idUserFriend) => {
    Swal.fire({
      title: t('friends_alert_delete_title'),
      text: t('friends_alert_delete_info'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('friends_alert_delete_confirm_button'),
      cancelButtonText: t('friends_alert_delete_cancel_button'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        let x = await deleteFriend(idUserCurrent, idUserFriend);
        if (typeof x[0] == "undefined" && typeof x[1] == "undefined") {
          setIsFriend(!isFriend);
          Swal.fire(
            t('friends_alert_delete_success_title'),
            t('friends_alert_delete_success_info'),
            'success'
          )
        }
      }
    })
  }

  const acceptFriendRequest = async (idUser, userUid) => {
    await friendRequestCheck(idUser, userUid, "accepted");
    window.location.reload();
  }

  useEffect(() => {
    loadUserData(iduser);
  }, [iduser]);

  if (isLoading) {
    return null;
  }

  return (
    <Layout>
      <div className="container padding90">
        <div className="row">
          <div className="col-sm-12">
            <div className="iq-card">
              <div className="iq-card-body profile-page p-0">
                <div className="profile-header">
                  <div className="cover-container">
                    <img className="rounded" height={325} width={970} style={{ maxWidth: "100%" }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = FALLBACK_COVER_PICTURE;
                      }}
                      src={userData?.cover && userData?.cover?.length >= 0 ? userData?.cover : FALLBACK_COVER_PICTURE} alt="cover" />
                  </div>
                  <div className="user-detail text-center mb-3">
                    <div className="profile-img-edit">
                      <img alt="profile" className="avatar-130 img-fluid"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = FALLBACK_PROFILE_PICTURE;
                        }}
                        height={150} width={200} style={{ borderRadius: 80 }}
                        src={userData?.profilePicture && userData?.profilePicture?.length >= 0 ? userData?.profilePicture : FALLBACK_PROFILE_PICTURE} />
                    </div>
                    <div className="profile-detail">
                      <h4>
                        {userData?.firstName} {userData?.lastName}
                      </h4>
                      {!isFriend && !friendRequestSend && !friendRequestPending && <button type="submit" onClick={() => sendFriendRequest(iduser, user.uid, `${userData.firstName} ${userData.lastName}`, user.displayName, user.photoURL, userData.userType).then(() => setFriendRequestSend(!friendRequestSend))} className="btn btn-sm btn-primary">{t("friends_add_friend")}</button>}
                      {friendRequestSend && !friendRequestPending && <p className="btn btn-sm btn-primary">{t("friends_request_send")}</p>}
                      {friendRequestPending && <button className="btn btn-sm btn-primary" onClick={() => acceptFriendRequest(iduser, user.uid)}>{t("friends_accept_friend")}</button>}
                      {isFriend && <>
                        <Link to={`/chat/${iduser}`} className="btn iconoazul d-none">
                          <i class="ri-message-2-line ri-2x"></i>
                        </Link>
                        <button type="submit" onClick={() => deleteFriendEvent(user.uid, iduser)} className="btn btn-sm btn-primary">{t("friends_delete_friend")}</button>
                      </>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="iq-card">
              <div className="iq-card-body p-0">
                <div className="user-tabing">
                  <ul className="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                    <li className="col-sm-6 p-0">
                      <a className="nav-link active" data-toggle="pill" href="#timeline">
                        {t("perfil_tab1")}
                      </a>
                    </li>
                    <li className="col-sm-6 p-0">
                      <a className="nav-link" data-toggle="pill" href="#about">
                        {t("perfil_tab2")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="tab-content">
              <div className="tab-pane fade active show" id="timeline" role="tabpanel">
                <div className="iq-card-body p-0">
                  <div className="row">
                    <PostListUser idUser={iduser} />
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="about" role="tabpanel">
                <div className="iq-card">
                  <div className="iq-card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <ul className="nav nav-pills basic-info-items list-inline d-block p-0 m-0">
                          <li>
                            <a className="nav-link active" data-toggle="pill" href="#basicinfo">
                              {t("profile_basic_information")}
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" data-toggle="pill" href="#aboutme">
                              {t("profile_aboutme_tab")}
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" data-toggle="pill" href="#socialnetwork">
                              {t("profile_social_tab")}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-9 pl-4">
                        <div className="tab-content">
                          <div className="tab-pane fade active show" id="basicinfo" role="tabpanel">
                            {userData && <BasicInfoView userData={userData} setUserData={setUserData} isReadOnly={true} isEnable={"disabled"} />}
                          </div>
                          <div className="tab-pane fade" id="aboutme" role="tabpanel">
                            <AboutMeView userData={userData} setUserData={setUserData} />
                          </div>
                          <div className="tab-pane fade" id="socialnetwork" role="tabpanel">
                            <SocialNetworkView userData={userData} setUserData={setUserData} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Viewuser;