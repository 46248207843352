import { useEffect, useState } from "react";
import { Facebook } from "react-content-loader";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { getCourses, getCoursesByLevel } from "../../services/coursesHelper";
import ModalViewCourse from "./ModalViewCourse";

const CoursesByLevel = ({level, lang}) => {
    const [courseList, setCourseList] = useState([]);
    const [lastVisible, setLastVisible] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [currentCourse, setCurrentCourse] = useState(null);
    const { t } = useTranslation();
    
    const fetchMoreData = async () => {
        setIsLoading(true);
        if (level === "All") {
            if (lastVisible) {
                await getCourses(lang, true, lastVisible).then(list => {
                    const last = list[list.length - 1];
                    let updated_list = courseList.concat(list);
                    setCourseList(updated_list);
                    setLastVisible(last);
                });
            }else{
                await getCourses(lang).then(list => {
                    const last = list[list.length - 1];
                    setCourseList(list);
                    setLastVisible(last);
                });
            }
        } else {
            fetchMoreDataByLevel();
        }

        setIsLoading(false);
    }

    const fetchMoreDataByLevel = async () => {
        if (lastVisible) {
            await getCoursesByLevel(lang, level, true, lastVisible).then(list => {
                const last = list[list.length - 1];
                let updated_list = courseList.concat(list);
                setCourseList(updated_list);
                setLastVisible(last);
            });
        } else {
            await getCoursesByLevel(lang, level).then(list => {
                const last = list[list.length - 1];
                setCourseList(list);
                setLastVisible(last);
            });
        }
    }

    useEffect(() => {
        const init = async() => {
            setCourseList([]);
            setLastVisible(null);
            fetchMoreData();
            setTimeout(() => {
                if(lastVisible == null){
                    setLastVisible(null);
                }
            }, 500);
        }
        init();
    }, [level]);

    const showModalView = (course) => {
        setCurrentCourse(course);
        setModalShow(!modalShow);
    }

    return (
        <>
            <div className="course-container">
                <InfiniteScroll
                    dataLength={courseList.length}
                    next={fetchMoreData}
                    hasMore={true}
                    loader={isLoading ? <Facebook /> : null}>
                    <div className="row">
                        {courseList.map((course, idx) => {
                            return (
                                <div key={idx} className="course-item col-4">
                                    <a role="button" tabIndex="0" onClick={() => showModalView(course)}>
                                        <img className="img-fluid" src={course.cover} alt={course.nombre} />
                                        <span>{course.nombre}</span>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                </InfiniteScroll>
                {
                    courseList.length === 0 ? <p className="pl-3">{t("courses_no_records_found")}</p> : null
                }
                <ModalViewCourse course={currentCourse} show={modalShow} onHide={() => setModalShow(false)} />
            </div>
        </>
    );
}

export default CoursesByLevel;