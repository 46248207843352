import { t } from "i18next";
import Layout from "../components/Layout";

const Libros = () => {

    return (
        <Layout>
            <div className="header-for-bg">
                <div className="background-header position-relative">
                    <img
                        src="https://ibloom.com.mx/pangu/libros.jpg"
                        className="img-fluid w-100"
                        alt="header-bg"
                    />
                    <div className="title-on-header">
                        <div className="data-block">
                            <h2>Libros</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-page" className="content-page">
                <div className="container ">
                    <div className="row">
                        <div className="col-sm-12">
                            <div data-aos="fade-right" className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-centered aos-init aos-animate" style={{}}>
                                <h2 style={{ color: '#BC955C', textAlign: 'center' }}>Ciclo Escolar 2022 - 2023</h2>
                                <ul className="tabrow area" style={{ margin: 0 }}>
                                    <li className="grado pre_prim_bar" rel="libros_primero_2020" style={{ zIndex: 10, color: 'white' }}><a onclick>Primer Grado - 2022<img src="https://www.conaliteg.sep.gob.mx/icons/librogris.png" style={{ width: 20 }} alt /></a></li>
                                </ul>
                                <div className="secc_libs" id="libros_primero_2020" style={{ display: 'block' }}>
                                    <div className="row" style={{ marginTop: '2%' }}>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P1COA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P1COA/000.jpg" className="img-responsive img-book" alt="Conocimiento del Medio." />
                                                            <b>Conocimiento del Medio.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 1°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P1ESA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P1ESA/000.jpg" className="img-responsive img-book" alt="Lengua Materna. Español." />
                                                            <b>Lengua Materna. Español.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 1°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P1FCA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P1FCA/000.jpg" className="img-responsive img-book" alt="Formación Cívica y Ética." />
                                                            <b>Formación Cívica y Ética.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 1°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P1LEA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P1LEA/000.jpg" className="img-responsive img-book" alt="Lecturas." />
                                                            <b>Lecturas.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 1°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P1MAA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P1MAA/000.jpg" className="img-responsive img-book" alt="Matemáticas." />
                                                            <b>Matemáticas.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 1°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* FIN PRIMERO 2020*/}
                                {/* INICIO SEGUNDO 2020*/}
                                <ul className="tabrow area" style={{ margin: 0 }}>
                                    <li className="grado pre_seg_bar" rel="libros_segundo_2020"><a style={{ zIndex: 10, color: 'white' }}>Segundo Grado&nbsp;- 2022<img src="https://www.conaliteg.sep.gob.mx/icons/librogris.png" style={{ width: 20 }} alt /></a></li>
                                </ul>
                                <div className="secc_libs" id="libros_segundo_2020">
                                    <div className="row" style={{ marginTop: '2%' }}>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P2COA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P2COA/000.jpg" className="img-responsive img-book" alt="Conocimiento del Medio." />
                                                            <b>Conocimiento del Medio.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 2°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P2ESA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P2ESA/000.jpg" className="img-responsive img-book" alt="Lengua Materna. Español." />
                                                            <b>Lengua Materna. Español.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 2°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P2FCA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P2FCA/000.jpg" className="img-responsive img-book" alt="Formación Cívica y Ética." />
                                                            <b>Formación Cívica y Ética.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 2°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P2LEA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P2LEA/000.jpg" className="img-responsive img-book" alt="Lecturas." />
                                                            <b>Lecturas.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 2°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P2MAA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P2MAA/000.jpg" className="img-responsive img-book" alt="Matemáticas." />
                                                            <b>Matemáticas.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 2°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* FIN SEGUNDO 2020*/}
                                {/*INICIO TERCER 2020*/}
                                <ul className="tabrow area" style={{ margin: 0 }}>
                                    <li className="grado pre_seg_bar" rel="libros_tercero_2020"><a style={{ zIndex: 10, color: 'white' }}>Tercer Grado&nbsp;- 2022<img src="https://www.conaliteg.sep.gob.mx/icons/librogris.png" style={{ width: 20 }} alt /></a></li>
                                </ul>
                                <div className="secc_libs" id="libros_tercero_2020">
                                    <div className="row" style={{ marginTop: '2%' }}>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3CNA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3CNA/000.jpg" className="img-responsive img-book" alt="Ciencias Naturales." />
                                                            <b>Ciencias Naturales.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3DMA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3DMA/000.jpg" className="img-responsive img-book" alt="Desafíos Matemáticos." />
                                                            <b>Desafíos Matemáticos.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3LEA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3LEA/000.jpg" className="img-responsive img-book" alt="Lecturas." />
                                                            <b>Lecturas.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3ESA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3ESA/000.jpg" className="img-responsive img-book" alt="Lengua Materna. Español." />
                                                            <b>Lengua Materna. Español.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3FCA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3FCA/000.jpg" className="img-responsive img-book" alt="Formación Cívica y Ética." />
                                                            <b>Formación Cívica y Ética.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3AGS.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3AGS/000.jpg" className="img-responsive img-book" alt="Aguascalientes. La entidad donde vivo." />
                                                            <b>Aguascalientes. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3BCN.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3BCN/000.jpg" className="img-responsive img-book" alt="Baja California. La entidad donde vivo." />
                                                            <b>Baja California. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3BCS.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3BCS/000.jpg" className="img-responsive img-book" alt="Baja California Sur. La entidad donde vivo." />
                                                            <b>Baja California Sur. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3CAM.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3CAM/000.jpg" className="img-responsive img-book" alt="Campeche. La entidad donde vivo." />
                                                            <b>Campeche. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3CDM.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3CDM/000.jpg" className="img-responsive img-book" alt="Ciudad de México. La entidad donde vivo." />
                                                            <b>Ciudad de México. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3CHH.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3CHH/000.jpg" className="img-responsive img-book" alt="Chihuahua. La entidad donde vivo." />
                                                            <b>Chihuahua. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3CHP.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3CHP/000.jpg" className="img-responsive img-book" alt="Chiapas. La entidad donde vivo." />
                                                            <b>Chiapas. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3COA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3COA/000.jpg" className="img-responsive img-book" alt="Coahuila de Zaragoza. La entidad donde vivo." />
                                                            <b>Coahuila de Zaragoza. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3COL.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3COL/000.jpg" className="img-responsive img-book" alt="Colima. La entidad donde vivo." />
                                                            <b>Colima. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3DUR.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3DUR/000.jpg" className="img-responsive img-book" alt="Durango. La entidad donde vivo." />
                                                            <b>Durango. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3GRO.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3GRO/000.jpg" className="img-responsive img-book" alt="Guerrero. La entidad donde vivo." />
                                                            <b>Guerrero. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3GTO.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3GTO/000.jpg" className="img-responsive img-book" alt="Guanajuato. La entidad donde vivo." />
                                                            <b>Guanajuato. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3HID.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3HID/000.jpg" className="img-responsive img-book" alt="Hidalgo. La entidad donde vivo." />
                                                            <b>Hidalgo. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3JAL.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3JAL/000.jpg" className="img-responsive img-book" alt="Jalisco. La entidad donde vivo." />
                                                            <b>Jalisco. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3MEX.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3MEX/000.jpg" className="img-responsive img-book" alt="Estado de México. La entidad donde vivo." />
                                                            <b>Estado de México. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3MIC.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3MIC/000.jpg" className="img-responsive img-book" alt="Michoacán. La entidad donde vivo." />
                                                            <b>Michoacán. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3NAY.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3NAY/000.jpg" className="img-responsive img-book" alt="Nayarit. La entidad donde vivo." />
                                                            <b>Nayarit. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3NLE.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3NLE/000.jpg" className="img-responsive img-book" alt="Nuevo León. La entidad donde vivo." />
                                                            <b>Nuevo León. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3OAX.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3OAX/000.jpg" className="img-responsive img-book" alt="Oaxaca. La entidad donde vivo." />
                                                            <b>Oaxaca. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3PUE.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3PUE/000.jpg" className="img-responsive img-book" alt="Puebla. La entidad donde vivo." />
                                                            <b>Puebla. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3QRO.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3QRO/000.jpg" className="img-responsive img-book" alt="Quintana Roo. La entidad donde vivo." />
                                                            <b>Quintana Roo. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3QUE.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3QUE/000.jpg" className="img-responsive img-book" alt="Querétaro. La entidad donde vivo." />
                                                            <b>Querétaro. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3SIN.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3SIN/000.jpg" className="img-responsive img-book" alt="Sinaloa. La entidad donde vivo." />
                                                            <b>Sinaloa. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3SLP.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3SLP/000.jpg" className="img-responsive img-book" alt="San Luis Potosí. La entidad donde vivo." />
                                                            <b>San Luis Potosí. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3SON.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3SON/000.jpg" className="img-responsive img-book" alt="Sonora. La entidad donde vivo." />
                                                            <b>Sonora. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3TAB.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3TAB/000.jpg" className="img-responsive img-book" alt="Tabasco. La entidad donde vivo." />
                                                            <b>Tabasco. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3TAM.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3TAM/000.jpg" className="img-responsive img-book" alt="Tamaulipas. La entidad donde vivo." />
                                                            <b>Tamaulipas. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3TLA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3TLA/000.jpg" className="img-responsive img-book" alt="Tlaxcala. La entidad donde vivo." />
                                                            <b>Tlaxcala. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3VER.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3VER/000.jpg" className="img-responsive img-book" alt="Veracruz. La entidad donde vivo." />
                                                            <b>Veracruz. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3YUC.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3YUC/000.jpg" className="img-responsive img-book" alt="Yucatán. La entidad donde vivo." />
                                                            <b>Yucatán. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P3ZAC.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P3ZAC/000.jpg" className="img-responsive img-book" alt="Zacatecas. La entidad donde vivo." />
                                                            <b>Zacatecas. La entidad donde vivo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 3°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*FIN TERCER 2020*/}
                                {/*INICIO CUARTO 2020*/}
                                <ul className="tabrow area" style={{ margin: 0 }}>
                                    <li className="grado pre_seg_bar" rel="libros_cuarto_2020"><a style={{ zIndex: 10, color: 'white' }}>Cuarto Grado&nbsp;- 2022<img src="https://www.conaliteg.sep.gob.mx/icons/librogris.png" style={{ width: 20 }} alt /></a></li>
                                </ul>
                                <div className="secc_libs" id="libros_cuarto_2020">
                                    <div className="row" style={{ marginTop: '2%' }}>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P4AMA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P4AMA/000.jpg" className="img-responsive img-book" alt="Atlas de México." />
                                                            <b>Atlas de México.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 4°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P4CCA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P4CCA/000.jpg" className="img-responsive img-book" alt="Conoce nuestra Constitución." />
                                                            <b>Conoce nuestra Constitución.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 4°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P4CNA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P4CNA/000.jpg" className="img-responsive img-book" alt="Ciencias Naturales." />
                                                            <b>Ciencias Naturales.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 4°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P4DMA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P4DMA/000.jpg" className="img-responsive img-book" alt="Desafíos Matemáticos." />
                                                            <b>Desafíos Matemáticos.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 4°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P4ESA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P4ESA/000.jpg" className="img-responsive img-book" alt="Lengua Materna. Español." />
                                                            <b>Lengua Materna. Español.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 4°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P4FCA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P4FCA/000.jpg" className="img-responsive img-book" alt="Formación Cívica y Ética." />
                                                            <b>Formación Cívica y Ética.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 4°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P4GEA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P4GEA/000.jpg" className="img-responsive img-book" alt="Geografía." />
                                                            <b>Geografía.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 4°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P4HIA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P4HIA/000.jpg" className="img-responsive img-book" alt="Historia." />
                                                            <b>Historia.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 4°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P4LEA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P4LEA/000.jpg" className="img-responsive img-book" alt="Lecturas." />
                                                            <b>Lecturas.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 4°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* FIN CUARTO 2020*/}
                                {/*INICIO QUINTO 2020*/}
                                <ul className="tabrow area" style={{ margin: 0 }}>
                                    <li className="grado pre_seg_bar" rel="libros_quinto_2020"><a style={{ zIndex: 10, color: 'white' }}>Quinto Grado&nbsp;- 2022<img src="https://www.conaliteg.sep.gob.mx/icons/librogris.png" style={{ width: 20 }} alt /></a></li>
                                </ul>
                                <div className="secc_libs" id="libros_quinto_2020">
                                    <div className="row" style={{ marginTop: '2%' }}>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P5AGA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P5AGA/000.jpg" className="img-responsive img-book" alt="Atlas de Geografía del Mundo." />
                                                            <b>Atlas de Geografía del Mundo.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 5°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P5CNA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P5CNA/000.jpg" className="img-responsive img-book" alt="Ciencias Naturales." />
                                                            <b>Ciencias Naturales.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 5°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P5DMA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P5DMA/000.jpg" className="img-responsive img-book" alt="Desafíos Matemáticos." />
                                                            <b>Desafíos Matemáticos.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 5°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P5ESA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P5ESA/000.jpg" className="img-responsive img-book" alt="Español." />
                                                            <b>Español.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 5°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P5FCA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P5FCA/000.jpg" className="img-responsive img-book" alt="Formación Cívica y Ética." />
                                                            <b>Formación Cívica y Ética.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 5°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P5GEA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P5GEA/000.jpg" className="img-responsive img-book" alt="Geografía." />
                                                            <b>Geografía.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 5°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P5HIA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P5HIA/000.jpg" className="img-responsive img-book" alt="Historia." />
                                                            <b>Historia.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 5°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P5LEA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P5LEA/000.jpg" className="img-responsive img-book" alt="Lecturas." />
                                                            <b>Lecturas.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 5°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/Z7822.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/Z7822/000.jpg" className="img-responsive img-book" alt="Formación Cívica y Ética. Cuaderno de aprendizaje." />
                                                            <b>Formación Cívica y Ética. Cuaderno de aprendizaje.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 5°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*FIN QUINTO 2020*/}
                                {/*INICIO SEXTO 2020*/}
                                <ul className="tabrow area" style={{ margin: 0 }}>
                                    <li className="grado pre_seg_bar" rel="libros_sexto_2020"><a style={{ zIndex: 10, color: 'white' }}>Sexto Grado&nbsp;- 2022<img src="https://www.conaliteg.sep.gob.mx/icons/librogris.png" style={{ width: 20 }} alt /></a></li>
                                </ul>
                                <div className="secc_libs" id="libros_sexto_2020">
                                    <div className="row" style={{ marginTop: '2%' }}>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P6CNA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P6CNA/000.jpg" className="img-responsive img-book" alt="Ciencias Naturales." />
                                                            <b>Ciencias Naturales.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 6°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P6DMA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P6DMA/000.jpg" className="img-responsive img-book" alt="Desafíos Matemáticos." />
                                                            <b>Desafíos Matemáticos.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 6°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P6ESA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P6ESA/000.jpg" className="img-responsive img-book" alt="Español." />
                                                            <b>Español.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 6°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P6FCA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P6FCA/000.jpg" className="img-responsive img-book" alt="Formación Cívica y Ética." />
                                                            <b>Formación Cívica y Ética.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 6°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P6GEA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P6GEA/000.jpg" className="img-responsive img-book" alt="Geografía. Quinta edición" />
                                                            <b>Geografía. Quinta edición</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 6°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P6HIA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P6HIA/000.jpg" className="img-responsive img-book" alt="Historia." />
                                                            <b>Historia.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 6°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/P6LEA.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/P6LEA/000.jpg" className="img-responsive img-book" alt="Lecturas." />
                                                            <b>Lecturas.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 6°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/Z7778.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/Z7778/000.jpg" className="img-responsive img-book" alt="Cuaderno de Actividades. Geografía." />
                                                            <b>Cuaderno de Actividades. Geografía.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 6°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ clear: 'left' }}>
                                            <div className="row">
                                                <a target="_blank" href="https://www.conaliteg.sep.gob.mx/2022/Z7823.htm">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                        <p className="btn-guinda" style={{ textAlign: 'center' }}>
                                                            <img src="https://www.conaliteg.sep.gob.mx/2022/m/Z7823/000.jpg" className="img-responsive img-book" alt="Formación Cívica y Ética. Cuaderno de aprendizaje." />
                                                            <b>Formación Cívica y Ética. Cuaderno de aprendizaje.</b><br />
                                                            Editorial: SEP<br />
                                                            Grado: 6°<br />
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* FIN SEXTO 2020*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>



    );
}

export default Libros;