import { addDoc, collection, getCountFromServer, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { getUserByUid, updateUser, uploadPicture } from "../../services/authHelper";
import { getSubscriptionById } from "../../services/subscriptionsHelper";
const collectionName = "users";
const pType = "teacher";
const SType = "student";

export const getTotalUsers = async () => {
    const colRef = collection(db, collectionName);
    const query_ = query(colRef, where('userType', 'in', [pType, SType]));
    const snapshot = await getCountFromServer(query_);
    return snapshot.data().count;
}

export const getTotalProfesors = async () => {
    const colRef = collection(db, collectionName);
    const query_ = query(colRef, where('userType', '==', pType));
    const snapshot = await getCountFromServer(query_);
    return snapshot.data().count;
}

export const getTotalStudents = async () => {
    const colRef = collection(db, collectionName);
    const query_ = query(colRef, where('userType', '==', SType));
    const snapshot = await getCountFromServer(query_);
    return snapshot.data().count;
}

export const getProfesors = async () => {
    let data = [];
    try {
        const q = query(collection(db, collectionName), where("userType", "==", pType));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const { firstName, lastName, infoPagos, subscription, uid } = doc.data();
            const userInfo = { firstName, lastName, infoPagos, subscription, uid, fullname: `${firstName} ${lastName}` };
            data.push(userInfo);
        });
        const result = await getCountClass(data);
        return result;
    } catch (error) {
        console.error("error getProfesors", error);
    }
    return data;
}

export const getCountClass = async (pList) => {
    const profesorsWithCountClass = await Promise.all(
        pList.map(async (profesor) => {
            const cFreeClass = await getCountFreeClass(profesor.uid);
            const cPaidClass = await getCountPaidClass(profesor.uid);
            return { ...profesor, countFreeClass: cFreeClass, countPaidClass:cPaidClass }
        })
    );
    return profesorsWithCountClass;
}

export const getCountFreeClass = async(uid) => {
    const colRef = collection(db, "lessons");
    const query_ = query(colRef, where('uid', '==', uid), where('type', '==', 'free'));
    const snapshot = await getCountFromServer(query_);
    return snapshot.data().count;
}

export const getCountPaidClass = async(uid) => {
    const colRef = collection(db, "lessons");
    const query_ = query(colRef, where('uid', '==', uid), where('type', '==', 'paid'));
    const snapshot = await getCountFromServer(query_);
    return snapshot.data().count;
}

export const getStudents = async () => {
    let data = [];
    try {
        const q = query(collection(db, collectionName), where("userType", "==", SType));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const { firstName, lastName, uid, email } = doc.data();
            const userInfo = { firstName, lastName, uid, email};
            data.push(userInfo);
        });
    } catch (error) {
        console.error("error getStudents", error);
    }
    return data;
}

export const processPay = async(pInfo, amount, cuid, file) => {
    let result ={ isSuccess: false, message: "error_something_wrong" };
    const uData = await getUserByUid(pInfo.uid);
    if(uData?.subscription?.isPremium){
        const currentSubscription = await getSubscriptionById(uData.uid, uData.subscription.usid);
        if (currentSubscription) {
            const cDate = new Date();
            const endDate = currentSubscription.endDate.toDate();
            if (cDate.getTime() < endDate.getTime()) {
                await payProfesor(uData, pInfo, amount, cuid, file)
                .then(r => {
                    result.isSuccess = r;
                });
            }else{
                result.message = "admin_profesors_subscription_required";
            }
        }else{
            result.message = "admin_profesors_subscription_required";
        }
    }else{
        result.message = "admin_profesors_subscription_required";
    }
    return result;
}

export const payProfesor = async (uData, pInfo, amount, cuid, file) => {
    //const dbInfo = await getUserByUid(pInfo.uid);
    const newData = { ...uData, infoPagos: pInfo.infoPagos };
    try {
        const url = await uploadPicture(`pagos/${pInfo.uid}/${new Date().getTime()}`, file);
        if(url){
            await updateUser(newData);
            await addPaymentHistory(newData.uid, cuid, amount, url);
            return true;
        }
        return false;
    } catch (error) {
        console.error("error payProfesor", error); 
        return false;  
    }
}

export const addPaymentHistory = async (uid, cuid, amount, url) => {
    const coll = collection(db, `users/${uid}/pagos`);
    await addDoc(coll, {
        to: uid,
        from: cuid,
        pagado: amount,
        comprobante: url,
        timestamp: serverTimestamp()
    });
}