import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

const sidebarMenu = [
  { text: "sidebar_home", route: "/admin/home", icon: "fa fa-home" },
  { text: "Alumnos", route: "/admin/students", icon: "ri-user-fill" },
  { text: "Escuelas", route: "/admin/escuelas", icon: "fa fa-building-o" },
  { text: "Padres o tutores", route: "/admin/padres", icon: "fa fa-users" },
  { text: "Profesores", route: "/admin/profesors", icon: "ri-user-2-fill" },
  { text: "Directores", route: "/admin/directores", icon: "ri-user-fill" },
  { text: "Materias", route: "/admin/materias", icon: "fa fa-list-alt" },
  { text: "Calificaciones", route: "/admin/calificaciones", icon: "fa fa-file-text-o" },
  { text: "Graficas de rendimiento", route: "/admin/rendimiento", icon: "fa fa-pie-chart" },
  { text: "Ranking", route: "/admin/ranking", icon: "fa fa-bar-chart" },
  { text: "Calendario", route: "/admin/calendario", icon: "las la-calendar" },
  { text: "Notificaciones", route: "/admin/notificaciones", icon: "ri-notification-2-line" }
];

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();
  
  const renderList = () => {
    return sidebarMenu.map((option, index) => {
      return (
        <li
          key={index}
          onClick={() => setActiveLink(index)}
          className={`${activeLink === index ? "active" : ""}`}
        >
          <NavLink
            to={option.route}
            exact
          >
            <div className="iq-waves-effect">
              <i className={option.icon} />
              {t(option.text)}
            </div>
          </NavLink>
        </li>
      );
    });
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    const activeItem = sidebarMenu.findIndex(item => item.route === `${currentPath}`);
    setActiveLink(currentPath.length === 0 ? 0 : activeItem);
  }, [location]);

  return (
    <div className="iq-sidebar">
      <div id="sidebar-scrollbar">
        <nav className="iq-sidebar-menu">
          <ul id="iq-sidebar-toggle" className="iq-menu">
            {renderList()}
          </ul>
        </nav>
        <div className="p-3" />
      </div>
    </div>
  );
};

export default Sidebar;
