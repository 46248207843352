import { useState } from "react";
import CourseFilter from "./CourseFilter";
import CoursesByLevel from "./CoursesByLevel";

const EnglishCourses = () => {
    const [level, setLevel] = useState("All");
    
    return(
        <>
            <div className="courses">
                <CourseFilter level={level} setLevel={setLevel} />
                <CoursesByLevel lang="english" level={level} />
            </div>
        </>
    );
}

export default EnglishCourses;