import { addDoc, collection, deleteDoc, doc, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
const collectionName = "events";

export const saveEvent = async (data) => {
    try {
        await addDoc(collection(db, collectionName), {
            uid: data.uid,
            title: data.title,
            description: data.description,
            dateStart: data.dateStart,
            dateEnd: data.dateEnd,
            color: data.color,
            isActive: true,
        });
    } catch (error) {
        console.log("error saveEvent", error); 
    }
}

export const bookLesson = async(lesson) => {
    let result = false;
    try {
        await setDoc(doc(db, collectionName, `${lesson.uid}${lesson.id}`), lesson);
        await updateDoc(doc(db, `users/${lesson.uid}/lessons`, lesson.id), { isScheduled: true});
        result = true;
    } catch (error) {
        console.log("error bookLesson", error);   
    }
    return result;
}

export const updateEvent = async (data) => {
    const docRef = doc(db, collectionName, data.id);
    await updateDoc(docRef, {
        title: data.title,
        description: data.description,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        color: data.color
    });
};

export const deleteEvent = async (id) => {
    try {
        await deleteDoc(doc(db, collectionName, id));
    } catch (error) {
        console.log("error deleteEvent", error);   
    }
};

export const loadCalendarEvents = async (uid, setEvents) => {
    const currentDate = new Date();
    const [day, month, year] = [currentDate.getDate(), currentDate.getMonth(), currentDate.getFullYear()];
    const startDate = new Date(year, month - 4, day, 0, 0, 0);
    const endDate = new Date(year, month + 4, day, 23, 59, 59);

    const q = query(collection(db, collectionName), where("uid", "==", uid), 
    where("dateStart", ">=", startDate), 
    where("dateStart", "<=", endDate));
    const unsubscribe = onSnapshot(q, (snap) => {
        const events = snap.docs.map((doc) => {
            return {
                id: doc.id,
                title: doc.get("title"),
                description: doc.get("description"),
                start: doc.get("dateStart").toDate(),
                end: doc.get("dateEnd").toDate(),
                color: doc.get("color"),
                allDay: false,
            };
        });
        setEvents([...events]);
    });
    return () => unsubscribe();
}

export const loadCurrentEvents = async (uid, setEvents) => {
    const currentDate = new Date();
    const [day, month, year] = [currentDate.getDate(), currentDate.getMonth(), currentDate.getFullYear()];
    const startDate = new Date(year, month, day, 0, 0, 0);
    const endDate = new Date(year, month, day, 23, 59, 59);

    const q = query(collection(db, collectionName), where("uid", "==", uid), 
    where("dateEnd", "<=", endDate), 
    where("dateEnd", ">=", startDate),
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
        const events = snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                title: doc.get("title"),
                description: doc.get("description"),
                start: doc.get("dateStart").toDate(),
                end: doc.get("dateEnd").toDate(),
                color: doc.get("color"),
                allDay: false,
            };
        });
        setEvents(events);
    });
    return () => unsubscribe();
}