export const  yupLocale = {
    mixed: {
        default: {
            key: 'validations_invalid',
        },
        required: {
            key: 'validations_required',
        },
        notType: ({ type }) => ({
            key: 'validations_invalidType',
            values: { type },
        }),
    },
    string: {
        email: {
            key: 'validations_email',
        },
        min: ({ min }) => ({
            key: 'validations_stringMin',
            values: { min },
        }),
        max: ({ max }) => ({
            key: 'validations_stringMax',
            values: { max },
        }),
    }
};