import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getExerciseById } from "../../services/exercisesHelper";
import Choices from "./Choices";
import SentenceCompletion from "./SentenceCompletion";
import TieUp from "./TieUp";

const ExerciseById = () => {
    const { exerciseId } = useParams();
    const [exercise, setExercise] = useState(null);
    const navigate = useNavigate();

    const loadExerciseWithQuestions = async () => {
        await getExerciseById(exerciseId).then((exercise) => { setExercise(exercise); });
    }
    useEffect(() => {
        loadExerciseWithQuestions();
    }, [exerciseId]);
    
    const renderQuestions = () => {
        if(exercise?.tipo){
            if(exercise?.tipo === "1" || exercise?.tipo === "2"){
                return <Choices exerciseId={exerciseId} exerciseType={exercise?.tipo} />
            }else if(exercise?.tipo === "3"){
                return <TieUp exerciseId={exerciseId} />
            }else if(exercise?.tipo === "4"){
                return <SentenceCompletion exerciseId={exerciseId} keyAnswers={exercise?.respuestas}/>
            }
            return null;
        }
        return null;
    }

    return (
        <div className="iq-card px-2">
            <div className="iq-card-header">
                <div className="row justify-content-between">
                    <div class="col-sm-12 col-md-6">
                        <h2 className="pt-2">{exercise?.titulo}</h2>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div className="d-flex float-right">
                            <button onClick={() => navigate("/exercises/all")} className="btn btn-ligth mt-2"><i class="ri-arrow-go-back-fill"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iq-card-body">
                <div className="container exercises">
                    <h6>Comentario de profesor</h6>
                    <div className="row">
                        <h5 className="mb-2">{exercise?.auxiliar}</h5>
                        {renderQuestions()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExerciseById;