import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../authContext";
import { createInitialUserTest, getCListeningQuestions, getListeningDiagnostic, getUserTestInfo, isTopicEvaluated, saveScore } from "../../services/DiagnosticHelper";
import { ShowSwalAlert } from "../Utils";

const CListening = () => {
    const { diagnosticId } = useParams();
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [isEvaluate, setIsEvaluate] = useState(false);
    const [listeningData, setListeningData] = useState(null);
    const [userTestInfo, setUserTestInfo] = useState(null);
    const navigate = useNavigate();
    const { user, userData } = useAuth();
    const { t } = useTranslation();
    
    const loadListeningData =  async() => {
        await getListeningDiagnostic(diagnosticId).then((data) => {
            setListeningData(data);
            document.getElementById("listening-content").innerHTML = data.html2;
        });
        await getCListeningQuestions(diagnosticId).then((result) => setQuestions(result))
        await isTopicEvaluated(user.uid, diagnosticId, "listening").then((result) => {
            setIsEvaluate(result)
        });
        await createInitialUserTest(user.uid, diagnosticId).then(() => {
            getUserTestInfo(user.uid, diagnosticId).then((testInfo) => setUserTestInfo(testInfo))
        });
    }

    useEffect(() => {
        loadListeningData();
    }, [diagnosticId]);

    const handleOnChange = (id, idx) => {
        const index = answers.findIndex(a => a.id === id);
        if (index >= 0) {
            const newAnswers = [...answers];
            newAnswers[index].answer = idx;
            setAnswers(newAnswers);
        } else {
            setAnswers([...answers, { id, answer: idx }]);
        }
    }

    const checkandSaveScore = async () => {
        if (answers.length === 0 || answers.length < questions.length) {
            ShowSwalAlert({ icon: "info", title: t('exercises_all_answers_required') });
            return;
        }
        let score = 0;
        const updatedQuestions = questions.map(q => {
            const r = answers.find(a => a.id === q.id);
            if (r) {
                q.answer = r.answer;
                if("" + r.answer === q.correcta ){
                    q.feedback = "ri-check-line bg-success";
                    score++;
                }else{
                    q.feedback = "ri-close-line bg-danger";
                }
            }
            return q;
        });
        setQuestions(updatedQuestions);

        try {
            const data = { listening: score, status: userTestInfo.status + 1 }
            await saveScore(user.uid, diagnosticId, data);
        } finally {
            setIsEvaluate(!isEvaluate);
        }
    }

    return (
        <div className="iq-card px-2">
            <div className="iq-card-header">
                <div className="row justify-content-between">
                    <div class="col-sm-12 col-md-6">
                        <h2 className="pt-2">{listeningData?.titulo}</h2>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div className="d-flex float-right">
                            <button onClick={() => navigate(`/tests/${diagnosticId}/detail`)} className="btn btn-ligth mt-2"><i class="ri-arrow-go-back-fill"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iq-card-body">
                <div className="containder">
                    <h6>Comentario de profesor</h6>
                    <div className="row">
                        <div className="col-sm-12">
                            <div id="listening-content"></div>
                        </div>
                        <>
                            {questions.map((question, qkey) => {
                                return (
                                    <div className="col-sm-12" key={qkey}>
                                        <p className="w-100">{question.pregunta}</p>
                                        {
                                            <div className="form-group">{question.respuestas.map((r, idx) =>
                                                <div key={idx}><input type="radio" id={`${question.id}-${idx}`} onChange={() => handleOnChange(question.id, idx)} value={r} name={question.id} /> <label htmlFor={`${question.id}-${idx}`}>{r}</label> {question.answer === idx ? <i className={question.feedback}></i> : null}</div>
                                            )}
                                            </div>
                                        }
                                    </div>
                                );
                            })
                            }

                        </>
                        {!isEvaluate ? userData?.subscription?.isPremium && <button onClick={() => checkandSaveScore()} className="btn btn-primary">{t("diagnostictests_verify")}</button> : <button onClick={() => navigate(`/tests/${diagnosticId}/detail`)} className="btn btn-primary mt-2"><i class="ri-arrow-go-back-fill"></i></button>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CListening;