import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { t } from "i18next";
import moment from "moment";
import { useState } from "react";
import { paypalSubscription, paypalSubscriptionTEST } from "../../Config";
import { activateSubscription, enablePremiumSubscription } from "../../services/subscriptionsHelper";
import { ShowSwalAlert } from "../Utils";

const SubscriptionPay = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    // const createOrder = (data, actions) => {
    //     return actions.order.create({
    //         purchase_units: [
    //             {
    //                 description: `Pangu - subscription ${props?.subscription?.id}`.trim(),
    //                 amount: {
    //                     value: props.subscription?.price,
    //                 },
    //             },
    //         ],
    //     });
    // }

    const createSubscription = (data, actions) => {
        const planId = props.subscription?.planId;
        //return actions.subscription.create({ 'plan_id': planId });
        return actions.subscription.create({ 'plan_id': 'P-42C674627L2792407MQNUW6I' });//TEST        
    }
    const onApprove = async (data, actions) => {
        // console.log("data", data);
        console.log("data.subscriptionID", data.subscriptionID);
        //await activateUserSubscription(data.subscriptionID, data.orderID);
    }

    const activateUserSubscription = async (subscriptionID, orderId) => {
        setIsSubmitting(!isSubmitting);
        const startDate = new Date(moment());
        await enablePremiumSubscription(props.user, subscriptionID, props.subscription?.type, orderId).then(r => {
            ShowSwalAlert({ icon: 'success', title: t('subscription_activate') });
        }).then(() => {
            activateSubscription(props.user, props.subscription, startDate, subscriptionID, orderId);
        }).finally(() => {
            setIsSubmitting(!isSubmitting);
            props.onHide();
            window.location.reload();
        })
    }

    return (
        <>
            <div className="col">
                <div className="iq-card">
                    <ul className="post-opt-block d-flex align-items-center list-inline m-2">
                        <li className="iq-bg-primary rounded p-2 pointer mr-3" onClick={() => props.setStep("subscription")}>{t("subscription_pay_goback")}</li>
                    </ul>
                    <div className="iq-card-body text-center">
                        <h2 className="mb-4">${props.subscription?.price}</h2>
                        {
                            props.subscription?.price > 0 && <>
                                <h3 className="mb-4">{t("subscription_pay_title")}</h3>
                                <p className="font-size-18">{t("subscription_pay_description")}</p>
                            </>
                        }
                        <ul className="list-unstyled line-height-4 mb-0">
                            <li>{`${props.subscription?.days} ${t('subscription_days')}`}</li>
                        </ul>
                    </div>
                    {
                        <PayPalScriptProvider options={paypalSubscriptionTEST}>
                            <PayPalButtons
                                style={{ layout: "vertical", label: "subscribe" }}
                                createSubscription={createSubscription}
                                // createOrder={createOrder}
                                onApprove={onApprove}
                            /></PayPalScriptProvider>
                    }
                </div>
            </div>

        </>
    );
}

export default SubscriptionPay;