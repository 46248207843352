import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
const collectionName = "administrador";

export const getInfo = async (uid) => {
    let user = {};
    const docRef = doc(db, collectionName, uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        user = docSnap.data()
    }
    return user;
}