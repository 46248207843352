import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { updateUser } from "../../services/authHelper";
import { ErrorMessage } from "../ErrorMessage";

const AboutMe = ({ userData, setUserData }) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    const handleOnSubmit =async (evt) => {
        evt.preventDefault();
        setIsSubmitting(true);
        try {
            await updateUser(userData);
        } finally {
            setIsSubmitting(false);
        }
    }
    
    const handleOnchange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const rawValue = value.trim();
        if (rawValue === "") {
            setErrors({ ...errors, [name]: { message: { key: "validations_required" } } })
        }
        else {
            const descriptionProfile = {...userData["descriptionProfile"]}
            descriptionProfile[name] = value;
            setErrors({ ...errors, [name]: undefined })
            setUserData({ ...userData, descriptionProfile: descriptionProfile });
        }
    };

    return (
        <>
            <div className="row">
                <div className="iq-card-body">
                    <h4>{t("profile_aboutme_tab")}</h4>
                    <hr />
                    <form onSubmit={handleOnSubmit}>
                        <div className="row">
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="description">{t("profile_aboutme_description")}</Form.Label>
                                <Form.Control as="textarea" rows={5} id="description" name="description" isInvalid={!!errors?.description}
                                onChange={handleOnchange} onBlur={handleOnchange} defaultValue={userData?.descriptionProfile?.description} style={{ lineHeight:1.5 }} />
                                <ErrorMessage error={errors.description?.message} />
                            </Form.Group>

                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="cv">{t("profile_aboutme_cv")}</Form.Label>
                                <Form.Control type="text" rows={5} id="cv" name="cv" isInvalid={!!errors?.cv}
                                onChange={handleOnchange} onBlur={handleOnchange} defaultValue={userData?.descriptionProfile?.cv} />
                                <ErrorMessage error={errors.cv?.message} />
                            </Form.Group>

                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="videoLink">{t("profile_aboutme_videolink")}</Form.Label>
                                <Form.Control type="text" rows={5} id="videoLink" name="videoLink" isInvalid={!!errors?.videoLink}
                                onChange={handleOnchange} onBlur={handleOnchange} defaultValue={userData?.descriptionProfile?.videoLink} />
                                <ErrorMessage error={errors.videoLink?.message} />
                            </Form.Group>
                        </div>
                        <button type="submit" className="btn btn-primary mr-2" disabled={isSubmitting}>
                            {t("profile_update")} {isSubmitting && <Spinner animation="border" variant="light" size="sm" />}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default AboutMe;