import { useTranslation } from "react-i18next";

const CourseFilter = ({ level, setLevel }) => {
    const { t } = useTranslation();
    return (
        <div className="course-filter mb-20">
            <ul className="filter-btn">
                <li onClick={() => setLevel("All")} className={level === "All" ? "active" : ""}>{t("courses_all_levels")}</li>
                <li onClick={() => setLevel("a1")} className={level === "a1" ? "active" : ""}>A1</li>
                <li onClick={() => setLevel("a2")} className={level === "a2" ? "active" : ""}>A2</li>
                <li onClick={() => setLevel("b1")} className={level === "b1" ? "active" : ""}>B1</li>
                <li onClick={() => setLevel("b2")} className={level === "b2" ? "active" : ""}>B2</li>
                <li onClick={() => setLevel("c1")} className={level === "c1" ? "active" : ""}>C1</li>
                <li onClick={() => setLevel("c2")} className={level === "c2" ? "active" : ""}>C2</li>
            </ul>
        </div>
    );
}

export default CourseFilter;