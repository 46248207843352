import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../authContext";
import { getMessageList, sendMessage } from "../../services/chatHelper";
import { uploadFileToStorage } from "../../services/storageUtil";
import { handleFileValidations } from "../../utils/FileValidation";
import { createImageFromInitials } from "../Utils";
import ChatContent from "./ChatContent";
import ChatFooter from "./ChatFooter";
import ChatHeader from "./ChatHeader";
import PreviewImage from "./PreviewImage";
import Recording from "./Recording";

const Chat = ({ friendData, fromAllFriends }) => {
    const toUserName = friendData ? `${friendData.firstName} ${friendData.lastName}` : "";
    const avatarFrom = friendData?.profilePicture ? friendData?.profilePicture : createImageFromInitials(50, `${toUserName}`, '#50b5ff');
    const idChat = friendData ? friendData.uid : null;
    const { user } = useAuth();
    const [file, setFile] = useState("");
    const [isFileSeleted, setIsFileSeleted] = useState(false);
    const [progress, setProgress] = useState("");
    const [inputMessage, setInputMessage] = useState("");
    const [isRecording, setIsRecording] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [messageList, setMessageList] = useState([]);
    const { t } = useTranslation();

    async function sendMessages(e) {
        e.preventDefault();
        try {
            setIsSubmitting(true);
            if (isFileSeleted) {
                const dTime = new Date().getTime()
                const path = `chats/${user.uid}/Image-Video/${idChat}/${dTime}`
                const fileType = file.type.split('/')[0];
                await uploadFileToStorage(path, file, { contentType: file.type }, setProgress).then((url) => {
                    sendMessage({ userUid: user.uid, idChat, fromUserName: user.displayName, toUserName, message: inputMessage, fileUrl: url, fileType: fileType});
                }).catch((error) => {
                    console.log("uploadFileToStorage error", error);
                });
            } else {
                sendMessage({ userUid: user.uid, idChat, fromUserName: user.displayName, toUserName, message: inputMessage })
            }
        } catch (error) {
            console.log("sendMessages error", error);
        } finally {
             setFile("");
             setIsFileSeleted(false);
             setInputMessage("");
             setProgress("");
             setIsSubmitting(false);
        }
    }

    const handleFileChange = async (e) => {
        handleFileValidations(e, setFile, setIsFileSeleted);
    }
    
    useEffect(() => {
        getMessageList({ setMessageList, userUid: user.uid, idChat: idChat });
    }, [user.uid, idChat]);

    return (
        <>
            <div className={fromAllFriends ? "col-lg-8 chat-data p-0 chat-data-right" : "col-lg-12 chat-data p-0 chat-data-right"}>
                <div className="tab-content">
                    {fromAllFriends &&
                        <div className="tab-pane fade active show" id="default-block" role="tabpanel">
                            <div className="chat-start">
                                <span className="iq-start-icon text-primary"><i className="ri-message-3-line" /></span>
                                <button id="chat-start" className="btn bg-white mt-3">{t("chat_start")}</button>
                            </div>
                        </div>
                    }
                    <div className={fromAllFriends ? "tab-pane fade" : "tab-pane fade active show"} id="chatbox1" role="tabpanel">
                        <ChatHeader toUserName={toUserName} avatarFrom={avatarFrom} friendData={friendData} fromAllFriends={fromAllFriends} />
                        {file ?
                            <PreviewImage file={file} setFile={setFile} setIsFileSeleted={setIsFileSeleted} progress={progress} isSubmitting={isSubmitting}/>
                            :
                            <ChatContent idChat={idChat} messageList={messageList} avatarFrom={avatarFrom} />
                        }
                        {isRecording ?
                            <Recording idChat={idChat} toUserName={toUserName} setIsRecording={setIsRecording} />
                            :
                            <ChatFooter inputMessage={inputMessage} setInputMessage={setInputMessage} setIsRecording={setIsRecording}
                                handleFileChange={handleFileChange} isFileSeleted={isFileSeleted} sendMessages={sendMessages} isSubmitting={isSubmitting}/>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Chat;