import { doc, setDoc, collection, query, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import axios from "axios";

export const traducir = async ({ message, UserUid, idChat, idMessage, lang}) => {
  const target = lang === 'es' ? `idiomao=es&idiomaf=en` : `idiomao=en&idiomaf=es`;
  axios.get(`https://somosprincesasymas.com/pangea/traductor/traductorwebget.php?${target}&texto=` + message, {}).then(res => {
    const translationRef = doc(db, `users/${UserUid}/chats/${idChat}/Messages/${idMessage}`)
    updateDoc(translationRef, {
      message: res.data
    })
  })
}

export const traducirInput = async ({ inputMessage, setInputMessage, lang }) => {
  const target = lang === 'es' ? `idiomao=es&idiomaf=en` : `idiomao=en&idiomaf=es`;
  if (inputMessage) {
    axios.get(`https://somosprincesasymas.com/pangea/traductor/traductorwebget.php?${target}&texto=` + inputMessage, {}).then(res => {
      setInputMessage(res.data)
    })
  }
}

export const getMessageList = async ({ setMessageList, userUid, idChat }) => {
  const q = query(collection(db, `users/${userUid}/chats/${idChat}/Messages`))
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const messages = [];
    querySnapshot.forEach((doc) => {
      messages.push({ ...doc.data(), id: doc.id });
    })
    setMessageList([...messages])
  });
  return () => unsubscribe();
}

export const sendAudio = async ({ userUid, idChat, toUserName, fromUserName, audioUrl }) => {
  await createChatUser({ userUid, idChat, toUserName, fromUserName });
  await createUserMessageAudio({ userUid, idChat, audioUrl })
}

export const sendMessage = async ({ userUid, idChat, fromUserName, toUserName, message, fileUrl, fileType}) => {
  await createChatUser({ userUid, idChat, fromUserName, toUserName });
  await createUserMessages({ userUid, idChat, message, fileUrl, fileType});
}

const createUserMessageAudio = async ({ userUid, idChat, audioUrl }) => {
  const fromTime = new Date().getTime();
  const toTime = new Date().getTime();
  const pathMessageFrom = `users/${userUid}/chats/${idChat}/Messages/${fromTime}`;
  const pathMessageTo = `users/${idChat}/chats/${userUid}/Messages/${toTime}`;
  const messageFromDocRef = doc(db, pathMessageFrom);
  const messageToDocRef = doc(db, pathMessageTo);
  const payload = {
    message: null,
    audio: audioUrl,
    messageType: "2",
    isActive: true,
    dateUtc: new Date().toLocaleTimeString(),
    idUserEnvio: userUid,
    idRecivied: idChat
  }
  setDoc(messageFromDocRef, {...payload, from: toTime, type: 'send'});
  setDoc(messageToDocRef, {...payload, from: fromTime, type: 'received'});
}

const createChatUser = async ({ userUid, idChat, fromUserName, toUserName }) => {
  const pfrom = `users/${userUid}/chats/${idChat}`;
  const chatFromDocRef = doc(db, pfrom);
  setDoc(chatFromDocRef, {
    nameUser: toUserName,
    uid: idChat,
    isActive: true,
    isArchived: false
  })

  const pTo = `users/${idChat}/chats/${userUid}`;
  const chatToDocRef = doc(db, pTo);
  setDoc(chatToDocRef, {
    nameUser: fromUserName,
    uid: userUid,
    isActive: true,
    isArchived: false
  })
}

const createUserMessages = async ({ userUid, idChat, message, fileUrl = null, fileType = null}) => {
  const fromTime = new Date().getTime();
  const toTime = new Date().getTime();
  const pathMessageFrom = `users/${userUid}/chats/${idChat}/Messages/${fromTime}`;
  const pathMessageTo = `users/${idChat}/chats/${userUid}/Messages/${toTime}`;
  const messageFromDocRef = doc(db, pathMessageFrom);
  const messageToDocRef = doc(db, pathMessageTo);
  let payload = {
    message: message,
    isActive: true,
    messageType: "0",
    dateUtc: new Date().toLocaleTimeString(),
    idUserEnvio: userUid,
    idRecivied: idChat
  };
  if(fileUrl && fileType){
    if(fileType === 'image'){
      payload.image = fileUrl;
      payload.messageType = "1";
    }else if(fileType === 'video'){
      payload.video = fileUrl;
      payload.messageType = "3";
    }
  }
  setDoc(messageFromDocRef, {...payload, from: toTime,  type: 'send'});
  setDoc(messageToDocRef, {...payload, from: fromTime, type: 'received'});
}

