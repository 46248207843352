import { collection, deleteDoc, doc, getDoc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { getFriendRequestById, getUserById } from "./userHelper";

const collectionName = "users";

export const sendFriendRequest = async (idUser, userIdRequester, username, usernameRequester, profilePicture, userType, profilePictureNotRequester, RequesterUserType, flagHelp = false) => {
    try {
        //Ayuda a que la solicitud se envie en direccion contraria, desde la sugerencia solamente
        if (flagHelp) {
            await setDoc(doc(db, collectionName, userIdRequester, "friendRequest", idUser), {
                userIdRequester: idUser,
                usernameRequester: username,
                userTypeRequester: userType,
                date: new Date().toUTCString(),
                idUser: userIdRequester,
                username: usernameRequester,
                userType: userType,
                profilePicture: profilePictureNotRequester,
                status: "pending"
            });
            idUser = userIdRequester;
        } else {
            await setDoc(doc(db, collectionName, idUser, "friendRequest", userIdRequester), {
                userIdRequester: userIdRequester,
                usernameRequester: usernameRequester,
                userTypeRequester: RequesterUserType,
                date: new Date().toUTCString(),
                idUser: idUser,
                username: username,
                userType: userType,
                profilePicture: profilePicture,
                status: "pending"
            });
        }

        let requestButton = document.getElementById("suggestion" + idUser);
        requestButton.style.display = "none";

        let checkButton = document.getElementById("check" + idUser);
        checkButton.style.display = "";
        checkButton.style.color = "darkblue";

    } catch (e) {
        console.log(e);
    }
}

export const friendRequest = async (uid, setFriendsRequest, setQtyFriendsRequest) => {
    //Get FriendRequest Time Real
    var q = query(collection(db, collectionName, uid, "friendRequest"), where("status", "==", "pending"));
    //Snapshot Time Real - Cuando hay un cambio en la colección de friendRequest, el callback dentro de la función es ejecutado, Actualizando todos los datos.
    const unsub = onSnapshot(q, async (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
            docs.push({ uid: doc.id, ...doc.data() });
        });
        //let friendsRequest = await getFriendRequest(user);
        setFriendsRequest(docs);
        setQtyFriendsRequest(String(docs.length));
        
        if(document.getElementById("updateSuggestionComponent") !== null){
            //console.log(document.getElementById("updateSuggestionComponent"));
            let componentRefresh = document.getElementById("updateSuggestionComponent");
            componentRefresh.click();
        }
        
    });
    return () => unsub;
};

export const friendRequestCheck = async function (uidUserRequest, uidUser, status) {
    //console.log(uidUserRequest);
    //Se obtiene el usuario que inicio sesion para traer el dato de foto de perfil.
    let dataUserCurrent = await getUserById(uidUser);
    //console.log(dataUserCurrent);
    //Se obtiene la solicitud de amistad del usuario que se acepto o rechazo
    let dataRequest = await getFriendRequestById(uidUser, uidUserRequest);
    //Se obtiene el status de la solicitud, si es rechazada o acetada
    dataRequest.status = status;
    //Se agrega un atributo con la fecha de aceptación o rechazo
    dataRequest.dateAccepted = new Date().toUTCString();
    //Si se acepta la solicitud
    if (status === "accepted") {
        // Se inserta a la coleccion users y en el documento del usuario que inicio sesion, en la subcolección de friends el objeto representante a un amigo con los datos del usuario que envío la solicitud de amistad.
        let insertFriend = await setDoc(doc(db, collectionName, uidUser, "friends", uidUserRequest), { friendRequest: dataRequest, friendData: { idUser: uidUserRequest, friendName: dataRequest.usernameRequester, profilePicture: dataRequest.profilePicture } });
        //console.log(insertFriend);
        // Se inserta a la coleccion users y en el documento del usuario que envío la solicitud de amistad, en la subcolección de friends el objeto representante a un amigo con los datos del usuario que inicio sesion.
        await setDoc(doc(db, collectionName, uidUserRequest, "friends", uidUser), { friendRequest: dataRequest, friendData: { idUser: uidUser, friendName: dataRequest.username, profilePicture: dataUserCurrent.profilePicture } });
        //console.log(insertFriend2);
        //alert("Amigo insertado");
        //Se elimina la solicitud de amistad. los datos se guardan ahora al nivel del amigo.
        await deleteDoc(doc(db, collectionName, uidUser, "friendRequest", uidUserRequest));
        //console.log(responseDelete);
        return insertFriend;
    }
    else {
        return null;
    }
}

export const cancelFriendRequest = async function (uidUserRequest, uidUser) {
    //Se elimina la solicitud de amistad. los datos se guardan ahora al nivel del amigo.
    let responseDelete = await deleteDoc(doc(db, collectionName, uidUser, "friendRequest", uidUserRequest));
    return responseDelete;
}

export const isUserFriend = async (currentUid, toUser) => {
    let exists = false;
    try {
        const docRef = doc(db, collectionName, currentUid, "friends", toUser);
        const docSnap = await getDoc(docRef);
        exists  = docSnap.exists()
    } catch (error) {
        console.log("error getLessonsById", error);
    }
    return exists;
}

export const isFriendRequestSend = async(currentUid, toUser, reverse = false) => {
    let exists = false;
    try {
        const uid = reverse ? currentUid : toUser;
        const requestDoc = reverse ? toUser : currentUid;
        const docRef = doc(db, collectionName, uid, "friendRequest", requestDoc);
        const docSnap = await getDoc(docRef);
        exists  = docSnap.exists()
    } catch (error) {
        console.log("error isFriendRequestSend", error);
    }
    return exists;
}

export const isFriendRequestPending = async(currentUid, toUser) => {
    const isRequested = await isFriendRequestSend(currentUid, toUser, true);
} 