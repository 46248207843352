import { useEffect, useState } from "react";
import { getTotalProfesors, getTotalStudents, getTotalUsers } from "../services/userService";
import Layout from "../layout/Layout";
import { Link } from "react-router-dom";

const AdminHome = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalProfesors, setTotalProfesors] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);

  useEffect(() => {
    const getCount = async () => {
      const total = await getTotalUsers() + 40;
      const tProfesors = await getTotalProfesors();
      const tStudents = await getTotalStudents();
      setTotalUsers(total);
      setTotalProfesors(tProfesors);
      setTotalStudents(tStudents);
    }

    getCount();
    //format
    //https://s-yadav.github.io/react-number-format/docs/intro

  }, []);

  return (
    <Layout>
      <div className="header-for-bg">
        <div className="background-header position-relative">
          <img
            src="https://ibloom.com.mx/pangu/admin.jpg"
            className="img-fluid w-100"
            alt="header-bg"
          />
          <div className="title-on-header">
            <div className="data-block">
              <h2>Administrador</h2>
            </div>
          </div>
        </div>
      </div>
      <div id="content-page" className="content-page">
        <div className="container ">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-body">
                  <div className="container padding90">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="counter-container">
                          <img src="../images/admin/todos.png" alt="Todos" />
                          <span>{totalUsers}</span>
                          <span>Usuarios</span>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="counter-container">
                          <img src="../images/admin/profesores.png" alt="Todos" />
                          <span>{totalProfesors}</span>
                          <Link to={"/admin/profesors"}>Profesores</Link>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="counter-container">
                          <img src="../images/admin/alumnos.png" alt="Todos" />
                          <span>{totalStudents}</span>
                          <Link to={"/admin/students"}>Alumnos</Link>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="counter-container">
                          <img src="../images/admin/profesores.png" alt="Todos" />
                          <span>20</span>
                          <span>Directores</span>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="counter-container">
                          <img src="../images/admin/alumnos.png" alt="Todos" />
                          <span>20</span>
                          <span>Padres o Tutores</span>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="counter-container">
                          <img src="https://ibloom.com.mx/pangu/geo.png" alt="Todos" />
                          <span>90</span>
                          <span>Georreferenciación</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AdminHome;