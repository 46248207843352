import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { AdminRoute, AuthProvider, ProtectedRoute } from './authContext';
import Amigos from './pages/Amigos';
import Alumnos from './pages/Alumnos';
import Perfil from './pages/Perfil';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Chat from './pages/chat/Chat';
import SignUp from './pages/SignUp';
import Calendario from './pages/Calendario';
import Lessons from './pages/Lessons';
import AddLesson from './components/lessons/AddLesson';
import MyLesson from './components/lessons/MyLesson';
import EditLesson from './components/lessons/EditLesson';
import LessonByIdWrapper from './components/lessons/LessonByIdWrapper';
import StudentsByLesson from './components/lessons/StudentsByLesson';
import LessonListMyTeachers from './components/lessons/LessonListMyTeachers';
import VideoLesson from './components/lessons/VideoLesson';
import LessonListOthers from './components/lessons/LessonListOthers';
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import Videocall from './pages/Videocall';
import Viewuser from './pages/Viewuser';
import Exercises from './pages/Exercises';
import ExerciseList from './components/Exercises/ExerciseList';
import ExerciseById from './components/Exercises/ExerciseById';
import DiagnosticTests from './pages/DiagnosticTests';
import TestList from './components/diagnosticTest/TestList';
import Grammar from './components/diagnosticTest/Grammar';
import Detail from './components/diagnosticTest/Detail';
import AListening from './components/diagnosticTest/AListening';
import AReading from './components/diagnosticTest/AReading';
import BCReading from './components/diagnosticTest/BCReading';
import BListening from './components/diagnosticTest/BListening';
import CListening from './components/diagnosticTest/CListening';
import FriendChat from './pages/chat/FriendChat';
import Courses from './pages/Courses';
import AdminLogin from './admin/pages/AdminLogin';
import AdminHome from './admin/pages/AdminHome';
import Profesors from './admin/pages/Profesors';
import Students from './admin/pages/Students';
import MonthlySubscription from './components/subscriptions/MonthlySubscription';
import BiyearlySubscription from './components/subscriptions/BiyearlySubscription';
import YearlySubscription from './components/subscriptions/YearlySubscription';
import Libros from './pages/Libros';
import Ranking from './pages/Ranking';
import Tareas from './pages/Tareas';
import Calificaciones from './pages/Calificaciones';
import Materias from './components/Materias';
import Nop from './admin/pages/Nop';
import TareasDocente from './pages/TareasDocente';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path='/' element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path='/amigos' element={<ProtectedRoute><Amigos /></ProtectedRoute>} />
        <Route path='/alumnos' element={<ProtectedRoute><Alumnos /></ProtectedRoute>} />        
        <Route path='/chat' element={<ProtectedRoute><Chat /></ProtectedRoute>} />
        <Route path='/chat/:friendId' element={<ProtectedRoute><FriendChat /></ProtectedRoute>} />
        <Route path='/profile' element={<ProtectedRoute><Perfil /></ProtectedRoute>} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<ProtectedRoute><SignUp /></ProtectedRoute>} />
        <Route path='/register' element={<Register />} />
        <Route path='/calendario' element={<ProtectedRoute><Calendario /></ProtectedRoute>} />
        <Route path='/libros' element={<ProtectedRoute><Libros /></ProtectedRoute>} />
        <Route path='/ranking' element={<ProtectedRoute><Ranking /></ProtectedRoute>} />
        <Route path='/tareas' element={<ProtectedRoute><Tareas /></ProtectedRoute>} />
        <Route path='/tareasdocente' element={<ProtectedRoute><TareasDocente /></ProtectedRoute>} />
        <Route path='/calificaciones' element={<ProtectedRoute><Calificaciones /></ProtectedRoute>} />
        <Route path='/courses' element={<ProtectedRoute><Courses /></ProtectedRoute>} />
        <Route path='/lessons' element={<ProtectedRoute><Lessons /></ProtectedRoute>}>
          <Route index element={<Materias target="/lessons/my" />} />
          <Route path='my' element={<MyLesson />} />
          <Route path='all' element={<LessonListMyTeachers />} />
          <Route path='others' element={<LessonListOthers />} />
          <Route path='new' element={<AddLesson />} />
          <Route path=':lessonId' element={<LessonByIdWrapper />} >
            <Route path='edit' element={<EditLesson />} />
            <Route path='students' element={<StudentsByLesson />} />         
          </Route>
        </Route>
        <Route path='/exercises' element={<ProtectedRoute><Exercises /></ProtectedRoute>}>
          <Route index element={<Materias target="/exercises/all" />} />
          <Route path='all' element={<ExerciseList />} />
          <Route path=':exerciseId' element={<ExerciseById />} />
        </Route>
        <Route path='/tests' element={<ProtectedRoute><DiagnosticTests /></ProtectedRoute>}>
          <Route index element={<Materias target="/tests/all" />} />
          <Route path='all' element={<TestList />} />
          <Route path=':diagnosticId' element={<LessonByIdWrapper />} >
            <Route path='detail' element={<Detail />} />
            <Route path='grammar' element={<Grammar />} />
            <Route path='a-listening' element={<AListening />} />         
            <Route path='b-listening' element={<BListening />} />         
            <Route path='c-listening' element={<CListening />} />         
            <Route path='a-reading' element={<AReading />} />         
            <Route path='bc-reading' element={<BCReading />} />         
          </Route>
        </Route>
        <Route path='/lessons/:lessonId/class' element={<ProtectedRoute><VideoLesson /></ProtectedRoute>} />
        <Route path='/videocall/:id' element={<ProtectedRoute><Videocall /></ProtectedRoute>} />
        <Route path='/viewuser/:iduser' element={<ProtectedRoute><Viewuser /></ProtectedRoute>} />
        <Route path='/admin/login' element={<AdminLogin />} />
        <Route path='/admin/home' element={<AdminRoute> <AdminHome /> </AdminRoute>} />
        <Route path='/admin/profesors' element={<AdminRoute> <Profesors /> </AdminRoute>} />
        <Route path='/admin/students' element={<AdminRoute> <Students /> </AdminRoute>} />
        <Route path='/admin/directores' element={<AdminRoute> <Nop titulo="Directores" /> </AdminRoute>} />
        <Route path='/admin/padres' element={<AdminRoute> <Nop titulo="Padres"/> </AdminRoute>} />
        <Route path='/admin/escuelas' element={<AdminRoute> <Nop titulo="Escuelas"/> </AdminRoute>} />
        <Route path='/admin/materias' element={<AdminRoute> <Nop titulo="Materias"/> </AdminRoute>} />
        <Route path='/admin/calificaciones' element={<AdminRoute> <Nop titulo="Calificaciones"/> </AdminRoute>} />
        <Route path='/admin/rendimiento' element={<AdminRoute> <Nop titulo="Graficas de rendimiento"/> </AdminRoute>} />
        <Route path='/admin/ranking' element={<AdminRoute> <Nop titulo="Ranking"/> </AdminRoute>} />
        <Route path='/admin/calendario' element={<AdminRoute> <Nop titulo="Calendario"/> </AdminRoute>} />
        <Route path='/admin/notificaciones' element={<AdminRoute> <Nop titulo="Notificaciones"/> </AdminRoute>} />

        

        <Route path='/mensual/:iduser' element={<MonthlySubscription />} />
        <Route path='/semestral/:iduser' element={<BiyearlySubscription />} />
        <Route path='/anual/:iduser' element={<YearlySubscription />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
