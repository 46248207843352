import { updateProfile } from "firebase/auth";
import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../authContext";
import { updateUser } from "../../services/authHelper";
import { COUNTRIES, GENDERS, LANGUAGES_ES, LANGUAGES_EN} from "../../utils/data";
import { ErrorMessage } from "../ErrorMessage";

const BasicInfo = ({ userData, setUserData }) => {
    const { t , i18n } = useTranslation();
    const { user } = useAuth();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const LANGUAGES = i18n.language === "es"  ? LANGUAGES_ES : LANGUAGES_EN;
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        try {
            await updateProfile(user, {
                displayName: `${userData.firstName} ${userData.lastName}`,
                photoURL: userData.profilePicture
            });
            await updateUser(userData);
        } catch (error) {

        } finally {
            setIsSubmitting(false);
        }
    };

    const handleOnchange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const rawValue = value.trim();
        if (rawValue === "") {
            setErrors({ ...errors, [name]: { message: { key: "validations_required" } } })
        }
        else {
            setErrors({ ...errors, [name]: undefined })
            setUserData({ ...userData, [name]: value });
        }
    };

    const handleLanguageChange = (evt) => {
        evt.preventDefault();
        const { name, value } = evt.target;
        const rawValue = value.trim();
        if (rawValue === "") {
            setErrors({ ...errors, [name]: { message: { key: "validations_required" } } })
        }
        else {
            const copy = { ...userData["language"] }
            copy[name] = value;
            if(copy.native === "spanish"){
                copy.nativo = "español";
            }else if(copy.native === "english"){
                copy.nativo = "inglés";
            }else if(copy.native === "french"){
                copy.nativo = "francés"
            }else if(copy.native === "german"){
                copy.nativo = "alemán"
            }
            setErrors({ ...errors, [name]: undefined })
            setUserData({ ...userData, language: copy });
        }
    }

    return (
        <>
            <h4>{t("profile_basic_information")}</h4>
            <hr />
            <div className="row">
                <div className="iq-card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row align-items-center">
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="firstName">{t("register_firstName")}</Form.Label>
                                <Form.Control type="text" id="firstName" name="firstName" isInvalid={!!errors?.firstName}
                                    onChange={handleOnchange} onBlur={handleOnchange} defaultValue={userData?.firstName} />
                                <ErrorMessage error={errors.firstName?.message} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="lastName">{t("register_lastName")}</Form.Label>
                                <Form.Control type="text" id="lastName" name="lastName" isInvalid={!!errors?.lastName}
                                    onChange={handleOnchange} onBlur={handleOnchange} defaultValue={userData?.lastName} />
                                <ErrorMessage error={errors.lastName?.message} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="email">{t("register_email")}</Form.Label>
                                <Form.Control type="text" id="email" name="email" isInvalid={!!errors?.email} defaultValue={userData?.email} readOnly />
                                <ErrorMessage error={errors.email?.message} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="telephoneNumber">{t("profile_telephone")}</Form.Label>
                                <Form.Control type="text" id="telephoneNumber" name="telephoneNumber" isInvalid={!!errors?.telephoneNumber}
                                    onChange={handleOnchange} onBlur={handleOnchange} defaultValue={userData?.telephoneNumber} />
                                <ErrorMessage error={errors.telephoneNumber?.message} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="countryOfOrigin">{t("profile_country_of_origin")}</Form.Label>
                                <select id="countryOfOrigin" name="countryOfOrigin" value={userData?.countryOfOrigin} onChange={handleOnchange} className="form-control">
                                    {COUNTRIES.map((c) => <option value={c.country}>{c.country}</option>)}
                                </select>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="countryResidence">{t("profile_country_residence")}</Form.Label>
                                <select id="countryResidence" name="countryResidence" value={userData?.countryResidence} onChange={handleOnchange} className="form-control">
                                    {COUNTRIES.map((c) => <option value={c.country}>{c.country}</option>)}
                                </select>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="gender">{t("profile_gender")}</Form.Label>
                                <select id="gender" name="gender" value={userData?.gender} onChange={handleOnchange} className="form-control">
                                    {GENDERS.map((gender) => <option value={gender.value}>{gender.label}</option>)}
                                </select>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="dateOfBirth">{t("register_date_dob")}</Form.Label>
                                <Form.Control type="date" id="dateOfBirth" name="dateOfBirth" isInvalid={!!errors?.dateOfBirth}
                                    onChange={handleOnchange} onBlur={handleOnchange} defaultValue={userData?.dateOfBirth} />
                                <ErrorMessage error={errors.telephoneNumber?.message} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="usertype">{t("profile_user_type")}</Form.Label>
                                <Form.Control type="text" id="userType" name="userType" isInvalid={!!errors?.userType} value={t(userData?.userType)} readOnly/> 
                                <ErrorMessage error={errors.userType?.message} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="profession">{t("profile_profession")}</Form.Label>
                                <Form.Control type="text" id="profession" name="profession" isInvalid={!!errors?.profession}
                                    onChange={handleOnchange} onBlur={handleOnchange} defaultValue={userData?.profession} />
                                <ErrorMessage error={errors.profession?.message} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="favoriteHobbie">{t("profile_hobby")}</Form.Label>
                                <Form.Control type="text" id="favoriteHobbie" name="favoriteHobbie" isInvalid={!!errors?.favoriteHobbie}
                                    onChange={handleOnchange} onBlur={handleOnchange} defaultValue={userData?.favoriteHobbie} />
                                <ErrorMessage error={errors.favoriteHobbie?.message} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="native">{t("profile_native_language")}</Form.Label>                                
                                <select id="native" name="native" value={userData?.language?.native} onChange={handleLanguageChange} className="form-control">
                                    {LANGUAGES.map((lang) => <option value={lang.value}>{lang.label}</option>)}
                                </select>
                            </Form.Group>
                            {userData?.userType && (userData?.userType === "student") && 
                            <>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="goalLearning">{t("register_target_lang_s")}</Form.Label>
                                <select id="goalLearning" name="goalLearning" value={userData?.language?.goalLearning} onChange={handleLanguageChange} className="form-control">
                                    {LANGUAGES.map((lang) => <option value={lang.value}>{lang.label}</option>)}
                                </select>
                            </Form.Group>
                            
                            <div className="qr form-group col-sm-12">
                                <label>Tu certificado de estudio validado por la SEP</label>
                                <a className="qralumno" href="https://ibloom.com.mx/pangu/qr.png" target="_blank"><img width="img-fluid" src="https://ibloom.com.mx/pangu/qr.png" /></a>
                                <p className="font-weight-bold">Encriptado infalsificable con tecnología Blockchain</p>
                            </div>
                            </>
                            
                            }
                            
                        </div>
                        <button type="submit" className="btn btn-primary mr-2" disabled={isSubmitting}>
                            {t("profile_update")} {isSubmitting && <Spinner animation="border" variant="light" size="sm" />}
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default BasicInfo;