import React, { useEffect, useState } from "react";
import { useAuth } from "../../authContext";
import { getFriends } from "../../services/friendHelper";
import { createImageFromInitials } from "../Utils";

function FriendList({ friendData, setFriendData }) {
  const { user } = useAuth();
  const [friendList, setFriendList] = useState([]);

  useEffect(() => {
    getFriends(user.uid, setFriendList);
  }, []);
  
  useEffect(() => {
    if(friendData){
      const currentFriend = friendList.find(f => f.uid === friendData.uid);
      if(currentFriend){
        setFriendData(currentFriend);
      }
    }
  }, [friendList]);

  return (
    <>
      <div className="chat-sidebar-channel scroller mt-5 pl-3">
        <ul className="iq-chat-ui nav flex-column  justify-content-between nav-pills">
          <div className="chat-sidebar-name justify-content-between">
            {friendList.map((friend, index) => {
              return (
                <div key={index} onClick={() => { setFriendData(friend); }}>
                  <a data-toggle="pill" href="#chatbox1"  >
                    <div className="d-flex align-items-center">
                      <div className="avatar mr-2">
                        <img src={friend.profilePicture ? friend.profilePicture : createImageFromInitials(50, `${friend.firstName} ${friend.lastName}`, '#50b5ff')} alt="chatuserimage" className="avatar-50 mt-1" />
                        <span className="avatar-status">
                          {friend?.online ? <i className="ri-checkbox-blank-circle-fill text-success" /> : <i className="ri-checkbox-blank-circle-fill text-danger" />}
                          </span>
                      </div>
                      <div className="chat-sidebar-name">
                        <h6 className="mb-0">{`${friend.firstName} ${friend.lastName}`}</h6>
                      </div>
                    </div>
                  </a>
                  <hr />
                </div>
              )
            })}
          </div>
        </ul>
      </div>


    </>
  )
};

export default React.memo(FriendList)