import { useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FALLBACK_PROFILE_PICTURE } from "../services/userHelper";

const SearchBar = () => {
  const { t } = useTranslation();
  const APPLICATION_ID = "S2TU4B1XGL";
  const SEARCH_API_KEY = "555b8fad158278990669c8011c2dd039";
  const ALGOLIA_INDEX = "prod_pangu_users";
  
  const client = algoliasearch(APPLICATION_ID, SEARCH_API_KEY);
  const index = client.initIndex(ALGOLIA_INDEX);

  const [results, setResults] = useState(null);

  const performSearch = async (value) => {
    const { hits } = await index.search(value, {
      hitsPerPage: 15,
    });

    const results = hits.map((hit) => {
      const {
        objectID: key,
        profilePicture,
        firstName,
        userTipo,
        lastName,
        countryResidence,
      } = hit;
      return {
        key,
        firstName,
        lastName,
        userTipo,
        profilePicture,
        countryResidence,
      };
    });
    setResults(results);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    value === "" ? setResults(null) : performSearch(value);
  };

  return (
    <div className="iq-search-bar">
      <form>
        <input
          className="text search-input"
          placeholder={t("chat_search")}
          onChange={handleChange}
          type="search"
        />
      </form>

      {results === null ? null : (
        <div
          className="ais-Hits"
          style={{ position: "absolute", background: "white" }}
        >
          <ul>
            {results.map((result) => {
              const {
                key,
                firstName,
                lastName,
                profilePicture,
                userTipo,
              } = result;
              return (
                <li key={key} className="d-flex align-items-center">
                  <div className="user-img img-fluid">
                    <img
                      onError={({currentTarget}) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = FALLBACK_PROFILE_PICTURE;
                      }}
                      src={profilePicture && profilePicture.length > 0 ? profilePicture: FALLBACK_PROFILE_PICTURE}
                      className="rounded-circle avatar-40"
                      alt="profile"
                    ></img>
                  </div>
                  <div className="media-support-info ml-4">
                    <div className="d-flex align-items-center">
                      <h6
                        dangerouslySetInnerHTML={{
                          __html: firstName + " " + lastName,
                        }}
                      />
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: userTipo,
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center  ml-3">
                   <Link  to={"/viewuser/"+key} className="mr-3 btn btn-secondary btn-sm rounded">
                      {t("searchbar_view_profile")}
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
