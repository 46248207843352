import Layout from "../components/Layout";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../authContext";
import {
  getUserByUid,
  updateUser,
  uploadProfilePic,
  uploadPicture,
} from "../services/authHelper";
import { useTranslation } from "react-i18next";
import SocialNetwork from "../components/profile/SocialNetwork";
import BasicInfo from "../components/profile/BasicInfo";
import AboutMe from "../components/profile/AboutMe";
import { updateProfile } from "firebase/auth";
import PostListUser from "../components/posts/PostListUser";
import Payment from "../components/profile/Payment";
import Subscription from "../components/profile/Subscription";

const Perfil = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [userData, setUserData] = useState(null);
  const refFileInput = useRef(null);
  const refPFileInput = useRef(null);
  const pType = "teacher";

  const handleOnClickProfilePic = () => {
    refFileInput.current.click();
  };

  const handleOnClickPortadaPic = () => {
    refPFileInput.current.click();
  };

  const handleFileChange = async (event) => {
    event.preventDefault();
    const fileList = event.target.files;
    if (fileList && fileList.length) {
      const result = await uploadProfilePic(user.uid, fileList[0]);
      if (result) {
        const updateUserData = { ...userData, profilePicture: result }
        setUserData(updateUserData);
        await updateProfile(user, { photoURL: result });
        await updateUser(updateUserData);
      }
    }
  };

  const handleFileChange2 = async (event) => {
    event.preventDefault();
    const filePList = event.target.files;
    if (filePList && filePList.length) {
      const result = await uploadPicture(`cover/${user.uid}`, filePList[0]);
      if (result) {
        const updateUserData = { ...userData, cover: result }
        setUserData(updateUserData);
        await updateUser(updateUserData);
      }
    }
  };

  const loadUserData = async (uid) => {
    const data = await getUserByUid(uid);
    setUserData(data);
  };



  useEffect(() => {
    loadUserData(user.uid);
  }, [user.uid]);

  return (
    <Layout>
      <div className="container padding90">
        <div className="row">
          <div className="col-sm-12">
            <div className="iq-card">
              <div className="iq-card-body profile-page p-0">
                <div className="profile-header">
                  <div className="cover-container">
                    <img className="rounded" height={325} width={970} style={{ maxWidth: "100%" }}
                      src={userData?.cover && userData?.cover?.length >= 0 ? userData?.cover : "https://firebasestorage.googleapis.com/v0/b/pangea-daee0.appspot.com/o/default%2Fcover?alt=media&token=6d96fe47-d66e-49ee-b0d0-1667b640c8ed"} alt="cover" />
                    <div className="p-image">
                      <i className="ri-pencil-line upload-button" onClick={handleOnClickPortadaPic} />
                      <input ref={refPFileInput} className="file-upload" type="file"
                        onChange={handleFileChange2} accept="image/*" />
                    </div>
                  </div>
                  <div className="user-detail text-center mb-3">
                    <div className="profile-img-edit">
                      <img alt="profile" className="avatar-130 img-fluid"
                        height={150} width={200} style={{ borderRadius: 80 }}
                        src={userData?.profilePicture && userData?.profilePicture?.length >= 0 ? userData?.profilePicture : "https://firebasestorage.googleapis.com/v0/b/pangea-daee0.appspot.com/o/perfil%2FprofilePicture?alt=media&token=f4bd9726-0b2e-4640-b7ea-3b5bc6ef07a6"} />
                      <div className="p-image">
                        <i className="ri-pencil-line upload-button" onClick={handleOnClickProfilePic} />
                        <input ref={refFileInput} className="file-upload" type="file"
                          onChange={handleFileChange} accept="image/*" />
                      </div>
                    </div>
                    <div className="profile-detail">
                      <h4>{userData?.firstName} {userData?.lastName}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="iq-card">
              <div className="iq-card-body p-0">
                <div className="user-tabing">
                  <ul className="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                    <li className="col-sm-6 p-0">
                      <a className="nav-link active" data-toggle="pill" href="#timeline">
                        {t("perfil_tab1")}
                      </a>
                    </li>
                    <li className="col-sm-6 p-0">
                      <a className="nav-link" data-toggle="pill" href="#about">
                        {t("perfil_tab2")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="tab-content">
              <div className="tab-pane fade active show" id="timeline" role="tabpanel">
                <div className="iq-card-body p-0">
                  <div className="row">
                    <PostListUser idUser={user.uid} />
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="about" role="tabpanel">
                <div className="iq-card">
                  <div className="iq-card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <ul className="nav nav-pills basic-info-items list-inline d-block p-0 m-0">
                          <li>
                            <a className="nav-link active" data-toggle="pill" href="#basicinfo">
                              {t("profile_basic_information")}
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" data-toggle="pill" href="#aboutme">
                              {t("profile_aboutme_tab")}
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" data-toggle="pill" href="#socialnetwork">
                              {t("profile_social_tab")}
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" data-toggle="pill" href="#subscriptions">
                              {t("profile_subscriptions_tab")}
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" data-toggle="pill" href="#Certificados">
                              Certificados
                            </a>
                          </li>

                          {
                            userData && userData.userType === pType && <>
                              <li>
                                <a className="nav-link" data-toggle="pill" href="#payment">
                                  {t("profile_payment_tab")}
                                </a>
                              </li>
                            </>
                          }
                        </ul>
                      </div>
                      <div className="col-md-9 pl-4">
                        <div className="tab-content">
                          <div className="tab-pane fade active show" id="basicinfo" role="tabpanel">
                            {userData && <BasicInfo userData={userData} setUserData={setUserData} />}
                          </div>
                          <div className="tab-pane fade" id="aboutme" role="tabpanel">
                            <AboutMe userData={userData} setUserData={setUserData} />
                          </div>
                          <div className="tab-pane fade" id="socialnetwork" role="tabpanel">
                            <SocialNetwork userData={userData} setUserData={setUserData} />
                          </div>
                          <div className="tab-pane fade" id="subscriptions" role="tabpanel">
                            <Subscription userData={userData} setUserData={setUserData} />
                          </div>
                          {
                            userData && userData.userType === pType && <>
                              <div className="tab-pane fade" id="payment" role="tabpanel">
                                <Payment userData={userData} setUserData={setUserData} />
                              </div>
                            </>
                          }
                          <div className="tab-pane fade" id="Certificados" role="tabpanel">
                            <div className="ccertificado">
                              <h4>Certificados</h4>
                              <p>Certificado de primaria</p>
                              <img className="img-fluid" src="https://ibloom.com.mx/pangu/primaria.jpg" />
                              <p>Certificado de secundaria</p>
                              <img className="img-fluid" src="https://ibloom.com.mx/pangu/secundaria.jpg" />
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Perfil;
