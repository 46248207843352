import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAuth } from "../authContext";

const sidebarMenuStudent = [
  { text: "sidebar_home", route: "/", icon: "las la-newspaper" },
  { text: "sidebar_lessons", route: "/lessons", icon: "ri-book-read-line" },
  { text: "Tareas", route: "/tareas", icon: "fa fa-calendar-check-o" },
  { text: "sidebar_calendar", route: "/calendario", icon: "las la-calendar" },
  { text: "sidebar_friends", route: "/amigos", icon: "las la-user-friends" },
  { text: "sidebar_chat", route: "/chat", icon: "ri-message-2-line" },
  { text: "Libros", route: "/libros", icon: "fa fa-book" },
  { text: "Contenido extra", route: "/courses", icon: "fa fa-play" },
  { text: "Ejercicios de práctica", route: "/exercises", icon: "fa fa-list-alt" },
  { text: "sidebar_diagnostic", route: "/tests", icon: "ri-numbers-line" },
  { text: "Calificaciones", route: "/calificaciones", icon: "fa fa-file-text-o" },
  { text: "Ranking", route: "/ranking", icon: "fa fa-bar-chart" },
  { text: "sidebar_profile", route: "/profile", icon: "las la-user" },
];

const sidebarMenuTeacher = [
  { text: "sidebar_home", route: "/", icon: "las la-newspaper" },
  { text: "sidebar_lessons", route: "/lessons", icon: "ri-book-read-line" },
  { text: "Tareas", route: "/tareasdocente", icon: "fa fa-calendar-check-o" },
  { text: "sidebar_calendar", route: "/calendario", icon: "las la-calendar" },
  { text: "sidebar_exercises", route: "/exercises", icon: "fa fa-list-alt" },
  { text: "sidebar_diagnostic", route: "/tests", icon: "ri-numbers-line" },
  { text: "Alumnos", route: "/alumnos", icon: "las la-user-friends" },
  { text: "sidebar_friends", route: "/amigos", icon: "las la-user-friends" },
  { text: "sidebar_chat", route: "/chat", icon: "ri-message-2-line" },
  { text: "Ranking", route: "/ranking", icon: "fa fa-bar-chart" },
  { text: "sidebar_profile", route: "/profile", icon: "las la-user" },
];

const Sidebar = () => {
  const { userData } = useAuth();
  const [activeLink, setActiveLink] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();
  //const filteredMenuStudent = !userData?.subscription?.isPremium ? sidebarMenuStudent.filter(m => m.text !== 'sidebar_diagnostic') : sidebarMenuStudent;
  const listOptions = userData?.userType === "student" ? sidebarMenuStudent : sidebarMenuTeacher;

  const renderList = () => {
    return listOptions.map((option, index) => {
      return (
        <li
          key={index}
          onClick={() => setActiveLink(index)}
          className={`${activeLink === index ? "active" : ""}`}
        >
          <NavLink
            to={option.route}
            exact
          >
            <div className="iq-waves-effect">
              <i className={option.icon} />
              {t(option.text)}
            </div>
          </NavLink>
        </li>
      );
    });
  };

  useEffect(() => {
    const currentPath = window.location.pathname.split('/')[1];
    const activeItem = listOptions.findIndex(item => item.route === `/${currentPath}`);
    setActiveLink(currentPath.length === 0 ? 0 : activeItem);
  }, [location]);

  return (
    <div className="iq-sidebar">
      <div id="sidebar-scrollbar">
        <nav className="iq-sidebar-menu">
          <ul id="iq-sidebar-toggle" className="iq-menu">
            {renderList()}
          </ul>
        </nav>
        <div className="p-3" />
      </div>
    </div>
  );
};

export default Sidebar;
