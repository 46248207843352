import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../authContext";
import { createInitialUserTest, getReadingDiagnostic, getUserTestInfo, isTopicEvaluated, saveScore } from "../../services/DiagnosticHelper";
import { ShowSwalAlert } from "../Utils";

const BCReading = () => {
    const { diagnosticId } = useParams();
    const [isEvaluate, setIsEvaluate] = useState(false);
    const [readingData, setReadingData] = useState(null);
    const [userTestInfo, setUserTestInfo] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user, userData } = useAuth();

    const loadReadingData = async () => {
        await getReadingDiagnostic(diagnosticId).then((data) => {
            setReadingData(data);
            document.getElementById("reading-content").innerHTML = data.html;
        });
        await isTopicEvaluated(user.uid, diagnosticId, "reading").then((result) => {
            setIsEvaluate(result);
            if(result === true){
                document.getElementById("listening-content").style.pointerEvents = "none";
            }
        });
        await createInitialUserTest(user.uid, diagnosticId).then(() => {
            getUserTestInfo(user.uid, diagnosticId).then((testInfo) => setUserTestInfo(testInfo))
        });
    }

    useEffect(() => {
        loadReadingData();
    }, [diagnosticId]);
    
    const checkandSaveScore = async () => {
        let answers = [];
        let score = 0;
        for (const item of Object.entries(readingData.respuestas)) {
            const key = item[0];
            const value = item[1];
            const el = document.getElementById(key);
            if(el){
                if(el.value.toUpperCase() === value.toUpperCase()){
                    score++;
                    answers.push({id: key, value: el.value});
                    el.style.border = "1px solid green";
                }else{
                    answers.push({id: key, value: el.value});
                    el.style.border = "1px solid red";
                }
            }
        }

        if (answers.length === 0 || answers.length < readingData.respuestas.length) {
            ShowSwalAlert({ icon: "info", title: t('exercises_all_answers_required') });
            return;
        }

        try {
            const data = { reading: score, status: userTestInfo.status + 1}
            await saveScore(user.uid, diagnosticId, data);
        } finally {
            setIsEvaluate(!isEvaluate);
        }
    }

    return (
        <div className="iq-card px-2">
            <div className="iq-card-header">
                <div className="row justify-content-between">
                    <div class="col-sm-12 col-md-6">
                        <h2 className="pt-2">{readingData?.titulo}</h2>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div className="d-flex float-right">
                            <button onClick={() => navigate(`/tests/${diagnosticId}/detail`)} className="btn btn-ligth mt-2"><i class="ri-arrow-go-back-fill"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iq-card-body">
                <div className="containder">
                    <h6>Comentario de profesor</h6>
                    <div className="row">
                        <div id="reading-content"></div>
                        {!isEvaluate ? userData?.subscription?.isPremium && <button onClick={() => checkandSaveScore()} className="btn btn-primary">{t("diagnostictests_verify")}</button> : <button onClick={() => navigate(`/tests/${diagnosticId}/detail`)} className="btn btn-primary mt-2"><i class="ri-arrow-go-back-fill"></i></button>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BCReading;