import { Modal } from "react-bootstrap";

export const MTareasProfesor = (props) => {

    return (
        <>
            <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Tareas revisadas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tareas tareas2">
                        {/*Card*/}
                        <div className="card">
                            {/*Card Header*/}
                            <div className="card-header">
                                <p>Tareas revisadas</p>
                            </div>
                            {/*Card Body*/}
                            <div className="card-body">
                                <div className="container">
                                    <div className="tareas">
                                        {/*Card*/}
                                        <div className="card">
                                            {/*Card Header*/}
                                            {/*Card Body*/}
                                            <div className="card-body">
                                                <p className="heading1"><span className="tomorrow">Ayer</span> <span className="float-right headingright">2
                                                    tareas</span> </p>
                                                <p> <i className="fa fa-calculator ml-2" aria-hidden="true" /> <span className="task mt-4">Matemáticas</span>
                                                    <span className="time ml-2">Alumnos</span> <span className="float-right">Revisada</span> </p>
                                                <p><i className="fa fa-file-text-o ml-2" aria-hidden="true" />
                                                    <span className="task">Español</span> <span className="time ml-2">Alumnos</span> <span className="float-right">Revisada</span>
                                                </p>
                                                <p className="heading2"><span className="tomorrow">Hace 2 días</span> <span className="float-right headingright">3
                                                    tareas</span> </p>
                                                <p> <i className="fa fa-calculator ml-2" aria-hidden="true" /> <span className="task mt-4">Matemáticas</span>
                                                    <span className="time ml-2">Alumnos</span> <span className="float-right">Revisada</span> </p>
                                                <p><i className="fa fa-file-text-o ml-2" aria-hidden="true" />
                                                    <span className="task">Español</span> <span className="time ml-2">Alumnos</span> <span className="float-right">Revisada</span>
                                                </p>
                                                <p><i className="fa fa-newspaper-o ml-2" aria-hidden="true" />
                                                    <span className="task">Historia</span> <span className="time ml-2">Alumnos</span> <span className="float-right">Revisada</span>
                                                </p>
                                                <a className="vermastareas" href="#">Ver más tareas</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}