import { Modal } from "react-bootstrap";

export const MNuevoEjercicio = (props) => {
    return (
        <>
            <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Nuevo Ejercicio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <form className="fejercicios">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">Nombre:</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1">Tipo:</label>
                                <select className="form-control" id="exampleFormControlSelect1">
                                    <option>Verdadero/Falso</option>
                                    <option>Opcion Multiple</option>
                                    <option>Relacionar</option>
                                    <option>Completar Frase</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput11">Pregunta 1:</label>
                                <input type="text" className="form-control" id="exampleFormControlInput11" placeholder />
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="exampleRadios" id defaultValue="option1" defaultChecked />
                                <label className="form-check-label" htmlFor>
                                    Respuesta 1
                                    <input type="text" className="form-control" id="exampleFormControlInput11" placeholder />
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="exampleRadios" id defaultValue="option1" defaultChecked />
                                <label className="form-check-label" htmlFor>
                                    Respuesta 2
                                    <input type="text" className="form-control" id="exampleFormControlInput11" placeholder />
                                </label>
                            </div>
                            <button type className="btn btn-primary mt-2">Agregar respuesta</button><span>{" "}</span>
                            <button type className="btn btn-primary mt-2">Agregar otra pregunta</button>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Comentario para el alumno:</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows={1} defaultValue={""} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea11">Comentario para los padres o turores:</label>
                                <textarea className="form-control" id="exampleFormControlTextarea11" rows={1} defaultValue={""} />
                            </div>
                            <button type className="btn btn-primary mt-2">Guardar</button>
                        </form>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
}