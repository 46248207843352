import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { CreateEvents } from "../components/calendario/AddEvent";
import "../components/calendario/acc.css";
import { FullC } from "../components/calendario/FullC";
import { useAuth } from "../authContext";
import EventList from "../components/calendario/EventList";
import { loadCurrentEvents } from "../services/calendarHelper";

const Calendario = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [modalShow, setModalShow] = useState(false);
  var [currentEvents, setCurrentEvents] = useState([]);

  useEffect(() => {
    loadCurrentEvents(user.uid, setCurrentEvents);
  }, []);

  return (
    <>
      <Layout>
        <div className="header-for-bg">
          <div className="background-header position-relative">
            <img
              src="https://ibloom.com.mx/pangu/calendario.jpg"
              className="img-fluid w-100"
              alt="profile-bg"
            />
            <div className="title-on-header">
              <div className="data-block">
                <h2>{t("c_title")}</h2>
              </div>
            </div>
          </div>
        </div>
        <div id="content-page" className="content-page">
          <div className="container">
            <div className="row row-eq-height">
              <div className="col-md-3">
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title ">{t("c_classification")}</h4>
                    </div>
                  </div>
                  <div className="iq-card-body">
                    <ul className="m-0 p-0 job-classification">
                      <li>
                        <i className="ri-check-line" style={{ backgroundColor: "#f94176" }}/>
                        {t("c_prioridad1")}
                      </li>
                      <li>
                        <i className="ri-check-line" style={{ backgroundColor: "#50b5ff" }}/>
                        {t("c_prioridad2")}
                      </li>
                      <li>
                        <i className="ri-check-line" style={{ backgroundColor: "#663387" }}/>
                        {t("c_prioridad3")}
                      </li>
                      <li>
                        <i className="ri-check-line" style={{ backgroundColor: "#f2cc21" }}/>
                        {t("c_prioridad4")}
                      </li>
                    </ul>
                  </div>
                </div>
                <EventList currentEvents={currentEvents} />
              </div>
              <div className="col-md-9">
                <div className="iq-card">
                  <div className="iq-card-body">
                    <Button to={""} onClick={() => setModalShow(true)}>{t("calendario_add")}</Button>
                    <CreateEvents show={modalShow} onHide={() => setModalShow(false)}/>
                  </div>
                  <div className="iq-card-body">
                    <FullC />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Calendario;
