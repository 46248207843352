import { useEffect, useState } from "react";
import { useAuth } from "../../authContext";
import { getPostsByUserId } from "../../services/postHelper";
import { Posts } from "./Posts";
import { Facebook } from 'react-content-loader'

const PostListUser = ({idUser}) => {
    const [posts, setPosts] = useState([]);
    const [enableDelete, setEnableDelete] = useState(false);
    const [loading, setLoading] = useState(true);
    const { user } = useAuth();

    const loadPosts = async (uid) => {
        await getPostsByUserId(uid).then((data) => { 
            setPosts(data);
            if(user.uid === uid){
                setEnableDelete(true);
            }
            setLoading(!loading);
        });
        
    }

    useEffect(() => {
        loadPosts(idUser);
    }, [idUser]);

    const updatePost = (pId) => {
        const updatedPost = posts.filter(p => p.id !== pId);
        setPosts(updatedPost);
    }

    if(loading){
        return <Facebook />
    }

    return (
        <>
            {posts && posts.length > 0 && posts.map((post, index) => (
                <Posts key={post.id} user={ {...user, uid: idUser} } post={post} enableDelete={enableDelete} updatePost={updatePost} />
            ))}
        </>
    );
}

export default PostListUser;