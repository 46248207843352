import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuth } from "../authContext";
import { DropLanguajes } from "../i18n/dropLanguajes";
import { useEffect, useState } from "react";
import FriendRequestHeader from './friendRequest/FriendRequestHeader';
import Modalvideocall from './videocall/IncomingCall';
import { Notification } from "./Notification";
import { getNotificationBytId, listenerNotification } from "../services/notificationHelper";
import SearchBar from "./SearchBar";
import { listenVideoCall } from "../services/videocallHelper";

const Header = () => {
  const { t, i18n } = useTranslation();
  const { user, onSignOut } = useAuth();

  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotifications] = useState(false);
  const [showIncomingCall, setShowIncomingCall] = useState(false);
  const [incomingCall, setIncomingCall] = useState(null);

  const init = async () => {
    await getNotificationBytId(user.uid, setNewNotifications)
      .then((data) => {
        setNotifications(data);
      });
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    listenerNotification(user.uid, setNotifications, setNewNotifications);
  }, []);

  useEffect(() => {
    listenVideoCall(user.uid, setShowIncomingCall, setIncomingCall);
  }, []);

  return (
    <div className="iq-top-navbar">
      <div className="iq-navbar-custom">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <div className="iq-navbar-logo d-flex justify-content-between">
            <Link to="/">
            <img src="https://ibloom.com.mx/pangu/hame.svg" alt="Pángu" />
            </Link>
            <div className="iq-menu-bt align-self-center">
              <div className="wrapper-menu iq-waves-effect">
                <div className="main-circle">
                  <i className="ri-menu-line" />
                </div>
              </div>
            </div>
          </div>
          <SearchBar />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-label="Toggle navigation"
          >
            <i className="ri-menu-3-line" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto navbar-list">
              <FriendRequestHeader />
              <li className="nav-item">
                <a className="search-toggle iq-waves-effect">
                  <i className="ri-notification-2-line"></i>
                  {newNotification &&
                    <span className="bg-danger dots"></span>
                  }
                </a>
                <div className="iq-sub-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <Notification
                      notifications={notifications}
                      setNotifications={setNotifications}
                      user={user}
                    />
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown d-none">
                <a className="search-toggle iq-waves-effect">
                  <i className="ri-message-2-line"></i>
                </a>
                <div className="iq-sub-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0 ">
                      <div className="bg-primary p-3">
                        <h5 className="mb-0 text-white">
                          Todos los mensajes
                          <small className="badge  badge-light float-right pt-1">
                            5
                          </small>
                        </h5>
                      </div>
                      <a className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className>
                            <img
                              className="avatar-40 rounded"
                              src="images/user/01.jpg"
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Bni Emma Watson</h6>
                            <small className="float-left font-size-12">
                              13 Jun
                            </small>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <a className="iq-waves-effect d-flex align-items-center">
                  <div className="caption">
                    <h6 className="mb-0 line-height">{user?.displayName}</h6>
                  </div>
                </a>
              </li>
              <li>
                <a className="iq-waves-effect d-flex align-items-center">
                  <div className="caption">
                    <DropLanguajes t={t} i18n={i18n} />
                  </div>
                </a>
              </li>
            </ul>
            <ul className="navbar-list">
              <li>
                <a className="search-toggle iq-waves-effect d-flex align-items-center">
                  <i className="ri-arrow-down-s-fill" />
                </a>
                <div className="iq-sub-dropdown iq-user-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0 ">
                      <div className="bg-primary p-3 line-height">
                        <h5 className="mb-0 text-white line-height">
                          {user?.displayName}
                        </h5>
                        <span className="text-white font-size-12">
                          {user?.email}
                        </span>
                      </div>
                      <Link
                        to="/profile"
                        className="iq-sub-card iq-bg-warning-hover"
                      >
                        <div className="media align-items-center">
                          <div className="rounded iq-card-icon iq-bg-warning">
                            <i className="ri-profile-line" />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">{t("header_edit_profile")}</h6>
                            <p className="mb-0 font-size-12">
                              {t("header_modify_your_data")}
                            </p>
                          </div>
                        </div>
                      </Link>
                      <div className="d-inline-block w-100 text-center p-3">
                        <a
                          onClick={onSignOut}
                          className="bg-primary iq-sign-btn"
                          role="button"
                        >
                          {t("header_logout")}
                          <i className="ri-login-box-line ml-2" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {showIncomingCall && incomingCall && <Modalvideocall incomingCall={incomingCall} showIncomingCall={showIncomingCall} onHide={() => {
        setShowIncomingCall(!showIncomingCall);
        setIncomingCall(null);
      }} />}
    </div>
  );
};

export default Header;
