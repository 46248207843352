import { useState, useRef, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'

import { useTranslation } from "react-i18next";
import { createPost, uploadMedia, uploadPostPic } from "../../services/postHelper";
import ReactPlayer from 'react-player'
import { ShowSwalAlert } from "../Utils";
import { MAX_FILE_SIZE } from "../../utils/FileValidation";

export const CreatePosts = (props) => {
    const { t } = useTranslation();
    const [isValid, setIsValid] = useState(true);
    const [disableButton, setDisableButton] = useState(false);
    const [text, setText] = useState('');
    const [file, setFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const refFileInput = useRef(null);
    const [progress, setProgress] = useState(0);

    const savePost = async () => {
        setDisableButton(true);
        setIsValid(false);
        const post = {
            uid: props.user.uid,
            text: text,
            userName: props.user.displayName ? props.user.displayName : "Anonymus",
            photoProfile: props.user.photoURL ? props.user.photoURL : "",
            timestamp: new Date(),
            isUpdated: true,
            privacity: "public",
            isActive: true,
            status: "0"
        }
        /*
        status <- el campo lo usan como string
        0 = publicacion de solo texto
        1 = imagen con texto
        2 = audio con texto
        3 = video con texto 
        */
        if (file) {
            const fileType = file.type.split('/')[0];
            if (fileType === 'image') {
                await uploadMedia(props.user.uid, file, setProgress).then((url) => {
                    post.photoPost = url;
                    post.status = "1";
                }).catch((error) => {
                    console.log(error);
                })
            } else if (fileType === 'video') {
                await uploadMedia(props.user.uid, file, setProgress).then((url) => {
                    post.video = url;
                    post.status = "3";
                }).catch((error) => {
                    console.log(error);
                })
            }
        }
        const result = await createPost(props.user.uid, post);
        if (result) {
            props.setPosts([{ id: result.id, ...post }, ...props.posts])
            cleanModal();
        } else {
            console.log(result);
        }
    }

    const handleOnClickProfilePic = () => {
        refFileInput.current.click();
    };

    const cleanModal = () => {
        props.onHide();
        setDisableButton(false);
        setIsValid(true);
        setText('');
        setFileDataURL(null);
        setFile(null);
        setProgress(0);
    }

    const handleFileChange = async (event) => {
        event.preventDefault();
        const fileList = [...event.target.files];
        var file = fileList[0];
        if (!file) return;
        if (file.type.split('/')[0] === 'image') {
            setFile(file);
        } else if (file.type === 'video/mp4' || file.type === 'video/m4v') {
            const filesize = file.size / (1024 * 1024);
            if (filesize > MAX_FILE_SIZE) {
                ShowSwalAlert({ icon: "info", title: t('validations_max_file_size', { max: MAX_FILE_SIZE }) });
            } else {
                setFile(file);
            }
        } else {
            ShowSwalAlert({ icon: "info", title: t('validations_file_not_allowed') });
        }
    }

    const previewFile = (file) => {
        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                setFileDataURL(e.target.result);
            }
            fileReader.readAsDataURL(file);
        }
    }

    useEffect(() => {
        previewFile(file);
    }, [file]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('posts_create_title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="modal-body">
                    <div className="d-flex align-items-center">
                        <input type="text" className="form-control rounded" value={text} onChange={e => setText(e.target.value)} placeholder={t('posts_type_here')} style={{ border: 'none' }} />
                    </div>
                    <hr />
                    <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
                        <li className="col-md-6 mb-3" onClick={handleOnClickProfilePic}>
                            <div className="iq-bg-primary rounded p-2 pointer mr-3">
                                <img src="images/small/07.png" alt="icon" className="img-fluid" /> {t("home_image_video")}
                            </div>
                        </li>
                    </ul>
                    <hr />
                    <div>
                        <input ref={refFileInput} className="file-upload" type="file" onChange={handleFileChange} accept="image/*, video/mp4, video/m4v" />
                    </div>
                    <div className="user-post">
                        <div className="d-flex col-md-12 justify-content-md-center">
                            {fileDataURL && file.type.split('/')[0] === 'image' ? <img src={fileDataURL} className="img-fluid" alt="" /> :
                                <ReactPlayer url={fileDataURL} width='100%' height='100%' controls />}
                        </div>
                    </div>
                </div>
                {disableButton &&
                    <div className="text-center">
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="visually-hidden"></span>
                        </Spinner>
                        <p>{file ? progress : ""}</p>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                {isValid && text.trim().length > 0 &&
                    <Button onClick={savePost} disabled={disableButton}>{t('button_send')}</Button>
                }
                <Button className="btn-danger" onClick={() => cleanModal()}>{t('button_close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}