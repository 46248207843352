import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { updateUser } from "../../services/authHelper";
import { ErrorMessage } from "../ErrorMessage";

const SocialNetwork = ({ userData, setUserData }) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    
    const handleSocialChange = (evt) => {
        evt.preventDefault();
        const { name, value } = evt.target;
        const rawValue = value.trim();
        if (rawValue === "") {
            setErrors({ ...errors, [name]: { message: { key: "validations_required" } } })
        }
        else {
            const accounts = {...userData["accounts"]};
            accounts[name] = value; 
            setErrors({ ...errors, [name]: undefined })
            setUserData({ ...userData, accounts: accounts });
        }
    }

    const handleOnSubmit = async (evt) => {
        evt.preventDefault();
        setIsSubmitting(true);
        try {
            await updateUser(userData);
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <>
            <h4>{t("profile_social_tab")}</h4>
            <hr />
            <div className="row">
                <div className="iq-card-body">
                    <form onSubmit={handleOnSubmit}>
                        <div className="row">
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="facebook">Facebook</Form.Label>
                                <Form.Control type="text" id="facebook" name="facebook" isInvalid={!!errors?.facebook}
                                    onChange={handleSocialChange} onBlur={handleSocialChange} defaultValue={userData?.accounts?.facebook} />
                                <ErrorMessage error={errors.facebook?.message} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="ios">IOS</Form.Label>
                                <Form.Control type="text" id="ios" name="iOS" isInvalid={!!errors?.iOS}
                                    onChange={handleSocialChange} onBlur={handleSocialChange} defaultValue={userData?.accounts?.iOS} />
                                <ErrorMessage error={errors.iOS?.message} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="instagram">Instagram</Form.Label>
                                <Form.Control type="text" id="instagram" name="instagram" isInvalid={!!errors?.instagram}
                                    onChange={handleSocialChange} onBlur={handleSocialChange} defaultValue={userData?.accounts?.instagram} />
                                <ErrorMessage error={errors.instagram?.message} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="twitter">Twitter</Form.Label>
                                <Form.Control type="text" id="twitter" name="twitter" isInvalid={!!errors?.twitter}
                                    onChange={handleSocialChange} onBlur={handleSocialChange} defaultValue={userData?.accounts?.twitter} />
                                <ErrorMessage error={errors.twitter?.message} />
                            </Form.Group>
                        </div>
                        <button type="submit" className="btn btn-primary mr-2">
                            {t("profile_update")} {isSubmitting && <Spinner animation="border" variant="light" size="sm" />}
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SocialNetwork;