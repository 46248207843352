import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useAuth } from "../../authContext";
import { ErrorMessage } from "../ErrorMessage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveEvent } from "../../services/calendarHelper";

const initialValues = {title: "", description: "", dateStart: "", dateEnd: "", color: "", compartir: ""};

export const CreateEvents = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const currentDate = new Date();
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [formVal, setformVal] = useState(initialValues);
  const [validation, setValidation] = useState(initialValues);

  const handleClose = () => { setformVal(initialValues); 
    setStartDate(null);
    setEndDate(null);
    props.onHide();
  };

  const addEvent = async () => {
    await checkValidation();
    if(!isDirty()){
      const { title, description, color } = formVal;
        await saveEvent({
          uid: user.uid,
          title: title,
          description: description,
          dateStart: startDate,
          dateEnd: endDate,
          color: color,
        });
        setformVal(initialValues);
        handleClose();
    };
  }

  const handleOnChange = (property, value) => {
    setformVal({ ...formVal, [property]: value });
    if(value.trim() === ""){
      setValidation({...validation, [property]: `${t("validations_required")}`})
    }else{
      setValidation({...validation, [property]: ""})
    }
  }
  
  const isDirty = () => {
    let hasError = false;
    for (const item of Object.entries(validation)) {
      if(item[1].length > 0 ){
        hasError = true;
        break;
      }
    }
    return hasError;
  }

  const checkValidation = async () => {
    const errors = validation;

    if (!formVal.title?.trim()) {
      errors.title = `${t("validations_required")}`;
    } else {
      errors.title = "";
    }
    if (!formVal.description?.trim()) {
      errors.description = `${t("validations_required")}`;
    } else {
      errors.description = "";
    }
    if (!formVal.color?.trim()) {
      errors.color = `${t("validations_required")}`;
    } else {
      errors.color = "";
    }
    if (!formVal.dateStart?.trim()) {
      errors.dateStart = `${t("validations_required")}`;
    } else {
      errors.dateStart = "";
    }
    if (!formVal.dateEnd?.trim()) {
      errors.dateEnd = `${t("validations_required")}`;
    } else {
      errors.dateEnd = "";
    }

    setValidation(errors);
  };

  const filterStartDate = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const filterEndDate = (time) => {
    const selectedDate = new Date(time);
    return startDate.getTime() < selectedDate.getTime();
  };

  return (
    <Modal show={props.show}  onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("calendario_add")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <Form.Group className="mb-3">
                <Form.Label>{t("calendario_titulo")}</Form.Label>
                <Form.Control
                  type="text"
                  id="title"
                  className="form-control mb-0"
                  placeholder={t("calendario_titulo")}
                  value={formVal.title}
                  isInvalid={!!validation.title}
                  onChange={(e) => handleOnChange("title", e.target.value)}
                />
                <ErrorMessage error={validation.title} />
              </Form.Group>
            </div>
            <div className="form-group col-md-6">
              <Form.Group className="mb-3">
                <Form.Label>{t("calendario_descripcion")}</Form.Label>
                <Form.Control
                  type="text"
                  id="description"
                  className="form-control mb-0"
                  placeholder={t("calendario_descripcion")}
                  value={formVal.description}
                  isInvalid={!!validation.description}
                  onChange={(e) => handleOnChange("description", e.target.value)}
                />
                <ErrorMessage error={validation.description} />
              </Form.Group>
            </div>
          </div>

          <label>{t("calendario_prioridad")}</label>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label
                className="flex justify-center items-center cursor-pointer hover:brightness-90 duration-200 w-8 h-8 rounded-md p1 m"
                htmlFor="p1"
              >
                <Form.Check type="radio">
                  <Form.Check.Input
                    type="radio"
                    checked={formVal.color === "#f94176"}
                    onChange={(e) => handleOnChange("color", e.target.value)}
                    label={t("c_prioridad1")}
                    style={{ color: "white !important" }}
                    name="prioridad"
                    value="#f94176"
                    id="p1"
                  />
                  <Form.Check.Label style={{ color: "white" }} htmlFor="p1">
                    {t("c_prioridad1")}
                  </Form.Check.Label>
                </Form.Check>
              </label>
            </div>
            <div className="form-group col-md-3">
              <label
                className="flex justify-center items-center cursor-pointer hover:brightness-90 duration-200 w-8 h-8 rounded-md p2 m"
                htmlFor="p2"
              >
                <Form.Check type="radio">
                  <Form.Check.Input
                    type="radio"
                    checked={formVal.color === "#50b5ff"}
                    onChange={(e) => handleOnChange("color", e.target.value)}
                    label={t("c_prioridad2")}
                    style={{ color: "white" }}
                    name="prioridad"
                    value="#50b5ff"
                    id="p2"
                  />
                  <Form.Check.Label style={{ color: "white" }} htmlFor="p2">
                    {t("c_prioridad2")}
                  </Form.Check.Label>
                </Form.Check>
              </label>
            </div>
            <div className="form-group col-md-3">
              <label
                className="flex justify-center items-center cursor-pointer hover:brightness-90 duration-200 w-8 h-8 rounded-md p3 m"
                htmlFor="p3"
              >
                <Form.Check type="radio">
                  <Form.Check.Input
                    type="radio"
                    checked={formVal.color === "#663387"}
                    onChange={(e) => handleOnChange("color", e.target.value)}
                    label={t("c_prioridad3")}
                    style={{ color: "white" }}
                    name="prioridad"
                    value="#663387"
                    id="p3"
                  />
                  <Form.Check.Label style={{ color: "white" }} htmlFor="p3">
                    {t("c_prioridad3")}
                  </Form.Check.Label>
                </Form.Check>
              </label>
            </div>
            <div className="form-group col-md-3">
              <label
                className="flex justify-center items-center cursor-pointer hover:brightness-90 duration-200 w-8 h-8 rounded-md p4 m"
                htmlFor="p4"
              >
                <Form.Check type="radio">
                  <Form.Check.Input
                    type="radio"
                    checked={formVal.color === "#f2cc21"}
                    onChange={(e) => handleOnChange("color", e.target.value)}
                    label={t("c_prioridad4")}
                    style={{ color: "white" }}
                    name="prioridad"
                    value="#f2cc21"
                    id="p4"
                  />
                  <Form.Check.Label style={{ color: "white" }} htmlFor="p4">
                    {t("c_prioridad4")}
                  </Form.Check.Label>
                </Form.Check>
              </label>
            </div>
            <ErrorMessage error={validation.color} />
          </div>
          <label>Compartir con</label>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label
                className="flex justify-center items-center cursor-pointer hover:brightness-90 duration-200 w-8 h-8 rounded-md p1 m"
                htmlFor="c1"
              >
                <Form.Check type="radio">
                  <Form.Check.Input
                    type="radio"
                    checked={formVal.compartir === "#f94176"}
                    onChange={(e) => handleOnChange("compartir", e.target.value)}
                    label="Todos"
                    style={{ color: "white !important" }}
                    name="compartir"
                    value="#f94176"
                    id="c1"
                  />
                  <Form.Check.Label style={{ color: "white" }} htmlFor="c1">
                    Todos
                  </Form.Check.Label>
                </Form.Check>
              </label>
            </div>

            <div className="form-group col-md-3">
              <label
                className="flex justify-center items-center cursor-pointer hover:brightness-90 duration-200 w-8 h-8 rounded-md p2 m"
                htmlFor="c2"
              >
                <Form.Check type="radio">
                  <Form.Check.Input
                    type="radio"
                    checked={formVal.compartir === "#50b5ff"}
                    onChange={(e) => handleOnChange("compartir", e.target.value)}
                    label="Estudiantes"
                    style={{ color: "white !important" }}
                    name="compartir"
                    value="#50b5ff"
                    id="c2"
                  />
                  <Form.Check.Label style={{ color: "white" }} htmlFor="c1">
                    Estudiantes
                  </Form.Check.Label>
                </Form.Check>
              </label>
            </div>

            <div className="form-group col-md-3">
              <label
                className="flex justify-center items-center cursor-pointer hover:brightness-90 duration-200 w-8 h-8 rounded-md p3 m"
                htmlFor="c3"
              >
                <Form.Check type="radio">
                  <Form.Check.Input
                    type="radio"
                    checked={formVal.compartir === "#663387"}
                    onChange={(e) => handleOnChange("compartir", e.target.value)}
                    label="Padres o Tutores"
                    style={{ color: "white !important" }}
                    name="compartir"
                    value="#663387"
                    id="c3"
                  />
                  <Form.Check.Label style={{ color: "white" }} htmlFor="c1">
                    Padres o Tutores
                  </Form.Check.Label>
                </Form.Check>
              </label>
            </div>

          </div>

          <div className="form-row">
            <div className="form-group col-sm-6">
              <Form.Group className="mb-3">
                <Form.Label>{t("calendario_inicio")}</Form.Label>
                <DatePicker className="form-control" dateFormat="d/MM/yyyy h:m aa" showTimeSelect selected={startDate} minDate={currentDate}
                  onChange={(date) => { setStartDate(date); handleOnChange("dateStart", date.toISOString());}}
                  filterTime={filterStartDate} />
                <ErrorMessage error={validation.dateStart} />
              </Form.Group>
            </div>
            <div className="form-group col-sm-6">
              <Form.Group className="mb-3">
                <Form.Label>{t("calendario_final")}</Form.Label>
                <DatePicker className="form-control" dateFormat="d/MM/yyyy h:m aa" showTimeSelect selected={endDate} minDate={currentDate}
                  onChange={(date) => { setEndDate(date); handleOnChange("dateEnd", date.toISOString());}}
                  filterTime={filterEndDate} />                
                <ErrorMessage error={validation.dateEnd} />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={addEvent}>{t("button_send")}</Button>
        <Button className="btn-danger" onClick={props.onHide}>
          {t("button_close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
