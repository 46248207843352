import { collection, orderBy, startAfter, limit, query, where, addDoc, deleteDoc, getDocs, getDoc, doc, setDoc, onSnapshot } from "firebase/firestore";

import { httpsCallable } from "firebase/functions";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { sendPasswordResetEmail } from "firebase/auth";

import { auth, storage, db, functions } from "../firebaseConfig";

const isUserExist = async (uid)  => {
    try {
        let exists = false;
        const isUserExist = httpsCallable(functions, "isUserExist");
        await isUserExist({uid})
            .then((result) => {
                exists = result;
            });
        return exists;
    } catch (error) {
        console.error('Error |isUserExist| :',error);
        return error;
    }
}

const isEmailTaken = async (email)  => {
    let isTaken = false;
    try {
        const isEmailTaken = httpsCallable(functions, "isEmailTaken");
        await isEmailTaken({email})
            .then(({data}) => {
                isTaken = data;
            });
        return isTaken; 
    } catch (error) {
        console.error('Error |isEmailTaken| :',error);
    }
    return isTaken;
}

const getUserByUid = async (uid) => {
    let dataPosts = [];
    try {
        const ref = doc(db, `users/${uid}`);

        const docSnapshot = await getDoc(ref);
        if(docSnapshot.exists()) {
            dataPosts = docSnapshot.data();
        }
    } catch (error) {
        console.error('Error |getPostsByUserId| :',error);
        return false;
    }
    return dataPosts;
}

const onSnapshotUserByUid = async (uid, setUser) => {
    const unsubscribe  = onSnapshot(doc(db, `users/${uid}`), (doc) => {
        setUser(doc.data());
    });

    return () => unsubscribe();
}

const registerNewUser = async (newUser) => {
    try {
        let result = false;
        const registerNewUser = httpsCallable(functions, "registerNewUser");
        await registerNewUser({newUser})
            .then(({data}) => {
                if(data === "success") {
                    result = true;
                } else {
                    throw new Error(data);
                }
            });
        return result; 
    } catch (error) {
        console.error('Error |registerNewUser| :',error);
        return error;
    }
}

const updateUser = async (user) => {
    try {
        let userUpdated = false;
        const updateUser = httpsCallable(functions, "updateUser");
        await updateUser({user})
            .then(({data}) => {
                if(data === "success") {
                    userUpdated = true;
                } else {
                    throw new Error(data);
                }
            });
        return userUpdated; 
    } catch (error) {
        console.error('Error |updateUser| :',error);
        return error;
    }
}

const uploadProfilePic = async (uid, file) => {
    const storageRef = ref(storage, `perfil/${uid}`);
    const result = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
}

const uploadPicture = async (path, file) => {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
}

const resetPassword = async (email) => {
    let result = false;
    await sendPasswordResetEmail(auth, email)
        .then(function () {
            result = true;
            return result;
        }).catch(function (e) {
            console.log(e)
        });
    return result;
}

export { 
    isUserExist, isEmailTaken, getUserByUid, registerNewUser, 
    updateUser, uploadProfilePic, resetPassword, uploadPicture,
    onSnapshotUserByUid 
}