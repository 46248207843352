import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { isEmailTaken } from "../services/authHelper";
import "../i18n/i18n";
import { useTranslation } from "react-i18next";
import { DropLanguajes } from "../i18n/dropLanguajes";
import { createNewUser } from "../services/userHelper";
import { useForm } from "react-hook-form";
import { Form, FormSelect, InputGroup, Spinner } from "react-bootstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import moment from 'moment';
import { ErrorMessage } from "../components/ErrorMessage";
import { ModalPassword } from "../components/ModalPassword";
import { LANGUAGES_EN, LANGUAGES_ES } from "../utils/data";

const initialValues = {
  firstName: "", lastName: "", email: "",
  password: "", userType: "", dateOfBirth: null,
  gender: "", nativeLang: "", targetLang: "",
  termsOfUse: false
}

const Register = () => {
  const { t, i18n } = useTranslation();
  const [emailTaken, setEmailTaken] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordConfirmType, setPasswordConfirmType] = useState("password");
  const navigate = useNavigate();
  const LANGUAGES = i18n.language === "es" ? LANGUAGES_ES : LANGUAGES_EN;

  const validationSchema = yup.object({
    firstName: yup.string().trim().required(),
    lastName: yup.string().trim().required(),
    email: yup.string().email().required(),
    password: yup.string().required().min(8),
    confirmPwd: yup.string().required().oneOf([yup.ref('password')], { key: 'validations_password_confirm' }),
    userType: yup.string().nullable().required(),
    dateOfBirth: yup.date().nullable().required(),
    // .test('dob', { key: 'validations_age' }, (value) => moment().diff(value, 'years') >= 18),
    gender: yup.string().optional(),
    nativeLang: yup.string().required(),
    targetLang: yup.string().required(),
    termsOfUse: yup.boolean().oneOf([true], { key: 'validations_termsOfUse' })
  });

  const { register, setError, watch, handleSubmit, formState: { isSubmitting, errors } } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  });

  const userType = watch("userType", t("register_p"));

  const handleEmailTaken = (isTaken) => {
    setEmailTaken(isTaken);
    if (isTaken) {
      setError("email", { type: "emailTaken", message: { key: "register_email_taken" } });
    }
  }

  const onSubmit = async (data) => {
    const { email } = data;
    const emailTaken = await isEmailTaken(email);
    if (emailTaken === false) {
      const { email, password, firstName, lastName } = data;
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const displayName = `${firstName} ${lastName}`;
        const photoURL = 'https://firebasestorage.googleapis.com/v0/b/pangea-daee0.appspot.com/o/perfil%2FprofilePicture?alt=media&token=f4bd9726-0b2e-4640-b7ea-3b5bc6ef07a6';
        await updateProfile(user, {
          displayName: displayName,
          photoURL: photoURL
        });
        const userData = { ...data, uid: user.uid, profilePicture: photoURL };
        const result = await createNewUser(userData);
        if (result === true) {
          await sendEmailVerification(user);
        }
        navigate("/");
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          handleEmailTaken(true);
        }
      }
    }
    else {
      handleEmailTaken(true);
    }
  }

  const togglePassword = (evt) => {
    evt.preventDefault();
    const pType = passwordType === "text" ? "password" : "text";
    setPasswordType(pType);
  }

  const togglePasswordConfirm = (evt) => {
    evt.preventDefault();
    const pType = passwordConfirmType === "text" ? "password" : "text";
    setPasswordConfirmType(pType);
  }

  return (
    <div className="sign-in register">
      <div className="container p-0">
        <div className="row">
          <div className="col-md-6 text-center pt-5 order-2 order-lg-1">
            <div className="sign-in-intro text-white">
              <div className="sign-in-logo">
                <img src="https://ibloom.com.mx/pangu/hame.svg" alt="Pángu" />
              </div>
            </div>
          </div>
          <div className="col-md-6 bg-white pt-5 order-1 order-lg-2 todoh">
            <div className="float-right align-middle">
              <DropLanguajes t={t} i18n={i18n} />
            </div>
            <div className="sign-in-form" style={{ overflow: "auto" }}>
              <h1 className="mb-0">{t("register_title")}</h1>
              <Form onSubmit={handleSubmit(onSubmit)}>

                <div className="form-row">
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="firstName">{t("register_firstName")}</Form.Label>
                    <Form.Control
                      type="text"
                      id="firstName"
                      className="form-control mb-0"
                      placeholder={t("register_firstName")}
                      isInvalid={!!errors.firstName}
                      {...register("firstName")}
                    />
                    <ErrorMessage error={errors.firstName?.message} />
                  </Form.Group>
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="lastName">{t("register_lastName")}</Form.Label>
                    <Form.Control
                      type="text"
                      id="lastName"
                      className="form-control mb-0"
                      placeholder={t("register_lastName")}
                      isInvalid={!!errors.lastName}
                      {...register("lastName")}
                    />
                    <ErrorMessage error={errors.lastName?.message} />
                  </Form.Group>
                </div>

                <div className="form-group">
                  <Form.Group>
                    <Form.Label htmlFor="email">{t("register_email")}</Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      className="form-control mb-0"
                      placeholder={t("register_email")}
                      isInvalid={!!errors.email}
                      {...register("email")}
                    />
                    <ErrorMessage error={errors.email?.message} />
                    {emailTaken && <span className="dark-color d-inline-block line-height-2">
                      <Link to={""} onClick={() => setShowModal(true)}>
                        {t("login_recover_pass")}
                      </Link>
                    </span>}
                  </Form.Group>
                </div>

                <div className="form-group">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="password">{t("register_password")}</Form.Label>
                    <InputGroup className="mb-0">
                      <Form.Control
                        type={passwordType}
                        id="password"
                        placeholder={t("register_password")}
                        isInvalid={!!errors.password}
                        {...register("password")}
                      />
                      <button className="btn btn-outline-primary" onClick={togglePassword}>
                        {passwordType === "password" ? <i className="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                      </button>
                    </InputGroup>
                    <ErrorMessage error={errors.password?.message} />
                  </Form.Group>
                </div>

                <div className="form-group">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="confirmPwd">{t("register_password_confirm")}</Form.Label>
                    <InputGroup className="mb-0">
                      <Form.Control
                        type={passwordConfirmType}
                        id="confirmPwd"
                        placeholder={t("register_password_confirm")}
                        isInvalid={!!errors.confirmPwd}
                        {...register("confirmPwd")}
                      />
                      <button className="btn btn-outline-primary" onClick={togglePasswordConfirm}>
                        {passwordType === "password" ? <i className="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                      </button>
                    </InputGroup>
                    <ErrorMessage error={errors.confirmPwd?.message} />
                  </Form.Group>
                </div>

                <div className="form-row mb-3">
                  <label className="col-sm-12">{t("register_utype")}</label>
                  <div className="form-group col-md-6">
                    <Form.Check type="radio">
                      <Form.Check.Input type="radio" id={t("register_p")} name="userType" value={t("register_p")} {...register("userType")} />
                      <Form.Check.Label htmlFor={t("register_p")}>{t("register_p")}</Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div className="form-group col-md-6">
                    <Form.Check type="radio">
                      <Form.Check.Input type="radio" id={t("register_e")} name="userType" value={t("register_e")} {...register("userType")} />
                      <Form.Check.Label htmlFor={t("register_e")}>{t("register_e")}</Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div className="form-group col-md-6">
                    <Form.Check type="radio">
                      <Form.Check.Input type="radio" id={t("register_p")} name="userType" value={t("register_p")} {...register("userType")} />
                      <Form.Check.Label htmlFor={t("register_p")}>Director</Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div className="form-group col-md-6">
                    <Form.Check type="radio">
                      <Form.Check.Input type="radio" id={t("register_e")} name="userType" value={t("register_e")} {...register("userType")} />
                      <Form.Check.Label htmlFor={t("register_e")}>Padre,Madre o Tutor</Form.Check.Label>
                    </Form.Check>
                  </div>
                  <ErrorMessage error={errors.userType?.message} />
                </div>

                <div className="form-row">
                  <Form.Group className="form-group col-sm-6 mb-3">
                    <Form.Label htmlFor="dateOfBirth">{t("register_date_dob")}</Form.Label>
                    <Form.Control
                      type="date"
                      id="dateOfBirth"
                      className="form-control mb-0"
                      placeholder={t("register_date_dob")}
                      isInvalid={!!errors.dateOfBirth}
                      {...register("dateOfBirth")}
                    />
                    <ErrorMessage error={errors.dateOfBirth?.message} />
                  </Form.Group>

                  <div className="form-group col-sm-6">
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="gender">{t("register_gender")}</Form.Label>
                      <FormSelect id="gender"
                        className="browser-default custom-select"
                        isInvalid={!!errors.gender}
                        {...register("gender")}
                      >
                        <option value="Male">Masculino</option>
                        <option value="Female">Femenino</option>
                      </FormSelect>
                      <ErrorMessage error={errors.gender?.message} />
                    </Form.Group>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="nativeLang">{t("register_native_lang")}</Form.Label>
                      <FormSelect
                        id="nativeLang"
                        className="browser-default custom-select"
                        isInvalid={!!errors.nativeLang}
                        {...register("nativeLang")}
                      >
                        {LANGUAGES.map((lang) => <option value={lang.value}>{lang.label}</option>)}
                      </FormSelect>
                      <ErrorMessage error={errors.nativeLang?.message} />
                    </Form.Group>
                  </div>
                  <div className="form-group col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="targetLang">{userType === t("register_p") ? t("register_target_lang_t") : t("register_target_lang_s")}</Form.Label>
                      <FormSelect
                        id="targetLang"
                        className="browser-default custom-select"
                        isInvalid={!!errors.targetLang}
                        {...register("targetLang")}
                      >
                        {LANGUAGES.map((lang) => <option value={lang.value}>{lang.label}</option>)}
                      </FormSelect>
                      <ErrorMessage error={errors.targetLang?.message} />
                    </Form.Group>
                  </div>
                </div>

                <div className="form-row">
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="colegio">Colegio o Clave</Form.Label>
                    <Form.Control
                      type="text"
                      id="colegio"
                      className="form-control mb-0"
                      placeholder="Colegio"
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="estado">Estado</Form.Label>
                    <Form.Control
                      type="text"
                      id="estado"
                      className="form-control mb-0"
                      placeholder="Estado"
                    />
                  </Form.Group>
                </div>

                <div className="form-row">
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="municipio">Municipio</Form.Label>
                    <Form.Control
                      type="text"
                      id="municipio"
                      className="form-control mb-0"
                      placeholder="Municipio"
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="matricula">Matrícula</Form.Label>
                    <Form.Control
                      type="text"
                      id="matricula"
                      className="form-control mb-0"
                      placeholder="Matrícula"
                    />
                  </Form.Group>
                </div>

                <Form.Group className="form-group col-md-6">
                  <Form.Label htmlFor="identiciacion">Identificación oficial</Form.Label>
                  <input id="identiciacion" type="file" accept="*/*" />                  
                </Form.Group>

                <Form.Group className="form-group col-md-6">
                  <Form.Label htmlFor="foto">Foto: "Sin aretes o gorras"</Form.Label>
                  <input id="foto" type="file" accept="*/*" />                  
                </Form.Group>

                <div className="form-group col-md-12">
                  <Form.Group className="mb-3">
                    <div className="form-check">
                      <input type="checkbox" id="termsOfUse" name="userType" isInvalid={!!errors.termsOfUse} {...register("termsOfUse")} />
                      <Link to="/terms" className="p-1">{t("register_terms_of_use")}</Link>
                      <span>{t("register_terms_separator")}</span>
                      <Link to="/privacy" className="p-1">{t("register_privacy")}</Link>
                    </div>
                    <ErrorMessage error={errors.termsOfUse?.message} />
                  </Form.Group>
                </div>

                <div className="d-inline-block w-100">
                  <button type="submit" className="btn btn-primary float-right">
                    {t("register_signin")} {" "} {isSubmitting && <Spinner animation="border" variant="light" size="sm" />}
                  </button>
                </div>
                <div className="sign-info">
                  <span className="dark-color d-inline-block line-height-2">
                    {t("register_registered")} <Link to="/">{t("register_signup")}</Link>
                  </span>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ModalPassword
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
};

export default Register;
