import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { auth } from "./firebaseConfig";
import { getUserByUid, isEmailTaken } from "./services/authHelper";
import { listenUserPresence } from "./services/presenceHelper";
import { initSubscription, validateUserSubscription } from "./services/subscriptionsHelper";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const auth = useFirebaseAuth();
  if(auth.loading){
    return null;
  }
  listenUserPresence(auth?.user?.uid);
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};

export const AdminRoute = ({ children }) => {
  const { user, isAdmin } = useAuth();
  const location = useLocation();

  if (!user || !isAdmin) {
    return <Navigate to="/admin/login" replace state={{ from: location }} />;
  }
  
  return children;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const useFirebaseAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const navigate = useNavigate();

  const onSignOut = () => {
    signOut(auth).then(() => {
      if(isAdmin){
        setIsAdmin(!isAdmin);
      }
      setUser(null);
      window.location.reload();
    });
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithProvider(auth, provider);
  };

  const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    await signInWithProvider(auth, provider);
  };

  const signInWithApple = async () => {
    const provider = new OAuthProvider();
    await signInWithProvider(auth, provider);
  };

  const signInWithProvider = async (auth, provider) => {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const isEmailExist = await isEmailTaken(user.email);
    const uData = await getUserByUid(user.uid);
    if(!uData.subscription){
      await initSubscription(user.uid);
    }else{
      await validateUserSubscription(uData);
    }
    if (isEmailExist === false) {
      navigate("/signUp");
    }else{
      navigate("/");
    }
  };
  
  const signAdmin = (uInfo) => {
    if(uInfo.isAdmin){
      setIsAdmin(uInfo.isAdmin);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
  });

  return {
    user,
    loading,
    userData,
    isAdmin,
    setUserData,
    signInWithGoogle,
    signInWithFacebook,
    signInWithApple,
    onSignOut,
    signAdmin
  };
};

export default AuthContext