import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = ({children}) => {
    return (
        <>
            <Header />
            <Sidebar />
            <div id="content-page" className="content-page">
                {children}
            </div>
        </>
    );
}

export default Layout;