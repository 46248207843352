import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";

export const MNuevaTarea = (props) => {
    const currentDate = new Date();
    const [startDate, setStartDate] = useState(currentDate);

    const filterStartDate = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    return (
        <>
            <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Nueva tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tareas tareas2">
                        {/*Card*/}
                        <div className="card">
                            {/*Card Header*/}
                            <div className="card-header">
                                <p>Nueva tarea</p>
                            </div>
                            {/*Card Body*/}
                            <div className="card-body">

                                <form className="fejercicios ftareas">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect1">Materia:</label>
                                        <select className="form-control" id="exampleFormControlSelect1">
                                            <option>Matemáticas</option>
                                            <option>Computación</option>
                                            <option>Química</option>
                                            <option>Historia</option>
                                            <option>Español</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect1">Grupo:</label>
                                        <select className="form-control" id="exampleFormControlSelect1">
                                            <option>1A</option>
                                            <option>2B</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">Nombre:</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlTextarea11">Descripción:</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea11" rows={2} defaultValue={""} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlTextarea11">Material de apoyo:</label>
                                        <input className="form-control" type="file" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlTextarea1">Comentario para el alumno:</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows={2} defaultValue={""} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlTextarea11">Comentario para los papas o tutores:</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea11" rows={2} defaultValue={""} />
                                    </div>
                                    <div className="form-group">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fecha y hora de entrega</Form.Label>
                                            <DatePicker className="form-control" dateFormat="d/MM/yyyy h:m aa" showTimeSelect selected={startDate} minDate={currentDate}
                                                onChange={(date) => {
                                                    setStartDate(date);
                                                }}
                                                filterTime={filterStartDate} />
                                        </Form.Group>
                                    </div>
                                    <button type className="btn btn-primary mt-2">Enviar tarea</button>
                                </form>


                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}