import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import Layout from "../components/Layout";

const DiagnosticTests = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <div className="header-for-bg">
                <div className="background-header position-relative">
                    <img
                        src="https://ibloom.com.mx/pangu/evaluaciones.jpg"
                        className="img-fluid w-100"
                        alt="header-bg"
                    />
                    <div className="title-on-header">
                        <div className="data-block">
                            <h2>{t('diagnostictests_title')}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-page" className="content-page">
                <div className="container exercises">
                    <div className="row">
                        <div className="col-sm-12">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );

}

export default DiagnosticTests;