import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    signInWithEmailAndPassword,
} from "firebase/auth";
import "../../i18n/i18n";
import { useTranslation } from "react-i18next";
import { auth } from "../../firebaseConfig";
import { DropLanguajes } from "../../i18n/dropLanguajes";
import { ModalPassword } from "../../components/ModalPassword";
import Swal from "sweetalert2";
import { ShowSwalAlert } from "../../components/Utils";
import { Spinner } from "react-bootstrap";
import { getInfo } from "../../services/adminHelper";
import { useAuth } from "../../authContext";

const AdminLogin = () => {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { signAdmin } = useAuth();

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email === "" || password === "") {
            Swal.fire({
                icon: "error",
                title: "Debes llenar todos los campos",
                cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
                cancelButtonAriaLabel: "Ok",
            });
        } else {
            setIsSubmitting(true);
            await signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    getInfo(user.uid).then((uInfo) => {
                        if(uInfo.isAdmin){
                            signAdmin(uInfo);
                            navigate("/admin/home");
                        }else{
                            ShowSwalAlert({ icon: 'info', title: t("admin_login_access_not_allowed") });
                        }
                    }).catch((error) => {
                        if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
                            ShowSwalAlert({ icon: 'info', title: t("login_user_pass_wrong") });
                        } else {
                            ShowSwalAlert({ icon: 'info', title: t("login_something_wrong") });
                        }
                    }).finally(() => {
                        setIsSubmitting(false);
                    });  
                })
                .catch((error) => {
                    setIsSubmitting(false);
                    if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
                        ShowSwalAlert({ icon: 'info', title: t("login_user_pass_wrong") });
                    } else {
                        ShowSwalAlert({ icon: 'info', title: t("login_something_wrong") });
                    }
                });
        }
    };

    return (
        <div className="sign-in">
            <div className="container p-0">
                <div className="row">
                    <div className="col-md-6 text-center pt-5 order-2 order-lg-1">
                        <div className="sign-in-intro text-white">
                            <div href="#" className="sign-in-logo">
                                <img src="https://ibloom.com.mx/pangu/hame.svg" alt="Pángu" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 bg-white pt-5 order-1 order-lg-2 todoh">
                        <div className="float-right align-middle">
                            <DropLanguajes t={t} i18n={i18n} />
                        </div>
                        <div className="sign-in-form">
                            <h1 className="mb-0">{t("login_title")}</h1>
                            <p>{t("login_message")}</p>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="correo">{t("login_email")}</label>
                                    <input
                                        type="email"
                                        id="correo"
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="form-control mb-0"
                                        placeholder={t("login_email")}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">{t("login_password")}</label>
                                    <input
                                        type="password"
                                        id="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="form-control mb-0"
                                        placeholder={t("login_password")}
                                    />
                                </div>
                                <div className="d-inline-block w-100">
                                    <span className="dark-color d-inline-block line-height-2">
                                        <Link to={""} onClick={() => setModalShow(true)}>
                                            {t("login_recover_pass")}
                                        </Link>
                                    </span>
                                    <button type="submit" className="btn btn-primary float-right" disabled={isSubmitting}>
                                        {t("login_signin")} {" "} {isSubmitting && <Spinner animation="border" variant="light" size="sm" />}
                                    </button>
                                </div>
                                <div className="sign-info">
                                    <ModalPassword
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
