import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../authContext";
import { getDiagnosticById, getTitleWithRoute } from "../../services/DiagnosticHelper";

const Detail = () => {
    const { t } = useTranslation();
    const { diagnosticId } = useParams();
    const [diagnosticTest, setDiagnosticTest] = useState(null);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const { user } = useAuth();
    
    const COLUMNS = [
        { name: t("diagnostictests_column_title"), selector: row => row.titulo, sortable: true },
        { name: t("diagnostictests_column_completed"), selector: row => <img src={row.urlStatus} alt="status" /> },
        { name: t("diagnostictests_column_score"), selector: row => row.score, sortable: true  },        
        {
            cell: row => (
                <>
                    <button title={t("diagnostictests_column_start")} onClick={() => navigate(`/tests/${diagnosticId}/${row.to}`)} className="btn btn-light mr-1"><i class="ri-arrow-right-line"></i></button>
                </>
            )
        }
    ]

    const loadDetail = async () => {
        await getTitleWithRoute(user.uid, diagnosticId).then((result) => setData(result));
        await getDiagnosticById(diagnosticId).then((detail) => setDiagnosticTest(detail));
    }

    useEffect(() => {
        loadDetail();
    }, [diagnosticId]);

    return (
        <div>
            <div className="iq-card px-2">
                <div className="iq-card-header">
                    <div className="row justify-content-between">
                        <div class="col-sm-12 col-md-6">
                            <h2 className="pt-2">{diagnosticTest?.titulo}</h2>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div className="d-flex float-right">
                                <button onClick={() => navigate("/tests/all")} className="btn btn-ligth mt-2"><i class="ri-arrow-go-back-fill"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="iq-card-body">
                    <div className="containder">
                        <div className="row">
                            {data.length > 0 && <div className="table-responsive">
                                <DataTable columns={COLUMNS} data={data} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Detail;