import { collection, orderBy, startAfter, limit, query, where, addDoc, deleteDoc, getDocs, doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebaseConfig";

export const getCommentByPostId = async (uid, pid) => {
    let dataComments = [];
    try {
        const q = query(collection(db, `users/${uid}/posts/${pid}/comments`));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            dataComments.push({id: doc.id, ...doc.data()});
        });
    } catch (error) {
        console.error('Error |getCommentByPostId| :',error);
        return false;
    }
    return dataComments;
}

export const createComment = async (uid, pid, comment) => {
    let dataComment = {};
    try {
        dataComment = await addDoc(collection(db, `users/${uid}/posts/${pid}/comments`), comment);
    } catch (error) {
        console.error('Error |createComment| :',error);
        return false;
    }
    return dataComment;
}

export const updateComment = async (uid, pid, comment) => {
    try {
        await setDoc(doc(db, `users/${uid}/posts/${pid}/comments`, pid), comment);
    } catch (error) {
        console.error('Error |updateComment| :',error);
        return false;
    }
    return true;
    }

export const deleteComment = async (uid, pid, cid) => {
    try {
        await deleteDoc(doc(db, `users/${uid}/posts/${pid}/comments`, cid));
    } catch (error) {
        console.error('Error |deleteComment| :',error);
        return false;
    }
    return true;
}

export const uploadComment = async (uid, file) => {
    const storageRef = ref(storage, `posts/${uid}/comments/${file.name}`);
    const result = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
}