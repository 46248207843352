import { onDisconnect, onValue, serverTimestamp, set } from "firebase/database";
import { rtdb } from "../firebaseConfig"
import { ref } from "firebase/database";


var isOfflineForDatabase = {
    online: false,
    last_changed: serverTimestamp(),
};

var isOnlineForDatabase = {
    online: true,
    last_changed: serverTimestamp(),
};

export const listenUserPresence = (uid) => {
    if (uid) {
        var userStatusDatabaseRef = ref(rtdb, '/status/' + uid);
        const connectedRef = ref(rtdb, '.info/connected');
        onValue(connectedRef, (snapshot) => {
            if (snapshot.val() === false) {
                return;
            }
            set(userStatusDatabaseRef, isOnlineForDatabase);
            onDisconnect(userStatusDatabaseRef).set(isOfflineForDatabase);
        });
    }

}