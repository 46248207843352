import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext";
import { getUserByUid } from "../../services/authHelper";
import { computeUserLevel, getAllTests, saveUserResult } from "../../services/DiagnosticHelper";
import { MNuevaEvaluacion } from "./MNuevaEvaluacion";

const TestList = () => {
    const { t } = useTranslation();
    const [tests, setTests] = useState([]);
    const [userLevelInfo, setUserLevelInfo] = useState(null);
    const navigate = useNavigate();
    const { user, userData, setUserData } = useAuth();
    const [mostrarDetalle, setMostrarDetalle] = useState(false);

    const COLUMNS = [{ name: t("diagnostictests_column_title"), selector: row => <span title={row.titulo}>{row.titulo}</span>, sortable: true },
    { name: t("diagnostictests_column_level"), selector: row => <div><span className="d-block">{t(row.nivel1)}</span> <span className="d-block">{t(row.nivel2)}</span></div>, sortable: true },
    { name: t("diagnostictests_column_questions"), selector: row => row.totalpreguntas, sortable: true },
    { name: t("diagnostictests_column_completed"), selector: row => <img src={row.urlStatus} alt="status" /> },
    { name: t("diagnostictests_column_score"), selector: row => row.score, sortable: true },
    {
        cell: row => (
            <>
                <button title={t("diagnostictests_column_start")} onClick={() => navigate(`/tests/${row.id}/detail`)} className="btn btn-light mr-1"><i class="ri-arrow-right-line"></i></button>
            </>
        )
    }
    ]

    const loadUserData = async (uid) => {
        let currentUserData = userData || null;
        if (userData == null) {
            currentUserData = await getUserByUid(uid);
            if (currentUserData?.testResult) {
                setUserLevelInfo(currentUserData?.testResult);
            }
            setUserData(currentUserData);
        }
        if (userData?.testResult) {
            setUserLevelInfo(userData?.testResult);
        }
    }

    const loadTests = async () => {
        await getAllTests(user.uid, setTests);
        await loadUserData(user.uid);
    }

    useEffect(() => {
        loadTests();
    }, []);

    const canComputeLevel = () => {
        let counter = 0;
        for (const item of tests) {
            if (item.isCompleted) {
                counter++;
            }
        }

        return counter === 3 && userData?.testResult === undefined;
    }

    const computeLevel = () => {
        computeUserLevel(user.uid).then((level) => {
            setUserLevelInfo(level);
            saveUserResult(user.uid, { testResult: level });
        })
    }

    return (
        <div className="iq-card px-2">
            <div className="iq-card-body">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-sm-12">
                            {userData && userData.userType === "student" &&
                                <>
                                    <h4 className="mt-3 mb-3">Esta evaluación es para asignar tu nivel</h4>
                                    {userLevelInfo === null && canComputeLevel() && <>
                                        <button onClick={() => computeLevel()} className="btn btn-primary">{t("diagnostictests_compute_level")}</button>
                                    </>}
                                    {userLevelInfo && <>
                                        <h4 className="mt-3">{t("diagnostictests_level_result")}: <b>{userLevelInfo.level}</b></h4>
                                        <p>{t("diagnostictests_total_questions")}: <b>{userLevelInfo.total}</b>  {t("diagnostictests_level_score")}: <b>{userLevelInfo.score}</b> {t("diagnostictests_level_wrong_answers")}: <b>{userLevelInfo.wrongAnswers}</b> </p>
                                    </>}
                                </>
                            }

                            {userData && userData.userType === "teacher" &&
                                <>
                                    <div className="col-auto">
                                        <button className="btn btn-primary" onClick={() => setMostrarDetalle(!mostrarDetalle) }><i class="ri-add-fill"></i> Nueva evaluación</button>
                                    </div>
                                </>
                            }
                        </div>
                        
                        <div className="table-responsive">
                            <DataTable columns={COLUMNS} data={tests} pagination paginationComponentOptions={{ rowsPerPageText: t("datatable_rows_PerPageText") }} />
                        </div>
                    </div>
                </div>
            </div>
            {<MNuevaEvaluacion show={mostrarDetalle} onHide={()=> setMostrarDetalle(false)} />}
        </div>
    );
}

export default TestList;