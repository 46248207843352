import { useState } from "react";
import CourseFilter from "./CourseFilter";
import CoursesByLevel from "./CoursesByLevel";

const GermanCourses = () => {
    const [level, setLevel] = useState("All");
    
    return(
        <>
            <div className="courses">
                <CourseFilter level={level} setLevel={setLevel} />
                <CoursesByLevel lang="german" level={level} />
            </div>
        </>
    );
}

export default GermanCourses;