import { t } from "i18next";
import { ShowSwalAlert } from "../components/Utils";

export const MAX_FILE_SIZE = 100;

export const handleFileValidations = (e, setFile, setIsFileSeleted) => {
    const file = e.target.files[0];
    if (!file) return;
    const isValid = isValidFile(file);
    if (isValid) {
        const filesize = file.size / (1024 * 1024);
        if (filesize > MAX_FILE_SIZE) {
            ShowSwalAlert({ icon: "info", title: t('validations_max_file_size', { max: MAX_FILE_SIZE }) });
        } else {
            setFile(file);
            setIsFileSeleted(true);
        }
    } else {
        ShowSwalAlert({ icon: "info", title: t('validations_file_not_allowed') });
    }
}

export const isValidFile = (file) => {
    let isValid = false;
    if (file.type.split('/')[0] === 'image') {
        isValid = true;
    } else if (file.type === 'video/mp4' || file.type === 'video/m4v') {
        isValid = true;

    }
    return isValid;
}