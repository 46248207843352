export const studentModel = {
    uid: "",
    email: "",
    userType: "",
    dateOfBirth: "",
    countryResidence: "",
    countryOfOrigin: "",
    telephoneNumber: "",
    isAdult: "",
    gender: "",
    profilePicture: "",
    confirmedAccount: "",
    termsOfUse: "",
    scholarship: "",
    favoriteHobbie: "",
    wouldLikeToSpeak: "",
    profession: "",
    accounts: {
        facebook: "",
        instagram: "",
        twitter: "",
        iOS: "",
    },
    interests: {
        list: [],
        idealPartner: "",
    },
    language: {
        native: "",
        other: [{}],
        pretendToLearn: [],
        goalLearning: "",
    },
    availability: {
        zone: "",
        days: [
            {
                day: "",
                hour: "",
            },
            {
                day: "",
                hour: "",
            },
        ],
    },
    descriptionProfile: {
        videoLink: "",
        cv: "",
        description: "",
    },
};