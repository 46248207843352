import React from 'react'
import Header from '../../../components/Header'
import Sidebar from '../../../components/Sidebar'
import ScreenChat from './ScreenChat'

function InicioChat({ friendData, setFriendData}) {
  return (
    <div>
      <Header />
      <Sidebar />
      <ScreenChat friendData={friendData} setFriendData={setFriendData} />
    </div>
  )
}

export default InicioChat