import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { updateUser } from "../../services/authHelper";
import { ErrorMessage } from "../ErrorMessage";

const AboutMeView = ({ userData, setUserData }) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    const handleOnSubmit =async (evt) => {
        evt.preventDefault();
        setIsSubmitting(true);
        try {
            await updateUser(userData);
        } finally {
            setIsSubmitting(false);
        }
    }
    
    const handleOnchange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const rawValue = value.trim();
        if (rawValue === "") {
            setErrors({ ...errors, [name]: { message: { key: "validations_required" } } })
        }
        else {
            const descriptionProfile = {...userData["descriptionProfile"]}
            descriptionProfile[name] = value;
            setErrors({ ...errors, [name]: undefined })
            setUserData({ ...userData, descriptionProfile: descriptionProfile });
        }
    };

    return (
        <>
            <div className="row">
                <div className="iq-card-body">
                    <h4>{t("profile_aboutme_tab")}</h4>
                    <hr />
                    <form onSubmit={handleOnSubmit} className="formview">
                        <div className="row">
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="description">{t("profile_aboutme_description")}</Form.Label>
                                <b>{userData?.descriptionProfile?.description}</b>
                            </Form.Group>

                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="cv">{t("profile_aboutme_cv")}</Form.Label>
                                <b>{userData?.descriptionProfile?.cv}</b><a target="_blank" href={userData?.descriptionProfile?.cv} className="btn btn-sm btn-primary">{t("perfil_visit")}</a>
                            </Form.Group>

                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="videoLink">{t("profile_aboutme_videolink")}</Form.Label>
                                <b>{userData?.descriptionProfile?.videoLink}</b><a target="_blank" href={userData?.descriptionProfile?.videoLink} className="btn btn-sm btn-primary">{t("perfil_visit")}</a>
                            </Form.Group>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default AboutMeView;