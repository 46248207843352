import { useTranslation } from 'react-i18next';
import { Badge } from 'react-bootstrap';
import { useAuth } from '../../authContext';
import { useEffect, useState } from 'react';
import { storage, ref, getDownloadURL } from '../../firebaseConfig';
import { cancelFriendRequest, friendRequest, friendRequestCheck } from '../../services/friendRequestHelper';
import { Link } from 'react-router-dom';

const FriendRequestHeader = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const [friendsRequest, setFriendsRequest] = useState([]);
    const [qtyFriendsRequest, setQtyFriendsRequest] = useState([]);

    useEffect(() => {
        friendRequest(user.uid, setFriendsRequest, setQtyFriendsRequest);
    }, [user]);

    return (
        <li className="nav-item">
            <a className="search-toggle iq-waves-effect" href="#">
                <i className="ri-group-line"></i>
                <Badge id='friendRequestQty' bg="dark" style={{ borderRadius: "100%", display: "initial" }}>
                    {qtyFriendsRequest}
                </Badge>
            </a>

            <div className="iq-sub-dropdown iq-sub-dropdown-large" style={{ width: "auto", height: "auto", minWidth: "33vh" }}>
                <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0 ">
                        <div className="bg-primary p-3">
                            <h5 className="mb-0 text-white">{t('home_friend_request')}<small className="badge  badge-light float-right pt-1">{qtyFriendsRequest}</small></h5>
                        </div>

                        {
                            friendsRequest.map((doc) => {
                                var getFriendRequestTarget = async function (doc) {
                                    var gsReference = await getDownloadURL(ref(storage, doc.profilePicture));
                                    //console.log(document.getElementById('profile-fr-' + doc.uid));
                                    if (document.getElementById('profile-fr-' + doc.uid) !== null) {
                                        const img = document.getElementById('profile-fr-' + doc.uid);
                                        img.setAttribute('src', gsReference);
                                    }
                                    return gsReference;
                                }

                                getFriendRequestTarget(doc);
                                return (
                                    <div className="iq-friend-request">
                                        <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div className>
                                                    <img id={'profile-fr-' + doc.uid} className="avatar-40 rounded" alt="profile" />
                                                </div>
                                                <div className="media-body ml-3">
                                                    <h6 className="mb-0 "><Link to={`/viewuser/${doc.uid}`}>{doc.usernameRequester}</Link></h6>
                                                    <p className="mb-0">{String(new Date(doc.date).yyyymmdd())}</p>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center">
                                                <button className="mr-2 btn btn-sm btn-primary rounded" onClick={() => friendRequestCheck(doc.uid, user.uid, "accepted")}>{t('friend_request_header_accept')}</button>
                                                <button className="btn btn-sm btn-danger rounded" onClick={() => cancelFriendRequest(doc.uid, user.uid)} >{t('friend_request_header_cancel')}</button>
                                            </div>
                                        </div>
                                    </div>
                                )


                            })
                        }
                        
                    </div>
                </div>
            </div>
        </li>
    );
}

export default FriendRequestHeader;