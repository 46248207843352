import { t } from "i18next";
import { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import DataTable from 'react-data-table-component';
import { getStudents } from "../services/userService";
import { MDetalleAlumno } from "../modal/MDetalleAlumno";

const Students = () => {
    const [list, setList] = useState([]);
    const [mostrarDetalle, setMostrarDetalle] = useState(false);
    const [infoAlumno, setInfoAlumno] = useState(null);

    const mostrarDetalleAlumno = (row) => {
        setMostrarDetalle(!mostrarDetalle);
        setInfoAlumno(row);
    }
    
    const COLUMNS = [
        { name: t("admin_students_firstName"), selector: row => <span title={row.firstName}>{row.firstName}</span> },
        { name: t("admin_students_lastName"), selector: row => <span title={row.lastName}>{row.lastName}</span> },
        { name: t("admin_students_email"), selector: row => <span title={row.email}>{row.email}</span> },
        {
            cell: row => (
                <>
                    <button className="btn btn-light mr-1" title="Detalle" onClick={() => mostrarDetalleAlumno(row)}><i class="fa fa-eye"></i></button>
                </>
            )
        }

    ];

    useEffect(() => {
        const loadStudents = async () => {
            var result = await getStudents();
            setList(result);
        };

        loadStudents();
    }, []);
    
    return (
        <Layout>
            <div className="header-for-bg">
                <div className="background-header position-relative">
                    <img
                        src="https://ibloom.com.mx/pangu/admin.jpg"
                        className="img-fluid w-100"
                        alt="header-bg"
                    />
                    <div className="title-on-header">
                        <div className="data-block">
                            <h2>Alumnos</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-page" className="content-page">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div className="iq-card-body">
                                    <div className="container">
                                        <div className="row">
                                            <div className="table-responsive">
                                                {list && list.length > 0 && <DataTable columns={COLUMNS} data={list} pagination paginationComponentOptions={{ rowsPerPageText: t("datatable_rows_PerPageText") }} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {infoAlumno && <MDetalleAlumno show={mostrarDetalle} onHide={()=> setMostrarDetalle(false)} infoAlumno={infoAlumno} />}
            </div>
        </Layout>
    );
}

export default Students;