//import { useTranslation } from 'react-i18next';
//import { DropLanguajes } from "../i18n/dropLanguajes";
const Privacy = () => {
	
	//const { t, i18n } = useTranslation();  <DropLanguajes t={t} i18n={i18n} />
	return (
		/*<Layout>*/
		<div id="privacy" className="estilo1">
		<div className="iq-top-navbar">
		<div className="iq-navbar-custom">
		  <nav className="navbar navbar-expand-lg navbar-light p-0">
			<div className="iq-navbar-logo d-flex justify-content-between">
				<a href="/">
					<img src="images/plogol.png" alt="Pángu" className="margintop15" />
				</a>	
			</div>
		  </nav>
		</div>
	  </div>
			<div className="container padding90">
				<div className="row">
					<div className="col">
					<h4>Política de Privacidad Sobre el Uso de la Información</h4>
					<p>Esta Aplicación, Página y Servicio Web y Aplicación Web (“Aplicación” en adelante) es propiedad de HOP IT DE MEXICO, S.A. DE C.V (“Responsable” en adelante).</p>
					<p>El Responsable de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, es el “Responsable” del tratamiento de los personales que recaba de Usted, como “Titular” de los mismos. El presente “Aviso de Privacidad Integral”, es el complemento de cualesquiera otros avisos de privacidad simplificados o cortos que el “Responsable” haya puesto a su disposición y que sirve para informarle respecto a las finalidades primarias y secundarias para las cuales se trataran sus datos personales.</p>
					<p>Domicilio del "Responsable":</p>
					<p>Hacienda Xalpa mz. 3 lt. 12 B depto. 104 B, Colonia Hacienda del Parque 1ª. Sección, 54769, Municipio de Cuautitlán Izcalli, Estado de México.</p>
					<p>Atención a consultas: info@pangulearning.com </p>
					<p>Datos personales recabados. Dependiendo de las finalidades para las que Usted nos otorgue sus datos personales, le solicitaremos las siguientes categorías: datos personales de identificación y de contacto, datos personales de pago y otros datos personales relacionados a los servicios que usted quiera adquirir.</p>
					<p>A través de esta Aplicación, se puede obtener diversa información:</p>
					<p>Toda la Información Agregada e Información Personal que se obtenga del Usuario por el uso de esta Aplicación, será tratada de manera confidencial, además de ser protegida contra cualquier uso indebido, o alteración.</p>
					<p>El Usuario reconoce y acepta que esta Aplicación no obtiene la Información Personal automáticamente, sino que requiere que el Usuario se la proporcione en forma directa a través de completar sus datos y enviarlos a esta Aplicación a través de esta aplicación o por otros medios digitales y que previo a recabar su información se le otorga el aviso de privacidad correspondiente en el cual se le indica el tratamiento que tendrán sus datos personales y la forma bajo la cual podrá ejercer sus derechos de acceso, rectificación, cancelación y oposición, de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</p>
					<p>Queda establecido que es decisión del Usuario, otorgar la información personal a esta Aplicación. </p>
					<p>Por lo anterior, el Usuario es responsable de la veracidad y confiabilidad sobre dicha información. </p>
					<p>Que para efectos de lo dispuesto en los artículo 16, 18 y 18bis de la Ley Federal de Protección al Consumidor, el usuario otorga su consentimiento expreso y aceptación para recibir información con carácter o fines mercadotécnicos o publicitarios. Del mismo modo, el Usuario faculta a esta Aplicación y su Responsable, de la forma más amplia que legalmente proceda, para utilizar la Información Personal por su propio medio o a través de sus proveedores de servicios para: </p>
					<ul>
						<li>Brindarle un mejor servicio</li>
						<li>Ofrecerle información de Pangu y Pangu Learning</li>
						<li>Ofrecerle información de eventos relacionados a Pangu y Pangu Learning </li>
						<li>Enviarle notificaciones relacionadas a Pangu y Pangu Learning </li>
						<li>Brindar información de la empresa y sus partners</li>
						<li>Realizar actividades de promoción </li>
					</ul>
					<p>Al usar esta Aplicación, el usuario acepta las cláusulas de la Política de Privacidad y se sujeta a los términos de la misma.</p>
					<p>Esta Aplicación ha establecido procedimientos físicos, electrónicos y administrativos para proteger los datos que los usuarios compartan con la empresa a través de esta la APP.</p>
					<p>Le informamos que la presente Aplicación tiene implementados candados de seguridad para proteger la información.</p>
					<p>Liberación de responsabilidad</p>
					<p>Esta Aplicación ni ninguna otra parte implicada en la creación o producción de la misma, es responsable por daños de ninguna especie, ya sea que deriven en forma directa o indirecta del uso de la misma. Asimismo, ni esta Aplicación ni el Responsable, ni ninguna otra parte implicada en la creación o producción de esta Aplicación, es responsable por daños ocasionados por virus que puedan infectar el equipo de cómputo u otra propiedad del USUARIO debido al acceso en forma directa, o enviado a través de un vínculo o link de esta Aplicación, por la transferencia de datos, materiales, texto, imágenes, videos o sonidos de esta Aplicación, a su celular, módem, memoria SD o disco duro.</p>
					<p>Esta Aplicación y su Responsable se reserva el derecho a modificar, suspender o cancelar esta Aplicación, las especificaciones de los componentes y sus contenidos, sin previo aviso y sin incurrir en ninguna responsabilidad. Por lo anterior, es obligación del USUARIO revisar habitualmente los términos y condiciones de esta Aplicación, a fin de conocer la versión más actualizada del mismo.</p>
					<p>Transferencias. De conformidad al artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podríamos realizar una transferencia de sus datos nacional o internacional, sin que sea necesario su consentimiento, a Autoridades Gubernamentales, Administrativas y/o Judiciales, municipales, estatales o federales, sociedades filiales, subsidiarias, controladoras o aquellas pertenecientes al mismo grupo de sociedades que operan bajo los mismos procesos y políticas internas, a Autoridades Sanitarias, o bien entes privados de servicios de salud, comerciantes ya sea personas físicas o morales, para dar cumplimiento a cualquiera de las finalidades señaladas en el presente aviso de privacidad, incluyendo aquellas que sean necesarias para cumplir con las leyes o que hubiesen sido legalmente exigidas para salvaguardar el interés público, para la procuración o administración de justicia, cuando sea necesaria para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial, cuando sea necesaria para la prevención o diagnóstico médico, gestiones y/o prestación de servicios sanitarios o médicos, cuando sea necesaria o resulten de las obligaciones de un contrato celebrado o por celebrarse, o finalmente, para dar mantenimiento o cumplimiento a relaciones jurídicas entre titular y Responsable.</p>
					<p>Fecha de actualización: 06 de junio de 2022</p>
					<p>Cualquier duda o aclaración respecto a la información contenida en esta Aplicación, deberá ser dirigida a los siguientes datos de contacto: </p>
					<p>Domicilio del "Responsable": </p>
					<p>Hacienda Xalpa mz. 3 lt. 12 B depto. 104 B, Colonia Hacienda del Parque 1ª. Sección, 54769, Municipio de Cuautitlán Izcalli, Estado de México.</p>
					<p>Atención a consultas: info@pangulearning.com</p>
					</div>
				</div>
			</div>
        </div>
		/*</Layout>*/
		);
}

export default Privacy;