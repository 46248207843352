import React from 'react'
import { useAuth } from '../../../authContext';
import { createImageFromInitials } from '../../../components/Utils';
import { useTranslation } from 'react-i18next';
import FriendList from '../../../components/chat/FriendList';

function BarSideChat({ friendData, setFriendData }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const myAvatar = user.photoURL ? user.photoURL : createImageFromInitials(50, user.displayName, '#50b5ff');

  return (
    <div className="col-lg-4 chat-data-left scroller" >
      <div className="chat-search pt-3 pl-3">
        <div className="d-flex align-items-center">
          <div className="chat-profile mr-3">
            <img src={myAvatar} alt="profile-pic" style={{ width: "50px" }} />
          </div>
          <div className="chat-caption">
            <h5 className="mb-0">{user.displayName}</h5>
          </div>
          <button type="submit" className="close-btn-res p-3"><i className="ri-close-fill" /></button>
        </div>
        <div id="user-detail-popup" className="scroller">
          <div className="user-profile">
            <button type="submit" className="close-popup p-3"><i className="ri-close-fill" /></button>
            <div className="user text-center mb-4">
              <div className="avatar m-0">
                <img src={myAvatar} alt="avatar" style={{ width: "50px" }}/>
              </div>
              <div className="user-name mt-4">
                <h4>{user.displayName}</h4>
              </div>
              <div className="user-desc">
                <p>{user.email}</p>
              </div>
            </div>
            <hr />
            <div className="user-detail text-left mt-4 pl-4 pr-4">
              <h5 className="mt-4 mb-4">{t("barSide_about_me")}</h5>
              {/* <p>Estudiante<a href=""></a></p> */}
              <h5 className="mt-3 mb-3">{t("barSide_status")}</h5>
              <ul className="user-status p-0">
                <li className="mb-1"><i
                  className="ri-checkbox-blank-circle-fill text-success pr-1" /><span>{t("barSide_online")}  </span>
                </li>
                <li className="mb-1"><i
                  className="ri-checkbox-blank-circle-fill text-danger pr-1" /><span>{t("barSide_offline")}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center mt-3 ml-3 pt-3'>
        <h5 className>{t('chat_message_label')}</h5>
      </div>
      <FriendList friendData={friendData} setFriendData={setFriendData} />
    </div>
  )
}
export default BarSideChat

