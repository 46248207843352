import { collection, doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { getUserByUid, onSnapshotUserByUid } from "./authHelper";

export const getUserFriends = async (friendList) => {
  let friends = [];
  for (const friend of friendList) {
    const frientData = await getUserByUid(friend.uid);
    if (frientData) {
      friends.push(frientData);
    }
  }
  return friends;
}

export const getFriends = async (uid, setFriends) => {
  var q = query(collection(db, "users", uid, "friends"));
  const unsubscribe = onSnapshot(q, async(querySnapshot) => {
    let uids = [];
    querySnapshot.forEach((doc) => {
      uids.push(doc.id);
    });

    if(uids.length > 0 ){
      var qfriends = query(collection(db, "users"), where("uid", "in", uids));
      const friendsUnsubscribe = onSnapshot(qfriends, async(snapshot) => {
        const friends = [];
        snapshot.forEach((doc) => {
          friends.push(doc.data());
        });
        setFriends(friends);
      });
      return () => friendsUnsubscribe();
    }else{
      setFriends([]);
    }
  });
  return () => unsubscribe();
}

export const isMyFriend = async(currentUser, friendId) => {
  let exists = false;
    try {
        const docRef = doc(db, "users", currentUser, "friends", friendId);
        const docSnap = await getDoc(docRef);
        exists  = docSnap.exists()
    } catch (error) {
        console.log("error isMyFriend", error);
    }
    return exists;
}

export const getFriendData = async(uid, setFriend) => {
  onSnapshotUserByUid(uid, setFriend);
}