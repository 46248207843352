import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { getUserByUid, updateUser } from "./authHelper";
import { createNotification } from '../services/notificationHelper';
import { t } from "i18next";
import moment from "moment";
const collectionName = "lessons";

export const createLesson = async (lessonData) => {
    let lesson = {};
    try {
        lesson = await addDoc(collection(db, collectionName), lessonData);
    } catch (error) {
        console.log("error createLesson", error);
    }
    return lesson;
}

export const getTeacherLessons = async (uid, setLessons) => {
    try {
        let data = [];
        const q = query(collection(db, collectionName), where("uid", "==", uid), where("isActive", "==", true));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const lesson = { id: doc.id, ...doc.data() };
            data.push(lesson);
        });
        const lessonWithAuthor = await getLessonWithAuthorName(data);
        setLessons(lessonWithAuthor);
    } catch (error) {

    }
}

export const getStudentLessons = async (uid, setLessons) => {
    let data = [];
    const enrolledList = await getEnrrolledLessonByUid(uid);
    try {
        await Promise.all(
            enrolledList.map(async (l) => {
                const lesson = await getLessonsById(l.lessonId);
                lesson.approved = l.status === "approved";
                if (lesson.approved) {
                    lesson.isScheduled = l.isScheduled;
                    data.push(lesson);
                }
            })
        );
        const lessonWithAuthor = await getLessonWithAuthorName(data);
        setLessons(lessonWithAuthor);
    } catch (error) {
        console.log("error getStudentLessons", error);
    }
}

export const getAllLessonsOfMyTeachers = async (uid) => {
    const data = [];
    try {
        const enrolledList = await getEnrrolledLessonByUid(uid);
        const teacherLessons = await getAllLessonsMyTeacher(uid);
        for (const lesson of teacherLessons) {
            const enrolledFound = enrolledList.find(e => {
                if (e.lessonId === lesson.id) {
                    return true;
                }
                return false;
            });
            lesson.enrolled = enrolledFound ? true : false;
            data.push(lesson);
        }
        const lessonWithAuthor = await getLessonWithAuthorName(data);
        return lessonWithAuthor;
    } catch (error) {
        console.log("error getAllLessons", error);
    }
    return data;
}

export const getLessonsById = async (lessonId) => {
    let data = {};
    try {
        const docRef = doc(db, collectionName, lessonId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            data = { id: docSnap.id, ...docSnap.data() };
        }
    } catch (error) {
        console.log("error getLessonsById", error);
    }
    return data;
}

export const updateLesson = async (lesson) => {
    const lessonRef = doc(db, collectionName, lesson.id);
    await updateDoc(lessonRef, lesson);
}

export const deleteLessonById = async (lessonId) => {
    const lessonRef = doc(db, collectionName, lessonId);
    await deleteDoc(lessonRef);
}

export const enroll = async (lesson, user) => {
    let result = { success: true };
    const userRef = `users/${user.uid}/lessons`;
    const lessonRef = `${collectionName}/${lesson.id}/students`;
    try {
        await setDoc(doc(db, userRef, lesson.id), {
            lessonId: lesson.id,
            status: "pending",
            isScheduled: false,
            createdAt: new Date()
        });

        await setDoc(doc(db, lessonRef, user.uid), {
            uid: user.uid,
            status: "pending",
            createdAt: new Date()
        });
    } catch (error) {
        result.success = false;
        result.error = error;
        console.log("error enroll", error);
    }
    return result;
}

export const enrollWithPaypal = async (lesson, user, paypalId, paypalStatus, amount) => {
    let result = { success: true };
    const userRef = `users/${user.uid}/lessons`;
    const lessonRef = `${collectionName}/${lesson.id}/students`;
    try {
        await setDoc(doc(db, userRef, lesson.id), {
            lessonId: lesson.id,
            status: "approved",
            isScheduled: false,
            paypalId: paypalId,
            paypalStatus: paypalStatus,
            paypalAmount: amount,
            createdAt: new Date()
        });

        await setDoc(doc(db, lessonRef, user.uid), {
            uid: user.uid,
            status: "accepted",
            paypalId: paypalId,
            paypalStatus: paypalStatus,
            paypalAmount: amount,
            createdAt: new Date()
        });
    } catch (error) {
        result.success = false;
        result.error = error;
        console.log("error enroll", error);
    }
    return result;
}

const getEnrrolledLessonByUid = async (uid) => {
    let lessons = [];
    try {
        const q = query(collection(db, `users/${uid}/lessons`));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            lessons.push({ id: doc.id, ...doc.data() })
        });
    } catch (error) {
        console.log("error getEnrrolledLessonByUid", error);
    }

    return lessons;
}

export const getStudentsByLesson = async (lessonId) => {
    let students = [];
    try {
        const q = query(collection(db, `lessons/${lessonId}/students`));
        const querySnapshot = await getDocs(q);
        let data = [];
        querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() })
        });
        
        students = await Promise.all(
            data.map(async (s) => {
                const { firstName, lastName } = await getUserByUid(s.uid);
                const studentWithName = { ...s, fullName: `${firstName} ${lastName}` }
                return studentWithName;
            })
        );

    } catch (error) {
        console.log("error getStudentsByLesson", error);
    }
    return students;
}

export const processLessonsNotification = async (uid, user) => {
    try {
        const enrolledList = await getEnrrolledLessonByUid(uid);
        const date = new Date();
        const [day, month, year] = [date.getDate(), date.getMonth(), date.getFullYear()];
        const startDate = new Date(year, month, day, 0, 0, 0).getTime();
        const endDate = new Date(year, month, day, 23, 59, 59).getTime();
        for (const item of enrolledList) {
            if (item.status === "approved") {
                const lesson = await getLessonsById(item.lessonId);
                const tempStartDate = lesson.startDate.toDate();
                const lStartDate = tempStartDate.getTime();
                const lEndDate = lesson.endDate.toDate().getTime();
                const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), tempStartDate.getHours(), tempStartDate.getMinutes(), tempStartDate.getSeconds())
                if (lStartDate <= endDate && lStartDate >= startDate) {
                    const diffHour = currentDate.getHours() - date.getHours();
                    if(date.getHours() <= currentDate.getHours() && diffHour <= 1){
                        await createClassNotification(uid, lesson, user, currentDate);
                    }
                } else if (lEndDate <= endDate && lEndDate >= startDate) {
                    const diffHour = currentDate.getHours() - date.getHours();
                    if(date.getHours() <= currentDate.getHours() && diffHour <= 1){
                        await createClassNotification(uid, lesson, user, currentDate);
                    }
                }
            }
        }
    } catch (error) {
        console.log("error processLessonsNotification", error);
    }

}

export const createClassNotification = async (uid, lesson, user, currentDate) => {
    const text = t("lesson_notification", { title: lesson.title, date: moment(currentDate).format("DD/MM/YYYY hh:mm:ss a") });
    await createNotification(uid, lesson.id, user, text, "class");
}

export const getMyTeachers = async (uid) => {
    let teachers = [];
    try {
        const q = query(collection(db, `users/${uid}/friends`));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const friend = doc.data();
            if (friend.friendRequest && friend.friendRequest.userType === "teacher" && friend.friendRequest.status === "accepted") {
                teachers.push({ id: doc.id })
            }
        });

    } catch (error) {
        console.log("error getMyTeachers", error);
    }
    return teachers;
}

export const getAllLessonsMyTeacher = async (uid) => {
    let lessons = [];
    try {
        const teachers = await getMyTeachers(uid);
        for (const teacher of teachers) {
            const q = query(collection(db, collectionName), where("isActive", "==", true), where("uid", "==", teacher.id));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                lessons.push({ id: doc.id, ...doc.data() })
            });
        }
    } catch (error) {
        console.log("error getAllLessonsMyTeacher", error);
    }
    return lessons;
}

export const updatedLessonStudent = async (lessonId, studentId, status, approved = false) => {
    let result = false;
    try {
        const lessonStudentRef = doc(db, `lessons/${lessonId}/students/${studentId}`);
        const studentRef = doc(db, `users/${studentId}/lessons/${lessonId}`);
        await updateDoc(lessonStudentRef, { ...status, updatedAt: new Date() });
        if (approved) {
            await updateDoc(studentRef, { status: "approved", updatedAt: new Date() });
        }
        result = true;
    } catch (error) {
        console.log("error acceptedStudent", error);
    }
    return result;
}

export const getAllLessonsOfMyOtherTeachers = async (uid) => {
    const data = [];
    try {
        const myTeachers = await getMyTeachers(uid);
        const teacherUid = myTeachers.map((t) => t.id);
        let q = query(collection(db, collectionName), where("isActive", "==", true));
        if (teacherUid.length > 0) {
            q = query(collection(db, collectionName), where("isActive", "==", true), where("uid", "not-in", teacherUid));
        }
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() })
        });
        const lessonsWithAuthorName = await getLessonWithAuthorName(data);
        return lessonsWithAuthorName;

    } catch (error) {
        console.log("error getAllLessons", error);
    }
    return data;
}

export const getLessonWithAuthorName = async (lessons) => {
    const lessonsWithAuthorName = await Promise.all(
        lessons.map(async (lesson) => {
            const { firstName, lastName } = await getUserByUid(lesson.uid);
            return { ...lesson, author: `${firstName} ${lastName}` }
        })
    );
    return lessonsWithAuthorName;
}

export const updateAuthorPayment = async (lesson) =>{
    const user = await getUserByUid(lesson.uid);
    if(user){
        let ganancia = lesson.price * 0.8;
        if(user.infoPagos){
            user.infoPagos.total += lesson.price;
            user.infoPagos.misganancias += ganancia;
            if(user.infoPagos.pagadas > 0){
                user.infoPagos.porpagar -= user.infoPagos.pagadas;
            }else{
                user.infoPagos.porpagar += ganancia;
            }
        }else{
            user.infoPagos = {
                total: lesson.price,
                misganancias: ganancia,
                pagadas: 0,
                porpagar: ganancia 
            }
        }
        await updateUser(user);
    }
}