import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { createComment } from '../../services/commentHelper';
import { createNotification } from '../../services/notificationHelper';

export const CreateComment = ({user, post, comments, setComments}) => {
    
    const [comment, setComment] = useState("");
    const { t } = useTranslation();

    const saveComment = async() => {
        const dataComment = {
            uid: user.uid,
            comment: comment,
            userName: user.displayName ? user.displayName : "",
            timestamp: new Date(),
            photoProfile: user.photoURL ? user.photoURL : "",
            isActive: true
        };

        const result = await createComment(post.uid, post.id, dataComment);
        if(result) {
            setComment("");
            setComments([{id:result.id, ...dataComment}, ...comments]);
            const text = `${user.displayName} ${t('post_comment')}`;
            await createNotification(post.uid, post.id, user, text, "comentario");
        } else {
            console.log('Error: |saveComment|');
        }

    }

    return (
        <div className="comment-text d-flex align-items-center mt-3">
            <input type="text" value={comment} onChange={(e) => setComment(e.target.value)} className="form-control rounded" placeholder={t('posts_comment_placeholder')} />
            {comment.length > 0 &&
                <a onClick={() => (saveComment())} className='btn btn-primary'><i className="ri-send-plane-2-line ml-1 pt-2"></i></a>
            }
        </div>
    )
}
