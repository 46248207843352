import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
const collectionName = 'subscription';

export const getSubscriptionList = async () => {
    let subscriptions = [];
    try {
        const q = query(collection(db, collectionName), where("active", "==", true), orderBy("order"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            subscriptions.push({ id: doc.id, ...doc.data() })
        });
    } catch (error) {
        console.log("error getSubscriptionList", error);
    }
    return subscriptions;
}

export const getSubscriptionId = async (sid) => {
    let subscription = false;
    try {
        const ref = doc(db, `subscription/${sid}`);
        const docSnapshot = await getDoc(ref);
        if (docSnapshot.exists()) {
            subscription = docSnapshot.data();
        }
    } catch (error) {
        console.log("error getSubscriptionById", error);
    }
    return subscription;
}

export const getSubscriptionById = async (uid, sid) => {
    let subscription = false;
    try {
        const ref = doc(db, `users/${uid}/subscriptions/${sid}`);
        const docSnapshot = await getDoc(ref);
        if (docSnapshot.exists()) {
            subscription = docSnapshot.data();
        }
    } catch (error) {
        console.log("error getSubscriptionById", error);
    }
    return subscription;
}

export const validateUserSubscription = async (uData) => {
    try {
        if (uData.subscription.isPremium) {
            // const currentSubscription = await getSubscriptionById(uData.uid, uData.subscription.sid);
            // if (currentSubscription) {
            //     const cDate = new Date();
            //     const endDate = currentSubscription.endDate.toDate();
            //     if (cDate.getTime() > endDate.getTime()) {
            //         await cancelPremium(uData.uid);
            //     }
            // }
        }

    } catch (error) {
        console.log("error validateUserSubscription", error);
    }
}

export const cancelPremium = async (uid) => {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, {
        "subscription.isPremium": false
    });
}

export const initSubscription = async (uid) => {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, {
        subscription: {
            isTrialActivated: false,
            isPremium: false
        }
    });
}

export const enablePremiumSubscription = async (uData, sid, type, orderId = "") => {
    const userRef = doc(db, "users", uData.uid);
    let userSubscription = { ...uData.subscription};
    userSubscription.isPremium = true;
    userSubscription.sid = sid;
    userSubscription.orderId = orderId;
    userSubscription.type = type;
    
    await updateDoc(userRef, {
        subscription: userSubscription
    });
}

export const activateSubscription = async (userData, subscription, startDate, subscriptionID, orderId) => {
    let result = { isSucces: false, id: undefined }
    try {
        const docRef = await addDoc(collection(db, `users/${userData.uid}/subscriptions`), {
            sid: subscription.id,
            price: subscription.price,
            type: subscription.type,
            startDate,
            subscriptionID,
            orderId
        });
        result.isSucces = true;
        result.id = docRef.id;
    } catch (error) {
        console.log("error activateSubscription", error);
    }
    return result;
}