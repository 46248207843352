import { useEffect, useState } from "react";
import { enroll, getAllLessonsOfMyTeachers } from "../../services/lessonsHelper";
import DataTable from 'react-data-table-component';
import moment from "moment";
import { useAuth } from "../../authContext";
import { useNavigate } from "react-router-dom";
import { LANGUAGELEVEL } from "../../utils/data";
import { useTranslation } from "react-i18next";
import { LessonPay } from "./LessonPay";
import { createNotification } from "../../services/notificationHelper";

const LessonListMyTeachers = () => {
    const { t } = useTranslation();
    const [lessons, setLessons] = useState([]);
    const { user } = useAuth();
    const navigate = useNavigate();
    const [filterLevel, setFilterLevel] = useState('all');
    const [showPay, setShowPay] = useState(false);
    const [currentLesson, setCurrentLesson] = useState(null);

    const filteredLessons = lessons.filter(item => {
        if (filterLevel === "all") {
            return item;
        } else {
            return item.level && item.level.includes(filterLevel)
        }
    });

    const attendee = async (lesson) => {
        if (lesson.price === 0) {
            const result = await enroll(lesson, user);
            if (result.success) {
                const text = `${t("lesson_enrollment_notification", { title: lesson.title }) }`;
                await createNotification(lesson.uid, lesson.id, user, text, "Solicitud");
                const lessonUpdated = lessons.filter(l => l.id !== lesson.id);
                setLessons(lessonUpdated);
            }
        }else if(lesson.price > 0){
            setShowPay(true);
            setCurrentLesson(lesson);
        }

    }

    const COLUMNS = [{ name: t("lesson_column_title"), selector: row => <span title={row.title}>{row.title}</span> },
    { name: t("lesson_column_author"), selector: row => <span title={row.author}>{row.author}</span> },
    { name: t("lesson_column_level"), selector: row => <span title={t(row.level)}>{t(row.level)}</span> },
    { name: t("lesson_column_description"), selector: row => <span title={row.description}>{row.description}</span> },
    { name: t("lesson_column_price"), selector: row => row.price, sortable: true },
    { name: t("lesson_column_startAt"), cell: row => (<span>{moment(row.startDate.toDate()).format('DD/MM/YYYY hh:mm:ss a')}</span>) },
    { name: t("lesson_column_endAt"), cell: row => (<span>{moment(row.endDate.toDate()).format('DD/MM/YYYY hh:mm:ss a')}</span>) },
    {
        cell: row => (
            <>
                {!row.enrolled && <button title="Inscribirse" className="btn btn-light mr-1" onClick={() => attendee(row)}><i class="ri-user-add-line"></i></button>}
            </>
        )
    }];

    const loadLessons = async (uid) => {
        await getAllLessonsOfMyTeachers(uid).then((lessons) => setLessons(lessons));
    }

    const closeLessonPay = () => {
        setShowPay(false);
    }

    useEffect(() => {
        loadLessons(user.uid);
    }, []);

    return (
        <div className="iq-card px-2">
            <div className="iq-card-header">
                <div className="row justify-content-between">
                    <div class="col-sm-12 col-md-6">
                        <h2 className="pt-2">{t("lesson_all")}</h2>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div className="d-flex float-right">
                            <button onClick={() => navigate("/lessons/my")} className="btn btn-primary mt-2">{t("lesson_title_my")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iq-card-body">
                <div className="containder">
                    <div className="row">
                        <div className="form-row mr-2">
                            <div className="form-group form-inline">
                                <label htmlFor="level" className="mr-2">{t("lesson_level")}</label>
                                <select id="level" name="level" className="form-control" onChange={(e) => setFilterLevel(e.target.value)}>
                                    <option value="all" selected>Todos</option>
                                    {LANGUAGELEVEL.map((level) => <option key={level.value} value={level.value}>{t(level.label)}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <DataTable columns={COLUMNS} data={filteredLessons} pagination paginationComponentOptions={{ rowsPerPageText: t("datatable_rows_PerPageText") }} />
                        </div>
                    </div>
                </div>
            </div>
            {showPay && <LessonPay show={showPay} onHide={closeLessonPay} lesson={currentLesson} />}
        </div>
    );
}

export default LessonListMyTeachers;