import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext";
import { getQuestions, markEvaluatedExcercise } from "../../services/exercisesHelper";
import { ShowSwalAlert } from "../Utils";

const SentenceCompletion = ({ exerciseId, keyAnswers }) => {
    const [questions, setQuestions] = useState([]);
    const [isEvaluate, setIsEvaluate] = useState(false);

    const { t } = useTranslation();
    const { user } = useAuth();
    const navigate = useNavigate();

    const loadQuestions = async () => {
        await getQuestions(exerciseId, true).then((questions) => {
            setQuestions(questions);
        });
    }

    useEffect(() => {
        loadQuestions();
    }, [exerciseId]);

    const checkAnwers = async () => {
        let answers = [];
        for (const item of Object.entries(keyAnswers)) {
            const key = item[0];
            const value = item[1];
            const el = document.getElementById(key);
            if (el) {
                if (el.value === "") {
                    el.style.border = "1px solid red";
                }
                else if (el.value.toUpperCase() === value.toUpperCase()) {
                    answers.push({ id: key, value: el.value });
                    el.style.border = "1px solid green";
                } else {
                    answers.push({ id: key, value: el.value });
                    el.style.border = "1px solid red";
                }
            }
        }
        if (answers.length === 0 || answers.length < questions.length) {
            ShowSwalAlert({ icon: "info", title: t('exercises_all_answers_required') });
            return;
        }

        try {
            await markEvaluatedExcercise(user.uid, exerciseId);
        } finally {
            setIsEvaluate(!isEvaluate);
        }
    }

    return (
        <div className="col-sm-12">
            {questions.map((question, qkey) => {
                return (
                    <div key={qkey} className="col-sm-12 mb-2">
                        <div dangerouslySetInnerHTML={{ __html: question.pregunta1 }}></div>
                    </div>
                );
            })
            }
            {!isEvaluate ? <button onClick={() => checkAnwers()} className="btn btn-primary">{t("exercises_verify")}</button> : <button onClick={() => navigate("/exercises/all")} className="btn btn-primary mt-2"><i class="ri-arrow-go-back-fill"></i></button>}
        </div>
    );
}

export default SentenceCompletion;