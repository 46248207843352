import Layout from "../layout/Layout";

const Nop = ({ titulo }) => {
    return (
        <Layout>
            <div className="header-for-bg">
                <div className="background-header position-relative">
                    <img
                        src="https://ibloom.com.mx/pangu/admin.jpg"
                        className="img-fluid w-100"
                        alt="header-bg"
                    />
                    <div className="title-on-header">
                        <div className="data-block">
                            <h2>{titulo}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-page" className="content-page">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div className="iq-card-body">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Nop;