import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getLikebyCommentId, createCommentLike, deleteCommentLike, previousCommentLike } from '../../services/likeHelper';
import { createNotification, previousNotification } from '../../services/notificationHelper';
import { FALLBACK_PROFILE_PICTURE } from '../../services/userHelper';

export const Comments = ({ user, post, comment }) => {
    const [likes, setLikes] = useState([]);
    const [disableLike, setDisableLike] = useState(false);
    const { t } = useTranslation();
    const times = comment.timestamp.seconds ? new Date(comment.timestamp.seconds * 1000).toDateString() : comment.timestamp.toDateString();
    const getLikes = async () => {
        await getLikebyCommentId(user.uid, post.id, comment.id)
            .then((data) => {
                setLikes(data);
            });
    }

    useEffect(() => {
        getLikes();
    }, []);

    const addLike = async () => {
        if (!disableLike) {
            setDisableLike(true);
            const like = {
                uid: user.uid,
                nameUser: user.displayName ? user.displayName : 'Anonymus',
                timestamp: new Date()
            }
            const isExist = await previousCommentLike(post.uid, post.id, comment.id, user.uid);
            if (isExist && isExist.length > 0) {
                await deleteCommentLike(user.uid, post.id, comment.id, isExist[0].id)
                const cleanLikes = likes.filter(li => li.id !== isExist[0].id);
                setLikes(cleanLikes);
                await previousNotification(comment.uid, comment.id, setDisableLike);
            } else {
                const result = await createCommentLike(post.uid, post.id, comment.id, like);
                if (result) {
                    setLikes([{ id: result.id, like }, ...likes]);
                    const text = `${user.displayName} ${t('comment_like')}`;
                    const isNotify = await createNotification(comment.uid, comment.id, user, text);
                    if (isNotify) {
                        setDisableLike(false);
                    }
                } else {
                    console.log('NO SE GUARDO EL LIKE');
                }
            }
        }
    }

    return (
        <li className="mb-2">
            <div className="d-flex flex-wrap">
                <div className="user-img">
                    <img onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = FALLBACK_PROFILE_PICTURE;
                    }}
                    src={comment.photoProfile && comment.photoProfile?.length >= 0 ? comment.photoProfile : FALLBACK_PROFILE_PICTURE} alt="userimg" className="avatar-35 rounded-circle img-fluid" />
                </div>
                <div className="comment-data-block ml-3">
                    <strong>{comment.userName}</strong> <span>{times}</span>
                    <p className="mb-0">{comment.comment}</p>
                    <div className="d-flex flex-wrap align-items-center comment-activity">
                        <span onClick={() => addLike()}>
                            <img src="images/icon/01.png" className="img-fluid" alt="" /> {likes.length > 0 ? '' : t('posts_likes')}
                            {likes.length > 0 ? `${likes.length}` : ''}
                        </span>
                        {/* <a href="javascript:void();">reply</a>
                    <a href="javascript:void();">translate</a> */}
                    </div>
                </div>
            </div>
        </li>
    )
}
