import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../authContext";
import { FALLBACK_PROFILE_PICTURE, getFriendSuggestion } from "../../services/userHelper";
import { Card, Col, Image, Row } from 'react-bootstrap';
import { getUserByUid } from "../../services/authHelper";
import { sendFriendRequest } from "../../services/friendRequestHelper";
import { createVideocall } from "../../services/videocallHelper";
import { Link } from "react-router-dom";

const FriendSuggestion = () => {
    const { t } = useTranslation();
    const { user, userData, setUserData } = useAuth();
    const [users, setUsers] = useState([]);
    const nameUserSession = user.displayName;
    const [profilePicture, setProfilePicture] = useState([]);
    const forceUpdate = (() => getFriendsP());
        
    const getFriendsP = async function (uid) {
        try {
            var userSession = await getUserByUid(uid);
            setUserData(userSession);
            var friendsSuggestion = await getFriendSuggestion(userSession);
            setUsers(friendsSuggestion);
            setProfilePicture(userSession.profilePicture);
        } catch (e) {
            console.log("Error,", e);
        }
    }

    const onVideoCall = async (userTo) => {
        const roomUid =  await createVideocall({uidFrom: user.uid, uidTo: userTo, callerName: user.displayName});
        if(roomUid){
            window.open(`/videocall/${roomUid}`);
        }
    }

    useEffect(() => {
        getFriendsP(user.uid);
    }, [user.uid]);

    return (
        <>
            <Card id="card-fr-suggest" className="text-center" >
                <Card.Body style={{ borderBottom: "1px solid var(--iq-border-light)", color: "black" }}> <h5>{t('people_know_title')}</h5></Card.Body>
                <Card.Body>
                    <Card.Title style={{ color: "#7FADD4" }}> {t('people_know_subtitle')}</Card.Title>
                    <Card.Text>
                        {
                            users.map((uData, idx) => {
                                // console.log("uData", uData);
                                let x = (
                                    <Row className="p-2" key={idx}>
                                        <Col xs={3} >
                                            <Link to={`/viewuser/${uData.uid}`}>
                                            <Image onError={({currentTarget}) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = FALLBACK_PROFILE_PICTURE;}
                                            } src={uData.profilePicture && uData.profilePicture?.length >= 0  ? uData.profilePicture : FALLBACK_PROFILE_PICTURE } alt="story-img" className="img-fluid img-adapt" roundedCircle="true" 
                                            style={{ width: "50px", height: "50px", border: "1px solid #ddf4fa" }}></Image>
                                            </Link>
                                        </Col>
                                        <Col xs={6} className="d-flex align-items-center justify-content-center">
                                            <Link to={`/viewuser/${uData.uid}`}><h6>{uData.name}</h6><span>{t(uData.userType)}</span></Link>
                                        </Col>
                                        <Col xs={3} className="d-flex align-items-center justify-content-center ">
                                            <button onClick={() => onVideoCall(uData.uid)} className="chat-icon-videon iconoazul d-none">
                                                <i class="ri-vidicon-line"></i>
                                            </button>
                                            &nbsp;
                                            <a id={"suggestion" + uData.uid} href="/#" onClick={() => sendFriendRequest(uData.uid, user.uid, uData.name, nameUserSession, profilePicture, uData.userType, uData.profilePicture, userData.userType)} className="chat-icon-video iconoazul">
                                                <i class="ri-user-add-fill"></i>
                                            </a>
                                            <a id={"check" + uData.uid} href="/#" className="chat-icon-video" style={{ width: "100vh", display: "none" }}>
                                                <i class="ri-user-follow-fill"></i>
                                            </a>
                                        </Col>

                                    </Row>
                                );


                                return x;

                            })
                        }
                    </Card.Text>
                </Card.Body>
                <Card.Body style={{ borderTop: "1px solid var(--iq-border-light)" }} className="text-muted"><a id="updateSuggestionComponent" href="/#" onClick={() => forceUpdate()}> {t('people_know_footer')} </a></Card.Body>
            </Card>
        </>
    );
}

export default FriendSuggestion;