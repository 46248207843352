export const en_US = {
  translation: {
    change_language_es: "Spanish",
    change_language_en: "English",

    language_spanish: "Spanish",
    language_english: "English",
    language_french: "French",
    language_german: "German",

    //VALIDATIONS
    validations_invalid: "Invalid field",
    validations_required: "Required field",
    validations_invalidType: "You must be a {{type}}",
    validations_email: "Invalid email",
    validations_stringMin: "Must be at least {{min}} characters",
    validations_stringMax: "Maximum {{max}} characters",
    validations_age: "You must be over 18 years old",
    validations_termsOfUse: "You must accept the terms of use",
    validations_file_not_allowed: "File not allowed.",
    validations_max_file_size: "Maximum file size is {{max}} MB",
    validations_password_confirm: "Passwords does not match",

    //ERRORS
    error_something_wrong: "Something went wrong.",
    
    // BUTTONS
    button_close: "Close",
    button_accept: "Accept",
    button_send: "Send",

    // LOGIN
    login_friend: "Found new friends",
    login_connect: "Help to connect and share with more people in your life.",
    login_message: "Type your email and password.",
    login_title: "Log in",
    login_email: "Email",
    login_password: "Password",
    login_signin: "LogIn",
    login_registered: "You don't have an account?",
    login_signup: "Sign up",
    login_recover_pass: "Forgot your password?",
    login_recover_pass_title: "Recover your password",
    login_recover_pass_body:
      "Please enter your email to recover your password.",
    login_user_pass_wrong: "Invalid username or password.", 
    login_something_wrong: "Something went wrong, please try again.", 

    // REGISTER
    register_friend: "Found new friends.",
    register_connect:
      "Help to connect and share with more people in your life.",
    register_title: "New user",
    register_email: "Email",
    register_email_taken: "The email has already been taken",
    register_firstName: "First name",
    register_lastName: "Last name",
    register_password: "Password",
    register_password_confirm: "Password confirm",
    register_utype: "Select your profile",
    register_p: "Teacher",
    register_e: "Student",
    register_date_dob: "Date of birth",
    register_gender: "Gender",
    register_native_lang: "Native language",
    register_target_lang_t: "Teaching language",
    register_target_lang_s: "Learning language",
    register_terms_of_use: "Terms of use",
    register_terms_separator: "and",
    register_privacy: "privacy",
    register_signin: "Register",
    register_registered: "Do you already have an account?",
    register_signup: "Log in",

    // HOME
    home_friend_request: "Friend request",
    home_create_post: "Create post",
    home_type_here: "Type something...",
    home_search: "Type here to search",
    home_image_video: "Image/Video",
    //FriendRequest Header Buttons
    friend_request_header_cancel: "Reject",
    friend_request_header_accept: "Accept",
    student: "Student",
    teacher: "Teacher",

    //PEOPLE KNOW COMPONENT
    people_know_title: "People you may know",
    people_know_subtitle: "You may be interested in connecting with ...",
    people_know_footer: "Last updated 1 min. ago",

    // NOTIFICATION
    notification_title: "Notifications",
    notification_no_messages: "You don't have notifications",
    notification_goto_class: "Go to class",
    notification_goto_lesson: "See more",

    // SIDEBAR
    sidebar_home: "Home",
    sidebar_profile: "Profile",
    sidebar_friends: "Friends",
    sidebar_notifications: "Notifications",
    sidebar_friend_request: "Friend request",
    sidebar_chat: "Chat",
    sidebar_calendar: "Calendar",
    sidebar_lessons: "Lessons",
    sidebar_exercises: "Exercises",
    sidebar_diagnostic: "Diagnostic test",
    sidenar_courses: "Content",

    // POSTS
    posts_create_title: "Create post",
    posts_title_post: "Type title...",
    posts_type_here: "Type something...",
    posts_comments: "Comments",
    posts_show_comments: "Show Comments",
    posts_comment_placeholder: "Create Comment...",
    posts_likes: "Like",
    posts_delete_post: "Delete post",
    post_like: "liked your post",
    post_comment: "commented your post",
    post_confirm_delete: "Are you sure you want to delete this post?",

    // COMMENTS
    comment_like: "liked your comment",

    //PROFILE
    profile_telephone: "Telephone",
    profile_user_type: "User type",
    profile_profession: "Profession",    
    profile_basic_information: "Basic information",
    profile_hobby: "Hobby",
    profile_native_language: "Native language",
    profile_country_of_origin: "Country of origin",
    profile_country_residence: "Country residence",
    profile_gender: "Gender",
    profile_update: "Update",
    profile_aboutme_tab: "About me",
    profile_aboutme_description: "Description",
    profile_aboutme_cv: "CV",
    profile_aboutme_videolink: "Video link",
    profile_social_tab: "Social network",
    profile_payment_tab: "Payment",
    profile_subscriptions_tab: "Subscriptions",
    profile_subscriptions_cancel: "Cancel",
    profile_subscriptions_empty: "Without subscriptions",
    profile_payment_bank: "Bank",
    profile_payment_clabe: "Clabe",

    //PERFIL
    perfil_publicaciones: "Publications",
    perfil_seguidores: "Followers",
    perfil_seguidos: "Followed",
    perfil_publicacion: "Create post",
    perfil_contenido: "Write something here",
    perfil_comentarios: "Comment",
    perfil_gusta: "Like",
    perfil_traducir: "Translate",
    perfil_responder: "Reply",
    perfil_compartir: "Share",
    perfil_tab1: "Timeline",
    perfil_tab2: "Information",
    perfil_visit: "Visit",

    //CHAT
    chat_search: 'Search',
    chat_send: 'Send',
    chat_message_placeholder: 'Write a message here',
    chat_message_label: 'Messages',
    chat_start: "Start conversation",
    chat_details_name: "Name",
    chat_details_tel: "Telephone",
    chat_details_birth: "Birthday",
    chat_details_gender: "Gender",
    chat_details_language: "Language",
    barSide_about_me: "About me",
    barSide_status: "Status",
    barSide_online: "Online",
    barSide_offline: "Offline",
    
    //CALENDARIO
    calendario_add: "Add event",
    calendario_edit: "Edit event",
    calendario_titulo: "Title",
    calendario_descripcion: "Description",
    calendario_inicio: "Start date",
    calendario_final: "End date",
    calendario_prioridad: "Select priority",
    c_startDate: "Start",
    c_endDate: "End",
    c_prioridad1: "Priority 1",
    c_prioridad2: "Priority 2",
    c_prioridad3: "Priority 3",
    c_prioridad4: "Other",
    c_title: "Calendar and events",
    c_evento:"Event",
    c_title_event: "Today's events",
    c_c:"to",
    c_classification: "Classification",
    c_events_today: "No events for today",

    //Friends
    friends_label_principal: 'Friend list',
    friends_button_view_profile: 'Show profile',
    friends_button_remove_friend: 'Remove',
    friends_alert_delete_title: 'Are you sure?',
    friends_alert_delete_info: 'The action cannot be reversed later!',
    friends_alert_delete_confirm_button: 'Yes, remove it!',
    friends_alert_delete_cancel_button: 'Cancel',
    friends_alert_delete_success_title: 'Removed!',
    friends_alert_delete_success_info: 'The friend was deleted',
    friends_add_friend: 'Add to my friends',
    friends_delete_friend: 'Remove from my friends',
    friends_request_send: 'Request sent',
    friends_accept_friend: 'Accept friend',

    //LESSONS
    lesson_add: "New class",
    lesson_add_title: "Title",
    lesson_add_level: "Level",
    lesson_add_description: "Description",
    lesson_add_start: "Start",
    lesson_add_end: "End",
    lesson_add_price: "Price",
    lesson_add_save: "Register",
    lesson_add_notification_free: "Only 30 minute classes can be created",
    lesson_update: "Update",
    lesson_title_my: "My classes",
    lesson_my_teachers: "My teachers",
    lesson_my_others_teachers: "Other teachers",
    lesson_start_class: "Start class",
    lesson_edit_class: "Edit class",
    lesson_delete_class: "Delete class",
    lesson_manage_class: "Manage class students",
    lesson_schedule_class: "Schedule class",
    lesson_available_lessons: "Available lessons",
    lesson_all: "All lessons",
    lesson_language_all: "All",
    lesson_level: "Level",
    lesson_send_friend_request: "Send friend request",
    lesson_column_title: "Title",
    lesson_column_author: "Author",
    lesson_column_description: "Description",
    lesson_column_level: "Level",
    lesson_column_price: "Price",
    lesson_column_startAt: "Start",
    lesson_column_endAt: "End",
    lesson_alert_delete_title: "Confirm operation",
    lesson_alert_acction_delete: "Delete",
    lesson_notification: " You have {{title}} class at {{date}}",
    lesson_enrollment_complete: "Enrollment complete",
    lesson_enrollment_notification: "You have a registration request for {{title}}",
    lesson_class_collected: "Collected",
    lesson_class_paid: "Paid",

    //EXERCISES
    exercises_title: "Exercises",
    exercises_multiplechoices_option: "Multiple choices",
    exercises_truefalse_option: "True/False",
    exercises_tie_in: "Tie in",
    exercises_sentence_completion: "Sentence completion",
    exercises_column_title: "Title",
    exercises_column_level: "Level",
    exercises_column_type: "Type",
    exercises_column_resolve: "Resolve",
    exercises_verify: "Verify",
    exercises_completed: "Completed",
    exercises_completed_yes: "Yes",
    exercises_completed_no: "No",
    exercises_filter_all: "All",
    exercises_all_answers_required: "You must answer all questions",
    exercises_question: "Question",
    exercises_answer: "Answer",
    exercises_options: "Options",

    //TESTS
    diagnostictests_title: "Diagnostic tests",
    diagnostictests_column_title: "Title",
    diagnostictests_column_level: "Level",
    diagnostictests_column_questions: "Questions",
    diagnostictests_column_start: "Start diagnostic test",
    diagnostictests_column_completed: "Completed",
    diagnostictests_column_score: "Score",
    diagnostictests_completed_yes: "😀",
    diagnostictests_completed_No: "😢",
    diagnostictests_compute_level: "Calculate level",
    diagnostictests_level_result: "Your level is",
    diagnostictests_verify: "Verify",
    diagnostictests_total_questions: "Total questions",
    diagnostictests_level_score: "Correct answers",
    diagnostictests_level_wrong_answers: "Incorrect answers",

    //Courses
    courses_main_title: "Content",
    courses_all_levels: "All",
    courses_no_records_found: "There are no records to show",
    courses_download: "Download",

    //Header
    header_edit_profile: "Edit profile",
    header_modify_your_data: "Modify your data",
    header_logout: "Log out",
    
    //english levels
    a1: "A1: Initiation level",
    a2: "A2: Basic level",
    b1: "B1: Intermediate level",
    b2: "B2: Upper intermediate level",
    c1: "C1: Advanced level",
    c2: "C2: Very advanced level",

    //SEARCHBAR
    searchbar_view_profile: "View profile",

    //DataTable
    datatable_rows_PerPageText: "Rows per page:",

    //GENERAL
    confirm_delete_yes: "Yes",
    confirm_delete_No: "No",

    //Subscription
    subscription_trial: "Trial",
    subscription_monthly: "Monthly",
    subscription_biyearly: "Biyearly",
    subscription_yearly: "Yearly",
    subscription_days: "days",
    subscription_type_monthly: "Monthly subscription",
    subscription_type_biyearly: "Biyearly subscription",
    subscription_type_yearly: "Yearly subscription",
    subscription_activate: "Subscription activated",
    subscription_canceled: "Subscription canceled",
    subscription_pay_goback: "Go back",
    subscription_try_it: "Try",
    subscription_buy_it: "Buy",
    subscription_continue_free: "Continue free",
    subscription_pay_title: "Be part of the premium community", 
    subscription_pay_description: "Unlock all features: video calls between friends, paid classes with no time limit and free diagnostic test",

    //Admin
    admin_login_access_not_allowed: "You do not have access to this resource"
  }
}
