import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuth } from "../../authContext";

const Header = () => {
  const { t } = useTranslation();
  const { user, onSignOut } = useAuth();

  return (
    <div className="iq-top-navbar">
      <div className="iq-navbar-custom">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <div className="iq-navbar-logo d-flex justify-content-between">
            <Link to="/">
            <img src="https://ibloom.com.mx/pangu/hame.svg" alt="Pángu" />
            </Link>
            <div className="iq-menu-bt align-self-center">
              <div className="wrapper-menu iq-waves-effect">
                <div className="main-circle">
                  <i className="ri-menu-line" />
                </div>
              </div>
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-label="Toggle navigation"
          >
            <i className="ri-menu-3-line" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-list">
              <li>
                <a href="#nop" className="search-toggle iq-waves-effect d-flex align-items-center">
                  <i className="ri-arrow-down-s-fill" />
                </a>
                <div className="iq-sub-dropdown iq-user-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0 ">
                      <div className="bg-primary p-3 line-height">
                        <h5 className="mb-0 text-white line-height">
                          {user?.displayName}
                        </h5>
                        <span className="text-white font-size-12">
                          {user?.email}
                        </span>
                      </div>
                      <div className="d-inline-block w-100 text-center p-3">
                        <a
                          onClick={onSignOut}
                          className="bg-primary iq-sign-btn"
                          role="button"
                        >
                          {t("header_logout")}
                          <i className="ri-login-box-line ml-2" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
