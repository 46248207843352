import { Modal } from "react-bootstrap";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useAuth } from "../../authContext";
import { enrollWithPaypal, updateAuthorPayment } from "../../services/lessonsHelper";
import { ShowSwalAlert } from "../Utils";
import { useTranslation } from "react-i18next";

export const LessonPay = (props) => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const initialOptions = {
        "client-id": "AZmSK2-_R0xb7nJTjYom2dv5vI24dnkulNbx6yFrw0BhHEHWsljkk5yWkyzDsgYfVKdzh9ClDuu8Qmzh",
        currency: "MXN",
        intent: "capture"
    };

    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: `Pangu - ${props.lesson?.title}`,
                    amount: {
                        value: props.lesson?.price,
                    },
                },
            ],
        });
    }

    const onApprove = async (data, actions) => {
        const order = await actions.order.capture();
        if (order.status === 'COMPLETED' || order.status === 'PENDING') {
            var transaction = order.purchase_units[0].payments.captures[0];
            await updateAuthorPayment(props.lesson);            
            const result = await enrollWithPaypal(props.lesson, user, order.id, order.status, transaction.amount.value);
            if (result.success) {
                ShowSwalAlert({icon: 'success', title: t('lesson_enrollment_complete')});
                window.location.reload();
            }
        }else{
            props.onHide();
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{props.lesson?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.lesson && <PayPalScriptProvider options={initialOptions}>
                        <h4 className="text-center">Total: ${props.lesson.price}</h4>
                        <PayPalButtons
                            style={{ layout: "vertical" }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                        />
                    </PayPalScriptProvider>}
                </Modal.Body>
            </Modal>
        </>
    );
}