import moment from "moment";
import { useState } from "react";
import { Button, Accordion } from "react-bootstrap";
import { Pencil, Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { deleteEvent } from "../../services/calendarHelper";
import { UpdateEvents } from "./EditEvent";

const EventList = ({ currentEvents }) => {
    const { t } = useTranslation();
    const [event, setEvent] = useState(null);
    const [showEdit, setShowEdit] = useState(false);

    const editEvent = (event) => {
        setEvent(event);
        setShowEdit(!showEdit);
    };

    return (
        <>
            <div className="iq-card">
                <Accordion flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{t("c_title_event")}</Accordion.Header>
                        <Accordion.Body>
                            <ul className="m-0 p-0 today-schedule">
                                {currentEvents.map((event, i) => {
                                    return (
                                        <li key={event.id}>
                                            <div className="schedule-icon">
                                                <i className="ri-check-line" style={{ backgroundColor: `${event.color}` }} />
                                                <span>{t("c_evento")}: {event.title}</span>
                                            </div>
                                            <div className="schedule-text">
                                                <span>{t("c_startDate")}: {moment(event.start).format("h:mm:ss a")}</span>
                                                <span>{t("c_c")}</span>
                                                <span>{t("c_endDate")}: {moment(event.end).format("h:mm:ss a")}</span>
                                            </div>
                                            <div className="schedule-text" style={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                                alignItems: "center",
                                            }} >
                                                <Button onClick={(evt) => { evt.preventDefault(); editEvent(event); }} variant="warning">
                                                    <Pencil />
                                                </Button>
                                                <Button onClick={() => deleteEvent(event.id)} variant="danger">
                                                    <Trash />
                                                </Button>
                                            </div>
                                        </li>
                                    );
                                })}
                                {currentEvents.length === 0 && <li>{t("c_events_today")}</li>}
                            </ul>
                            
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            {event && <UpdateEvents event={event} show={showEdit} onHide={() => { setShowEdit(!showEdit); setEvent(null); }} />}
        </>
    )
}

export default EventList;